import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CreateBestClips2 from "./CreateBestClips2";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../../../config";
import axios from "axios";
import Slider from "react-slick";
import BestclipSearch from "./BestclipSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const CreateBestClips1 = ({ showCreateBestClipsPopup1, setShowCreateBestClipsPopup1, location, setLocation }) => {

  const [bestClips, setBestClips] = useState([]);
  const [category, setCategory] = useState([])
  const [show, setShow] = useState(false)
  const [categoryData, setcategoryData] = useState([])
  const [filteredCategory, setfilteredCategory] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  const inputRef = useRef(null);

  // It will open choose best clips section on click
  const handleClickBestClips = () => {
    inputRef.current.click();
  };

  // To get selected file of create best clips popup
  const handleSelectBestClips = async (e) => {
    setBestClips(e.target.files);
  };


  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
  };
  const handleData = (parentType) => {
    const filteredData = categoryData.filter(item => item.parentCategoryName === parentType);
    setfilteredCategory(filteredData)
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.post(`${BASEAPIURL}/post/getBestclipCategories`);
      const categoryData = response.data.list;
      setcategoryData(categoryData)
      const parentCategories = [];
      categoryData.forEach(item => {
        if (!parentCategories.includes(item.parentCategoryName)) {
          parentCategories.push(item.parentCategoryName);
        }
      });

      setCategory(parentCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories().then(() => handleData())
  }, []);

  const handleClose = () => {
    setShowCreateBestClipsPopup1(false);
    setBestClips([])
    setShow(false)
  }

  return (
    <>
      <section>
        {/* Create Reels Popup 1 */}
        <section>
          <Modal
            show={showCreateBestClipsPopup1}
            onHide={() => handleClose()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <div style={{ textAlign: "center", width: '100%' }}>
                <h5>Create Bestclip</h5>
              </div>
            </Modal.Header>
            <Modal.Body>
              <section id="create-reels-1" className="createClips">
                <div>
                  <p className="fw-bold fs-14 text-center">
                    Best Clip Category
                  </p>

                  <Slider {...settings} className="bestClipCategory">
                    {category.map((e, i) => (
                      <div key={i} onClick={() => { setShow(!show); handleData(e); }}>
                        <span style={{ border: `${selectedCategory && selectedCategory.parentCategoryName === e ? "2px solid #64A6F0" : "black"}` }} className=" cp">{e}</span>
                      </div>
                    ))}
                  </Slider>
                </div>

                {selectedCategory &&

                  <div className="d-flex align-items-center gap-3 my-2">

                    <div className="fw-bold fs-14">
                      <span>{selectedCategory.childCategoryImage} {selectedCategory.parentCategoryName} <span className="text-secondary">|</span> {selectedCategory.childCategoryName}</span>
                    </div>

                    <span className="cancel_category" onClick={() => setSelectedCategory(null)}>
                      <FontAwesomeIcon icon={faClose} size="15px" />
                    </span>
                  </div>
                }

                {show ?
                  <BestclipSearch  searchList={filteredCategory} setShow={setShow} setSearchList={setfilteredCategory} setSelectedCategory={setSelectedCategory} />
                  :
                  bestClips.length > 0 ?
                    <CreateBestClips2 setBestClips={setBestClips} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory}
                      bestClips={bestClips}
                      setShowCreateBestClipsPopup1={setShowCreateBestClipsPopup1}
                      location={location} setLocation={setLocation}
                    />
                    :

                    <div className="uploadBestClips"
                      onClick={handleClickBestClips}
                    >
                      <form>
                        <label htmlFor="reels"></label>
                        <input
                          type="file"
                          accept="video/*"
                          id="bestClips"
                          name="bestClips"
                          ref={inputRef}
                          onChange={handleSelectBestClips}
                          style={{ display: "none" }}
                        />
                        <img src={`${BASE_URL_STATIC}/upload.png`} alt="upload" />
                      </form>

                      <h4>Upload</h4>
                      <p style={{ color: "gray" }}>
                        Select a video from your gallery and share
                      </p>
                      <p style={{ color: "black" }}>{"(Max 5 min)"}</p>
                    </div>
                }
              </section>
            </Modal.Body>
          </Modal>
        </section>

        {/* Create Best Clips 2 */}

      </section>
    </>
  );
};

export default CreateBestClips1;
