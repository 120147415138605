import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";

const AddNewAddress = ({ showAddNewAddressPopup, setShowAddNewAddressPopup }) => {

  const [category, setCategory] = useState('');
  const [house, setHouse] = useState('');
  const [areaOrStreet, setAreaOrStreet] = useState('');
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const handleAddAddress = () => {
    // console.log('category : ' + category);
    // console.log('house : ' + house);
    // console.log('areaOrStreet : ' + areaOrStreet);
    // console.log('pincode : ' + pincode);
    // console.log('city : ' + city);
    // console.log('state : ' + state);

    setCategory('');
    setHouse('');
    setAreaOrStreet('');
    setPincode('');
    setCity('');
    setState('');
  };

  return (
    <>
      <Modal show={showAddNewAddressPopup} onHide={() => setShowAddNewAddressPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h4 className='text-center mb-3'>Add New Address</h4>

            <section className='overflow-y-scroll scrollbar-hidden pe-2 pb-3' style={{ maxHeight: '70vh' }}>
              <form>
                {/* Address Category */}
                <div>
                  <label htmlFor="category" className='bg-primary text-white px-3 py-2 rounded-pill'>Address Category</label>

                  <select className="form-select rounded-pill ms-2 mb-3" id='category' name='category' value={category} onChange={(e) => setCategory(e.target.value)} aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="Home">Home</option>
                    <option value="Office">Office</option>
                    <option value="Partner">Partner</option>
                  </select>
                </div>

                {/* House No. */}
                <label htmlFor="house" className='bg-primary text-white px-3 py-2 rounded-pill'>House No.</label>
                <input type="text" className='form-control rounded-pill ms-2 mb-3' value={house} onChange={(e) => setHouse(e.target.value)} id='house' name='house' placeholder='Enter House No.' />

                {/* Area/Street */}
                <label htmlFor="areaStreet" className='bg-primary text-white px-3 py-2 rounded-pill'>Area/Street</label>
                <input type="text" className='form-control rounded-pill ms-2 mb-3' value={areaOrStreet} onChange={(e) => setAreaOrStreet(e.target.value)} id='areaStreet' name='areaStreet' placeholder='Enter Area/Street' />
                
                {/* Pincode */}
                <label htmlFor="pincode" className='bg-primary text-white px-3 py-2 rounded-pill'>Pincode</label>
                <input type="text" className='form-control rounded-pill ms-2 mb-3' value={pincode} onChange={(e) => setPincode(e.target.value)} id='pincode' name='pincode' placeholder='Enter Area Pincode' />

                {/* City */}
                <label htmlFor="city" className='bg-primary text-white px-3 py-2 rounded-pill'>City</label>
                <input type="text" className='form-control rounded-pill ms-2 mb-3' value={city} onChange={(e) => setCity(e.target.value)} id='city' name='city' placeholder='Enter City' />

                {/* State */}
                <label htmlFor="state" className='bg-primary text-white px-3 py-2 rounded-pill'>State</label>
                <input type="text" className='form-control rounded-pill ms-2 mb-3' value={state} onChange={(e) => setState(e.target.value)} id='state' name='state' placeholder='Enter State' />

                {/* Button */}
                <div className='text-center'>
                  <button type='button' className='btn btn-primary rounded-pill px-5' onClick={handleAddAddress}>Add Address</button>
                </div>
              </form>
            </section>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewAddress;
