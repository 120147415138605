import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { getHangoutDetails, updateHangout } from '../../../UseContext/ApiHelpers';
import { profileContext } from '../../../UseContext/ProfileContext';
import { BASE_URL_STATIC } from '../../../../../config';

const HangoutEdit = ({ open, close, hangoutId, hangObj,loggedInUserId }) => {

    const { setHangoutDetails } = useContext(profileContext)

    const places =
        [
            {
                "place": "Restaurant",

            },
            {
                "place": "Parks and Recreation Areas",

            },
            {
                "place": "Museums and Cultural Institutions",
            },
            {
                "place": "Tourist Attractions",
            },
            {
                "place": "Historical Sites",
            },
            {
                "place": "Beaches",
            },
            {
                "place": "Shopping Centers",
            },
            {
                "place": "Entertainment Venues",
            },
            {
                "place": "Religious Sites",
            },
            {
                "place": "Natural Wonders",
            }
        ]

    const [input, setInput] = useState({ location: "", place: "", type: "" })
    function handleChange(e) {
        setInput({ ...input, [e.target.name]: e.target.value })
    }

    const hangoutDetails = { hangoutPlaceId: hangoutId, placeName: input.place, placeType: input.type, placeLocation: input.location }

    async function handleSubmit(e) {
        e.preventDefault()

        await updateHangout(hangoutDetails)
            .then(() => getHangoutDetails(loggedInUserId).then(res => setHangoutDetails(res)))
        close();
    }

    useEffect(() => {
        setInput(() => ({
            location: hangObj.location,
            place: hangObj.place,
            type: hangObj.type
        }))
    }, [hangObj])

    return (
        <div>
            <Modal
                show={open}
                onHide={close}
                centered
                backdrop="static"
                keyboard={false}
                className="create-call"
            >
                <Modal.Header closeButton>&nbsp;</Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={handleSubmit}>
                        <h5 style={{ fontFamily: 'metropolis' }}>Most Hangout Places</h5>

                        <div className="" >
                            <span
                                className="d-flex align-content-center"
                                style={{
                                    margin: '10px 0px',
                                    background: '#f5f5f5',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    width: "110px",
                                }}
                            >
                                <img src={`${BASE_URL_STATIC}/icon/location.svg`} alt="" />

                                <p
                                    style={{
                                        fontSize: '15px',
                                        margin: 'auto',
                                        color: '#616161',
                                        fontFamily: 'metropolis',
                                    }}
                                >
                                    Place type
                                </p>
                            </span>

                            <div className="col-lg-11">
                                <select
                                    className="form-control" name='type' onChange={handleChange}
                                    required
                                    style={{
                                        fontWeight: '600',
                                        border: '0px',
                                        borderBottom: '2px solid #a6a6a659',
                                        marginTop: '12px',
                                    }}
                                >
                                    {hangObj.placeType && (
                                        <option value={hangObj.placeType}>{hangObj.placeType}</option>
                                    )}
                                    {
                                        places.filter(e => e.place !== hangObj.placeType).map((e) => (
                                            <option key={e.place} value={e.place}>{e.place}</option>
                                        ))
                                    }
                                </select>
                            </div >

                        </div >

                        <div className="" >
                            <span
                                className="d-flex align-content-center"
                                style={{
                                    margin: '10px 0px',
                                    background: '#f5f5f5',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    width: "110px",
                                }}
                            >
                                <img src={`${BASE_URL_STATIC}/icon/location.svg`} alt="" />
                                <p
                                    style={{
                                        fontSize: '15px',
                                        margin: 'auto',
                                        color: '#616161',
                                        fontFamily: 'metropolis',
                                    }}
                                >
                                    Place Name
                                </p>
                            </span>
                            <input defaultValue={hangObj.placeName} type="text" name='place' onChange={handleChange} style={{ border: 'none', borderBottom: "2px solid #d4d4d4", width: "85%", margin: "auto", marginLeft: "10px", padding: "5px 10px" }} />

                        </div >

                        <div className="" >
                            <span
                                className="d-flex align-content-center"
                                style={{
                                    margin: '10px 0px',
                                    background: '#f5f5f5',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    width: "110px",
                                }}
                            >
                                <img src={`${BASE_URL_STATIC}/icon/location.svg`} alt="" />
                                <p
                                    style={{
                                        fontSize: '15px',
                                        margin: 'auto',
                                        color: '#616161',
                                        fontFamily: 'metropolis',
                                    }}
                                >
                                    Location
                                </p>
                            </span>
                            <input type="text" defaultValue={hangObj.placeLocation} name='location' onChange={handleChange} style={{ border: 'none', borderBottom: "2px solid #d4d4d4", width: "85%", margin: "auto", marginLeft: "10px", padding: "5px 10px" }} />

                        </div >


                        <div className='d-flex justify-content-center'>
                            <button type="submit" className="btn btn-dark mt-4 col-lg-4">Save</button>
                        </div>


                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default HangoutEdit