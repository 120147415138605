import React,{useContext} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteuserPostv2 } from '../../UseContext/ApiHelpers';
import { ApiContext } from '../../UseContext/ApiCall';


const PostDeletion = ({showPopupDelete, setShowPopupDelete, postsPopupData}) => {
 
  const { config, setallDataPost, allDataPost } = useContext(ApiContext);

  const handleClose = () => setShowPopupDelete(false);

  const Deletepost = async () => {
    await deleteuserPostv2({ id: postsPopupData._id }, config);

    const filter = allDataPost.filter(
      (item) => item._id !== postsPopupData._id
    );
    setallDataPost(filter);
    handleClose(); 
  };
  

  return (
    <div>
     

      <Modal
        show={showPopupDelete}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        className="main-modal p-4 m-3"
      >
       

        <Modal.Body className="popupModal-body mt-4 text-center">
          <p>Are you sure you want to delete this post?</p>
        </Modal.Body>
        <div  className='text-center'>
          <Button variant="secondary"  className='mx-1 my-2' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={Deletepost}>
            Delete
          </Button>
       

        </div>

      </Modal>
    </div>
  );
};

export default PostDeletion;
