import React, { useState, useContext, useRef } from "react";
import { FaCheck } from "react-icons/fa";
import { ApiContext } from "../../UseContext/ApiCall";
import axios from "axios";
import { signupuser } from "../../UseContext/ApiHelpers";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL, BASEURL } from "../../../../config";
import toast from "react-hot-toast";
import imageCompression from 'browser-image-compression';
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";

// import imageCompression from 'browser-image-compression';

const UserName = () => {

  const [usernameError, setUsernameError] = useState(false);
  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [regMessage, setRegMessage] = useState("");
  const [image, setImage] = useState(null);
  const [disabled, setIsdisable] = useState(false);

  const nav = useNavigate()

  const { setUserInfo, userInfo, config, setRegisteredImage, profileOption } = useContext(ApiContext);

  console.log("userInfo", disabled)
  const imageRef = useRef(null);

  async function handleChange(e) {
    const { name, value } = e.target;

    setUserInfo((prevUser) => ({ ...prevUser, [name]: value }));

    if (value.includes(" ")) {
      setUsernameError(true);

      setColor(""); // Reset color if username contains space
    } else {
      setUsernameError(false);
      if (value !== "") {
      } else {
      };
    };

    try {
      await axios.post(`${BASEAPIURL}/user/findusername`, { username: value }, config).then((res) => {
        console.log("ressas", res);
        if ( res.data.status === 0) {
          setColor("#d3d3d3");
          setMessage(res.data.message);
          setIsdisable(true)
        }
        else if ( res.data.status === 1) {
          setColor("#00c04b");
          setMessage(res.data.message);
          setIsdisable(false)
        }
        else {
          setIsdisable(true)
        }
      })
    } catch (error) {
      setIsdisable(false);
      setColor("");
      setMessage(error.response.data.message);
    };
  };

  const handleSubmit = async () => {

    if (!userInfo.email || !userInfo.phoneNumber) {
      nav('/signup/nameRegisteration')
      return;
    }
    else {
      signupuser(userInfo).then(async (res) => {
        console.log("resxyz", res);
        if (res && res.status === 1) {
          setRegMessage("Registration SuccessFull !");
          const login = await axios.post(`${BASEAPIURL}/user/userlogin`, { email: userInfo.email, mobile: '', password: userInfo.password }, config);
          console.log("")
          if (login) {
            toast.success(login.data.message);
            localStorage.setItem("token", login.data.token);
            window.location.href = BASEURL;
          }

        }
        else if (res && res.status === 2) {
          toast.error(res.data.message);
        }

        else {
          setRegMessage("");
        }

        const semail = sessionStorage.getItem("email");
        const spassword = sessionStorage.getItem("password");
        const bytes = CryptoJS.AES.decrypt(spassword, 'avinash123');
        const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
        const response = await axios.post(`${BASEAPIURL}/user/userlogin`, { email: semail, mobile: '', password: decryptedPassword }, config);
        if (response.status === 201) {
          toast.success(response.data.message);
          localStorage.setItem("token", response.data.token);
          window.location.href = BASEURL;
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("password");
          // setLoader(false);
          // setShowHeader(true);
        }
      });
    }
  };

  const handleIconClick = () => {
    imageRef.current.click()
  };

  const handleImageChange = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    if (e) {
      setRegisteredImage(e.target.files[0]);
      const compressedImage = await imageCompression(e.target.files[0], profileOption);
      setUserInfo((prevUser) => ({ ...prevUser, profileImage: compressedImage }));

    } else {
      setRegisteredImage(null);
    };

  };

  return (
    <div className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100 " >
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/usernameCover.png`} alt="usernameCover" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0 d-flex flex-column align-items-center h-25">

        {/* Logo */}
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <div className="position-relative mb-3 rounded-circle " style={{ height: '150px', width: '150px' }}>
          <img src={image || (AwsFileUrl + 'default/dummy.jpg')} className="h-100 w-100  rounded-circle" alt="profile" />

          <i className="far fa-camera position-absolute fs-6 fs-md-5 bg-secondary text-white p-1 p-md-2 rounded-pill" style={{ bottom: '7%', right: '7%', cursor: 'pointer' }} onClick={handleIconClick} />

          <input type="file" className="d-none" accept="image/*" ref={imageRef} onChange={handleImageChange} />
        </div>

        <from className="w-75 mx-auto text-center">
          <h4>Create your username</h4>

          <small>Username must be unique .The minimum required length for a user name is five characters. e.g.<b>username123</b></small>

          <p className="position-relative my-3">
            <input type="text" className="form-control form-control-lg rounded-pill" name="username" minLength="5" onChange={handleChange} placeholder="username123 (optional)" />

            <span className="position-absolute top-50 end-0 translate-middle-y me-3">
              <FaCheck size={20} color={!usernameError && color} />
            </span>
          </p>

          {usernameError && (<p className="text-danger text-align-center error_message">Username does not contain space</p>)}

          {!usernameError && message === "username already taken" && (<p className="text-align-center text-danger error_message">{message}</p>)}

          {!usernameError && message === "username available" && (<p className="text-align-center text-success error_message">{message}</p>)}

          <button type="button" className="w-100 rounded-pill btn btn-primary fs-5 py-2" disabled={disabled} onClick={handleSubmit}>Submit </button>

          {regMessage && (<p className="registration">Registration Successfull...!</p>)}
        </from>


      </section>
    </div>
  );
};

export default UserName;