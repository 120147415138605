import React, { useEffect, useState, useRef } from 'react';
import './arVr.css';
import ArVrEffects from './arVrEffects';
import ArVrBackground from './arVrBackground';
import ArVrBackgroundVideo from './arVrBackgroundVideo';
import ArVrEffects3d from './arVrEffects3d';
import ArVrEffectsEyeBrow from './arVrEffectsEyeBrow';
import { AwsFileUrl } from '../../config';
// https://ai.google.dev/edge/mediapipe/solutions/vision/image_embedder#configurations_options
// https://mediapipe-studio.webapps.google.com/studio/demo/face_landmarker
let windowWidth = localStorage.getItem('screenWidth');
let windowHeight = localStorage.getItem('screenHeight');
let cameraSelected = localStorage.getItem('selectedCamera');

const ArVrInded = () => {
    const [hasPermission, setHasPermission] = useState(null);
    const [selectedCamera, setSelectedCamera] = useState(cameraSelected);
    const [availabelCam, setAvailabelCam] = useState([]);
    const [actionContentShow, setActionContentShow] = useState(true);
    const BGonPage = 9;
    const [loading, setLoading] = useState(true);
    const [loadingBG, setLoadingBG] = useState(true);
    const emptyImage = `${AwsFileUrl}arvr/0.webp`;
    const OtherCanvasRef = useRef(null);
    const BGCanvasRef = useRef(null);
    const [customBG, setCustomBG] = useState('#cc0000');
    function MakeCustomBGUrl(color) {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        canvas.width = 1600;
        canvas.height = 900;
        const context = canvas.getContext('2d');
        // Fill the canvas with the specified color
        context.fillStyle = color;
        context.fillRect(0, 0, 1600, 900);
        // Convert the canvas to a Base64 image URL
        setBgImgUrl(canvas.toDataURL('image/png'));
        return false;
    }
    // Function to convert file to base64
    const convertToBase64Url = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type && !file.type.startsWith("image/")) {
                alert('Please select a valid image file.');
                return false;
            }
            try {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    setBgImgUrl(reader.result); // This is the base64 URL
                };
                reader.onerror = (error) => {
                    console.error("Error converting file to base64:", error);
                };
            } catch (e) {
                alert(e.message);
            }
        }
        return true;
    };
    const backgroundVideos = [
        { title: 'Fire hai mai', value: `${AwsFileUrl}arvr/video/fire.mp4`, bg: `${AwsFileUrl}arvr/video/image/fire.jpg` },
        { title: 'Banana Barish', value: `${AwsFileUrl}arvr/video/banana.mp4`, bg: `${AwsFileUrl}arvr/video/image/banana.jpg` },
        { title: 'Water Water', value: `${AwsFileUrl}arvr/video/water.mp4`, bg: `${AwsFileUrl}arvr/video/image/water.jpg` },
        { title: 'Party Mood', value: `${AwsFileUrl}arvr/video/partyBg.mp4`, bg: `${AwsFileUrl}arvr/video/image/partyBg.jpg` },
        { title: 'At Office', value: `${AwsFileUrl}arvr/video/officeVideo.mp4`, bg: `${AwsFileUrl}arvr/video/image/officeVideo.jpg` },
        { title: 'On Fire', value: `${AwsFileUrl}arvr/video/onFire.mp4`, bg: `${AwsFileUrl}arvr/video/image/partyBg.jpg` },
    ];
    const backgroundImages = [
        { title: 'XP Theme', value: `${AwsFileUrl}arvr/bg/2.jpg` },
        { title: 'Dark', value: `${AwsFileUrl}arvr/bg/3.jpg` },
        { title: 'Nature', value: `${AwsFileUrl}arvr/bg/4.jpg` },
        { title: 'Nature 2', value: `${AwsFileUrl}arvr/bg/5.jpg` },
        { title: 'Hell', value: `${AwsFileUrl}arvr/bg/6.jpg` },
        { title: 'Heavn', value: `${AwsFileUrl}arvr/bg/7.jpg` }
    ];

    const defaultLipsColors = [
        // Red Shades
        { title: 'Classic Red', color: '#cc0000', text: '#fff', type: "Red Shades" },
        { title: 'Ruby Red', color: '#9b111e', text: '#fff', type: "Red Shades" },
        { title: 'Crimson', color: '#dc143c', text: '#fff', type: "Red Shades" },
        { title: 'Cherry Red', color: '#d2042d', text: '#fff', type: "Red Shades" },
        { title: 'Scarlet', color: '#ff2400', text: '#fff', type: "Red Shades" },

        // Pink Shades
        { title: 'Blush Pink', color: '#de5d83', text: '#fff', type: "Pink Shades" },
        { title: 'Rose Pink', color: '#ff66cc', text: '#000', type: "Pink Shades" },
        { title: 'Bubblegum Pink', color: '#ff85a1', text: '#000', type: "Pink Shades" },
        { title: 'Magenta', color: '#ff00ff', text: '#fff', type: "Pink Shades" },
        { title: 'Dusty Pink', color: '#d8a7b1', text: '#000', type: "Pink Shades" },

        // Nude Shades
        { title: 'Warm Beige', color: '#d2b48c', text: '#000', type: "Nude Shades" },
        { title: 'Soft Peach', color: '#ffe5b4', text: '#000', type: "Nude Shades" },
        { title: 'Mauve Nude', color: '#b784a7', text: '#fff', type: "Nude Shades" },
        { title: 'Rosewood', color: '#b76e79', text: '#fff', type: "Nude Shades" },
        { title: 'Caramel', color: '#c68e6e', text: '#000', type: "Nude Shades" },

        // Berry & Plum Shades
        { title: 'Berry', color: '#8e4585', text: '#fff', type: "Berry & Plum Shades" },
        { title: 'Plum', color: '#673147', text: '#fff', type: "Berry & Plum Shades" },
        { title: 'Mulberry', color: '#c54b8c', text: '#fff', type: "Berry & Plum Shades" },
        { title: 'Raspberry', color: '#e30b5d', text: '#fff', type: "Berry & Plum Shades" },
        { title: 'Wine', color: '#722f37', text: '#fff', type: "Berry & Plum Shades" },

        // Deep Shades
        { title: 'Deep Burgundy', color: '#4a0e0e', text: '#fff', type: "Deep Shades" },
        { title: 'Maroon', color: '#800000', text: '#fff', type: "Deep Shades" },
        { title: 'Chocolate', color: '#3d1f0c', text: '#fff', type: "Deep Shades" },
        { title: 'Dark Cherry', color: '#550527', text: '#fff', type: "Deep Shades" },
        { title: 'Espresso', color: '#4b3621', text: '#fff', type: "Deep Shades" }
    ];

    const defaultSkinColors = [
        // Light Skin Tones
        { title: 'Porcelain', color: '#FBE8EB', text: '#000', type: "Light Skin Tones" },
        { title: 'Ivory', color: '#F2D6C4', text: '#000', type: "Light Skin Tones" },
        { title: 'Fair', color: '#F0D5BE', text: '#000', type: "Light Skin Tones" },
        { title: 'Light Peach', color: '#F8D1B1', text: '#000', type: "Light Skin Tones" },
        { title: 'Light Beige', color: '#F1E0C6', text: '#000', type: "Light Skin Tones" },
      
        // Medium Skin Tones
        { title: 'Beige', color: '#F1C6B4', text: '#000', type: "Medium Skin Tones" },
        { title: 'Honey', color: '#E1B69A', text: '#000', type: "Medium Skin Tones" },
        { title: 'Caramel', color: '#C49B77', text: '#000', type: "Medium Skin Tones" },
        { title: 'Olive', color: '#A67C52', text: '#fff', type: "Medium Skin Tones" },
        { title: 'Warm Beige', color: '#D1A27C', text: '#fff', type: "Medium Skin Tones" },
      
        // Darker Skin Tones
        { title: 'Bronze', color: '#9E5B40', text: '#fff', type: "Darker Skin Tones" },
        { title: 'Almond', color: '#8A5B3D', text: '#fff', type: "Darker Skin Tones" },
        { title: 'Chestnut', color: '#6E3C34', text: '#fff', type: "Darker Skin Tones" },
        { title: 'Cinnamon', color: '#7A4A3C', text: '#fff', type: "Darker Skin Tones" },
        { title: 'Deep Brown', color: '#3C1C1C', text: '#fff', type: "Darker Skin Tones" },
      
        // Very Dark Skin Tones
        { title: 'Espresso', color: '#3B1F18', text: '#fff', type: "Very Dark Skin Tones" },
        { title: 'Ebony', color: '#2D1B19', text: '#fff', type: "Very Dark Skin Tones" },
        { title: 'Midnight', color: '#1B0C0C', text: '#fff', type: "Very Dark Skin Tones" },
        { title: 'Chocolate', color: '#3D1F0C', text: '#fff', type: "Very Dark Skin Tones" },
        { title: 'Coffee', color: '#4B3621', text: '#fff', type: "Very Dark Skin Tones" }
    ];

    const getAvailableCameras = async () => {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevices = devices.filter((device) => device.kind === 'videoinput');
          setAvailabelCam(videoDevices);
          console.log("##### videoDevices $$$$$$$$$$$$$");
          console.log(videoDevices);
          console.log("##### videoDevices $$$$$$$$$$$$$");
          return videoDevices; // Returns an array of available cameras
        } catch (err) {
          setAvailabelCam([]);
          alert('Unable to Detect Any Camera on your Device.');
          console.error('Error fetching cameras:', err);
          return [];
        }
    };      

    const lipsColors = defaultLipsColors.reduce((acc, color) => {
        (acc[color.type] = acc[color.type] || []).push(color);
        return acc;
    }, {});

    const skinColors = defaultSkinColors.reduce((acc, color) => {
        (acc[color.type] = acc[color.type] || []).push(color);
        return acc;
    }, {});

    const [lipColor, setLipColor] = useState('transparent');
    const [lastActiveTab, setLastActiveTab] = useState('backgrounds');
    const [activeTab, setActiveTab] = useState('backgrounds');
    const [skinColor, setSkinColor] = useState('transparent'); // Default skin tone
    const [glassesImageUrl, setGlassesImageUrl] = useState(emptyImage);
    const [maskImageUrl, setMaskImageUrl] = useState(emptyImage);
    const [bgImgUrl, setBgImgUrl] = useState('/images/loginBG.jpg');
    const [bgVideoUrl, setBgVideoUrl] = useState(`${AwsFileUrl}arvr/video/fire.mp4`);
    windowWidth = localStorage.getItem('screenWidth');
    windowHeight = localStorage.getItem('screenHeight');

    // if (windowWidth > 1000) {
    //     windowWidth = 1920;
    //     windowHeight = 400;
    // }

    const glesses = [1, 2, 3, 4, 5, 6];
    const masks = [1, 2, 3];

    const checkPermissionStatus = async () => {
        if (navigator.permissions) {
          try {
            const status = await navigator.permissions.query({ name: "camera" });
            setHasPermission(status.state);
            // console.log('status');
            // console.log(status);
            // console.log('status');
            status.onchange = () => setHasPermission(status.state);
          } catch {
            setHasPermission("unknown");
          }
        } else {
            setHasPermission("unknown");
        }
    };

    const requestCameraPermission = async () => {
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
          setHasPermission("granted");
          getAvailableCameras();
        } catch (err) {
          setHasPermission("denied");
          if (hasPermission === 'denied') {
            alert("Camera access is denied. Please enable it in your browser settings.");
          } else {
            alert(err.message);
          }
        }
    };

    useEffect(() => {
        checkPermissionStatus();
        getAvailableCameras();
    }, [hasPermission]);

    const [currentPage, setCurrentPage] = useState({
        backgrounds: 1,
        glasses: 1,
        masks: 1,
        skin: 1,
        lips: 1,
    });
    const handlePageChange = (page, type) => {
        if (page === currentPage[type]) {
            return false;
        }
        setCurrentPage({ ...currentPage, [type]: page });
        // setCurrentPage({ ...currentPage, [type]: page, [`${type}Action`]: currentPage[`${type}Action`] + 1 });
        switch (type) {
            case "backgrounds":
                // const temp = showFilteredData(backgroundImages, page, itemsPerPage);
                // setFilterbackgroundImages(temp);
                break;
            default:
                console.log("None Selected for the Filterd");
                break;
        }
        return true;
    };
    function showFilteredData(data, currentPage, itemsPerPage) {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    }
    const renderPagination = (length, limit, type) => {
        const pageCount = Math.ceil(length / limit); // Calculate total pages
        const pages = [];
        for (let i = 1; i <= pageCount; i++) {
            pages.push(
                <li
                    key={i}
                    className={`page-item ${i === currentPage[type] ? 'active' : ''}`}
                    onClick={() => handlePageChange(i, type, limit)}
                >
                    <button className="page-link">{i}</button>
                </li>
            );
        }

        const theElements = (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center mt-3 mb-3">
                    <li className={`page-item ${currentPage[type] === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage[type] - 1, type, limit)}
                            disabled={currentPage[type] === 1}
                        >
                            Previous
                        </button>
                    </li>
                    {pages}
                    <li className={`page-item ${currentPage[type] === pageCount ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage[type] + 1, type, limit)}
                            disabled={currentPage[type] === pageCount}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        )

        return theElements;
    };

    // Function to capture screenshot of the canvas
    const captureScreenshot = () => {
        let canvasRef = null;
        if (lastActiveTab === "backgrounds") {
            canvasRef = BGCanvasRef;
        } else {
            canvasRef = OtherCanvasRef;
        }
        const canvas = canvasRef.current;
        if (canvas) {
            // Get the device pixel ratio (DPR)
            // const dpr = window.devicePixelRatio || 1;
            const dpr = 5;

            // Create a temporary canvas with higher resolution
            const tempCanvas = document.createElement('canvas');
            const tempCtx = tempCanvas.getContext('2d');

            // Set the temp canvas size to be the same as the original canvas but with higher resolution
            tempCanvas.width = canvas.width * dpr;
            tempCanvas.height = canvas.height * dpr;

            // Scale the context to match the higher resolution
            tempCtx.scale(dpr, dpr);

            // Draw the content of the original canvas onto the temporary canvas
            tempCtx.drawImage(canvas, 0, 0);

            // Capture the high-res data URL from the temporary canvas
            const dataURL = tempCanvas.toDataURL('image/png');  // PNG format

            // Trigger download with the high-quality image
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = 'arVrImage.png';  // Set the file name for download
            link.click();  // Trigger the download
        }
    };

    
    
    if (hasPermission === 'denied') {
        return ( 
            <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5">
                <div className="explore-sec d-block my-3 my-sm-5 my-lg-5">
                    <div className='container-fluid pt-3 pb-3 pb-1 bg-white'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card bg-dark'>
                                    <div className='card-body pt-3 pb-3 text-center'>
                                        <h5 className='text-white'>
                                            Camera access denied. Please enable it in your browser settings.
                                        </h5>
                                        <img
                                            src={`${AwsFileUrl}arvr/allowCameraSteps.jpg`}
                                            className="d-block mt-3 mb-3"
                                            alt="Allow Camera Steps"
                                            style={{ maxWidth: '340px', margin: '0px auto' }}
                                        />
                                        <a
                                            href="/"
                                            className='btn btn-info text-white'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                requestCameraPermission();
                                            }}
                                        >
                                            Try Again
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    if (availabelCam.length < 1) {
        return ( 
            <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5">
                <div className="explore-sec d-block my-3 my-sm-5 my-lg-5">
                    <div className='container-fluid pt-3 pb-3 pb-1 bg-white'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card bg-dark'>
                                    <div className='card-body pt-3 pb-3 text-center'>
                                        <h5 className='text-white mt-3'>
                                            No Camera found on your System, Try refreshing the Page or 
                                        </h5>
                                        <a
                                            href="/"
                                            className='btn btn-info mt-3 mb-3 text-white'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                requestCameraPermission();
                                            }}
                                        >
                                            Try Again
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }


    return (
        <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5">
            <div className="explore-sec d-block my-3 my-sm-5 my-lg-5">
                <div className='container-fluid pt-3 pb-3 pb-1 bg-white'>
                    <div className="row">
                        <div className="col-md-12 actionMenu">
                            {/* menu for the action will be here */}
                            <ul className="nav mb-3 pull-right nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'backgrounds' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('backgrounds');
                                        }}
                                        role="tab"
                                    >
                                        Background
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'backgroundsVideo' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('backgroundsVideo');
                                        }}
                                        role="tab"
                                    >
                                        BG Video
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'glasses' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('glasses');
                                        }}
                                        role="tab"
                                    >
                                        Glasses
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'eyeBrow' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('eyeBrow');
                                        }}
                                        role="tab"
                                    >
                                        Eye Brow
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'masks' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('masks');
                                        }}
                                        role="tab"
                                    >
                                        Mask
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'skin' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('skin');
                                        }}
                                        role="tab"
                                    >
                                        Skin
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'lips' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('lips');
                                        }}
                                        role="tab"
                                    >
                                        Lips
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: 'auto' }}>
                                    <button
                                        className={`nav-link ${activeTab === 'other' ? 'active' : ''}`}
                                        onClick={() => {
                                            setLastActiveTab(activeTab);
                                            setActionContentShow(true);
                                            setActiveTab('other');
                                        }}
                                        role="tab"
                                    >
                                        Other Action
                                    </button>
                                </li>
                            </ul>
                            <div className="clearBoth"></div>
                            {/* menu for the action will be here */}
                        </div>
                        <div className="col-md-7">
                            <div
                                id=""
                                onClick={() => {
                                    setActionContentShow(false);
                                }}
                            >
                                {(['glassesglasses', 'masks', 'skin', "lips"].includes(activeTab) || (activeTab === "other" && ['glassesglasses', 'masks', 'skin', "lips"].includes(lastActiveTab))) && (
                                    <ArVrEffects
                                        glassesImageUrl={glassesImageUrl}
                                        windowHeight={windowHeight}
                                        windowWidth={windowWidth}
                                        canvasRef={OtherCanvasRef}
                                        maskImageUrl={maskImageUrl}
                                        skinColor={skinColor}
                                        lipColor={lipColor}
                                        setLoading={setLoading}
                                        loading={loading}
                                        setActionContentShow={setActionContentShow}
                                        selectedCamera={selectedCamera}
                                    />
                                )}
                                {(activeTab === 'glasses' || (activeTab === "other" && lastActiveTab === 'glasses')) && (
                                    <ArVrEffects3d
                                        bgImgUrl={bgImgUrl}
                                        windowHeight={windowHeight}
                                        windowWidth={windowWidth}
                                        canvasRef={BGCanvasRef}
                                        setLoading={setLoadingBG}
                                        loadingBG={loadingBG}
                                        setActionContentShow={setActionContentShow}
                                        selectedCamera={selectedCamera}
                                    />
                                )}
                                {(activeTab === 'eyeBrow' || (activeTab === "other" && lastActiveTab === 'eyeBrow')) && (
                                    <ArVrEffectsEyeBrow
                                        bgImgUrl={bgImgUrl}
                                        windowHeight={windowHeight}
                                        windowWidth={windowWidth}
                                        canvasRef={BGCanvasRef}
                                        setLoading={setLoadingBG}
                                        loadingBG={loadingBG}
                                        setActionContentShow={setActionContentShow}
                                        selectedCamera={selectedCamera}
                                    />
                                )}
                                {(activeTab === 'backgrounds' || (activeTab === "other" && lastActiveTab === 'backgrounds')) && (
                                    <ArVrBackground
                                        bgImgUrl={bgImgUrl}
                                        windowHeight={windowHeight}
                                        windowWidth={windowWidth}
                                        canvasRef={BGCanvasRef}
                                        setLoadingBG={setLoadingBG}
                                        loadingBG={loadingBG}
                                        setActionContentShow={setActionContentShow}
                                        selectedCamera={selectedCamera}
                                    />
                                )}
                                {(activeTab === 'backgroundsVideo' || (activeTab === "other" && lastActiveTab === 'backgrounds')) && (
                                    <ArVrBackgroundVideo
                                        bgVideoUrl={bgVideoUrl}
                                        windowHeight={windowHeight}
                                        windowWidth={windowWidth}
                                        canvasRef={BGCanvasRef}
                                        setLoadingBG={setLoadingBG}
                                        loadingBG={loadingBG}
                                        setActionContentShow={setActionContentShow}
                                        selectedCamera={selectedCamera}
                                    />
                                )}
                            </div>

                            <div className="text-align-right">
                                {/* <i className="far fa-widnow"></i> */}
                            </div>

                        </div>
                        {actionContentShow ? '' : (
                            <a
                                className="actionContentShowBtn"
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActionContentShow(true);
                                }}
                            >
                                Show
                            </a>
                        )}
                        <div className={`${actionContentShow ? 'ACWidth100' : 'ACWidth0'} col-md-5 actionContent`}>
                            <div className="actionContentHideBtn">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActionContentShow(false);
                                    }}
                                >
                                    X
                                </a>
                            </div>
                            <div className="tab-content tab-content-arvr">

                                <div className={`actionElements tab-pane fade ${activeTab === 'masks' ? ' show active' : ''}`} id="masks">
                                    {/* {maskImageUrl} */}
                                    {masks.map((s) => (
                                        <div className='masks' key={s}>
                                            <img
                                                src={`${AwsFileUrl}arvr/mask/${s}.png`}
                                                alt=""
                                                onClick={() => {
                                                    setMaskImageUrl(`${AwsFileUrl}arvr/mask/${s}.png`);
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <div className={`actionElements tab-pane fade ${activeTab === 'glasses' ? ' show active' : ''}`} id="glasses">
                                    <div className='text-center'>
                                        {glesses.map((s, i) => (
                                            <div className='glasses text-center' key={s}>
                                                <img
                                                    src={`${AwsFileUrl}arvr/glasses/${s}.webp`}
                                                    alt=""
                                                    onClick={() => {
                                                        setGlassesImageUrl(`${AwsFileUrl}arvr/glasses/${s}.webp`);
                                                    }}
                                                />
                                                {i}
                                            </div>
                                        ))}
                                        <div className='glasses text-center'>
                                            <img
                                                src={emptyImage}
                                                alt=""
                                                onClick={() => {
                                                    setGlassesImageUrl(emptyImage);
                                                }}
                                            />
                                            None
                                        </div>
                                    </div>
                                </div>

                                <div className={`actionElements tab-pane fade ${activeTab === 'skin' ? ' show active' : ''}`} id="skin">
                                    
                                    {skinColor && skinColor !== "transparent" && (
                                        <div style={{ marginTop: '20px' }}>
                                            <p><b>Selected Color</b>: <span style={{ color: skinColor }}>{skinColor}</span></p>
                                        </div>
                                    )}
                                    <div>
                                        {Object.keys(skinColors).map((type) => (
                                            <div key={type} style={{ marginBottom: '20px' }}>
                                                <b>{type}</b>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                    {skinColors[type].map((s) => (
                                                        <a
                                                            key={s.color}
                                                            href="/arVr"
                                                            className="lipsBtn"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setLoading(true);
                                                                setSkinColor(`${s.color}20`); // Set the color using state
                                                            }}
                                                            style={{ backgroundColor: s.color, color: s.text }}
                                                        >
                                                            {s.title}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    
                                    <button className="skinBtn" style={{ background: 'rgba(255, 224, 189, 1)' }} onClick={() => setSkinColor('rgba(255, 224, 189, 0.2)')}>Light</button>
                                    <button className="skinBtn" style={{ background: 'rgba(205, 133, 63, 1)' }} onClick={() => setSkinColor('rgba(205, 133, 63, 0.2)')}>Medium</button>
                                    <button className="skinBtn" style={{ background: 'rgba(139, 69, 19, 1)' }} onClick={() => setSkinColor('rgba(139, 69, 19, 0.2)')}>Dark</button>
                                    <button className="skinBtn" style={{ background: '#f5f5f5' }} onClick={() => setSkinColor(false)}>No </button>
                                </div>

                                <div className={`actionElements tab-pane fade ${activeTab === 'backgroundsVideo' ? ' show active' : ''}`} id="lips">
                                    <div
                                        className="bgImg"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setBgVideoUrl('a');
                                        }}
                                    >
                                        <span>None</span>
                                        <img src={emptyImage} alt="None" style={{ background: '#000' }} />
                                    </div>
                                    {backgroundVideos.map((s, i) => (
                                        <div
                                            key={i}
                                            className="bgImg"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setBgVideoUrl(s.value);
                                            }}
                                        >
                                            <span>{s.title}</span>
                                            <img src={s.bg} alt="None" style={{ background: '#000' }} />
                                        </div>
                                    ))}
                                </div>
                                <div className={`actionElements tab-pane fade ${activeTab === 'backgrounds' ? ' show active' : ''}`} id="lips">
                                    <div
                                        className="bgImg"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setBgImgUrl(emptyImage);
                                        }}
                                    >
                                        <span>None</span>
                                        <img src={emptyImage} alt="None" style={{ background: '#000' }} />
                                    </div>
                                    {currentPage.backgrounds && showFilteredData(backgroundImages, currentPage.backgrounds, BGonPage-1).map((s, i) => (
                                        <div
                                            key={i}
                                            className="bgImg"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setBgImgUrl(s.value);
                                            }}
                                        >
                                            <span>{s.title}</span>
                                            <img src={s.value} alt={s.title} />
                                        </div>
                                    ))}
                                    {renderPagination(backgroundImages.length, BGonPage, 'backgrounds')}
                                    <div className="clearBoth"></div>
                                    <div className="clearBoth mt-3">
                                        <b>Select Color Below for background</b>
                                        <div className="form-group mt-2 d-flex">
                                            <input
                                                type="color"
                                                style={{ background: customBG }}
                                                className="customBgInp"
                                                name="color"
                                                value={customBG}
                                                onChange={(e) => {
                                                    setCustomBG(e.target.value);
                                                }}
                                            />
                                            <button
                                                className='btn btn-outline-secondary'
                                                style={{
                                                    borderRadius: '0px'
                                                }}
                                                onClick={(e) => {
                                                    MakeCustomBGUrl(customBG);
                                                }}
                                            >
                                                Set
                                            </button>
                                        </div>
                                    </div>
                                    <div className="clearBoth mt-3">
                                        <b>Select Color Below for background</b>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={convertToBase64Url}
                                            accept="image/*"
                                        />
                                    </div>
                                </div>

                                <div className={`actionElements tab-pane fade ${activeTab === 'lips' ? ' show active' : ''}`} id="lips">
                                    {lipColor && lipColor !== "transparent" && (
                                        <div style={{ marginTop: '20px' }}>
                                            <a
                                                href="/"
                                                className="pull-right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setLipColor('transparent')
                                                }}
                                            >
                                                X
                                            </a>
                                            <p><b>Selected Color</b>: <span style={{ color: lipColor }}>{lipColor}</span></p>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>
                                    )}
                                    <div>
                                        {Object.keys(lipsColors).map((type) => (
                                            <div key={type} style={{ marginBottom: '20px' }}>
                                                <b>{type}</b>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                    {lipsColors[type].map((s) => (
                                                        <a
                                                            key={s.color}
                                                            href="/arVr"
                                                            className="lipsBtn"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setLoading(true);
                                                                // setLipColor(s.color); // Set the color using state
                                                                setLipColor(`${s.color}70`); // Set the color using state
                                                            }}
                                                            style={{ backgroundColor: s.color, color: s.text }}
                                                        >
                                                            {s.title}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                                <div className={`actionElements tab-pane fade ${activeTab === 'other' ? ' show active' : ''}`} id="other">
                                    <a
                                        href="/arVr"
                                        className='pull-right btn btn-outline-danger'
                                        onClick={(e) => {
                                            const ShowLandMarks = localStorage.getItem('ShowLandMarks');
                                            if (ShowLandMarks && Number(ShowLandMarks) === 1) {
                                                localStorage.setItem('ShowLandMarks', 0);
                                            } else {
                                                localStorage.setItem('ShowLandMarks', 1);
                                            }
                                            e.preventDefault();
                                        }}
                                    >
                                        {localStorage.getItem('ShowLandMarks') === '1' ? 'Hide' : 'Show'} LandMarks
                                    </a>

                                    <select
                                        value={selectedCamera}
                                        onChange={(e) => {
                                            if (e.target.value && e.target.value !== '') {
                                                // alert(e.target.value);
                                                setSelectedCamera(e.target.value);
                                                localStorage.setItem('selectedCamera', e.target.value);
                                            }
                                        }}
                                    >
                                        <option option="">Select Camera</option>
                                        {availabelCam && availabelCam.length > 0 && availabelCam.map((s, i) => (
                                            <>
                                                {s.deviceId ? (
                                                    <option value={s.deviceId} key={s.deviceId}>
                                                        {s.label || `Camera ${i + 1}`}<hr/>
                                                    </option>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ))}
                                        
                                    </select>

                                    
                                    #{hasPermission}#
                                    ##{availabelCam.length}##
                                    {/* onClick={captureScreenshot} */}
                                    <button onClick={captureScreenshot} className="btn btn-success text-white">
                                        Capture Screenshot
                                    </button>
                                </div>

                                <div className='clearBoth'></div>

                            </div>

                            <div className='clearBoth'></div>

                        </div>
                    </div>



                </div>
            </div>
        </section>
    );
};

export default ArVrInded;
