import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASEAPIURL, getTodayDate, headerUserAPI } from '../../../config'
import UsageLimitAlert from "./UsageLimitAlert";

const UsageLimitCheck = ({ setTwoFactor, TwoFactor }) => {
    const [timeLimit, setTimeLimit] = useState(0);
    const [timeSpent, setTimeSpent] = useState(0);
    async function getTimeLimit() {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            const response = await axios.post(`${BASEAPIURL}/setting/getValue?ry=sjain`, { settingNav: 'usageTime', get: new Date() }, { headers: headers });
            if (response && response.data && response.data.message && response.data.message.setReminder) {
                // setTimeLimit(response.data.message.setReminder);
                setTimeLimit(Number(response.data.message.setReminder) * 60);
                // console.log(Number(response.data.message.setReminder) * 60);
                // console.log(`Number(response.data.message.setReminder) $ ${Number(response.data.message.setReminder)}`);
            } else {
                setTimeLimit(86400);
            }
        } catch (error) {
            // console.error('Error making post request:', error.message);
            setTimeLimit(86400);
            // You could also handle displaying error feedback to the user here
        }
    }

    async function updateDailyUsages(currentSpentTime) {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            await axios.post(`${BASEAPIURL}/user/updateDailyUsages?ry=sjain`, { forDate: getTodayDate(), seconds: currentSpentTime, get: new Date() }, { headers: headers });
            // alert(1);
        } catch (error) {
            console.error('Error making post request:');
            console.error(error.message);
            // You could also handle displaying error feedback to the user here
        }
    }

    async function getDailyUsages(currentSpentTime) {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            const temp = await axios.post(`${BASEAPIURL}/user/getDailyUsages?ry=sjain`, { forDate: getTodayDate(), seconds: currentSpentTime, get: new Date() }, { headers: headers });
            if (temp.data.data && temp.data.data.length > 0) {
                setTimeSpent(temp.data.data[0].seconds);
            } else {
                setTimeSpent(0);
            }
        } catch (error) {
            setTimeSpent(0);
            console.error('Error making post request:');
            console.error(error.message);
            // You could also handle displaying error feedback to the user here
        }
    }

    useEffect(() => {
        getTimeLimit();
        getDailyUsages();
        // for tracking the time spent on App
        const savedElapsedTime = parseInt(localStorage.getItem('elapsedTime'), 10) || 0;
        setTimeSpent(savedElapsedTime);
        const timeout = setTimeout(() => {
            const interval = setInterval(() => {
                setTimeSpent((prevTime) => {
                    const newTime = prevTime + 1;
                    // Check if the time limit is reached or a multiple of it
                    if (newTime % timeLimit === 0 && localStorage.getItem('UsageLimitForgot') !== getTodayDate()) {
                        setTwoFactor('UsageLimitCrossed');
                    }
                    // Update daily usage every 30 seconds
                    if (newTime > 55 && newTime % 55 === 0) {
                        updateDailyUsages(newTime);
                    }
                    // Save to localStorage
                    localStorage.setItem('elapsedTime', newTime);
                    return newTime;
                });
            }, 1000); // Interval runs every second after timeout
            // Clear interval on component unmount
            return () => clearInterval(interval);
        }, 10000); // 30-second delay before starting interval

        // Clear timeout on component unmount
        return () => clearTimeout(timeout);
      }, [timeLimit]);


    return (
        <>
            {/* timeSpent && timeLimit && timeSpent >= timeLimit &&  */}
            {TwoFactor ==="UsageLimitCrossed" ? <UsageLimitAlert timeSpent={timeSpent} setTimeSpent={setTimeSpent} setTwoFactor={setTwoFactor} /> : ''}
        </>
    )
}

export default UsageLimitCheck
