import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessInfoPopup = () => {

  const { showInfoPopup, setShowInfoPopup, pageAbout, setPageAbout, createPageInfo } = useContext(businessContext);

  return (
    <>
      <Modal show={showInfoPopup} onHide={() => setShowInfoPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Info</h4>
          
          <span className='bg-secondary-subtle rounded-3 px-3 mb-3 shadow-sm'>Date</span>

          <input type="date" className='d-block form-control border-0 border-bottom ms-3 mb-4' max={new Date().toISOString().split('T')[0]}  
            value={pageAbout.yearOfEstablishment} onChange={(e) => setPageAbout((prevState) => ({ ...prevState, yearOfEstablishment: e.target.value, }))} 
          />

          <span className='bg-secondary-subtle rounded-3 px-3 mb-3 shadow-sm'>About Page</span>

          <textarea id="businessBio" className="form-control ms-3 mb-3" style={{ height: '150px' }} placeholder='Write about your page...'
            value={pageAbout.aboutPage}  onChange={(e) => setPageAbout((prevState) => ({ ...prevState, aboutPage: e.target.value, }))}
          ></textarea>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createPageInfo}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessInfoPopup;