import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getSkillDetails, updateSkills } from '../../../UseContext/ApiHelpers';
import { profileContext } from '../../../UseContext/ProfileContext';
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';


const EditSkillsPopup = ({ open, close, skillsId, skillObj }) => {

    const skillsCategory = [
        {
            "category_id": 1,
            "category_name": "Technical Skills",
            "skills": [
                "Programming",
                "Database Management",
                "Network Administration",
                "Data Analysis",
                "Software Development",
                "Cybersecurity"
            ]
        },
        {
            "category_id": 2,
            "category_name": "Soft Skills",
            "skills": [
                "Communication",
                "Teamwork",
                "Problem Solving",
                "Adaptability",
                "Time Management",
                "Leadership"
            ]
        },
        {
            "category_id": 3,
            "category_name": "Language Skills",
            "skills": [
                "English",
                "Spanish",
                "French",
                "Mandarin",
                "German",
                "Japanese"
            ]
        },
        {
            "category_id": 4,
            "category_name": "Leadership Skills",
            "skills": [
                "Strategic Planning",
                "Conflict Resolution",
                "Decision Making",
                "Team Building",
                "Motivation",
                "Change Management"
            ]
        },
        {
            "category_id": 5,
            "category_name": "Creative Skills",
            "skills": [
                "Graphic Design",
                "Copywriting",
                "Photography",
                "Music Composition",
                "Artistic Drawing",
                "Innovation"
            ]
        }
    ];

    const { setSkillData } = useContext(profileContext)
    const {loggedInUserId} = useContext(ApiContext)

    const [range, setRange] = useState(1)
    const [category, setCategory] = useState('');
    const [skill, setSkills] = useState('');

    const handleCategoryChange = (e) => {
        const category = e.target.value;
        setCategory(category);
    };

    const skillsDetails = { skillId: skillsId, skillLevel: range, skillCategory: category, skillName: skill }

    var filteredSkills = (skillsCategory.find(function (categoryObj) {
        return categoryObj.category_name === category;
    }) || {}).skills || [];


    useEffect(() => {
        setRange(skillObj.skillLevel)
        setCategory(skillObj.skillCategory)
        setSkills(skillObj.skillName)
    }, [skillObj])

    async function handleSubmit(e) {
        e.preventDefault()
        await updateSkills(skillsDetails)
            .then(() => getSkillDetails(loggedInUserId)
                .then(res => setSkillData(res)))
        close();
    }

    return (
        <div>
            <Modal
                show={open}
                onHide={close}
                centered
                backdrop="static"
                keyboard={false}
                className="create-call"
            >
                <Modal.Header closeButton>&nbsp;</Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={handleSubmit}>
                        <h5 >Skills</h5>

                        <div className="">
                            <span
                                className="d-flex align-content-center"
                                style={{
                                    margin: '10px 0px',
                                    background: '#f5f5f5',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    width: "150px",
                                }}
                            >
                                <img src={`${BASE_URL_STATIC}/icon/platform.svg`} alt="" />
                                <p
                                    style={{
                                        fontSize: '15px',
                                        margin: 'auto',
                                        color: '#616161',
                                    }}
                                >
                                    Skill Categories
                                </p>
                            </span>



                            <div className="col-lg-11">
                                <select
                                    className="form-control"
                                    required
                                    style={{
                                        fontWeight: '600',
                                        border: '0px',
                                        borderBottom: '2px solid #a6a6a659',
                                        marginTop: '12px',
                                    }}
                                    onChange={handleCategoryChange}
                                    value={category}
                                >
                                    <option className='' style={{ textAlign: "center", color: '#616161' }}>--select--</option>
                                    {skillsCategory.map((e, i) => (
                                        <option key={i} value={e.category_name}>{e.category_name}</option>
                                    ))}
                                </select>

                            </div>

                        </div>

                        <div className="">
                            <span
                                className="d-flex align-content-center"
                                style={{
                                    margin: '10px 0px',
                                    background: '#f5f5f5',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    width: "120px",
                                }}
                            >
                                <img src={`${BASE_URL_STATIC}/icon/degree.svg`} alt="" />

                                <p
                                    style={{
                                        fontSize: '15px',
                                        margin: 'auto',
                                        color: '#616161',
                                    }}
                                >
                                    Skill Name
                                </p>
                            </span>

                            <div className="col-lg-11">
                                <select
                                    className="form-control"
                                    required
                                    style={{
                                        fontWeight: '600',
                                        border: '0px',
                                        borderBottom: '2px solid #a6a6a659',
                                        marginTop: '12px',
                                    }}
                                    value={skill}
                                    onChange={(e) => setSkills(e.target.value)}
                                >
                                    <option className='' style={{ textAlign: "center", color: '#616161' }}>--select--</option>
                                    {filteredSkills.map((skill, i) => (
                                        <option key={i} value={skill}>{skill}</option>
                                    ))}
                                </select>
                            </div>

                        </div>

                        <div className="">
                            <span
                                className="d-flex align-content-center"
                                style={{
                                    margin: '10px 0px',
                                    background: '#f5f5f5',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    width: "130px",
                                }}
                            >
                                <img src={`${BASE_URL_STATIC}/icon/location.svg`} alt="" />

                                <p
                                    style={{
                                        fontSize: '15px',
                                        margin: 'auto',
                                        color: '#616161',
                                    }}
                                >
                                    Current Level
                                </p>
                            </span>

                            <div>

                                <input type="range" className="w-100" min="1" max="3" value={range} onChange={(e) => setRange(e.target.value)} id="customRange2" style={{ height: "2px", width: "full" }} />

                                <div className='flex my-2'>
                                    <button type="button" className="btn btn-primary" onClick={() => setRange(1)}>Beginner</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRange(2)}>Intermediate</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setRange(3)}>Expert</button>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type="submit" className="btn btn-dark mt-4 col-lg-4">Save</button>
                        </div>


                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EditSkillsPopup;
