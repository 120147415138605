import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessWebsiteEmailPopup = () => {

  const { 
    showWebsiteEmailPopup, setShowWebsiteEmailPopup,
    pageAbout, setPageAbout,
    createWebsiteEmail, 
    setPageDetails,
  } = useContext(businessContext);

  // To handle website link & email change
  const handleWebsiteEmailChange = (e) => {
    setPageAbout((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    setPageDetails((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <Modal show={showWebsiteEmailPopup} onHide={() => setShowWebsiteEmailPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Website Link</h4>

          <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-3'>Link</span>
          <input type="text" className='form-control ms-3 mb-5 border-0 border-bottom shadow-none' name='websiteLink' placeholder='www.example.com...' 
            value={pageAbout.websiteLink} onChange={handleWebsiteEmailChange} 
          />

          {/* <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-3'>Email</span>
          <input type="text" className='form-control ms-3 mb-5 border-0 border-bottom shadow-none' name='email' placeholder='lorem@example.com...' 
            value={pageAbout.email} onChange={handleWebsiteEmailChange} 
          /> */}

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createWebsiteEmail}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessWebsiteEmailPopup
