import React, { useState, useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall';
import { Link } from "react-router-dom";
import FollowUnfollowButton from '../../../Button/FollowUnfollowButton';
import { BASEURL, BASE_URL_STATIC } from '../../../../../config';
import { Button, ButtonGroup } from 'react-bootstrap'; // Import React-Bootstrap Button and ButtonGroup

const ProfileFollowerPopup = ({ showFollowerPopup, setShowFollowerPopup }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('followers'); // Default to 'followers' tab
  const [followerSearchUsersList, setFollowerSearchUsersList] = useState([]); // To hold search results

  const { loggedInUserId, followerList = [], followingList = [], pendingList = [] } = useContext(ApiContext);

  // Effect hook to reset search query and results when tab changes
  useEffect(() => {
    setSearchQuery(''); // Reset search query on tab switch
    setFollowerSearchUsersList([]); // Clear search results
  }, [activeTab]);

  // Effect hook to set active tab to 'followers' when modal is opened
  useEffect(() => {
    if (showFollowerPopup) {
      setActiveTab('followers'); // Automatically set to 'followers' when modal opens
    }
  }, [showFollowerPopup]);

  const handleFollowerSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query

    const query = e.target.value.toLowerCase(); // Normalize for case-insensitive search
    if (activeTab === 'followers') {
      setFollowerSearchUsersList(
        followerList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    } else if (activeTab === 'following') {
      setFollowerSearchUsersList(
        followingList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    } else if (activeTab === 'pending') {
      setFollowerSearchUsersList(
        pendingList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    }
  };

  const getTabContent = () => {
    switch (activeTab) {
      case 'followers':
        return followerSearchUsersList.length > 0 ? followerSearchUsersList : followerList;
      case 'following':
        return followerSearchUsersList.length > 0 ? followerSearchUsersList : followingList;
      case 'pending':
        return followerSearchUsersList.length > 0 ? followerSearchUsersList : pendingList;
      default:
        return [];
    }
  };

  const tabContent = getTabContent();

  return (
    <section>
      <Modal
        show={showFollowerPopup}
        onHide={() => setShowFollowerPopup(false)}
        centered
        backdrop="static"
        keyboard={false}
        className="create-call"
      >
        <Modal.Header closeButton style={{ paddingRight: '10px' }}>&nbsp;</Modal.Header>
        <Modal.Body>
          {/* Tabs for Followers, Following, and Pending */}
          <div className="pfp-tabs mb-3">
            <ButtonGroup className="w-100">
              {['followers', 'following', 'pending'].map((tab) => (
                <Button
                  key={tab}
                  variant={activeTab === tab ? 'primary' : 'outline-secondary'}
                  className="flex-fill"
                  onClick={() => {
                    setActiveTab(tab); // Set the active tab when a tab is clicked
                    setSearchQuery(''); // Clear search query when switching tabs
                    setFollowerSearchUsersList([]); // Clear search results
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          {/* Search */}
          <div className="pfp-search mb-3">
            <form className="pfp-search-form">
              <img className="pfp-search-img" src={`${BASE_URL_STATIC}/icon/Search-2.svg`} alt="search" />
              <label htmlFor="followerSearch">
                <input
                  type="text"
                  id="followerSearch"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleFollowerSearch}
                />
              </label>
            </form>
            <span className="pfp-search-filter ms-2 ms-md-0">Filter</span>
          </div>

          {/* Tab Content */}
          <div className="follow_pop">
            {tabContent.length > 0 ? (
              <div className="px-2">
                {tabContent.map((user, index) => (
                  <div className="d-flex align-items-center mb-2 mb-lg-3" key={user.userId || index}>
                    <img
                      className="pfp-profile rounded-circle"
                      src={user.imageUrl || `${BASE_URL_STATIC}/dummy.png`}
                      alt={user.fname}
                      onError={(e) => (e.target.src = `${BASE_URL_STATIC}/dummy.png`)}
                    />

                    <Link
                      to={`${BASEURL}/profile/${user.userId}`}
                      className="ms-2 ms-sm-3 me-auto text-dark"
                      onClick={() => setShowFollowerPopup(false)}
                    >
                      <h6 className="pfp-name fw-bold">{user.fname}</h6>
                      <small className="text-secondary">
                        {user.userName || 'username06@'}
                      </small>
                    </Link>

                    {user.userId !== loggedInUserId && (
                      <FollowUnfollowButton otherUserId={user.userId} />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-muted">
                No {activeTab} found.
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ProfileFollowerPopup;
