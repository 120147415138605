// import { BASEAPIURL } from "../../../../../config";
import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { profileContext } from "../../../UseContext/ProfileContext";
import { myProfile } from "../../../UseContext/ApiHelpers";
import axios from "axios";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../../../config";

const ZodiacSign = ({ open, close, config, userProfile }) => {

  const signs = [
    {
      sign: "Aries",
    },
    {
      sign: "Taurus",
    },
    {
      sign: "Gemini",
    },
    {
      sign: "Cancer",
    },
    {
      sign: "Leo",
    },
    {
      sign: "Virgo",
    },
    {
      sign: "Libra",
    },
    {
      sign: "Scorpio",
    },
    {
      sign: "Sagittarius",
    },
    {
      sign: "Capricorn",
    },
    {
      sign: "Aquarius",
    },
    {
      sign: "Pisces",
    },
  ];

  const { zodiacVal, setzodiacVal } = useContext(profileContext);

  const data = {
    ...userProfile,
    zodiac: zodiacVal,
  };

  const updateUser = async () => {
    try {
      // Assuming education is an array of education objects
      const res = await axios.post(
        `${BASEAPIURL}/user/updateuserprofile`,
        {
          ...data
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error creating hangout:", error);
    }
  };

  function handleZodiacChange(e) {
    setzodiacVal(e.target.value);
  };

  function handleSubmit(e) {
    e.preventDefault();

    close();

    updateUser().then((res) =>
      myProfile(config).then((res) => setzodiacVal(res.zodiac))
    );
  };

  return (
    <>
      <section>
        <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="POST" onSubmit={handleSubmit}>
              <h5 style={{ fontFamily: "metropolis" }}>Zodiac Sign</h5>
              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "120px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/zodiac.svg`} alt="" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                      fontFamily: "metropolis",
                    }}
                  >
                    Zodiac Sign
                  </p>
                </span>

                <div className="col-lg-11">
                  <select
                    onChange={handleZodiacChange}
                    className="form-control"
                    required
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #a6a6a659",
                      marginTop: "12px",
                    }}
                    defaultValue={(userProfile && userProfile.zodiac) || ""}
                  >
                    <option value="" disabled
                      className=""
                      style={{ textAlign: "center", color: "#616161" }}
                    >
                      --select--
                    </option>
                    {signs.map((e) => (
                      <option key={e.sign} value={e.sign}>
                        {e.sign}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-dark mt-4 col-lg-4">Save</button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ZodiacSign;
