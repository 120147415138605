import React, { useContext } from 'react';
import { businessContext } from '../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../config';
import { useNavigate } from 'react-router-dom';


const BasicInfo = () => {

  const navigate = useNavigate();

  const { pageBasicInfo, setPageBasicInfo, categories, handleCreatePage } = useContext(businessContext);

  // console.log('pageBasicInfo : ', pageBasicInfo);

  // Function to get the subcategories for the selected category
  const getSubCategories = (selectedCategory) => {
    const categoryObj = categories.find(cat => cat.category === selectedCategory);

    return categoryObj ? categoryObj.subcategories : [];
  };

  // To handle cancel
  const handleCancel = () => {
    setPageBasicInfo({
      pageName: '',
      pageType: '',
      pageCategory: '',
      pageSubCategory: '',
    });
  };

  return (
    <>
   
      <section className='business-basicInfo vh-100 overflow-y-scroll scrollbar-hidden d-flex'>
        {/* Left Block  */}
        <div className='business-basicInfo-lb bg-primary overflow-y-scroll scrollbar-hidden d-none d-lg-block'>
          <img src="https://demo3.sjainventures.com/e-milo/images/Loginimg.png" className='h-100 w-100 object-fit-contain' alt="basic-info" />
        </div>

        {/* Right Block */}
        <div className='business-basicInfo-rb overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-sm-5' style={{ backgroundColor: '#fdfdfd' }}>
          {/* logo */}
          <div className='d-flex align-items-center py-3 py-md-4'>
            <i className='fas fa-chevron-left cursor-pointer' onClick={() => navigate(`/`)} style={{ height: '20px' }}></i>

            <div className='mx-auto cursor-pointer' onClick={() => navigate(`/`)}>
              <img src={`${AwsFileUrl}/images/logo.png`} style={{ height: '45px' }} alt="logo" />
            </div>
          </div>

          <h3 className='m-0'>Basic Info</h3>

          <div className='mt-3 mt-sm-4 ms-2 ms-sm-3 ms-lg-4'>
            {/* Page Name */}
            <div>
              <h4>Page Name</h4>

              <input type="text" className='form-control my-2' value={pageBasicInfo.pageName} onChange={(e) => setPageBasicInfo({ ...pageBasicInfo, pageName: e.target.value })} placeholder='Please enter your page name...' />

              <span className='text-secondary'>Enter a name that represents your business, brand, or personal profile. This name will be visible to others and should reflect the purpose of your page.</span>
            </div>

            <hr className='hr bg-secondary' />

            {/* Create Page For */}
            <div>
              <h4>Create Page For</h4>

              <span className='text-secondary my-1'>Choose the purpose of your page, whether it's for a business, personal profile, brand, or other type, to tailor the setup to your needs.</span>

              <select className="form-select" value={pageBasicInfo.pageType} onChange={(e) => setPageBasicInfo({ ...pageBasicInfo, pageType: e.target.value })} aria-label="Select Page Type">
                <option value="" disabled>Select Page Type</option>
                <option value="businessPage">Business Page</option>
                <option value="publicFigurePage">Public Figure Page</option>
              </select>
            </div>

            <hr className='hr bg-secondary' />

            {/* Page Category */}
            <div>
              <h4>Page Category</h4>

              <span className='text-secondary my-1'>Choose the category that best fits your page to help others understand what it's about.</span>

              <select className="form-select" value={pageBasicInfo.pageCategory} onChange={(e) => setPageBasicInfo({ ...pageBasicInfo, pageCategory: e.target.value, pageSubCategory: '' })} aria-label="Select Page Category" >
                <option value="" disabled>Select Page Category</option>

                {
                  categories.map((cat, index) => (
                    <option key={index} value={cat.category}>{cat.category}</option>
                  ))
                }
              </select>
            </div>

            <hr className='hr bg-secondary' />

            {/* Sub Category */}
            <div>
              <h4>Sub Category</h4>

              <span className='text-secondary my-1'>Choose multiple subcategories to give a clearer picture of your page’s focus or offerings.</span>

              <select className="form-select mt-2 mb-3 mb-sm-4" value={pageBasicInfo.pageSubCategory} onChange={(e) => setPageBasicInfo({ ...pageBasicInfo, pageSubCategory: e.target.value })} aria-label="Select Sub Category" >
                <option value="" disabled>Select Sub Category</option>

                {
                  getSubCategories(pageBasicInfo.pageCategory).map((subcat, index) => (
                    <option key={index} value={subcat}>{subcat}</option>
                  ))
                }
              </select>

              {/* Add More Sub Categories */}
              {/* <div className='px-3 py-1 d-inline-block rounded-3 bg-white' style={{ border: '1px dotted gray' }}>
                <img src="/images/business/icons/add-circle.svg" className='me-2' style={{ height: '20px', width: '20px' }} alt="add-circle" />
                <span>Add More Sub Categories</span>
              </div> */}
            </div>

            {/* Cancel & Done */}
            <div className='mt-4 mt-lg-5 mb-5 d-flex justify-content-end'>
              <button type="button" className='btn btn-secondary w-25 ms-auto me-3 me-sm-4 me-xl-5' onClick={handleCancel}>Cancel</button>

              <button type="button" className='btn btn-primary w-25' onClick={handleCreatePage} 
                disabled={ pageBasicInfo.pageName === '' || pageBasicInfo.pageType === '' || pageBasicInfo.pageCategory === '' || pageBasicInfo.pageSubCategory === '' }
              >Done</button>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
};

export default BasicInfo;