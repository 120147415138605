import React, { useContext, useState, useMemo } from "react";
import { ApiContext } from "../../../../UseContext/ApiCall";
import Modal from 'react-bootstrap/Modal';
import PhotoVideoTags from "./PhotoVideoTags";
import toast from "react-hot-toast";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StoryPhotoVideoPopup = () => {

  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([])
  const [selectedImage, setSelectedImage] = useState([]);
  const [loader, setLoader] = useState(false)

  const {
    photoVideos, setPhotoVideos,
    showStoryPhotoVideoPopup, setShowStoryPhotoVideoPopup,
    createUserStories,
    setshowCreateStoriesPopup,
    setShowStoriesAdvanceSettingPopup,
    setStoriesAdvanceSetting,
    config
  } = useContext(ApiContext);


  // To handle create stories
  const handleCreateStories = async () => {
    setLoader(true)
    await photoVideos.forEach((file, i) => {
      const formData = new FormData();
      formData.append('mediaUrls', file.file);
      if (file.type.startsWith("video")) {
        formData.append('thumbnail', selectedImage[i]);
      }
      formData.append('postTumbnailUrl', selectedImage)
      createUserStories(formData, 'post', tagsByCarouselItem[i], "").then(() => {
        setLoader(false)
        setShowStoryPhotoVideoPopup(false);
      }).catch((err) => {
        toast.error("Failed to post story")
        setLoader(false)
      });
    });

    // setPhotoVideos([]);

    setStoriesAdvanceSetting({
      visibility: 'public',
      expire: '24',
      like: 'on',
      comment: 'on',
    });
  };

  useMemo(() => {
    if (Array.isArray(photoVideos)) {
      // Map through photoVideos and create object URLs for each file
      const urls = photoVideos.map((item) => {
        if (item.file) {
          return URL.createObjectURL(item.file);
        }
        return null; // Handle cases where item.file might be missing
      }).filter(Boolean); // Remove any null values


      // Find the index of the first video file in photoVideos
      // const index = photoVideos.findIndex((item) => item.file && item.file.type.includes("video"));

      ;

      // Cleanup function for revoking object URLs
      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    }
  }, [photoVideos]);


  return (
    <>
      <Modal show={showStoryPhotoVideoPopup} onHide={() => setShowStoryPhotoVideoPopup(false)} centered backdrop="static" keyboard={false} className="create-call create-stories-popup" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className='p-3'>
          {/* Header */}
          <div className='d-flex mt-3'>
            <span className='cursor-pointer' onClick={() => { setShowStoryPhotoVideoPopup(false); setshowCreateStoriesPopup(true) }}>⮜</span>
            <h5 className='mb-0 mx-auto fw-semibold'>Add to Stories</h5>
          </div>

          <PhotoVideoTags setShowStoryPhotoVideoPopup={setShowStoryPhotoVideoPopup} config={config} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} selectedImage={selectedImage} setSelectedImage={setSelectedImage} setPhotoVideos={setPhotoVideos} photoVideos={photoVideos} />

          {/* Button */}
          <div className="text-center mt-4">
            <button type="button" className="btn btn-primary w-50 rounded-5" disabled={loader} onClick={handleCreateStories}>
              {
                loader
                  ?
                  <span>
                    <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />
                    {" "} Adding...
                  </span>
                  :
                  'Add to Stories'}
            </button>
          </div>

          {/* Advance Setting */}
          <div className="text-center">
            <small className="text-primary cursor-pointer"
              onClick={() => {
                setShowStoriesAdvanceSettingPopup(true);
              }}>Advance Setting</small>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoryPhotoVideoPopup;