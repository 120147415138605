import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { FaceMesh } from '@mediapipe/face_mesh';
import * as cam from '@mediapipe/camera_utils';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

const ArVrEffectsSection = ({ glassesObjUrl, windowWidth, windowHeight }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const sceneRef = useRef(null);
    const rendererRef = useRef(null);
    const cameraRef = useRef(null);
    const glassesRef = useRef(null);

    useEffect(() => {
        // Initialize the canvas and 3D scene
        const canvasElement2 = canvasRef.current;
        // const canvasCtx = canvasElement2.getContext('2d');
        rendererRef.current = new THREE.WebGLRenderer({ canvas: canvasElement2 });
        rendererRef.current.setSize(windowWidth, windowHeight);
        cameraRef.current = new THREE.PerspectiveCamera(75, windowWidth / windowHeight, 0.1, 1000);
        sceneRef.current = new THREE.Scene();
        
        // Load the glasses 3D model
        const loader = new OBJLoader();
        // glassesObjUrl
        loader.load('/assets/arVr/glasses2.obj', (object) => {
            glassesRef.current = object;
            sceneRef.current.add(object);
        });

        // Setup face mesh detector
        const faceMesh = new FaceMesh({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });

        faceMesh.setOptions({
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });

        // onResults callback
        const onResults = (results) => {
            if (results.multiFaceLandmarks && glassesRef.current) {
                const landmarks = results.multiFaceLandmarks[0];
                console.log('landmarks');
                console.log(landmarks);
                console.log('landmarks');
                // Extract key face landmarks for glasses positioning
                const leftEye = landmarks[33];
                const rightEye = landmarks[263];
                const nose = landmarks[1];

                const leftEyeX = leftEye.x * windowWidth;
                const rightEyeX = rightEye.x * windowWidth;
                const noseX = nose.x * windowWidth;

                const glassesWidth = Math.abs(rightEyeX - leftEyeX) * 2; // Scale factor for glasses width
                const glassesHeight = glassesWidth / 2; // Adjust the height to maintain the aspect ratio

                // Position and scale the glasses
                glassesRef.current.scale.set(glassesWidth / 100, glassesHeight / 100, 1);
                glassesRef.current.position.set(noseX / 100, (leftEye.y + rightEye.y) / 2 * windowHeight / 100, -1);
            }
        };

        // Start camera and processing
        const camera = new cam.Camera(videoRef.current, {
            onFrame: async () => {
                await faceMesh.send({ image: videoRef.current });
            },
            width: windowWidth,
            height: windowHeight,
        });
        camera.start();

        faceMesh.onResults(onResults);

        // Set up the video and canvas for drawing face mesh and 3D model
        const videoElement = document.getElementById('inputVideo');
        videoElement.width = windowWidth;
        videoElement.height = windowHeight;

        const canvasElement = document.getElementById('outputCanvas');
        canvasElement.width = windowWidth;
        canvasElement.height = windowHeight;

        // Start rendering loop for the 3D scene
        const animate = () => {
            requestAnimationFrame(animate);
            if (glassesRef.current) {
                rendererRef.current.render(sceneRef.current, cameraRef.current);
            }
        };
        animate();

    }, [glassesObjUrl, windowWidth, windowHeight]);

    return (
        <div>
            <video
                id="inputVideo"
                ref={videoRef}
                style={{ display: 'none' }}
                autoPlay
                muted
                className="videoCreateARVR"
            />
            <canvas id="outputCanvas" ref={canvasRef} className="canvasCreateARVR" />
        </div>
    );
};

export default ArVrEffectsSection;
