import React, { useContext, useEffect, useState } from "react";
import { AwsFileUrl, BASE_URL_STATIC } from '../../../../../config'
import { CContainer } from "@coreui/react";
import { BsBagCheck } from "react-icons/bs";
import { BASEURL } from "../../../../../config";
import { Link } from "react-router-dom";
import { getAddressDetails, getEducationDetails, getFavFood, getFavMovie, getHangoutDetails, getHobby, getInspiration, getRelation, getSkillDetails, getSocialDetails, getWorkDetails, otherProfile } from "../../../UseContext/ApiHelpers";
import { profileContext } from "../../../UseContext/ProfileContext";
import { places, skillsCategory, socialMedia } from "../../../Data/EducationData";
import { IoMdHome, IoMdLocate } from "react-icons/io";

const AboutUser = ({ paramsUserId }) => {

   const [inspirationList, setinspirationList] = useState([])

   const { userProfile, setUserProfile, bioInput, setBioInput, address, setAddress, educationDetails, setEducationDetails, social, setSocial, hangoutDetails, setHangoutDetails, zodiacVal, setzodiacVal, workData, setWorkData, skillData, setSkillData, selectedHobbies, setSelectedHobbies, movieDetails, setMovieDetails, foodDetails, setfoodDetails, relationsData, setRelationsData } = useContext(profileContext);

   // const { paramsUserId } = useParams()

   useEffect(() => {
      otherProfile(paramsUserId).then((res) => {
         setzodiacVal(res.zodiac); setUserProfile(res);
         setBioInput(res.bio);
      });
      getHobby(paramsUserId).then((res) => setSelectedHobbies(res));
      getHangoutDetails(paramsUserId).then((res) => setHangoutDetails(res));
      getEducationDetails(paramsUserId).then((res) => setEducationDetails(res));
      getSocialDetails(paramsUserId).then((res) => setSocial(res));
      getWorkDetails(paramsUserId).then((res) => {
         setWorkData(res);
      });
      getAddressDetails(paramsUserId).then((res) => { setAddress(res); });

      getSkillDetails(paramsUserId).then((res) => setSkillData(res));
      getFavMovie(paramsUserId).then(res => { setMovieDetails(res) })
      getFavFood(paramsUserId).then(res => { setfoodDetails(res) })
      getRelation(paramsUserId).then(res => {
         setRelationsData(res)
      })
      getInspiration(paramsUserId).then((res) => {
         setinspirationList(res)
      })

   }, [paramsUserId]);


   return (
      <>
         <section className="">
            <CContainer>
               <div className="profile-flex">
                  <div
                     className="col-lg-12"
                     style={{ display: "flex", justifyContent: "end" }}
                  >
                     <button
                        type="submit"
                        name="submit"
                        value="Save"
                        className="d-none"
                     >
                        <img src={`${BASE_URL_STATIC}/icon/save.svg`} alt="" />
                     </button>
                  </div>
               </div>

               <div className="profile-flex" style={{ flexDirection: "column" }}>
                  <div
                     className="col-lg-12"
                     style={{ fontSize: "20px" }}
                  >
                     <span>Bio</span>
                  </div>
                  <div className="col-lg-12" >
                     <span className="d-flex px-3 justify-content-between align-items-center">
                        <small className="text-muted p-2">
                           {bioInput
                              ? bioInput.substr(0, 280) + "..."
                              : "Tell me about yourself"}
                        </small>
                     </span>
                     <div
                        style={{
                           background: "#E8E8E8",
                           height: "0.5px",
                           marginRight: "20px",
                        }}
                     ></div>
                  </div>
               </div>

               <div className="profile-flex">
                  <div
                     className="col-lg-12 mt-4"
                     style={{ display: "flex", justifyContent: "end" }}
                  >
                  </div>
               </div>

               {
                  inspirationList && inspirationList.length > 0
                  &&
                  <div className="profile-flex" style={{ flexDirection: "column" }}>
                     <div className="col-lg-12" style={{ fontSize: "20px" }} >
                        <span>Inspiration</span>
                     </div>

                     <div className="col-12 d-flex flex-column gap-2 m-2 px-4 my-3">
                        {inspirationList && inspirationList.map((e, i) => (
                           <div key={i}>

                              <div className="d-flex justify-content-between gap-5 align-items-center my-2">
                                 <div className="d-flex justify-content-start gap-2 align-items-center" style={{ cursor: "pointer", fontSize: "14px" }}>

                                    <div className="imgBox">
                                       <img src={AwsFileUrl + (e.findUser && e.findUser[0].imageId)} alt=""  width='50px' height='50px' className="rounded-pill" />
                                    </div>

                                    {e.findUser && e.findUser.length > 0 ? <span>{e.findUser && e.findUser[0].fname}</span> : <span>{e.create && e.create.inspiredBy}</span>}
                                 </div>

                                 <div className="d-none">
                                    {e.findUser && e.findUser.length > 0 && <span className="ins_link">
                                       <Link to={`${BASEURL}/profile/${e.findUser[0]._id}`}>
                                          view profile
                                       </Link>
                                    </span>}

                                 </div>
                              </div>

                              <hr className="profile_hr" />
                           </div>
                        ))}
                     </div>
                  </div>
               }

               <div className="row mt-4 mb-2">
                  <div
                     className="col-lg-6"
                     style={{ fontSize: "20px" }}
                  >
                     <span>Profile</span>
                  </div>

                  <div
                     className="col-lg-6"
                     style={{ display: "flex", justifyContent: "end" }}
                  >
                     <img src={`${BASE_URL_STATIC}/icon/setting.svg`} alt="" width={18} />
                  </div>
               </div>

               <div style={{ background: "#E8E8E8", height: "0.5px" }}></div>

               <CContainer className="form-group accordion " id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center  accordion-item" >

                     {/* <div className="col-lg-12"> */}
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{ fontSize: "15px" }}>
                           <b >
                              Personal Info
                           </b>
                        </span>
                     </h2>
                     <div >

                        <span className="d-none px-2">

                           <img
                              src={`${BASE_URL_STATIC}/icon/pen.svg`}
                              alt=""
                              style={{
                                 background: "#E8E8E8",
                                 padding: "5px",
                                 width: "22px",
                                 height: "22px",
                                 borderRadius: "30%",
                                 cursor: "pointer"
                              }}
                           />

                        </span>

                     </div>

                  </div>
                  <CContainer id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                     <div className="container p-3 accordion-body ">

                        <div className="form-group">
                           <div className="profile-flex">
                              <span className="d-flex" style={{ marginRight: "5px" }}>
                                 <img src={`${BASE_URL_STATIC}/icon/name.svg`} alt="" />
                              </span>
                              <small style={{ padding: "10px" }}>
                                 <label style={{ fontSize: "15px" }}>Name</label>

                                 <p className="user_data">
                                    {userProfile.fname}
                                 </p>
                                 {userProfile.username && <p className="user_data">
                                    <i className="text-primary">{userProfile.username}</i>
                                 </p>}
                              </small>
                           </div>
                        </div>
                        <hr className="profile_hr" />
                        <div className="form-group">
                           <div
                              className="profile-flex"
                           >
                              <span className="d-flex" style={{ marginRight: "5px" }}>
                                 <img src={`${BASE_URL_STATIC}/icon/dob.svg`} alt="" />
                              </span>
                              <small style={{ padding: "10px" }}>
                                 <label style={{ fontSize: "15px" }}>DOB</label>
                                 <p className="user_data">
                                    {userProfile.dob && new Date(userProfile.dob).toDateString()}
                                 </p>
                              </small>
                           </div>
                        </div>
                        <hr className="profile_hr" />

                        <div className="form-group">
                           <div
                              className="profile-flex"

                           >
                              <span className="d-flex" style={{ marginRight: "5px" }}>
                                 <img src={`${BASE_URL_STATIC}/icon/gender.svg`} alt="" />
                              </span>
                              <small style={{ padding: "10px" }}>
                                 <label style={{ fontSize: "15px" }}>Gender</label>
                                 <p className="user_data">
                                    {userProfile.gender}
                                 </p>
                              </small>
                           </div>
                        </div>
                        <hr className="profile_hr" />

                        <div className="form-group">
                           <div
                              className="profile-flex"

                           >
                              <span className="d-flex" style={{ marginRight: "5px" }}>
                                 <img src={`${BASE_URL_STATIC}/icon/married.svg`} alt="" />
                              </span>
                              <small style={{ padding: "10px" }}>
                                 <label style={{ fontSize: "15px" }}>Martial status</label>
                                 <p className="user_data">
                                    {userProfile.maritial}
                                 </p>
                              </small>
                           </div>
                        </div>
                        <hr className="profile_hr" />

                        {userProfile.spouse &&
                           <>
                              <div className="form-group">
                                 <div
                                    className="profile-flex"

                                 >
                                    <span className="d-flex" style={{
                                       marginRight: "5px", height: "31px",
                                       maxHeight: "50px", opacity: "0.6"
                                    }}>
                                       <img src={`${BASE_URL_STATIC}/icon/spouse.svg`} alt="ee" />
                                       {/* <img src={`/images/icon/spouse.svg`} alt="" /> */}
                                    </span>
                                    <small style={{ padding: "10px" }}>
                                       <label style={{ fontSize: "15px" }}>Spouse Name</label>
                                       <p className="user_data">
                                          {userProfile.spouse}
                                       </p>
                                    </small>
                                 </div>
                              </div>
                              <hr className="profile_hr" />

                              <div className="form-group">
                                 <div
                                    className="profile-flex"

                                 >
                                    <span className="d-flex" style={{ marginRight: "5px", height: "20px", opacity: "0.6" }}>
                                       <img src={`${BASE_URL_STATIC}/icon/maritial_date.svg`} alt="ee" />
                                    </span>
                                    <span style={{ padding: "10px" }}>
                                       <label style={{ fontSize: "15px" }}>Maritual Date</label>
                                       <p className="user_data">
                                          {userProfile.maritualDate && new Date(userProfile.maritualDate).toDateString()}
                                       </p>
                                    </span>
                                 </div>
                              </div>
                              <hr className="profile_hr" />
                           </>
                        }
                     </div>

                  </CContainer>
               </CContainer>

               <CContainer className="form-group accordion " id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     {/* <div className="col-lg-12"> */}
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ fontSize: "15px" }}>
                           <b >
                              Control Info
                           </b>
                        </span>
                     </h2>

                     {/* </div> */}
                  </div>
                  <CContainer id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                     <div className="container p-3 accordion-body">

                        <div className="form-group">
                           <div
                              className="profile-flex"
                           >
                              <span className="d-flex me-2">
                                 <img src={`${BASE_URL_STATIC}/icon/mobile.svg`} alt="" />
                              </span>
                              <small style={{ padding: "10px", minWidth: "200px" }}>
                                 <label style={{ fontSize: "15px" }}>Mobile</label>
                                 <p style={{ fontSize: "14px" }}>
                                    {userProfile.mobile}
                                 </p>
                              </small>
                           </div>
                        </div>
                        <hr className="profile_hr" />

                        <div className="form-group">
                           <div
                              className="profile-flex"
                           >
                              <span className="d-flex me-2" >
                                 <img src={`${BASE_URL_STATIC}/icon/mail.svg`} alt="" />
                              </span>
                              <span style={{ padding: "10px", minWidth: "200px" }}>
                                 <label style={{ fontSize: "15px" }}>Email</label>
                                 <p style={{ fontSize: "14px" }}>
                                    {userProfile.email}
                                 </p>
                              </span>
                           </div>
                        </div>
                        <hr className="profile_hr" />
                     </div>

                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">

                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{ fontSize: "15px" }}>
                           <b >
                              Places Lived
                           </b>
                        </span>
                     </h2>


                  </div>

                  <CContainer id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                     <div className="container p-3 accordion-body ">
                        {address && address.length > 0
                           ? address.map((address, index) => (
                              <div className="form-group" key={index}>
                                 <div
                                    className="flex my-2 align-items-start" style={{ marginRight: "-10px" }}
                                 >
                                    <div style={{ maxWidth: "79%" }}
                                       className="d-flex align-items-start justify-content-start"
                                    >
                                       <span
                                          className="d-flex "
                                          style={{ margin: "17px 0px" }}
                                       >
                                          {address.addressType === "permanent" ? (
                                             <IoMdHome size={25} color="gray" />
                                          ) : (
                                             <IoMdLocate size={25} color="gray" />
                                          )}
                                       </span>
                                       <span style={{ padding: "0px 10px" }}>
                                          <label style={{ fontSize: "15px" }}>
                                             {address.addressType}
                                          </label>
                                          <div>
                                             { }
                                             <div>
                                                <div className="flex justify-content-start gap-1" style={{ fontSize: "14px" }}>
                                                   <span style={{ fontWeight: "bold" }}>
                                                      {address.city}
                                                   </span>
                                                   , (<span>{address.state}</span>
                                                   <span>{address.country}</span>)
                                                </div>
                                                <div>
                                                   <small
                                                      style={{
                                                         color: "gray",
                                                      }}
                                                   >
                                                      {address.aboutPlace}
                                                   </small>
                                                </div>
                                             </div>
                                          </div>
                                       </span>
                                    </div>

                                 </div>
                              </div>
                           ))
                           : ""}
                     </div>
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-groups" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{ fontSize: "15px" }}>
                           <b >
                              Education
                           </b>
                        </span>
                     </h2>

                  </div>
                  <CContainer id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body ">
                        {educationDetails.length > 0 ?
                           educationDetails.map((e, i) => (
                              <div className="form-group" key={i}>
                                 <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }} >

                                    <div style={{ maxWidth: "79%" }}
                                       className="d-flex align-items-start justify-content-start"
                                    >
                                       {e.schColl === "school" ? (
                                          <span
                                             className="d-flex me-2 mt-2"
                                             style={{ marginRight: "5px" }}
                                          >
                                             <img src={`${BASE_URL_STATIC}/icon/highschool.svg`} alt="" />
                                          </span>
                                       ) : (
                                          <span
                                             className="d-flex"
                                             style={{ marginRight: "5px" }}
                                          >
                                             <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                                          </span>
                                       )}

                                       <span className="d-flex flex-column" style={{ padding: "0px 10px" }} >
                                          <label style={{ fontSize: "12px", marginBottom: "5px" }}>
                                             {e.schColl} &nbsp;{" "}
                                             {new Date(e.startDate).getFullYear()} -{" "}
                                             {new Date(e.endDate).getFullYear()}
                                          </label>
                                          <span style={{ fontSize: "14px" }}><b>{e.degree}</b> </span>
                                          <span style={{ fontSize: "12px", color: "gray" }}>{e.instituteName}</span>
                                       </span>
                                    </div>
                                    <div className="d-none">
                                       <img
                                          src={`${BASE_URL_STATIC}/icon/pen.svg`}
                                          alt="img"
                                          style={{
                                             background: "#E8E8E8",
                                             padding: "5px",
                                             width: "22px",
                                             height: "22px",
                                             borderRadius: "30%",
                                             cursor: "pointer"
                                          }}
                                       />


                                    </div>
                                 </div>

                                 <hr className="profile_hr" />
                              </div>
                           )
                           ) : ""}
                     </div>
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" style={{ fontSize: "15px" }}>
                           <b >
                              Work
                           </b>
                        </span>
                     </h2>

                  </div>
                  <CContainer id="collapseEight" className="accordion-collapse collapse " aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body">
                        {workData.length > 0
                           ? workData.map((e, i) => (
                              <div className="form-group" key={i}>
                                 <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }}>
                                    <div style={{ maxWidth: "79%" }}
                                       className="d-flex align-items-start justify-content-start"
                                    >
                                       <span
                                          className="d-flex me-2 mt-2"
                                       >
                                          <BsBagCheck size={25} color="#808080" />
                                       </span>

                                       <div className="px-8 d-flex flex-column align-items-start">

                                          <small style={{ fontSize: "15px", display: `${e.workType === "Homemaker" ? 'none' : 'block'}` }}>
                                             <b> {e.jobTitle} </b>{e.jobTitle && "at "}<b>{e.companyName}</b>
                                             {e.workType && <span>&#40;</span>}
                                             {e.workType}
                                             {e.workType && <span>&#41;</span>}
                                          </small>

                                          <small style={{ display: `${e.workType !== "Homemaker" ? 'none' : 'block'}` }}> <b> {e.workType} </b></small>

                                          <div style={{ display: `${e.workType === "Homemaker" ? 'none' : 'block'}` }}>
                                             <small style={{ fontSize: "12px" }}>
                                                {new Date(e.startDate).getFullYear()}
                                             </small>{" "}
                                             <small>-</small>{" "}
                                             <small style={{ fontSize: "12px" }}>
                                                {e.currentlyWorking === "true" || isNaN(e.currentlyWorking) ? "present" : new Date(e.endDate).getFullYear()}
                                             </small> {e.campanyLocation && <small className="fs-6">&#xb7; {" "} </small>}
                                             <small style={{ display: `${e.workType === "Homemaker" ? 'none' : 'inline'}` }}>{e.campanyLocation}</small>
                                          </div>

                                          <small className="mt-2">{e.aboutCompany}</small>

                                       </div>
                                    </div>


                                 </div>

                                 <hr className="profile_hr" />
                              </div>
                           ))
                           : ""}
                     </div>
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style={{ fontSize: "15px" }}>
                           <b >
                              Skills
                           </b>
                        </span>
                     </h2>

                  </div>
                  <CContainer id="collapseEleven" className="accordion-collapse collapse " aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body">
                        {skillData.length > 0
                           ? skillData.map((e, i) => (
                              <div key={i} >
                                 <div className="flex my-2" style={{ marginRight: "-10px" }}>
                                    {skillsCategory.map((item, ie) => {
                                       if (item.category_name.toLowerCase() === e.skillCategory.toLowerCase()) {
                                          return (
                                             <div key={ie}
                                                className="flex"
                                                style={{ justifyContent: "start" }}
                                             >
                                                <span
                                                   className="d-flex"
                                                   style={{ marginRight: "5px", fontSize: "22px", color: "#808080a8" }}
                                                >
                                                   {item.icon}
                                                </span>
                                                <span >
                                                   <small style={{ fontSize: "12px" }}>
                                                      {e.skillCategory}
                                                   </small>
                                                   <p style={{ fontSize: '14px' }}>
                                                      {e.skillLevel === 1
                                                         ? "Beginner"
                                                         : e.skillLevel === 2
                                                            ? "Intermediate"
                                                            : e.skillLevel === 3
                                                               ? "Expert"
                                                               : ""}
                                                      <span
                                                         style={{
                                                            fontWeight: "bold",
                                                            margin: "0px 5px",
                                                         }}
                                                      >
                                                         {e.skillName}
                                                      </span>
                                                   </p>
                                                </span>
                                             </div>
                                          );
                                       }
                                       return null;
                                    })}


                                 </div>
                              </div>
                           ))
                           : null}
                     </div>
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">

                  <div className="d-flex justify-content-between align-items-center py-2 accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style={{ fontSize: "15px" }}>
                           <b >
                              Hobby
                           </b>
                        </span>
                     </h2>


                  </div>

                  <CContainer id="collapseTwelve" className="accordion-collapse collapse " aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                     <div className="container hobby_conatiner accordion-body ">
                        {selectedHobbies.map((e, i) => (
                           <div key={i}>
                              {/* <small>{e.icon}</small> */}
                              <p
                                 className="my-2 border bg-muted mx-2 p-2 h6 rounded-top text-sm shadow-lg"
                                 style={{ fontSize: "12px" }}
                                 key={e._id}
                              >
                                 {e.hobbyName}
                              </p>
                           </div>
                        ))}
                     </div>
                  </CContainer>


               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style={{ fontSize: "15px" }}>
                           <b >
                              Most Hangout Places
                           </b>
                        </span>
                     </h2>

                     <span className="d-none"
                     >

                     </span>
                  </div>
                  <CContainer id="collapseSix" className="accordion-collapse collapse " aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body">
                        {hangoutDetails.length > 0 ?
                           hangoutDetails.map((e) => (
                              <div className="form-group" key={e._id}>
                                 {places.map((item, i) => {
                                    if (item.place === e.placeType) {
                                       return (
                                          <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }} key={i}>
                                             <div style={{ maxWidth: "79%" }}
                                                className="d-flex align-items-start justify-content-start">
                                                <span
                                                   className="d-flex me-2 mt-2" style={{ color: "#8080809c" }}
                                                >
                                                   {item.icon}
                                                </span>


                                                <span style={{ padding: "0px 10px" }}>
                                                   <small style={{ fontSize: "12px" }}>
                                                      {e.placeType}
                                                   </small>
                                                   <p style={{ fontSize: "14px" }}> <b> {e.placeName}</b></p>
                                                   <small style={{ fontSize: "12px" }}>
                                                      {e.placeLocation}
                                                   </small>
                                                </span>
                                             </div>

                                             <div className="d-none">
                                                <img
                                                   src={`${BASE_URL_STATIC}/icon/pen.svg`}
                                                   alt="img"

                                                   style={{
                                                      background: "#E8E8E8",
                                                      padding: "5px",
                                                      width: "22px",
                                                      height: "22px",
                                                      borderRadius: "30%",
                                                      cursor: "pointer"
                                                   }}
                                                />

                                             </div>
                                          </div>
                                       );
                                    }
                                    return null;
                                 })}

                                 <hr className="profile_hr" />
                              </div>
                           )
                           ) : (
                              ""
                           )}
                     </div>
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-groups" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" style={{ fontSize: "15px" }}>
                           <b >
                              Zodia Sign
                           </b>
                        </span>
                     </h2>
                     <span className="d-none">
                        <img
                           src={`${BASE_URL_STATIC}/icon/pen.svg`}
                           alt="img"
                           style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer"
                           }}
                        />
                     </span>
                  </div>
                  <CContainer id="collapseSeven" className="accordion-collapse collapse " aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                     {zodiacVal && <div className="container accordion-body">
                        <div
                           className="d-flex justify-content-start my-2 align-items-center gap-2" style={{ padding: "0px 10px" }}
                        >
                           <span className="d-flex" style={{ marginRight: "5px" }}>
                              <img src={`${BASE_URL_STATIC}/icon/zodiac.svg`} alt="" />
                           </span>
                           <span>
                              <small style={{ fontSize: "12px" }}>
                                 Astrological Sign
                              </small>
                              <p style={{ fontSize: "14px" }}> <b> {zodiacVal} </b> </p>
                           </span>
                        </div>
                     </div>}
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-groups" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{ fontSize: "15px" }}>
                           <b >
                              Other Social Media
                           </b>
                        </span>
                     </h2>

                  </div>

                  <CContainer id="collapseFive" className="accordion-collapse collapse " aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body">
                        {social.length > 0 ?
                           social.map((e) => (
                              <div className="form-group" key={e._id}>
                                 {/* <div className='flex'> */}
                                 {socialMedia.map((item, i) => {
                                    if (item.name === e.plateform) {
                                       return (
                                          <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }} key={i}>
                                             <div
                                                style={{ maxWidth: "79%" }}
                                                key={item.name}
                                                className="d-flex align-items-start justify-content-start"
                                             >
                                                <span
                                                   className="d-flex me-2 mt-2"
                                                   style={{ color: "#8080809c" }}
                                                >
                                                   {item.icon}
                                                </span>
                                                <span style={{ padding: "0px 10px" }}>
                                                   <small style={{ fontSize: "12px" }}>
                                                      {e.plateform}
                                                   </small>
                                                   <p style={{ fontSize: "14px" }}> <b> {e.userhandle} </b> </p>
                                                   <small>
                                                      <Link target="_blank" to={e.sociallink}>
                                                         {e.sociallink}
                                                      </Link>
                                                   </small>
                                                </span>
                                             </div>

                                             <div className="d-none">
                                                <img
                                                   src={`${BASE_URL_STATIC}/icon/pen.svg`}
                                                   alt="img"

                                                   style={{
                                                      background: "#E8E8E8",
                                                      padding: "5px",
                                                      width: "22px",
                                                      height: "22px",
                                                      borderRadius: "30%",
                                                      cursor: "pointer"
                                                   }}
                                                />

                                             </div>
                                          </div>
                                       );
                                    }
                                    return null;
                                 })}
                                 {/* </div> */}
                                 <hr className="profile_hr" />
                              </div>
                           )
                           ) : (
                              ""
                           )}
                     </div>
                  </CContainer>
               </CContainer>

               {/* Family memeber and Realtionship */}
               <CContainer className="form-group accordion " id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center  accordion-item">

                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" style={{ fontSize: "15px" }}>
                           <b >
                              Family and Relationships
                           </b>
                        </span>
                     </h2>

                  </div>
                  <div id="flush-collapseOne" className="accordion-collapse collapse " aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                     <div className="accordion-body">
                        <div className="row">
                           {relationsData && relationsData.length > 0 && (
                              relationsData.map((item, index) => (
                                 <div className="col-lg-6" key={index}>
                                    <div className="profile-flex">
                                       <div >
                                          <span className="d-flex px-3 justify-content-between align-items-center">
                                             {item.userId === paramsUserId ? <div className="flex justify-content-start">
                                                {item.findUser && item.findUser.imageId ?
                                                   // <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/user/${item.findUser.imageId}`} alt="" />
                                                   <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                                      src={`${BASE_URL_STATIC}/dummy.png`}
                                                      alt="img"
                                                   />
                                                   :
                                                   <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                                      src={`${BASE_URL_STATIC}/dummy.png`}
                                                      alt="img"
                                                   />
                                                }

                                                <p>
                                                   <p style={{ fontSize: "14px" }}>{item.secondUserName && item.secondUserName}</p>

                                                   {item.siblingStatus === 0 &&
                                                      <span className="text-muted" style={{ fontSize: "13px" }}>
                                                         {item.secondUserAs}
                                                      </span>}

                                                   {item.siblingStatus === 1 &&
                                                      <span className="text-muted" style={{ fontSize: "13px" }}>
                                                         {item.secondUserAs}
                                                      </span>}
                                                </p>

                                             </div> :
                                                <div className="flex justify-content-start">
                                                   {item.findUser && item.findUser.imageId ?
                                                      // <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/user/${item.findUser.imageId}`} alt="" />
                                                      <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                                         src={`${BASE_URL_STATIC}/dummy.png`}
                                                         alt="img"
                                                      />
                                                      :
                                                      <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                                         src={`${BASE_URL_STATIC}/dummy.png`}
                                                         alt="img"
                                                      />
                                                   }
                                                </div>}
                                          </span>
                                       </div>
                                    </div>
                                 </div>
                              ))
                           )}
                        </div>
                     </div>

                  </div>

               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine" style={{ fontSize: "15px" }}>
                           <b >
                              Favourite Movie
                           </b>
                        </span>
                     </h2>

                  </div>
                  <CContainer id="collapseNine" className="accordion-collapse collapse " aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body">
                        {movieDetails.length > 0
                           ? movieDetails.map((e, i) => (

                              <div className="form-group" key={i}>

                                 <div className="flex my-2" style={{ marginRight: "-10px" }}  >
                                    <div style={{ maxWidth: "79%" }}
                                       className="d-flex align-items-start justify-content-start"
                                    >
                                       <span
                                          className="d-flex"
                                          style={{
                                             marginRight: "5px",
                                             height: "31px",
                                             maxHeight: "50px",
                                             opacity: "0.6"
                                          }}
                                       >
                                          <img src={`${BASE_URL_STATIC}/icon/fav_movie.svg`} alt="" />
                                       </span>
                                       <span style={{ padding: "0px 10px" }}>
                                          <p style={{ fontSize: "14px" }}>
                                             <b>{e.movieName}</b>
                                          </p>
                                          <small>
                                             {e.movieDescription}
                                          </small>
                                       </span>
                                    </div>


                                 </div>

                                 <hr className="profile_hr" />
                              </div>

                           ))
                           : ""}
                     </div>
                  </CContainer>
               </CContainer>

               <CContainer className="accordion form-group" id="accordionExample">
                  <div className="d-flex justify-content-between align-items-center accordion-item">
                     <h2 className="p-1 px-2 accordion-header me-auto" id="flush-headingOne">
                        <span className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen" style={{ fontSize: "15px" }}>
                           <b >
                              Favourite Food
                           </b>
                        </span>
                     </h2>

                  </div>
                  <CContainer id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                     <div className="container p-3 accordion-body">
                        {foodDetails.length > 0
                           ? foodDetails.map((e, i) => (

                              <div key={i}>
                                 <div className="flex my-2" style={{ marginRight: "-10px" }}>
                                    <div style={{ maxWidth: "79%" }}
                                       className="d-flex align-items-start justify-content-start"
                                    >
                                       <span
                                          className="d-flex"
                                          style={{
                                             marginRight: "5px",
                                             height: "31px",
                                             maxHeight: "50px",
                                             opacity: "0.6"
                                          }}
                                       >
                                          <img src={`${BASE_URL_STATIC}/icon/fav_food.svg`} alt="" />
                                       </span>
                                       <span style={{ padding: "0px 10px" }} className="set_width">
                                          <p style={{ fontSize: "14px" }}>
                                             <b>{e.foodName}</b>
                                          </p>
                                          <small>
                                             {e.foodDetail}
                                          </small>
                                       </span>
                                    </div>

                                 </div>

                                 <hr className="profile_hr" />
                              </div>

                           ))
                           : ""}
                     </div>
                  </CContainer>
               </CContainer>
            </CContainer>
         </section>
      </>
   )
}

export default AboutUser