import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EmojiPopup from '../EmojiPopup';
import CreatePhotoAndVideo2 from './CreatePhotoAndVideo/CreatePhotoAndVideo2';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchGif } from '../../UseContext/ApiHelpers';



function CreateGif({ open, gifType, setShowPv2, selectphotoAndVideo, setSelectedPhotoVideo, setSelectedEmoji, selectedEmoji, close, setOpenEmoji, openEmoji, setShowCreatePostPopup }) {

   const fileInputRef = useRef(null);
   const [fileUrl, setFileUrl] = useState([]);
   const [selectGif, setSelectGif] = useState([]);
   // const [inputVal, setInputVal] = useState('trendings')
   const [gifData, setGifData] = useState([])

   useMemo(() => {
      if (Array.isArray(selectphotoAndVideo)) {
         const urls = selectphotoAndVideo.map((file) => URL.createObjectURL(file));
         setFileUrl(urls);

         return () => {
            urls.forEach((url) => URL.revokeObjectURL(url));
         };
      }
   }, [selectphotoAndVideo]);

   async function urlToFile(url, filename, mimeType) {
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], filename, { type: mimeType });
   }

   async function convertUrlsToFiles(urlArray) {
      const filePromises = urlArray.map((url, index) => {
         const filename = `gif_${index}.gif`;
         return urlToFile(url, filename, 'image/gif');
      });

      const files = await Promise.all(filePromises);

      return files;
   }

   const handleSelectPhotoAndVideo = async (e) => {
      const selectedFiles = Array.from(e.target.files);
      setSelectedPhotoVideo(prev => [...selectedFiles, ...prev]);
      close();
      setShowPv2(true);
   };

   const handleImageClick = () => {
      fileInputRef.current.click();
   };


   const handleClose = () => {
      if (gifType === "post") {
         setShowPv2(true)
      }
      else if (gifType === "gif") {
         setShowCreatePostPopup(true)
         setShowPv2(false)
      }
      close();
      setOpenEmoji(false);
   }

   const handleSelectGif = (i) => {
      const selectedGif = gifData[i].media_formats.gif.url;
    
      // Check if the GIF is already selected
      if (selectGif.includes(selectedGif)) {
        setSelectGif(selectGif.filter((gif) => gif !== selectedGif));
      } else {
        setSelectGif([...selectGif, selectedGif]);
      }
    };

    
    const handleUpload = async () => {
      if (selectGif.length === 0) return;
    
      try {
        // Convert selected GIF URLs into File objects
        const files = await convertUrlsToFiles(selectGif);
    
        // Add the files to the selected photos/videos
        setSelectedPhotoVideo((prev) => [...files, ...prev]);
    
        // Close modal and proceed
        close();
        setShowPv2(true);
    
      } catch (error) {
        console.error("Error during file creation:", error);
      }
    };
    

   useEffect(() => {
      setSelectGif([])
   }, [open])

 

   useEffect(() => {
      let query = localStorage.getItem("gif_query")
      fetchGif(query).then((res) => {
         setGifData(res)
      })
   }, [])

   const handleChange = (e) => {
      localStorage.setItem("gif_query", e.target.value)
      // setInputVal(e.target.value)
      fetchGif(e.target.value).then((res) => {
         setGifData(res)
      })
   }


   return (
      <>
         <Modal show={open} onHide={handleClose} centered backdrop="static" keyboard={false} className="create-call">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
               <div className="row">
                  <p className="col-1" style={{ cursor: "pointer" }} onClick={handleClose}>
                     <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                  </p>
                  <p className="col-11 fs-6 text-black fw-bold text-center mb-2">
                     Create GIF
                  </p>
               </div>
               <>
                  {openEmoji ? (
                     <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                  ) : (
                     <Tabs defaultActiveKey="GIF" id="uncontrolled-tab-example" className="mb-3 w-100">
                        <Tab style={{ position: "relative" }} eventKey="GIF" title="GIF" className="api_gif">

                           {/* search input  */}
                           <div className='form-group mb-2 px-2'>
                              <input type="input" className="form-control" placeholder="Search gif" onChange={handleChange} />
                           </div>

                           <div className="row row-cols-4 align-items-center m-auto justify-content-center gap-1 ">
                              {gifData.map((e, i) => (
                                 <div key={i} className='col-2 shadow p-0 position-relative ' style={{width : "100px" , height : "100px"}}>
                                    <img
                                       onClick={() => handleSelectGif(i)}
                                       style={{objectFit : "cover"}}
                                       className="rounded-2 cp h-100 w-100 "
                                       key={i}
                                       src={e.media_formats && e.media_formats.gif.url}
                                       alt="gif_images"
                                    />
                                    {selectGif.includes(e.media_formats && e.media_formats.gif.url) &&
                                       <img className='checked_img' src="/images/checked.svg" alt="" />
                                    }
                                 </div>

                              ))}
                           </div>
                           <div className="col-12 col-lg-8 m-auto position-sticky bottom-0">
                              <button disabled={selectGif.length === 0} onClick={handleUpload} className="text_post_btn shadow">{`${selectGif.length === 0 ? 'Next' : `Next (${selectGif.length})`}`}</button>
                           </div>
                        </Tab>
                        <Tab eventKey="Browse GIF" title="Browse GIF" className="local_gif">
                           <div className="d-flex flex-column justify-content-center align-items-center" style={{ border: '2px dashed #d3d3d3' }}>
                              {fileUrl.length > 0 ? (
                                 <CreatePhotoAndVideo2 selectphotoAndVideo={selectphotoAndVideo} setSelectedPhotoVideo={setSelectedPhotoVideo} />
                              ) : (
                                 <img
                                    src="https://img.freepik.com/premium-vector/business-cloud-computing-data-provision-cloud-computing-services-web-page-banner-presentation-social-media-documents-cards-posters-vector-illustration_98702-1814.jpg"
                                    alt="git_pics"
                                    onClick={handleImageClick}
                                    style={{ cursor: 'pointer' }}
                                 />
                              )}
                              <input className="d-none" type="file" onChange={handleSelectPhotoAndVideo} ref={fileInputRef} multiple accept="image/gif" />
                           </div>
                           <div className="col-12 col-lg-8 m-auto">
                              <button onClick={handleImageClick} className="text_post_btn">Choose from gallery</button>
                           </div>
                        </Tab>
                     </Tabs>
                  )}
               </>
            </Modal.Body>
         </Modal>
      </>
   );
}

export default CreateGif;
