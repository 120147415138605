import React, { useContext, useEffect, useState } from 'react'
import { BiHome } from 'react-icons/bi'
import Modal from 'react-bootstrap/Modal';

import { selectCities, selectCountry, getAddressDetails, selectStates, updateAddress } from '../../../UseContext/ApiHelpers';
import { profileContext } from '../../../UseContext/ProfileContext';
import { BASE_URL_STATIC } from '../../../../../config';
import { ApiContext } from '../../../UseContext/ApiCall';

const PlaceEditpopup = ({ open, close, addressId, addObj }) => {

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [loader, setLoader] = useState(false)

  const [address, setUserAddress] = useState({ addressType: "", country: '', state: '', city: '', aboutPlace: '' });

  const { setAddress } = useContext(profileContext)
  const { loggedInUserId } = useContext(ApiContext)



  useEffect(() => {

    setState([]);
    setCity([]);

  }, []);

  useEffect(() => {
    setUserAddress((prev) => ({
      ...prev,
      addressType: addObj.addressType,
      aboutPlace: addObj.aboutPlace,
      country: addObj.country,
      state: addObj.state,
      city: addObj.city
    }))

    // Fetch the user address data and update the component state
    selectCountry().then((countryRes) => {
      setCountry(countryRes)
      setUserAddress((prevAddress) => ({
        ...prevAddress

      }));
    });
    
    selectStates(addObj.country).then((stateRes) => {
      setState(stateRes);

      // Once the states are fetched, fetch the cities
      selectCities(addObj.country, addObj.state).then((cityRes) => {
        setCity(cityRes);
        // Once the country is fetched, fetch the states
      });
    });

  }, [addObj]);

  function handleSubmit(e) {

    e.preventDefault();
    setLoader(true)
    const addressUpdate = { addressId, ...address }

    updateAddress(addressUpdate)
      .then(() => getAddressDetails(loggedInUserId)
        .then(res => {
          setAddress(res)
          setLoader(false)
            ;
        }))
  }


  function handleChange(e) {
    setUserAddress({ ...address, [e.target.name]: e.target.value })
    selectCountry().then(res => setCountry(res))
  }

  function handleCountryChange(e) {
    setUserAddress({ ...address, country: e.target.value })
    selectStates(e.target.value).then(res => setState(res))
  }

  function handleStateChange(e) {
    setUserAddress({ ...address, state: e.target.value })
    selectCities(address.country, e.target.value).then(res => setCity(res))
  }


  return (
    <div>
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h5 className='text-center'>Places Lived</h5>

          <form action="" onSubmit={handleSubmit}>
            <div className="form-group">

              {false ? <span className='text-danger text-center'>address type already exists</span> : ''}

              <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className="col-lg-4 my-2">
                  <span
                    className="d-flex"
                    style={{
                      marginRight: '5px',
                      background: '#f5f5f5',
                      padding: '6px',
                      width: "150px",
                      borderRadius: '10px',
                    }}
                  >
                    <span>{<BiHome size={20} color='#ccc' />}</span>

                    <p
                      style={{
                        fontSize: '15px',
                        margin: '5px',
                        color: '#9f9797b8',

                      }}
                    >
                      Address Type
                    </p>
                  </span>
                </div>

              </div>

              <select name="addressType" id="addressType" onChange={handleChange} value={address.addressType} className="form-control form-box m-auto "
                style={{
                  height: '50px',
                  fontWeight: '600',
                  border: '0px',
                  borderBottom: '2px solid #a6a6a659',
                  margin: '20px 0px',
                  width: '80%'
                }}>
                <option value="permanent">Permanent</option>
                <option value="current">Current</option>
              </select>


              <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className="col-lg-4 my-2">
                  <span
                    className="d-flex justify-content-start gap-2 align-center"
                    style={{
                      marginRight: '5px',
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span><img src={`${BASE_URL_STATIC}/icon/flag.png`} alt='' /></span>

                    <p
                      style={{
                        fontSize: '15px',
                        color: '#9f9797b8',

                      }}
                    >
                      Country
                    </p>
                  </span>
                </div>

              </div>

              <select name="country" id="country" onChange={handleCountryChange} value={address.country} className="form-control form-box m-auto "
                style={{
                  height: '50px',
                  fontWeight: '600',
                  border: '0px',
                  borderBottom: '2px solid #a6a6a659',
                  margin: '20px 0px',
                  width: '80%'
                }}>
                {addObj.country && (
                  <option value={addObj.country}>{addObj.country}</option>
                )}
                {country
                  .filter(countryName => countryName.name !== addObj.country)
                  .map((countryName, index) => (
                    <option key={index} value={countryName.name}>
                      {countryName.name}
                    </option>
                  ))}
              </select>


              {state && <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className="col-lg-4 my-2">
                  <span
                    className="d-flex"
                    style={{
                      marginRight: '5px',
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span><img src={`${BASE_URL_STATIC}/icon/location.svg`} alt='' width={15} /></span>
                    <p
                      style={{
                        fontSize: '15px',
                        margin: '5px 10px',
                        color: '#9f9797b8',

                      }}
                    >
                      State
                    </p>
                  </span>
                </div>
              </div>}


              {state && <select name="state" id="state" onChange={handleStateChange} value={address.state} className="form-control form-box m-auto "
                style={{
                  height: '50px',
                  fontWeight: '600',
                  border: '0px',
                  borderBottom: '2px solid #a6a6a659',
                  margin: '20px 0px',
                  width: '80%'
                }}>
                {addObj.state && (
                  <option value={addObj.state}>{addObj.state}</option>
                )}
                {state
                  .filter(stateName => stateName.name !== addObj.state)
                  .map((stateName, index) => (
                    <option key={index} value={stateName.name}>
                      {stateName.name}
                    </option>
                  ))}
              </select>}

              {city && <div
                className="row"
                style={{
                  display: 'flex',
                  padding: '10px 0px 0px 25px',
                }}
              >
                <div className="col-lg-4 ">
                  <span
                    className="d-flex"
                    style={{
                      margin: '10px 0px',
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                    }}
                  >
                    <span><img src={`${BASE_URL_STATIC}/icon/location.svg`} alt='' width={15} /></span>

                    <p
                      style={{
                        fontSize: '15px',
                        margin: '5px 10px',
                        color: '#9f9797b8',

                      }}
                    >
                      City
                    </p>
                  </span>
                </div>

                {city && <select name="city" id="city" onChange={handleChange} value={address.name} className="form-control form-box m-auto "
                  style={{
                    height: '50px',
                    fontWeight: '600',
                    border: '0px',
                    borderBottom: '2px solid #a6a6a659',
                    margin: '20px 0px',
                    width: '80%'
                  }}>
                  {addObj.city && (
                    <option value={addObj.city}>{addObj.city}</option>
                  )}
                  {city
                    .filter(cityName => cityName.name !== addObj.city)
                    .map((cityName, index) => (
                      <option key={index} value={cityName.name}>
                        {cityName.name}
                      </option>
                    ))}

                </select>}


                <div
                  className="row"
                  style={{
                    display: 'flex',
                    padding: '10px 0px 0px 25px',
                  }}
                >
                  <div className="col-lg-12 ">
                    <span
                      className="d-flex justify-content-start gap-2 align-items-center"
                      style={{
                        marginRight: '5px',
                        background: '#f5f5f5',
                        padding: '6px',
                        borderRadius: '10px',
                      }}
                    >
                      <span><img src={`${BASE_URL_STATIC}/icon/chat.svg`} alt='' width={20} /></span>
                      <p
                        style={{
                          fontSize: '15px',
                          color: '#9f9797b8',

                        }}
                      >
                        Tell me something about place
                      </p>
                    </span>
                    <textarea onChange={handleChange}
                      type="text" name='aboutPlace'
                      defaultValue={addObj.aboutPlace}
                      maxLength={150}
                      style={{
                        fontSize: '15px',
                        border: '1px solid #ccc',
                        marginLeft: '15px',
                        marginTop: '20px',
                        lineHeight: '50px',
                        width: '330px'
                      }}
                      className="form-control col-lg-12 "
                      placeholder="Add something..."
                    // value={user.fname}
                    />
                  </div>
                </div>

              </div>}


              {country && country.length > 0 && <div
                className="col-lg-12 d-flex"
                style={{
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <button type="submit" className="edit-modal-btn" onClick={close}>
                  {loader ? "saving..." : " Save "}
                </button>
              </div>}


            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PlaceEditpopup