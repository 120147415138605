import React, {  useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../config';

const OnBoadingPopup = () => {
    const [show, setShow] = useState(true); 

    const handleClose = () => {
            localStorage.setItem("is_first_time", "no");
            setShow(false);
    };

    return (    
        <>
            <Modal show={show} onHide={() => handleClose()} size='xl' centered backdrop="static" keyboard={false} className="create-call p-0" >
                <Modal.Body className='p-0'>
                        <div className=" card terms-card">
                            <div className="terms-header text-center " style={{backgroundImage : `url(${BASE_URL_STATIC}/sc_welcome_bg.jpg)`}}>
                                <h2 className='welcome_sc'>Welcome to Softcorner</h2>
                            </div>
                            <div className="card-body-sc">
                                <ol className="list-group">
                                    <li className="list-group-item">Users must be at least 13 years old to create an account.</li>
                                    <li className="list-group-item">All content shared must comply with community guidelines and local laws.</li>
                                    <li className="list-group-item">Harassment, hate speech, or discriminatory content is strictly prohibited.</li>
                                    <li className="list-group-item">Users are responsible for safeguarding their account credentials.</li>
                                    <li className="list-group-item">The platform reserves the right to remove content that violates guidelines.</li>
                                    <li className="list-group-item">Spamming, phishing, or sharing malicious links is not allowed.</li>
                                    <li className="list-group-item">Personal data will be handled according to the app's Privacy Policy.</li>
                                    <li className="list-group-item">Violation of terms may result in suspension or termination of accounts.</li>
                                    <li className="list-group-item">The app is not liable for third-party content shared by users.</li>
                                    <li className="list-group-item">Terms and conditions are subject to change without prior notice.</li>
                                </ol>
                            </div>
                            <div className="card-footer text-end">
                                <button className="text-white btn_sc" onClick={handleClose}>close</button>
                            </div>
                        </div>
                 
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OnBoadingPopup;
