import React, { useContext, useEffect } from "react";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../config";
import Slider from "react-slick";
import ApplyJobPopup from "../Popup/ApplyJobPopup";
import { businessContext } from "../UseContext/BusinessContext";
// import BusinessAddListingFrenchisePopup from "../Popup/Home/Listings/BusinessAddListingFrenchisePopup";
import AddJobPopup from "../Popup/Home/Listings/AddJobPopup";
import EditJobPopup from "../Popup/Home/Listings/EditJobPopup";
import ViewJobPopup from "../Popup/Home/Listings/ViewJobPopup";
import AddFranchisePopup from "../Popup/Home/Listings/AddFranchisePopup";
import EditFranchisePopup from "../Popup/Home/Listings/EditFranchisePopup";
import ViewFranchisePopup from "../Popup/Home/Listings/ViewFranchisePopup";



const BusinessHomeListings = () => {

  const { 
    paramsPageId,
    setShowApplyJobPopup,
    checkIsItMyPage, isItMyPage,

    setShowAddJobPopup, setShowEditJobPopup, setShowViewJobPopup,
    jobs, setJob,
    getJobs, 

    setShowAddFranchisePopup, setShowEditFranchisePopup, setShowViewFranchisePopup,
    franchises, setFranchise,
    getFranchises,
  } = useContext(businessContext);


  const jobSetting = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const frenchiseSetting = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  useEffect(() => {
    checkIsItMyPage(paramsPageId);
    getJobs(paramsPageId);
    getFranchises(paramsPageId);
  }, [paramsPageId]);


  return (
    <>
      {/* Popup */}
      <>
        {/* Add job popup */}
        <AddJobPopup />

        {/* Edit job popup */}
        <EditJobPopup />

        {/* View job popup */}
        <ViewJobPopup />

        {/* Add franchise popup */}
        <AddFranchisePopup />

        {/* Edit franchise popup */}
        <EditFranchisePopup />

        {/* View franchise popup */}
        <ViewFranchisePopup />



        {/* Apply job popup */}
        <ApplyJobPopup />
      </>


      <section className="">
        <div className="rounded-3 shadow-sm px-3 py-2 mb-5 d-flex flex-column flex-sm-row justify-content-between align-items-center gap-3" style={{ backgroundColor: '#f5f5f5' }}>
          <h4 className="mb-0">Your Listings</h4>

          {/* Search Bar */}
          <div className="bg-white px-3 py-1 rounded-pill d-flex flex-grow-1">
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} className="ms-2 me-2" alt="search" />
            <input type="search" className="form-control border-0 shadow-none" placeholder="Search..." />
          </div>

          <button type="button" className="btn bg-white">
            <img src={`/images/business/icons/add-circle.svg`} className="me-2" style={{ height: '20px' }} alt="add-circle" />
            <span className="text-primary">Add Listing</span>
          </button>
        </div>

        <>
          {
            isItMyPage
            ?
            // ----- It will show to logged in user -----
            <>
              {/* Jobs */}
              <div className="mb-5">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Jobs</h5>
                  <img src={`/images/business/icons/add-circle.svg`} className="ms-2 me-auto cursor-pointer" style={{ height: '20px' }} alt="add" onClick={() => setShowAddJobPopup(true)} />
                  <span className="text-primary">See All</span>
                </div>

                <div className="">
                  <Slider {...jobSetting} className='listing-job-slick'>
                    {
                      jobs && jobs.length > 0
                      && 
                      jobs.map((job, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2 position-relative' onClick={() => {setJob(job); setShowViewJobPopup(true)}} key={index}>
                          <div>
                            <img src={job.images && job.images.length > 0 && AwsFileUrl+job.images[0].image} className='h-100 w-100 object-fit-contain rounded-3 border' style={{ maxHeight: '200px' }} alt="job" />
                          </div>

                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <h5 className="fw-semibold mb-0 d-inline-block text-nowrap text-truncate" style={{ maxWidth: '125px', overflow: 'hidden' }}>{job.title}</h5>

                              <small className="badge bg-secondary-subtle text-dark">{job.experience}</small>
                            </div>

                            <small className="d-block text-secondary mt-1 mb-2">{job.description && `${job.description.slice(0, 40)}...`}</small>

                            <small className="d-block fw-semibold">Salary</small>
                            <h5 className="text-primary fw-semibold m-0">₹ {job.salary}</h5>

                            {/* Boost & Share & Enquiry */}
                            <div className="d-flex flex-wrap gap-2 mt-3">
                              <button type="button" className="btn btn-sm border border-primary d-flex">
                                <img src={`/images/business/icons/boost-1-blue.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-primary">Boost</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Share</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Enquiry</span>
                              </button>
                            </div>
                          </div>

                          {/* Edit */}
                          <img src={`/images/business/icons/edit-blue.svg`} className="position-absolute top-0 end-0 mt-3 me-3 mt-sm-2 me-sm-2 cursor-pointer" style={{ height: '20px' }} alt="edit" 
                            onClick={(e) => {e.stopPropagation(); setShowEditJobPopup(true); setJob(job)}}
                          />
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>

              {/* Franchise */}
              <div className="mb-5">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Franchise</h5>
                  <img src={`/images/business/icons/add-circle.svg`} className="ms-2 me-auto cursor-pointer" style={{ height: '20px' }} alt="add" onClick={() => setShowAddFranchisePopup(true)} />
                  <span className="text-primary">See All</span>
                </div>

                <div>
                  <Slider className='listing-franchise-slick' {...frenchiseSetting}>
                    {
                      franchises && franchises.length > 0
                      &&
                      franchises.map((franchise, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2 position-relative' onClick={() => {setFranchise(franchise); setShowViewFranchisePopup(true)}} key={index}>
                          <div>
                            <img src={franchise.images && franchise.images.length > 0 && AwsFileUrl+franchise.images[0].image} className='h-100 w-100 object-fit-contain rounded-3 border' style={{ maxHeight: '200px' }} alt="franchise" />
                          </div>

                          <div>
                            <h5 className="fw-semibold mb-0 d-inline">{franchise.title}</h5>

                            <small className="d-block text-secondary mt-1 mb-2">{franchise.description && `${franchise.description.slice(0, 40)}...`}</small>

                            {/* Phone */}
                            <div className="border border-primary px-2 rounded-pill d-inline-block me-3">
                              <img src={`/images/business/icons/phone-blue.svg`} style={{ height: '23px' }} alt="call" />
                            </div>

                            {/* Email */}
                            <div className="border border-primary px-2 rounded-pill d-inline-block">
                              <img src={`/images/business/icons/mail-blue.svg`} style={{ height: '23px' }} alt="call" />
                            </div>

                            <div className="d-flex flex-wrap gap-3 mt-3">
                              <button type="button" className="btn btn-sm border border-primary d-flex">
                                <img src={`/images/business/icons/boost-1-blue.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-primary">Boost</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Share</span>
                              </button>

                              <button type="button" className="btn btn-sm btn-primary border border-primary d-flex">
                                <img src={`/images/business/icons/share-white.svg`} className="me-2" style={{ height: '20px' }} alt="boost-1-blue" />
                                <span className="text-white">Enquiry</span>
                              </button>
                            </div>
                          </div>

                          {/* Edit */}
                          <img src={`/images/business/icons/edit-blue.svg`} className="position-absolute top-0 end-0 mt-3 me-3 mt-sm-3 me-sm-3 cursor-pointer" style={{ height: '20px' }} alt="edit" 
                            onClick={(e) => {e.stopPropagation(); setShowEditFranchisePopup(true); setFranchise(franchise)}}
                          />

                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </>
            :
            // ----- It will show to other user -----
            <>
              {/* Jobs */}
              <div className="mb-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Jobs</h5>
                  <span className="text-primary">See All</span>
                </div>

                <div className="">
                  <Slider {...jobSetting} className='listing-job-slick'>
                    {
                      jobs && jobs.length > 0
                      &&
                      jobs.map((job, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2' key={index}>
                          <div>
                            <img src={job.images && job.images.length > 0 && AwsFileUrl+job.images[0].image} className='h-100 w-100 object-fit-cover rounded-3 border' style={{ maxHeight: '200px' }} alt="job" />
                          </div>

                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <h5 className="fw-semibold mb-0 d-inline-block text-nowrap text-truncate" style={{ maxWidth: '125px', overflow: 'hidden' }}>{job.title}</h5>

                              <small className="badge bg-secondary-subtle text-dark">{job.experience}</small>
                            </div>

                            <small className="d-block text-secondary mt-1 mb-2">{job.description && `${job.description.slice(0, 75)}...`}</small>

                            <small className="d-block fw-semibold">Salary</small>
                            <h5 className="text-primary fw-semibold m-0">₹ {job.salary}</h5>

                            <div className="d-flex flex-wrap align-items-center gap-3 mt-3">
                              <button type="button" className="btn btn-primary" onClick={() => setShowApplyJobPopup(true)}>Apply Now</button>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/phone-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/mail-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/message-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>

              {/* Franchise */}
              <div className="mb-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="fw-semibold mb-0">Franchise</h5>
                  <span className="text-primary">See All</span>
                </div>

                <div className="">
                  <Slider {...frenchiseSetting} className='listing-franchise-slick'>
                    {
                      franchises && franchises.length > 0
                      &&
                      franchises.map((franchise, index) => (
                        <div className='d-flex flex-column flex-sm-row bg-white gap-3 p-2 rounded-3 me-md-2' key={index}>
                          <div>
                            <img src={AwsFileUrl+franchise.image} className='h-100 w-100 object-fit-cover rounded-3 border' style={{ maxHeight: '200px' }} alt="franchise" />
                          </div>

                          <div>
                            <h5 className="fw-semibold mb-0 d-inline">{franchise.heading}</h5>

                            <small className="d-block text-secondary mt-1 mb-2">
                              {franchise.description && `${franchise.description.slice(0, 75)}...`}
                            </small>

                            <div className="d-flex flex-wrap align-items-center gap-3">
                              <button type="button" className="btn btn-primary">Contact Us</button>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/phone-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/mail-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>

                              <div className="border border-primary px-2 py-1 rounded-pill">
                                <img src={`/images/business/icons/message-blue.svg`} style={{ height: '25px' }} alt="phone" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </>
          }
        </>
      </section>
    </>
  );
};

export default BusinessHomeListings;