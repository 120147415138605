import React, { useRef, useContext, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { BASEAPIURL } from '../../../../../config';
import { ApiContext } from '../../UseContext/ApiCall';
// import CenterBlockStory1 from './CenterBlockStory1/CenterBlockStory1';
import CenterBlockPosts from './CenterBlockPosts/CenterBlockPosts';
import SuggestedFlix from './SuggestedFlix/SuggestedFlix';
import Stories from './Stories/Stories';
import { getAllPostData2} from '../../UseContext/ApiHelpers';

const CenterBlock = () => {
  const [page , setPage] = useState(2)

  const {  config , setallDataPost } = useContext(ApiContext);

  const refObs = useRef();
  const observe = new IntersectionObserver(() => console.log()
  );

  if (refObs.current) {
    observe.observe(refObs.current)
  };

  // To handle infinite scroll in center block of home page
  const handleInfiniteScroll = () => {
    let innerHeight, scrollTop, scrollHeight;

    innerHeight = window.innerHeight;
    scrollTop = document.getElementsByClassName('center-block')[0].scrollTop;
    scrollHeight = document.getElementsByClassName('center-block')[0].scrollHeight;

    if((innerHeight + scrollTop) === scrollHeight) {
      getAllPostData2('postType' , page , config).then((res) => {
        if(res && res.posts && res.posts.length > 0) {
          setallDataPost(prev => [...prev, ...res.posts])
          setPage(page + 1);
        }
      });
    };
  };

  return (
    <>
      <section className="center-block pt-5" onScroll={handleInfiniteScroll}>
        {/* <CenterBlockStory1 /> */}
        <Stories />
        <SuggestedFlix/>
        <CenterBlockPosts page={page} />
      </section>
    </>
  );
}

export default CenterBlock;
