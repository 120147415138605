import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../UseContext/ApiCall";
import { BASE_URL_STATIC, AwsFileUrl } from "../../../../config";
import { getPostData } from '../../UseContext/ApiHelpers'
import ProfilePostPopup from "../../Popup/Profile/ProfileShow/ProfilePostPopup";
import { useParams } from "react-router-dom";
import Loader from "../../../Loader";

const ProfilesReels = () => {

  const [postData, setpostData] = useState([]);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [mainpoastData, setmainpoastData] = useState('')
  const { profileUserId, config } = useContext(ApiContext);
  const [loader, setLoader] = useState(false)


  const [isOtherUserAccountPrivate, setIsAccountPrivate] = useState(false)

  // To get profile reels
  useEffect(() => {
    getProfileData()
  }, [profileUserId]);

  const { paramsUserId } = useParams();


  const getProfileData = async () => {
    setLoader(true)
    try {
      const responsedata = await getPostData(paramsUserId, 'flix', config)
      if (responsedata.status === 1) {
        setIsAccountPrivate(true)
      } else {
        setpostData(responsedata.posts)
      }
      setLoader(false)
    }
    catch (error) {
      setLoader(false)
      console.error(error);
    }
  }


  if (loader) {
    return (
      <div className="position-relative">
        <Loader />
      </div>
    )
  }

  else if (isOtherUserAccountPrivate) {
    return (
      <section className="profileReels-private">
        <div className="profileReels-private-img">
          <img src={`${BASE_URL_STATIC}/accountPrivacy.png`} alt="accountPrivacy" />
        </div>
        <span className="profileReels-private-head">Account is private</span>
      </section>
    )
  }

  return (
    <>
      {/* Profile Reel Popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />
      <section className="col-12 col-lg-7 p-0 mx-auto ps-3 row d-flex gap-3">
        {
          postData
            ?
            postData.map((item) => (
              <div key={item._id} className='px-0 col-2 mx-1 mb-1 mx-lg-2 mb-lg-3'>



                <div style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '200px', width: '100%', borderRadius: '0.5rem', overflow: 'hidden'
                }}>
                  {item.postTumbnailUrl && (
                    <img src={`${AwsFileUrl + item.postTumbnailUrl}`} alt={item.title} className='img-fluid' onClick={() => { setShowPostsPopup(!showPostsPopup); setmainpoastData(item); setPostId(item._id) }} />

                  )}

                </div>
              </div>
            ))
            :
            <div className='w-100 d-flex justify-content-center'>
              <span className="loaderMain"></span>
            </div>
        }
      </section>
    </>
  );
}

export default ProfilesReels;
