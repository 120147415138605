import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';
import toast from 'react-hot-toast';
import { businessContext } from '../../../UseContext/BusinessContext';


const EditMilestonePopup = () => {

  const {
    showEditMilestonePopup, setShowEditMilestonePopup,
    milestone, setMilestone,
    milestoneImages, setMilestoneImages,
    updateMilestone,
    deleteMilestoneImage,
  } = useContext(businessContext);

  const fileInputRef = useRef(null);

  // handle click
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  // handle file change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if((milestoneImages.length + milestone.images.length + files.length) <= 5) {
      setMilestoneImages((prevImages) => [...files, ...prevImages]);
    } else {
      toast.error(`You can only select 5 images`);
    };
  };
  
  // handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setMilestone((prevJob) => ({
      ...prevJob,
      [name]: value,
    }));
  };

  // remove milestone image 
  const removeProductImage = (indexToRemove) => {
    setMilestoneImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  // handle popup close
  const handlePopupClose = () => {
    setShowEditMilestonePopup(false);

    setMilestoneImages([]);

    setMilestone({
      images: [],
      title: '',
      description: '',
      price: '',
      link: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };


  return (
    <>
      <Modal show={showEditMilestonePopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Edit Milestone</h4>

          {/* Images */}
          <div className='mb-3'>
            {/* Add */}
            <>
              {
                (milestoneImages.length + milestone.images.length) < 5
                &&
                <div className='p-1 bg-secondary-subtle rounded-circle d-inline-block mb-2 pt-0' onClick={handleDivClick}>
                  <img src="/images/business/icons/add-circle.svg" style={{ height: '20px' }} alt="add" />

                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </div>
              }
            </>

            {
              <Slider className="milestone-images-slider" {...settings}>
                {/* Local Images */}
                {
                  milestoneImages && milestoneImages.length > 0 
                  &&
                  milestoneImages.map((image, index) => (
                    <div className='position-relative' key={index}>
                      <div className='border rounded-3'>
                        <img src={URL.createObjectURL(image)} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                      </div>

                      {/* Delete */}
                      <>
                        {
                          (milestoneImages.length + milestone.images.length) > 1
                          &&
                          <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => removeProductImage(index)}>
                            <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                          </div>
                        }
                      </>
                    </div>
                  ))
                }

                {/* Live Images */}
                {
                  milestone && milestone.images && milestone.images.length > 0
                  &&
                  milestone.images.map((value, index) => (
                    <div className='position-relative' key={index}>
                      <div className='border rounded-3'>
                        <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                      </div>

                      {/* Delete */}
                      <>
                        {
                          (milestone.images.length) > 1
                          &&
                          <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => deleteMilestoneImage(value)}>
                            <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                          </div>
                        }
                      </>
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={milestone.title} onChange={handleInputChange} placeholder='Title...' />

          {/* Date */}
          <small className='text-secondary'>Date</small>
          <input type="date" className='form-control border-0 border-bottom shadow-none mb-4' name='date' value={milestone.date} onChange={handleInputChange} />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={milestone.description} onChange={handleInputChange} placeholder='Description...'></textarea>
          
          {/* Edit */}
          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={updateMilestone}>Edit</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditMilestonePopup;