import React, { useContext, useRef, useState } from 'react';
import { businessContext } from '../UseContext/BusinessContext';
import Modal from "react-bootstrap/Modal";
import { AwsFileUrl } from '../../../config';


const UpdateProfilePopup = () => {

  const [profile, setProfile] = useState(null);

  const {
    showUpdateProfilePopup, setShowUpdateProfilePopup,
    pageDetails,
    uploadPageProfile,
    deletePageProfile,
  } = useContext(businessContext);


  // ----- Profile ----- 
  const profileInputRef = useRef(null);

  return (
    <>
      <Modal show={showUpdateProfilePopup} onHide={() => setShowUpdateProfilePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h4 className='text-center'>Update Profile</h4>
            
            <div className='d-flex gap-3 align-items-center justify-content-evenly my-4'>
              <div className='rounded-circle border position-relative' style={{ height: '150px', width: '150px' }}>
                <img src={pageDetails && AwsFileUrl+pageDetails.imageId} className='object-fit-contain h-100 w-100 rounded-circle' alt="profile" />

                <>
                  {
                    pageDetails && pageDetails.imageId !== 'default/add-image-icon.jpg'
                    &&
                    <div className='position-absolute p-1 pt-0 rounded-circle' style={{ backgroundColor: '#40404078', top: '15%', right: '15%' }} onClick={deletePageProfile}>
                      <img src="/images/icons/delete-white.svg" style={{ height: '20px', width: '20px' }} alt="delete" />
                    </div>
                  }
                </>
              </div>

              <>
                {
                  profile !== null
                  &&
                  <div className='rounded-circle border position-relative' style={{ height: '150px', width: '150px' }}>
                    <img src={URL.createObjectURL(profile)} className='object-fit-contain h-100 w-100 rounded-circle' alt="profile" />

                    <div className='position-absolute p-1 pt-0 rounded-circle' style={{ backgroundColor: '#40404078', top: '15%', right: '15%' }} onClick={() => setProfile(null)}>
                      <img src="/images/icons/delete-white.svg" style={{ height: '20px', width: '20px' }} alt="delete" />
                    </div>
                  </div>
                }
              </>
            </div>

            <div className='text-center'>
              <>
                {
                  profile === null
                  ?
                  <button type="button" className='btn btn-warning w-50' onClick={() => profileInputRef.current.click()}>Add</button>
                  :
                  <button type="button" className='btn btn-primary w-50' onClick={() => {uploadPageProfile(profile); setProfile(null)}}>Update</button>
                }
              </>

              <input type="file" ref={profileInputRef} style={{ display: 'none' }}  onChange={(e) => setProfile(e.target.files[0])}  accept="image/*"  />
            </div>
          </section>
        </Modal.Body> 
      </Modal>
    </>
  )
}

export default UpdateProfilePopup
