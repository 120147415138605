import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessActiveHoursPopup = () => {

  const { 
    showActiveHoursPopup, setShowActiveHoursPopup, 
    activeHour, setActiveHour,
    createActiveHour,
  } = useContext(businessContext);

  const week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  return (
    <>
      <Modal show={showActiveHoursPopup} onHide={() => setShowActiveHoursPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-5'>Active Hours</h4>

          {/* Day */}
          <>
            <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-4'>Day</span>

            <div className='ms-3 mb-4'>
              {
                week.map(day => (
                  <span className={`${activeHour[day] === 'true' ? 'bg-primary text-white' : 'border text-secondary'} rounded-3 p-2 me-2 mb-2 text-capitalize`} key={day}
                    onClick={() => setActiveHour(prevState => ({ ...prevState, [day]: activeHour[day] === 'true' ? 'false' : 'true' }))}
                  >{day}</span>
                ))
              }
            </div>
          </>

          {/* Time */}
          <>
            <span className='bg-secondary-subtle rounded-3 px-3 shadow-sm mb-4'>Time</span>

            <div className='d-flex flex-column flex-sm-row align-items-center ms-3 mb-5'>
              {/* Start Time */}
              <input type="time" className='form-control border-0 border-bottom' name='startTime'
                value={activeHour.startTime} onChange={(e) => setActiveHour((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
              />

              <span className='mx-3'>To</span>

              {/* End Time */}
              <input type="time" className='form-control border-0 border-bottom' name='endTime'
                value={activeHour.endTime} onChange={(e) => setActiveHour((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))} 
              />
            </div>
          </>

          <div className='text-center'>
            <button type="button" className='btn btn-primary rounded-pill w-50' onClick={createActiveHour}
              disabled={(activeHour.sun === 'false' && activeHour.mon === 'false' && activeHour.tue === 'false' && activeHour.wed === 'false' && activeHour.thu === 'false' && activeHour.fri === 'false' && activeHour.sat === 'false') || activeHour.startTime === '' || activeHour.endTime === '' }
            >{activeHour._id ? 'Update' : 'Add'}</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessActiveHoursPopup;