import { useRef } from 'react';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ProfileImage from '../UseContext/ProfileImage';
import { ApiContext } from '../UseContext/ApiCall';
import { GoogleMap, Marker } from '@react-google-maps/api';
import EmojiPopup from './EmojiPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PostSettings from '../Button/PostSettings';
// import { createCheckIn } from '../UseContext/ApiHelpers';
import * as htmlToImage from 'html-to-image';
// import { saveAs } from 'file-saver';
import { uploadpostUrl, uploadPost } from "../UseContext/ApiHelpers";
import TagPeople from '../Button/TagPeople';


const CheckinPreviewPopup = ({ setSelectedEmoji,setPosts, setSelectedAddLocation, selectedAddLocation, setCheckIn, open, selectedLocation, selectedEmoji, close, location }) => {

   const [postLoader, setPostLoader] = useState(false)
   const [openEmoji, setOpenEmoji] = useState(false)
   const [description, setDescription] = useState("")
   const [tagMessage, setTagmessage] = useState('')
   const [selectedUser, setSelectedUser] = useState([])
   const [openInput, setOpenInput] = useState(false)

   const { loggedInUserId, loggedInUserName, config, setallDataPost,postType } = useContext(ApiContext)

   useEffect(() => {
      setPostLoader(false)
   }, [])

   const refs = useRef(null);

   const dataURLtoBlob = (dataURL) => {
      const parts = dataURL.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
         uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
   };


   const handleSubmit = async () => {
      setPostLoader(true)
      if (refs.current) {
         htmlToImage.toPng(refs.current, { cacheBust: true })
            .then(async (dataUrl) => {

               const blob = dataURLtoBlob(dataUrl);
               const formData = new FormData();
               formData.append("mediaUrls", blob, 'output.png');
               formData.append("postTumbnailUrl", blob, 'output.png');
               // formData.append("audio", 'https://webaudioapi.com/samples/audio-tag/chrono.mp3')
               // formData.append("start", 'ssss') 
               // formData.append("end", 'sss')
               await uploadpostUrl(formData, config).then(async (res) => {
                  const mediaPostData = {

                     maintype : postType,
                     description: description,
                     mediaUrls: res.mediaUrls[0],
                     postTumbnailUrl: res.postTumbnailUrl || '',
                     type: 'location',
                     emotion: selectedEmoji.emotion,
                     emotionName: selectedEmoji.emotionName,
                     emotionIcon: selectedEmoji.icon,
                     lat: selectedLocation.lat,
                     lng: selectedLocation.lng,
                     city: selectedAddLocation.location,
                     place: selectedAddLocation.place


                  };
                  const mediaPost = await uploadPost(mediaPostData, config)
                  if (postType === 'postType') {
                     setallDataPost(prev => [mediaPost.post, ...prev]);
                   }else{
                     setPosts(prev => [mediaPost.post, ...prev])
                   }
                  setCheckIn(false)
                  setSelectedAddLocation(null)
                  close();

               })
            })
            .catch((error) => {
               console.error('Error converting HTML to PNG:', error);
            });
      }

      // const obj = {
      //    type: "checkin",
      //    lat: selectedLocation.lat,
      //    lng: selectedLocation.lng,
      //    description: description,
      //    city: selectedLocation.location,
      //    place: selectedLocation.place
      // }
   }

   const handleCity = (location) => {
      let loc = location && location.split(",")[0]
      return loc
   }

   return (
      <>
         <Modal show={open} onHide={() => { close(); setOpenEmoji(false) }} centered backdrop="static" keyboard={false} className="create-call" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>

            <Modal.Body>
               {openEmoji ?
                  <>
                     <div className="row">
                        <p className="col-1" style={{ cursor: "pointer" }}
                           onClick={() => { setOpenEmoji(false) }}
                        >
                           <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                        </p>
                        <p className="col-11 text-black fs-6 fw-bold text-center mb-2">
                           Check In
                        </p>
                     </div>

                     <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                  </>
                  :
                  <>
                     <div className="row">
                        <p className="col-1" style={{ cursor: "pointer" }}
                           onClick={() => { close(); setCheckIn(true) }}
                        >
                           <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                        </p>
                        <p className="col-11 fw-bold fs-6 text-black text-center mb-2">
                           Check In
                        </p>
                     </div>

                     <div className="userDetail d-flex align-items-center  gap-2 mt-3">
                        <div className="imgBox" >
                           <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                        </div>

                        <div className="nameBox">
                           <span className="fs-6">
                              {loggedInUserName}
                              {selectedEmoji ?
                              
                                 <span className="ms-1 d-inline-block " onClick={() => { setOpenEmoji(true); }}>
                                    {selectedEmoji.emotion}
                                    <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                                    {selectedEmoji.emotionName}{" "}
                                    <span>{tagMessage}</span>
                                 </span>
                                 :
                                 <span className='fs-14 text-secondary ms-1'> {tagMessage}</span>
                              }
                           </span>
                        </div>
                     </div>

                     <p className='text-primary fw-bold cp text-end'>
                        <span onClick={() => setOpenInput(!openInput)}>
                           Tag People
                        </span>
                     </p>

                     {openInput ?

                        <TagPeople setTagmessage={setTagmessage} selectedUser={selectedUser} setSelectedUser={setSelectedUser} config={config} />
                        :
                        <div>
                           <div className="d-flex flex-column justify-content-between flex-md-row flex-lg-row gap-3 align-items-center border border-1 rounded-4 p-2 my-2 bg-white">

                              <div ref={refs} className='w-100'>

                                 <GoogleMap
                                    mapContainerStyle={{ height: "150px", width: "100%" }}
                                    center={selectedLocation}
                                    zoom={17}
                                    // onLoad={(map) => {
                                    //     setMap(map);
                                    //     setLoading(false);
                                    // }}
                                    options={{ zoomControl: false, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
                                 >
                                    {selectedLocation && (
                                       <Marker position={selectedLocation} />
                                    )}
                                 </GoogleMap>
                              </div>


                              <div className="d-flex flex-column w-100">
                                 <small className="text-secondary">City</small>
                                 <p className='text-break text-black' style={{ fontSize: '14px', fontWeight: '500' }}>{handleCity(location.location)}</p>
                                 {/* <p className='text-break text-black' style={{ fontSize: '14px', fontWeight: '500' }}>{location.location}</p> */}
                                 <small className="text-secondary mt-2">Place</small>
                                 <p className='w-100 text-break text-black' style={{ fontSize: '14px', fontWeight: '500' }}>{location.place}</p>
                              </div>
                           </div>

                           <div>
                              <textarea
                                 style={{ resize: "none", width: "97%" }}
                                 className='m-2 border border-1 rounded-2 p-2'
                                 name="textArea"
                                 id="textArea"
                                 rows="3"
                                 onChange={(e) => setDescription(e.target.value)}
                                 placeholder="write something about place"
                              ></textarea>
                           </div>
                        </div>
                     }

                     <PostSettings />

                     <div className='col-8 m-auto'>
                        <button onClick={handleSubmit} className='text_post_btn'>
                           {
                              postLoader ? <> <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> posting...</> : "Post"
                           }

                        </button>
                     </div>
                  </>
               }
            </Modal.Body>
         </Modal>
      </>

   )
}

export default CheckinPreviewPopup
