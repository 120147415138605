import React, { useEffect, useState, useContext, useRef } from "react";
import { getDataTypePost } from "../UseContext/ApiHelpers";
import { BASE_URL_STATIC } from "../../../config";

import PostTime from "../Home/CenterBlock/CenterBlockPosts/PostTime";
import FollowUnfollowButton from "../Button/FollowUnfollowButton";
import FavouriteMemeButton from "../Button/FavouriteButton/FavouriteMemeButton";
import { ApiContext } from "../UseContext/ApiCall";
import ProfilePostPopup from "../Popup/Profile/ProfileShow/ProfilePostPopup";
import ImageTag from "../../Common/assets/imageTag";
import Loader from "../../Loader";

const Memes = () => {

  const [allMemes, setAllMemes] = useState([])
  const [page, setPage] = useState(1)

  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [postData, setPostData] = useState(null);
  const [loader, setLoader] = useState(false);

  const { loggedInUserId } = useContext(ApiContext);

  const { config } = useContext(ApiContext)

  document.title = "Emilo-memes"

  //------------------------------------------------------------------------------------------------

  const scrollContainerRef = useRef(null);

  const handleInfiniteScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        const currentPage = page;
        alert()

        //---------------- Working Area------------------------
        getDataTypePost('memes', 'postType', "", currentPage, config, 10).then((res) => {
          if (res.status === 200) {
            setAllMemes((prevPosts) => [...prevPosts, ...res.data.posts]);
            //-----------------------------------------------------------------
            setPage(currentPage + 1);
          }
        });

      }
    }
  };

  console.log("page" , page)

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      console.log("checking......")
      container.addEventListener("scroll", handleInfiniteScroll);
      return () => {
        container.removeEventListener("scroll", handleInfiniteScroll);
      };
    }
  }, [page]);

  //------------------------------------------------------------------------------------------------


  useEffect(() => {
    setLoader(true)
    getDataTypePost('memes', 'postType', "", 1, config, 20).then(res => {
      setAllMemes(res.data.posts)
      setPage(page + 1)
      setLoader(false)
    });
  }, []);

  return (
    <>
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postData} postId={postId} />

      {loader ?
        <Loader />
        :
        <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5" ref={scrollContainerRef}>
          <div className="explore-sec my-3 my-sm-5 my-lg-5">
            {
              allMemes
              &&
              allMemes.map((post, index) => (
                <div className="rounded-4 position-relative cursor-pointer" style={{ height: '200px' }} onClick={() => { setShowPostsPopup(true); setPostId(post._id); setPostData(post); }} key={index}>
                  {
                    post.mediaUrls[0].filetype === 'image'
                      ?
                      <ImageTag url={post.mediaUrls[0].url} classHere="h-100 w-100 rounded-4 object-fit-cover shadow-sm" altHere="one" />
                      :
                      <ImageTag url={post.mediaUrls[0].thumbnail || post.postTumbnailUrl} classHere="h-100 w-100 rounded-4 object-fit-cover shadow-sm" altHere="two" />
                    // <ReactPlayer url={AwsFileUrl+post.postTumbnailUrl} className='h-100 w-100 rounded-4 object-fit-cover shadow-sm' playing={true} />
                  }

                  <div className="px-2 pb-1 rounded-3 position-absolute top-0 end-0 mt-3 me-3" style={{ backgroundColor: '#e2e3e591' }}>
                    <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="post" />
                  </div>

                  <div className="hover-info position-absolute top-50 start-50 translate-middle h-100 w-100 d-flex align-items-center justify-content-center">
                    <span className="me-2">
                      <img src={`${BASE_URL_STATIC}/explore-icon4.png`} className="me-1" alt="Views" />
                      <span className="text-white">{post._id && post.viewcount}</span>
                    </span>

                    <span className="me-2">
                      <img src={`${BASE_URL_STATIC}/explore-icon5.png`} className="me-1" alt="Likes" />
                      <span className="text-white">{post._id && post.likecount}</span>
                    </span>

                    <span className="me-2">
                      <img src={`${BASE_URL_STATIC}/explore-icon6.png`} className="me-1" alt="Comments" />
                      <span className="text-white">{post._id && post.commentcount}</span>
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      }

      {/* Mobile Memes */}
      {loader ?
        <Loader />
        : <section className="mobile-memes" ref={scrollContainerRef}>
          <div className="mm-memes">
            {
              allMemes
              &&
              allMemes.map((memes, index) => (


                <div className="mm-meme" key={index}>
                  {/* User */}
                  <div className="mmm-user">
                    <div className="mmm-user-profile">
                      <img src={`${BASE_URL_STATIC}/user-icon${index + 1}.jpg`} alt="profile" />
                    </div>

                    <div className="mmm-user-header">
                      <div className="mmm-user-header-1">
                        <span className="mmm-user-name">{memes.fname}</span>
                        <span className="mmm-user-activity">Activity</span>
                      </div>

                      <div className="mmm-user-header-2">
                        <span className="mmm-user-location">Location</span>
                        <span className="mmm-user-time">
                          <PostTime date={memes.createdTime} />
                        </span>
                      </div>
                    </div>

                    <div className="mmm-user-fubtn">
                      {
                        memes.userId !== loggedInUserId
                        &&
                        <FollowUnfollowButton otherUserId={memes.userId} />
                      }
                    </div>

                    <div className="mmm-user-threeDot">
                      <i className="far fa-ellipsis-v" />
                    </div>
                  </div>

                  {/* Show */}
                  <div className="mmm-show">
                    <img src={`${BASE_URL_STATIC}/cp-${index + 1}.png`} alt="show" />
                  </div>

                  {/* Area */}
                  <div className="mmm-area">
                    <div className="mmm-area-view">
                      {
                        memes.viewcount === 0
                          ?
                          <img src={`${BASE_URL_STATIC}/icon/view-1.svg`} alt="view" />
                          :
                          <img src={`${BASE_URL_STATIC}/icon/view-2.svg`} alt="view" />
                      }
                      <span>{memes.viewcount}</span>
                    </div>


                    <div className="mmm-area-like" >
                      <img src={`${BASE_URL_STATIC}/explore-icon5.png`} alt="" />
                      <span>{memes.likecount}</span>

                    </div>

                    <div className="mmm-area-comment">
                      <img src={`${BASE_URL_STATIC}/icon/comment-1.svg`} alt="view" />
                      <span>{memes.commentcount}</span>
                    </div>

                    <div className="mmm-area-share">
                      <img src={`${BASE_URL_STATIC}/icon/share-1.svg`} alt="view" />
                      <span>{memes.sharecount}</span>
                    </div>

                    <div className="mmm-area-favourite">
                      <FavouriteMemeButton memeId={memes.memeId} />
                    </div>

                    <div className="mmm-area-download">
                      <img src={`${BASE_URL_STATIC}/icon/download-1.svg`} alt="view" />
                    </div>
                  </div>

                  {/* Description */}
                  {
                    memes.description
                    &&
                    <div className="mmm-description">
                      <span>{memes.description}</span>
                    </div>
                  }

                  <hr className="mm-meme-hr" />
                </div>
              ))
            }
          </div>
        </section>}
    </>
  );
}

export default Memes;
