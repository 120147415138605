import React, { useRef, useEffect } from "react";
import { FaceMesh } from "@mediapipe/face_mesh";
import { Camera } from "@mediapipe/camera_utils";

const EyebrowEffect = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    // Initialize MediaPipe FaceMesh
    const faceMesh = new FaceMesh({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
    });

    faceMesh.setOptions({
      maxNumFaces: 5,
      refineLandmarks: false,
      minDetectionConfidence: 0.3,
      minTrackingConfidence: 0.3,
    });

    faceMesh.onResults((results) => {
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

      // Draw eyebrow effect
      if (results.multiFaceLandmarks) {
        results.multiFaceLandmarks.forEach((landmarks) => {
          // Left Eyebrow Landmarks (65, 55, 52, 53, 46)
          const leftEyebrow = [65, 55, 52, 53, 46].map((index) => landmarks[index]);

          // Right Eyebrow Landmarks (295, 285, 282, 283, 276)
          const rightEyebrow = [295, 285, 282, 283, 276].map((index) => landmarks[index]);

          // Draw the left eyebrow
          drawPolygon(canvasCtx, leftEyebrow, "rgba(255, 0, 0, 0.5)");

          // Draw the right eyebrow
          drawPolygon(canvasCtx, rightEyebrow, "rgba(0, 0, 255, 0.5)");
        });
      }
    });

    const drawPolygon = (ctx, points, color) => {
      ctx.beginPath();
      ctx.moveTo(points[0].x * canvasElement.width, points[0].y * canvasElement.height);
      points.forEach((point) => {
        ctx.lineTo(point.x * canvasElement.width, point.y * canvasElement.height);
      });
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    };

    const camera = new Camera(videoElement, {
      onFrame: async () => {
        await faceMesh.send({ image: videoElement });
      },
      width: 640,
      height: 480,
    });

    camera.start();
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <video ref={videoRef} autoPlay width={640} height={480} />
      </div>
      <div className="col-md-6">
        <canvas ref={canvasRef} width={640} height={480} style={{ border: "1px solid black" }} />
      </div>
    </div>
  );
};

export default EyebrowEffect;
