import React,{useState, useContext} from 'react'
import { AwsFileUrl, BASE_URL_STATIC } from '../../../../config'
import Slider from 'react-slick'
import LikeButton from '../../../Emilo/Button/LikeButton/LikeButton'
import { Link } from 'react-router-dom'
import ViewPosts from '../../../Emilo/Button/ViewPosts'
import { ReadMoreData } from '../../../Emilo/Data/EducationData'
import { ApiContext } from '../../../Emilo/UseContext/ApiCall'

const BoostPostmediu = ({BoostPostId}) => {
    const posts = [BoostPostId]
    const [showPostsPopup] = useState(false);
    const { trendingfeed,  ReadMore} = useContext(ApiContext);


    const feedslider = {
        autoplay: false,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        pauseOnHover: true,
        adaptiveHeight: true
      };

  return (
    <div>



<div className='rounded-3 px-2 px-lg-5'>
            {
             posts &&  posts.length > 0 
              ?
              posts.map((post, index) => (
                <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }} key={index}>
                  <div className='d-flex align-items-center'>


                    <img src={AwsFileUrl + post.business_img} className='rounded-circle' style={{ height: '43px', width: '43px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>{post.business_name}</h6>
                      <span className='text-primary'>Sponsored</span>
                    </div>

                    <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
                  </div>

                  {post.type === 'text' ?
                    <div className="text_post mb-4 mt-2">
                      <ReadMoreData>
                        <div dangerouslySetInnerHTML={{ __html: post.description }}></div>
                      </ReadMoreData>
                    </div>

                    :
                    <div className="textArea mt-3">
                      <ReadMore>
                        {post.description}
                      </ReadMore>
                    </div>}

                  {/* <img src={post} className='w-100 object-fit-contain bg-secondary-subtle' style={{ maxHeight: '60vh' }} alt="post" /> */}
                  {/* <img src={post} className='w-100 object-fit-contain' alt="post" /> */}

                  <Slider {...feedslider}>
                    {post._id && post.mediaUrls.map((image, index) => (
                      <ViewPosts showPostsPopup={showPostsPopup} post={post} index={index} fileType={image.filetype} imageSrc={image.url && image.url} vidSrc={image.url && image.url} key={image._id} mediaTags={image.tags} trendingfeed={trendingfeed} />
                    ))}
                  </Slider>

                  {post.business_about && post.business_about.websiteLink && <div className='d-flex justify-content-between align-items-center my-3'>
                    <div>
                      <h6 className='fw-semibold mb-0'>{post.business_about.websiteLink}</h6>
                      <span >
                        <ReadMore>{post.business_about.aboutPage}</ReadMore>
                      </span>
                      {/* <span >{post.business_about.aboutPage}</span> */}
                    </div>

                    <button type="button" className='btn btn-primary'>
                      <Link to={post.business_about.websiteLink} target='_blank' className='text-white text-decoration-none' >
                        Visit Us
                      </Link>
                    </button>
                  </div>}

                  <hr className='hr bg-secondary' />

                  {/* View, Like, Comment and Share */}
                  <div className='d-flex align-items-center justify-content-between'>
                    {/* View */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/view-2.svg`} className='me-2' alt="view" />
                      <span>{post.viewcount}</span>
                    </div>

                    {/* Like  */}
                    <div className='text-center'>
                      <LikeButton postedBy={post.postedBy} likecount={post.likecount} postId={post._id} postType={post.type} />
                    </div>

                    {/* Comment */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/comment-1.svg`} className='me-2 cp' alt="view"  />
                      <span>{post.commentcount}</span>
                    </div>

                    {/* Share */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/share-1.svg`} className='me-2 cp' alt="view" />
                      {/* <span>{post.share}</span> */}
                    </div>

                    {/* Boost Ad */}
                  
                      <button type="button" className='btn btn-light shadow-sm'   >
                    
                      <img src={`/images/business/icons/boost-1-blue.svg`} className='shadow-sm me-2 cp' style={{ height: '25px' }} alt="boost" />
                      <span className='text-primary'>Boost Ad</span>
                    </button>
                 
                  </div>
                </div>
              )) 
              :
              <div className='d-flex flex-column gap-3 align-items-center justify-content-center mt-3'>
                <img src="/images/business/icons/add-circle-gray.svg" style={{ height: '100px' }} alt="add" />

                <span className='text-secondary text-center w-75'>Start engaging with your audience! Create your first post to share updates, news, or introduce your page.</span>
              </div>
            }
          </div>
      
    </div>
  )
}

export default BoostPostmediu
