import React from 'react';
import { AwsFileUrl } from '../../../../../config';

const StoryCircle = ({ colors , imageUrl }) => {
    const numColors = colors.length; // Directly use the length of the selected colors
    const radius = 30;
    const gapWidth = 20; // Reduced gap between color segments for smaller size
    const imageRadius = radius - 5; // Image size reduced to create a white border
    const ringRadius = radius; // Outer colored ring
   

    const circumference = 2 * Math.PI * ringRadius;
    const gapAngle = Math.asin(gapWidth / circumference) * 2;
    const arcAngle = (2 * Math.PI - (numColors * gapAngle)) / numColors;

    return (
        <>
            <svg className='mt-2' width={50} height={50} style={{ overflow: 'visible' }}>
                {/* Clip the image inside the circle */}
                <defs>
                    <clipPath id="circleClip">
                        <circle cx={radius} cy={radius} r={imageRadius} />
                    </clipPath>
                </defs>

                {/* Add white border */}
                <circle
                    cx={radius}
                    cy={radius}
                    r={ringRadius}
                    fill="white" // The white border between the image and the ring
                />

                {/* Add the image inside the circle */}
                <image
                    href={AwsFileUrl + imageUrl}
                    x={radius - imageRadius} // Center the image horizontally
                    y={radius - imageRadius} // Center the image vertically
                    width={50}
                    height={50}
                    preserveAspectRatio="xMidYMid slice" // Ensures the image covers the circle
                    clipPath="url(#circleClip)" // Clip the image to a smaller circle
                />

                {/* Draw the ring(s) for 2, 3, or more colors */}
                {numColors === 1 ? (
                    <circle
                        cx={radius}
                        cy={radius}
                        r={ringRadius}
                        stroke={colors[0]}
                        strokeWidth="2" // Thickness of the ring
                        fill="none"
                    />
                ) : (
                    colors.map((color, index) => {
                        const startAngle = index * (arcAngle + gapAngle) - Math.PI / 2; // Start at the top
                        return (
                            <path
                                key={index}
                                d={`M ${radius + ringRadius * Math.cos(startAngle)} ${radius + ringRadius * Math.sin(startAngle)}
                                    A ${ringRadius} ${ringRadius} 0 0 1 ${radius + ringRadius * Math.cos(startAngle + arcAngle)} ${radius + ringRadius * Math.sin(startAngle + arcAngle)}
                                `}
                                stroke={color}
                                strokeWidth="2" // Thickness of the ring
                                fill="none"
                            />
                        );
                    })
                )}
            </svg>
        </>
    );
};


export default StoryCircle;
