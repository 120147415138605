import React, { useContext, useEffect, useMemo,  useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';

import axios from 'axios';
import { BASEAPIURL } from '../../../../config';
import { profileContext } from '../../UseContext/ProfileContext';


const ReportPost = ({}) => {
     

  const {showReportPostPopup, setShowReportPostPopup, config,      allDataPost, setallDataPost,
      setunHide} = useContext(ApiContext);


  const { profilePostData } = useContext(profileContext);

  const [getReport, setGetReport] = useState([]);




  const getreport = () => {
    axios.post(`${BASEAPIURL}/post/getreport`, {}, config)
      .then(res => {
        console.log("res xyz", res.data.reportCategories)
        setGetReport(res.data.reportCategories);

      })
      .catch(err => console.log(err))

  }


  useEffect(() => {
    getreport();

  }, [])


  const ReportpostV2 = (postId, targetType, userId, reportPost ) =>{
    setunHide((items) =>{
      return [...items, ...allDataPost.filter(item => item._id ===postId) ]
    })

     axios.post(`${BASEAPIURL}/post/reportPost`, { postId, targetType, userId, reportPost }, config)
     .then(res =>  console.log(res))
     .catch(err =>  console.log(err)); 

     axios.post(`${BASEAPIURL}/post/hidepost`, { postId: postId }, config).then(res =>  {

          const updatedPosts =  allDataPost.map(post => 
          post._id ===  postId ? { hide: "true" , _id :  postId } : post
        );
          setallDataPost(updatedPosts); 
         setShowReportPostPopup(false); 
        }
       
     ).catch(err =>{
        console.error(err)
      })
    
   }
   



  
  return (
    <>
      <section className='reportPostPopups'>
        <Modal show={showReportPostPopup} onHide={() => setShowReportPostPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='reportPostPopup'>
              <h4 className='reportPostPopup-head'>Report Post</h4>
              <p className='text-center my-4'> whay  are   you reporting this  post  ?</p>

              <div className='ml-md-3 pl-md-2'>
              <div  className='text-left'>
              {
                getReport && getReport.length > 0 && getReport.map((item, index) => (
                  <p key={index}  className='mx-4 my-3'   onClick={() => ReportpostV2(profilePostData._id, profilePostData.type, profilePostData.postedBy, item)}>
                    {item}
                  </p>
                ))
              }
              </div>

             </div>
              

        
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default ReportPost
