import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { ApiContext } from "../../UseContext/ApiCall";
import LikePopup from "../../Popup/LikePopup";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../../config";
import { createNotification, fetchLikeStatus } from "../../UseContext/ApiHelpers";

const LikeButton = ({ postId, likecounts, postedBy, postType }) => {
 
  const [like, setLike] = useState(false); // Changed to boolean
  const [likeCount, setLikeCount] = useState(likecounts);
  const [showLikePopup, setShowLikePopup] = useState(false);
  const { config, loggedInUserId } = useContext(ApiContext);

  // To handle like or dislike
  const handleLike = async () => {
    const action = like ? "dislike" : "like"; // Determine action based on current state
    setLikeCount(prev => Math.max(0, like ? prev - 1 : prev + 1)); // Adjust count

    try {
      await axios.post(`${BASEAPIURL}/post/postEvent`, {
        postId: postId,
        postType: postType,
        postedBy,
        eventType: action
      }, config);

      const data = {
        targetId: postedBy,
        userType: "user",
        targetEvent: "like",
        targetType: postType,
        targetEventId: postId,
        notificationType: "emilo",
        isSeen: like === false ? 0 : 1,
      }

      if (postedBy !== loggedInUserId) {
        createNotification(data, config)
          .then(res => {
            // setOldNotifications(prev => [res , ...prev])
          })
          .catch(err => {
            console.log(err);
          });
      }
      setLike(!like); // Toggle like state

      // insert api for notifications 

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchLikeStatus(postId, config).then((res) => {
      setLike(res);
    });
  }, []);

  return (
    <>
  {postType !== 'flix' &&  <LikePopup showLikePopup={showLikePopup} setShowLikePopup={setShowLikePopup} postId={postId} />}
      <div className={`${postType !== 'flix' && 'd-flex'}`} style={{ alignItems: 'center' }}>
        {postType === 'flix' ? <img
          className="likePostBtn"
          src={`${BASE_URL_STATIC}/icon/like-0.svg`}
          alt=""
          onClick={handleLike}
          style={{ cursor: "pointer", marginRight: "5px" }}
        />
          :
          <img
            className="likePostBtn"
            src={`${BASE_URL_STATIC}/icon/${!like ? 'like-1.svg' : 'like-2.svg'}`}
            alt=""
            onClick={handleLike}
            style={{ cursor: "pointer", marginRight: "5px" }}
          />

        }
        <span onClick={() => setShowLikePopup(true)} className={`${postType === 'flix' && 'text-white fs-10'}`}>{likeCount}</span>
      </div>
    </>
  );
};

export default LikeButton;
