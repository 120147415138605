import React, { useContext, useRef } from 'react';
import { businessContext } from '../../../UseContext/BusinessContext';
import Modal from 'react-bootstrap/Modal';
import Slider from 'react-slick';
import toast from 'react-hot-toast';


const AddServicePopup = () => {

  const {
    showAddServicePopup, setShowAddServicePopup,
    service, setService,
    serviceImages, setServiceImages,
    createService,
  } = useContext(businessContext);

  const fileInputRef = useRef(null);

  // handle click
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  // handle file change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if((serviceImages.length + files.length) <= 5) {
      setServiceImages((prevImages) => [...files, ...prevImages]);
    } else {
      toast.error(`You can only select 5 images`);
    };
  };

  // handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setService((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  // handle remove file
  const handleRemoveFile = (fileIndex) => {
    setServiceImages((prevImages) => prevImages.filter((_, index) => index !== fileIndex));
  };

  const settings = {
    dots: true,           
    infinite: false,
    speed: 500,           
    slidesToShow: 1,        
    slidesToScroll: 1,        
    adaptiveHeight: true,     
  };

  return (
    <>
      <Modal show={showAddServicePopup} onHide={() => setShowAddServicePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Add Service</h4>

          {/* Images */}
          <>
            {
              serviceImages && serviceImages.length > 0
              ?
              <div className='mb-3'>
                <>
                  {
                    serviceImages.length < 5
                    &&
                    <div className='p-1 bg-secondary-subtle rounded-circle d-inline-block mb-2 pt-0' onClick={handleDivClick}>
                      <img src="/images/business/icons/add-circle.svg" style={{ height: '20px' }} alt="add" />

                      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                    </div>
                  }
                </>

                <Slider className="service-images-slider" {...settings}>
                  {
                    serviceImages && serviceImages.length > 0
                    &&
                    serviceImages.map((image, index) => (
                      <div className="border position-relative rounded-3" key={index}>
                        <img src={URL.createObjectURL(image)} className="rounded-3 object-fit-contain" style={{ height: '300px', width: '100%' }} alt={`Product ${index}`} />

                        <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => handleRemoveFile(index)}>
                          <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                        </div>
                      </div>
                    ))
                  }
                </Slider>
              </div>
              :
              <div className='d-flex flex-column align-items-center w-75 mx-auto rounded-3 py-4 mb-5 cursor-pointer' style={{ border: '1px dashed gray' }} onClick={handleDivClick}>
                <img src={`/images/business/icons/upload-photo-blue.svg`} style={{ height: '80px' }} alt="upload-blue" />

                <small className='text-primary'>Upload</small>
                <small className='text-secondary'>or</small>
                <small>Drop your file here</small>

                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
              </div>
            }
          </>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={service.title} onChange={handleInputChange} placeholder='Title...' />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={service.description} onChange={handleInputChange} placeholder='Description...'></textarea>

          {/* Price */}
          <small className='text-secondary'>Price</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='price' value={service.price} onChange={handleInputChange} placeholder='Price...' />

          {/* Number */}
          <small className='text-secondary'>Number</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-5' name='number' value={service.number} onChange={handleInputChange} placeholder='Number...' />

          {/* Add */}
          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={createService}
              disabled={serviceImages.length === 0 || service.title === '' || service.description === '' || service.price === '' || service.number === ''}
            >Add</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddServicePopup;