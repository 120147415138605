import toast from 'react-hot-toast';

import React, { useState, useContext } from 'react';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../config';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OnboardingPin = () => {

  const [pin, setPin] = useState('');
  const [loader, setLoader] = useState(false)
  const [confirmPin , setConfirmPin] = useState('')

  const { config, scImage } = useContext(ApiContext);

  // To handle create soft corner profile
  const handleCreateSoftCornerProfile = async (pin) => {
    setLoader(true)
    if(pin !== confirmPin){
      setLoader(false)
      return toast.error("Pin didn't matched")
    }
    const formData = new FormData();
    formData.append('scProfile', scImage)
    formData.append('password', pin)
    await axios.post(`${BASEAPIURL}/softcorner/createscprofile`, formData, config)
      .then(res => {
        localStorage.setItem('SCtoken', res.data.token);
        localStorage.setItem('SCuserId', res.data.scUserId);
        window.location.href = '/SoftCorner/'
        setLoader(false);
          const isFirstTime = localStorage.getItem("is_first_time");
          if (isFirstTime !== "no") {
              localStorage.setItem("is_first_time", "yes");
          }
      })
      .catch(err => {
        setLoader(false);
      });
  };

  return (
    <>
      <section className='onboardingPin'>
        <div className='onboardingPin-area'>
          {/* Emilo Logo */}
          <div className='onboardingPin-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingPin-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingPin-area-data w-100'>
            <h2 className='onboardingPin-area-data-head'>Create PIN</h2>

            <p className='onboardingPin-area-data-message mt-3'>Enter 4-digit security PIN</p>

            <OtpInput
              value={pin}
              inputType='password'
              onChange={setPin}
              numInputs={4}
              renderSeparator={<pre> </pre>}
              renderInput={(props) => <input {...props} className='onboardingPin-area-data-pin' style={{ width: '70px' }} />}
            />

            <p className='onboardingPin-area-data-message mt-3'>Re-enter 4-digit security PIN</p>

            <OtpInput
              value={confirmPin}
              inputType='password'
              onChange={setConfirmPin}
              numInputs={4}
              renderSeparator={<pre> </pre>}
              renderInput={(props) => <input {...props} className='onboardingPin-area-data-pin' style={{ width: '70px' }} />}
            />
          </div>

          {/* Button */}
          <div className='onboardingPin-area-button'>
            <button disabled={pin.length < 4 && !loader} type="button" className='onboardingPin-area-btn' onClick={() => handleCreateSoftCornerProfile(pin)}>
              {loader ?
                <>
                  <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />{" "}
                  <span>Creating...</span>
                </>
                :
                'Create profile'
              }

            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnboardingPin;
