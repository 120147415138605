import React, { useState, useContext } from "react";
import { ApiContext } from "../UseContext/ApiCall";
import { useEffect } from "react";
import { BASEAPIURL } from "../../../config";
import axios from "axios";
import { checkAccountPrivacy, createNotification } from "../UseContext/ApiHelpers";
import toast from "react-hot-toast";
import FollowRequest from "./FollowRequest";

const FollowUnfollowButton = ({ otherUserId, type }) => {

 

  
  const [delOpen, setDelOpen] = useState(false)
  const [status , setStatus] = useState(0); 


  const { fetchFollowStatus, setOtherUserId, config, getFollowerList, profileUserId, getFollowingList } = useContext(ApiContext);
  
  const handleFollowUnfollow = async (otherUserId, isFollowing) => {
   
    setOtherUserId(otherUserId);
    //For notification

    checkAccountPrivacy(otherUserId , config).then(async(res) => {
      if (res==='private' && action === 'follow') {
       
        const data = {
          targetId: otherUserId,
          userType: "user",     
          targetEvent: "follow",
          targetType: "follow",
          notificationType: "emilo",
          // targetEventId: postId,
          isSeen: 0,}

     
        createNotification(data, config)
        toast.success("Follow request sent")
   

        await axios.post(`${BASEAPIURL}/user/followuser`, { followingID: otherUserId, action: action }, config)
          .then(async (res) => {
            console.error("omg ", res.data.status)

            setStatus( res.data.status)
       
            getFollowerList(profileUserId);
            getFollowingList(profileUserId);
  
          })
          .catch((err) => {
            console.log("err in follow ", err)
            return null;
          });
        
  
      }
      else if(res==='private' && status=== 3 ){
        setDelOpen(true); 

      } 
      else if(res==='private' && status=== 1){
        setDelOpen(true); 

      }
      else {
         await axios.post(`${BASEAPIURL}/user/followuser`, { followingID: otherUserId, action: action }, config)
          .then(async (res) => {
            console.log("res data ", res.data.status)
          
              
         
            setStatus( res.data.status)
          
            getFollowerList(profileUserId);
            getFollowingList(profileUserId);
  
          })
          .catch((err) => {
            console.log("err in follow ", err)
            return null;
          });
          toast.success("Followed  successfuly")
        
      }
    })
    
    const action = status ?  "unfollow" : "follow" ;

  };

  useEffect(() => {
   fetchFollowStatus(otherUserId,  setStatus);
  }, [otherUserId]);

  return (
    <>

      < FollowRequest delOpen={delOpen}   setDelOpen={setDelOpen}   getFollowingList={getFollowingList}   setStatus={setStatus} status={status}  getFollowerList={ getFollowerList}  otherUserId={otherUserId} />
      {type === 'flix' ?
         <button type="button" className="followUnfollowBtn_flix fs-10" onClick={() => handleFollowUnfollow(otherUserId)}>
      
         {status === 0  ? "Follow" : (status   === 3 ? "Requested" : "Unfollow")}
        </button>
        :
        <button type="button" className="followUnfollowBtn" onClick={() => handleFollowUnfollow(otherUserId)}>
          {status === 0 ? "  Follow  " :  ( status=== 3 ? "Requested" : "Unfollow")}
         </button>
      }
    </>
  );
};

export default FollowUnfollowButton;
