import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { ApiContext } from "../../UseContext/ApiCall";
import CryptoJS from "crypto-js";
import { BASE_URL_STATIC } from "../../../../config";

const CreatePassword = () => {
  const [show, setShow] = useState(false);
  const [passwords, setPasswords] = useState({
    password: "",
    confirmpassword: "",
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State for disabling the next button
  const [passwordMatch, setPasswordMatch] = useState(null); // State for password match

  const { setUserInfo } = useContext(ApiContext);
  const nav = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({ ...prevPasswords, [name]: value }));
  };
console.log(passwordMatch);

  // Password validation checks
  const hasAlphanumeric = /[a-zA-Z]/.test(passwords.password) && /\d/.test(passwords.password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(passwords.password);
  const hasMinLength = passwords.password.length >= 8;

  // Check if password meets the required criteria
  const isValidPassword = () => {
    return hasAlphanumeric && hasSpecialChar && hasMinLength;
  };

  // Check if passwords match (only if both are at least 8 characters long)
  const doPasswordsMatch = () => {
    const { password, confirmpassword } = passwords;
    return password.length >= 8 && confirmpassword.length >= 8 && password === confirmpassword;
  };

  // Handle password submission
  const handleNextClick = () => {
    if (isValidPassword()) {
      if (!doPasswordsMatch()) {
        setPasswordMatch(false);
        return;
      }

      // If passwords match, proceed with encryption and navigation
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        password: passwords.password,
      }));

      const encryptedPassword = CryptoJS.AES.encrypt(passwords.password, "avinash123").toString();
      sessionStorage.setItem("password", encryptedPassword);

      nav("/signup/username");
    } else {
      setPasswordMatch(null); // Reset match status if validation fails
    }
  };

  useEffect(() => {
    // Enable the button only if the password is valid and passwords match
    setIsButtonDisabled(!(isValidPassword() && doPasswordsMatch()));
  }, [passwords]);

  return (
    <section className="row vh-100 mx-auto">
      {/* Left */}
      <section className="d-none d-lg-block col-lg-6 p-0 h-100">
        <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/createPassword.png`} alt="createPassword" />
      </section>

      {/* Right */}
      <section className="col-12 col-lg-6 p-0">
        {/* Logo */}
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <form className="w-75 mx-auto">
          <div className="fs-1 mb-3">Create password</div>

          <small className="mb-3">
            <ul className="ps-0">
              <li style={{ color: hasAlphanumeric ? "green" : "red" }}>
                {hasAlphanumeric ? "✔️ Must include both letters and numbers" : "❌ Must include both letters and numbers"}
              </li>
              <li style={{ color: hasSpecialChar ? "green" : "red" }}>
                {hasSpecialChar ? "✔️ Should contain at least one special character" : "❌ Should contain at least one special character"}
              </li>
              <li style={{ color: hasMinLength ? "green" : "red" }}>
                {hasMinLength ? "✔️ Password must be at least 8 characters long" : "❌ Password must be at least 8 characters long"}
              </li>
              {/* Password Match Check */}
              <li style={{ color: doPasswordsMatch() ? "green" : "red" }}>
                {doPasswordsMatch() ? "✔️ Passwords match" : "❌ Passwords do not match"}
              </li>
            </ul>
          </small>

          {/* Password Field */}
          <div className="position-relative my-3">
            <input
              type={show ? "text" : "password"}
              className="form-control form-control-lg rounded-pill"
              onChange={handleChange}
              name="password"
              placeholder="Password"
              value={passwords.password}
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y me-3"
              onClick={() => setShow(!show)}
            >
              {show ? <IoEyeOutline size={20} /> : <FaRegEyeSlash size={20} />}
            </span>
          </div>

          {/* Confirm Password Field */}
          <div className="position-relative my-3">
            <input
              type={show ? "text" : "password"}
              className="form-control form-control-lg rounded-pill"
              onChange={handleChange}
              name="confirmpassword"
              placeholder="Re-Type password"
              value={passwords.confirmpassword}
            />
            <span
              className="position-absolute top-50 end-0 translate-middle-y me-3"
              onClick={() => setShow(!show)}
            >
              {show ? <IoEyeOutline size={20} /> : <FaRegEyeSlash size={20} />}
            </span>
          </div>

          <button
            type="button"
            className="btn btn-primary w-100 rounded-pill fs-5"
            onClick={handleNextClick}
            disabled={isButtonDisabled}
          >
            Next
          </button>
        </form>
      </section>
    </section>
  );
};

export default CreatePassword;
