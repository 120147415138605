import React, { useContext, useEffect, useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { AwsFileUrl, BASEAPIURL, BASEAdminAPIURL, BASEURL, BASE_URL_STATIC, gCaptcahSiteKey } from "../../../config";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { ApiContext } from "../UseContext/ApiCall";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import toast from "react-hot-toast";
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

function Login() {
  // attemt blocked here
  const lockDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
  const [attempts, setAttemptValue] = useState(0);
  function setAttempts(v) {
    if (v >= 50) {
      setIsLocked(true);
      const lockEnd = Date.now() + lockDuration;
      localStorage.setItem('lockEnd', lockEnd);
      setTimeout(() => setIsLocked(false), lockDuration);
    }
    setAttemptValue(v);
  }
  const [isLocked, setIsLocked] = useState(false);
  // attemt blocked here
  const recaptchaRef = useRef(null);
  const EmailRef = useRef(null); // Step 2: Create a reference
  const MobileRef = useRef(null); // Step 2: Create a reference
  const toastConfig = {
    onClick: () => toast.dismiss(), // Close the toast on click
  }
  const [Type, setType] = useState("email");
  const [captcha, setCaptcha] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [checkBox, setCheckBox] = useState(); 
  const  [UserData , setUserData] = useState([])
 
  const { config, setShowHeader } = useContext(ApiContext);
  
  useEffect(() => {
    // login attemots to be checked
    const lockEnd = localStorage.getItem('lockEnd');
    if (lockEnd && Date.now() < lockEnd) {
      setIsLocked(true);
      const remainingTime = lockEnd - Date.now();
      setTimeout(() => setIsLocked(false), remainingTime);
    }
    // login attemots to be checked
    setShowHeader(false);
    const storedEmail = sessionStorage.getItem("email");
    const storedPassword = sessionStorage.getItem("password");
    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedPassword) {
      // Decrypt the password using CryptoJS
      const bytes = CryptoJS.AES.decrypt(storedPassword, 'avinash123');
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
      setPassword(decryptedPassword);
    }

    // for getting the user lar and long
    let UserLat = 0;
    let UserLong = 0;
    let UserMsg = "";
    try {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                UserLat = position.coords.latitude;
                UserLong = position.coords.longitude;
                UserMsg = "User Location from the Web Browser";
                if (UserLong) {
                  localStorage.setItem('LocUserLong', UserLong);
                }
                if (UserLat) {
                  localStorage.setItem('LocUserLat', UserLat);
                }
                localStorage.setItem('LocUserMsg', UserMsg);
              },
              (error) => {
                  switch(error.code) {
                      case error.PERMISSION_DENIED:
                          UserMsg = "User denied the request for Geolocation.";
                          break;
                      case error.POSITION_UNAVAILABLE:
                          UserMsg = "Location information is unavailable.";
                          break;
                      case error.TIMEOUT:
                          UserMsg = "The request to get user location timed out.";
                          break;
                      case error.UNKNOWN_ERROR:
                          UserMsg = "An unknown error occurred.";
                          break;
                          default:
                          UserMsg = "An unknown error occurred.";
                  }
                  // localStorage.setItem('LocUserLong', 0);
                  // localStorage.setItem('LocUserLat', 0);
                  localStorage.setItem('LocUserMsg', UserMsg);
              }
          );
      } else {
          UserMsg = "Geolocation is not supported by this browser.";
          localStorage.setItem('LocUserMsg', UserMsg);
      }
    } catch(e) {
      localStorage.setItem('LocUserMsg', e.message);
    }
    // for getting the user lar and long
    setTimeout(() => {
      const internalInput = EmailRef.current.querySelector('input'); // Adjust if necessary
      if (internalInput) {
        internalInput.focus();
      }
    }, 111);
  }, []);
  
  const verifyCaptchaFnc = async (captchaToken) => {
    setLoader(true);
    const temp = { deviceType: 'web', captcha: captchaToken }
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await axios.post(`${BASEAdminAPIURL}/api/login/verify-captcha`, temp, { headers: headers })
        setLoader(false);
        if (response.data.status === 1) {
          setCaptcha(true);
          handleSubmitApi();
        } else {
            toast.error(response.data.message, toastConfig);
            setCaptcha(false);
        }
        // handle success or perform any necessary action
    } catch (error) {
        setLoader(false)
        console.error('Error making post request:', error)
        toast.error(error.message, toastConfig);
        setCaptcha(false);
        // handle error or display error message
    }
}


const handleSubmit = async () => {
  setLoader(true);
  if (!captcha) {
      recaptchaRef.current.execute();
      console.error("Please complete the CAPTCHA challenge to verify you are not a bot.")
      return false;
  }
  handleSubmitApi();
}

const handleSubmitApi = async () => {

  if (isLocked) {
    toast.error('Account is locked. Please try again later.');
    return;
  }

  setLoader(true);

  if(checkBox === true) {
    const encryptedPassword = CryptoJS.AES.encrypt(password, 'avinash123').toString();

    sessionStorage.setItem("email", email)
    sessionStorage.setItem("password", encryptedPassword);
    // console.log("decryptedPassword", decryptedPassword)
  } else {
    sessionStorage.removeItem("email")
    sessionStorage.removeItem("password")
  };

  const temp = 1000  

  try {
    if (( email || mobile ) && password) {
      const res = await axios.post(`${BASEAPIURL}/user/userlogin`, { email, password, mobile }, config);
      // here is the api call
      if (res && res.data && res.data.token) {
        toast.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        setAttempts(0);
        setShowHeader(true);
        window.location.href = BASEURL;
      } else {
        toast.error("An error occurred. Please try again later.");
        setAttempts(attempts + 1);
      }
      // hre is the api call
      setTimeout(() => {
        setLoader(false);
      }, temp);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, temp);
      toast.error("Please enter your email or mobile number and password.", toastConfig);
      setAttempts(attempts + 1);
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message, toastConfig);
    } else {
      toast.error(error.message, toastConfig);
    }
    setAttempts(attempts + 1);
    setTimeout(() => {
      setLoader(false);
    }, temp);
  };

};
  
const UserSearch = async (value) => {
  try {
    setEmail('')

    if (value.length > 10) {

      const response = await axios.post(`${BASEAPIURL}/user/userloginmultipleaccount`, { mobile: value });
       setUserData(response.data.user); 
    } else {
      console.log("Invalid mobile number length. Must be greater than 10 characters.");
    }
  } catch (error) {
    console.error('Error occurred while handling user login:', error);
  }
};
const handleChange = (event) => {
  setEmail(event.target.value);
  setUserData([])
  
};

console.log("UserData", UserData);


  return (
    <>
      <section className="row vh-100 mx-auto">
        {/* left section */}
        <div className="d-none d-lg-block col-lg-6 p-0 h-100">
          <img className="bg-primary h-100 w-100 object-fit-contain" src={`${BASE_URL_STATIC}/Loginimg.png`} alt="Loginimg" />
        </div>

        {/* right section */}
        <div className="col-12 col-lg-6 p-0">
          {/* Logo */}
          <div className="text-center">
            <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
          </div>

          <form
            className="w-75 mx-auto"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="fs-1 mb-3 mb-lg-5">Login</div>

            <div ref={EmailRef} style={{ display: Type === 'email' ? "" : "none" }}>
              <input
                type="text"
                className="form-control form-control-lg rounded-pill p-4" 
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                placeholder="Username or Email"
              />
            </div>

            <div ref={MobileRef} style={{ display: Type === 'mobile' ? "" : "none" }}>
              <PhoneInput
                value={mobile}
                onChange={(value) => {
                  setmobile(value); 
                  UserSearch(value); 
                }}
                defaultCountry="IN"
                placeholder="Mobile"
              />
            </div>

            <div className="text-right">
              {Type === 'email' ? (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    setType('mobile');
                    const internalInput = MobileRef.current.querySelector('input'); // Adjust if necessary
                    if (internalInput) {
                      internalInput.focus();
                    }
                  }}
                >
                  <small>Via Mobile</small>
                </a>
              ) : (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    setType('email');
                    setEmail('')
                    const internalInput = EmailRef.current.querySelector('input'); // Adjust if necessary
                    if (internalInput) {
                      internalInput.focus();
                    }
                  }}
                >
                  <small>Via Email</small>
                </a>
              )}
            </div>
           
                  {UserData && UserData.length > 1 &&
                     UserData.map((user, index) => (
                      <div className="searchList mx-4 my-3 justify-content-center d-flex  align-items-center  ">
                     <div className="col-12 col-lg-9">
                        <div className={`me-2 ms-2 px-3 mt-1 d-flex box-shadow border justify-content-between border-secondary-subtle rounded-2 
                          ${email=== user.email? 'border-primary text-primary' : 'border-secondary text-black'} `} key={index} 
                          style={{ 
                            background: email === user.email ? 'rgba(13, 110, 253, 0.1)' : 'transparent', 
                        transition: 'all 0.3s ease-in-out' 
                            }}>
                              <div className="d-flex">
                           <div className="p-2">
                              <img
                                 className="searchImage"
                                 src={user.imageId ? (AwsFileUrl + user.imageId) : `${BASE_URL_STATIC}/dummy.png`}
                                 alt="profile"
                                 onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`}
                              />
                           </div>

                           <div className="userSearchList  mt-1 fw-bold">
                              <span>{user.fname}</span>
                              <div>{user.username ? <span>{user.username}</span> : <span>{user.fname}</span>}</div>
                           </div>
                              </div>

                           <input
                              type="radio"
                              id={user.email}
                              name="UserId"
                              className="cp"
                              value={user.email}
                              checked={email=== user.email}
                              onChange={handleChange}
                            />
                        </div>
                        </div>
                        </div>

                     ))
                  }
           
            <div className="mb-4"></div>
              {UserData && UserData.length <= 1 ? 
            <div className="position-relative">

      <input type={show ? "text" : "password"} className="form-control form-control-lg rounded-pill" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" /> 




              <span className="position-absolute top-50 end-0 translate-middle-y me-3" onClick={() => setShow(!show)} >
                {show ? <IoEyeOutline size={20} /> : <FaRegEyeSlash size={20} />}
              </span>
            </div>   : null}

            <div className="row w-100 mx-auto my-3">
              <div className="col-auto ms-0 ms-sm-4 ms-md-4 me-auto p-0">
                <input type='checkbox' className="form-check-input" id='keepMeSignedIn' onChange={(e) => setCheckBox(e.target.checked)} />
                <label htmlFor="keepMeSignedIn" className="form-check-label ms-1">Keep me signed in</label>
              </div>

              <Link to={`${BASEURL}/signup/fp-mobile_email`} className="col-auto p-0"><small>Forgot password ?</small></Link>
            </div>
            <center>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={gCaptcahSiteKey} // replace with your actual site key
                    onChange={verifyCaptchaFnc}
                    size="invisible" // Set ReCAPTCHA to invisible mode
                />
            </center>
            <button disabled={loader} type="submit" className="w-100 btn btn-primary rounded-pill py-1 py-md-2 fs-5 my-3" >
              {
                loader 
                ? 
                <span className="flex justify-content-center">
                  <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />{" "}
                  please wait...
                </span>
                : 
                "Sign in"
              }
            </button>
          </form>


          <div className="text-center text-secondary">Don't have an account? <Link to={`${BASEURL}/signup`}> Sign Up.</Link></div>
        </div>
      </section>
    </>
  );
}

export default Login;


