import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { ScApiContext } from "../useContext/softCornerContext";
import { addSCPartner, createConnection, getPartnerinfo } from "../../Emilo/UseContext/ScApiHelpers";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";
import axios from "axios";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../config";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";


const AddRemoveBtn = ({ partnerUserId }) => {

  const [btnLoader, setbtnLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    fetchAddStatus, setCurrentCrush, setBtnEnable, setRightBlockUser,
    scConfig, setIsSoftCornerPartnerExist, setChatId
  } = useContext(ScApiContext);

  const { config, loggedInUserId } = useContext(ApiContext)

  useEffect(() => {
    fetchAddStatus(partnerUserId);
    setCurrentCrush(partnerUserId)

  }, []);


  const handleClick = () => {
    setbtnLoader(true);
    addSCPartner(loggedInUserId, partnerUserId, "add", scConfig).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message);
        setbtnLoader(false);
        setRightBlockUser(null)
      }
      else {
        toast.error(res.data.message);
        setbtnLoader(false);
      }
    }).then(() => {
      getPartnerinfo(config).then((res) => {
        if (res.status === 200) {
          setBtnEnable(false);
          setIsSoftCornerPartnerExist(true);
        }
        else if (res.status === 201) {
          setBtnEnable(true);
          setIsSoftCornerPartnerExist(false);
        } else {
          setIsSoftCornerPartnerExist(false)
        }
      })
      createConnection(scConfig).then((res) => {
        setChatId(res.chatId)
      })
    })
    addProfileCrush('crush')
  }

  const addProfileCrush = async (type) => {
    await axios.post(`${BASEAPIURL}/softcorner/createprofileviewsc`, { type, crushUserId: partnerUserId }, config)
      .then(res => {
        return null;
      })
      .catch(err => {
        console.log(err);
      });
  };

  const ConfirmPopup = () => {
    return (
      <div>
        <Modal show={open} onHide={() => setOpen(false)} centered backdrop="static" keyboard={false} className="create-call p-0" >
          <Modal.Body className='p-2'>

            {/* T & C */}
            <div className='mx-3 my-3 d-flex align-items-start'>
              <img className='me-3' src={`${BASE_URL_STATIC}/top-icon3.png`} alt="logo" />
              <div className=''>
                <h6 className='fw-bold d-block'>Note</h6>
                <small>Once you add the first user, you can change or select another user within <b>24 hours</b> . Otherwise, you will have to wait until <b>96 hours</b>  are over to make any changes or select another user. Until then, you will not be able to add another user.
                 {/* <Link>Learn more...</Link>  */}
                 </small>
              </div>
            </div>

            <div className="flex col-10 mx-auto mb-3">

              <button className="cancel_partner" onClick={() => setOpen(false)}>
                Cancel
              </button>

              <button
                type="button"
                className="confirm_partner"
                onClick={() => {
                  handleClick()
                }}
              >
                {btnLoader ? <span className="spinner-border p-2" role="status">
                  <span className="visually-hidden"></span>
                </span> : 'Confirm'}
              </button>

            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  return (
    <>
      <ConfirmPopup />
      <button
        type="button"
        className="btn btn-primary px-5 rounded-5"
        onClick={() => setOpen(true)}>
        Add
      </button>
    </>
  );
};

export default AddRemoveBtn;
