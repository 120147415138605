
import React, { useState, useContext, useEffect } from 'react';
import PostTime from '../Home/CenterBlock/CenterBlockPosts/PostTime';
import axios from 'axios';
import { ApiContext } from '../UseContext/ApiCall';
import { Link } from 'react-router-dom';
import { BASEURL, BASE_URL_STATIC, AwsFileUrl, BASEAPIURL } from '../../../config';
import EmojiPicker from 'emoji-picker-react';
import { createNotification } from '../UseContext/ApiHelpers';

const AddComments = ({ commentType, setComment, clickedreply, setClickedReply, postId, viewReply, setShowProfilePostCommentThreeDotPopup, comments, setComments, parentSpinner, setParentSpinner, postsPopupData,setPostsPopupData }) => {

  const [createComment, setCreateComment] = useState('');
  const [commentStates, setCommentStates] = useState({});
  const [parentCommentId, setParentCommentId] = useState('')
  const [replayingCommentUserId, setReplayingCommentUserId] = useState("")
  // const [openEmoji, setOpenEmoji] = useState(false);
  const [viewChildComment, setViewChildComment] = useState(false);
  const [commentIndex, setCommentIndex] = useState(null);
       

  const { config, socket, openEmoji, setOpenEmoji,  loggedInUserId , setallDataPost} = useContext(ApiContext);

  const typeIdMapping = {
    reel: "reelId",
    post: "postId",
    bestclip: "bestclipId",
    meme: "memeId",
  };

  const handleEmojiPick = (e) => {
    setCreateComment(prev => prev + e.emoji)
  };




  const payload = {

    postId: postsPopupData._id,
    eventType: "comment",
    postedBy: postsPopupData.postedBy,
    postType: "post",
    comments: [{ text: createComment }]

  }

  const getPayLoad = {
    type: commentType,
    [typeIdMapping[commentType]]: postId,
  };

  const createChildCommentPayload = {
    commentid: parentCommentId,
    eventType: "replyComments",
    childComment: createComment, 
    createdFor  : replayingCommentUserId,

  }


  const HandleLikeComment = async (comment) => {

    const commentLike = {
      commentid: comment._id,
      eventType: "likecomment"
    };


    try {
      const res = await axios.post(`${BASEAPIURL}/post/postEvent`, commentLike, config);

      if (res.data.data) {
        // Update the specific comment in the state
        setComments((prevComments) =>
          prevComments.map((c) =>
            c._id === comment._id
              ? { ...c, isLiked: 1, commentlikeCount: c.commentlikeCount + 1 }
              : c
          )
        );
      } else {
        setComments((prevComments) =>
          prevComments.map((c) =>
            c._id === comment._id
              ? { ...c, isLiked: 2, commentlikeCount: c.commentlikeCount - 1 }
              : c
          )
        );

      }
    } catch (err) {
      console.error(err);
    }
  }

  const HandleChildCommentsLike = async (id) => {
    try {
      const childreplayPayload = { childreplayId: id };
      const res = await axios.post(`${BASEAPIURL}/post/likereplaycomments`, childreplayPayload, config);

      if (res.data.status === 1) {
        setComments((prev) =>
          prev.map((comment) => {
            const replyComments = comment.replyComments || [];

            const updatedReplies = replyComments.map((reply) => {
              if (reply._id === id) {
                const like = reply.likes;
                like.push(loggedInUserId)

                return { ...reply, likes: reply.likes };
              }
              return reply;
            });

            return { ...comment, replyComments: updatedReplies };
          })
        );
      } else {
        setComments((prev) =>
          prev.map((comment) => {
            const replyComments = comment.replyComments || [];

            const updatedReplies = replyComments.map((reply) => {
              if (reply._id === id) {
                const like = reply.likes;
                like.pop(loggedInUserId)
                return { ...reply, likes: reply.likes };
              }
              return reply;
            });

            return { ...comment, replyComments: updatedReplies };
          })
        );
      }

    } catch (error) {
      console.log(error)

    }
  }

  const handleProfilePostCommentThreeDot = (comment) => {
    setComment(comment);
    setShowProfilePostCommentThreeDotPopup(true);

  };

  const handleCommentReply = (comment) => {

    setClickedReply(true);
    setParentCommentId(comment._id);
    setReplayingCommentUserId(comment.commentUser.id);
    if (comment.fname) {
      return setCreateComment('@' + (comment.fname) + ' ');
    }
    setCreateComment('@' + (comment.commentUser.username || comment.commentUser.fname) + ' ');

  };

  const handleCommentReply2 = (comment) => {
    setClickedReply(true);
    setParentCommentId(comment.commentid);
    setReplayingCommentUserId(comment.userid);
    if (comment.fname) {
      return setCreateComment('@' + (comment.fname) + ' ');
    }
    setCreateComment('@' + (comment.commentUser.username || comment.commentUser.fname) + ' ');

  };

  // To get comment
  const getPostComment = async () => {
    setParentSpinner(true);
    await axios.post(`${BASEAPIURL}/post/getAllcomments`, getPayLoad, config)
      .then(res => {

        setComments(res.data);
        setParentSpinner(false);
      })
      .catch(err => {
        console.log("error", err);
        setComments(null);
        setParentSpinner(false)
        return null;
      });
  };

  useEffect(() => {
    getPostComment()
  }, [])
  
  if(createComment.length === 0){
       setClickedReply(false);
  }


  // To create comment
  const createPostComment = async (e, comment, config) => {
    e.preventDefault()
    setOpenEmoji(false);

    await axios.post(`${BASEAPIURL}/post/postEvent`, payload, config)
      .then(async (res) => {
        setCreateComment('');
        getPostComment();
        setPostsPopupData((prev) => ({
          ...prev,
          commentcount: (Number(prev.commentcount) || 0) + 1
        }));
        setallDataPost((prev) => prev.map((post) => {
          return {
            ...post,
            commentcount: post._id === postsPopupData._id ? (Number(post.commentcount) || 0) + 1 : post.commentcount
          }
        }))
        

        const data = {
          targetId: postsPopupData.postedBy,
          userType: "user",
          targetEvent: "comment",
          targetType: postsPopupData.type,
          targetEventId: postsPopupData._id,
          notificationType : "emilo",
        }

        if (postsPopupData.postedBy !== loggedInUserId) {
          createNotification(data, config)
            .then(res => {
              // setOldNotifications(prev => [res , ...prev])
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        return null;
      });
  };



  // To create child comment   
  const handleSendReply = async (e) => {
    e.preventDefault()

    await axios.post(`${BASEAPIURL}/post/postEvent`, createChildCommentPayload, config)
      .then(async res => {

        setCreateComment('');
        setClickedReply(false);
        getPostComment();

        // For notification
        await axios.post('https://emilonode.sjain.io/user/createemnotification', { postId: res.data.message.postId, childCommentId: res.data.message._id, type: 'childcomment' }, config)
          .then(res => {

            let notificationData = {
              type: res.data.type,
              senderUserId: res.data.senderUserId && res.data.senderUserId,
              receiverUserId: res.data.receiverUserId && res.data.receiverUserId,
              message: res.data.message && res.data.message,
              createdAt: res.data.createdTime && res.data.createdTime,
              fname: res.data.fname && res.data.fname,
              postId: res.data.postId && res.data.postId,
              postImg: res.data.postImg && res.data.postImg,
              profileImg: res.data.profileImg && res.data.profileImg,
              tumbnailImg: res.data.tumbnailImg && res.data.tumbnailImg,
            }

            socket.emit("send_message_emilo_notification", notificationData);
          })
          .catch(err => {
            return null;
          });
      })
      .catch(err => {
        return null;
      });
  };

  // TO toggle view reply
  const toggleViewReply = (commentId) => {
    setCommentStates((prevStates) => ({
      ...prevStates,
      [commentId]: !prevStates[commentId],
    }));
  };
 



  return (
    <>
      <div className='profilePostsPopup-details-comments'>
        <h3 className='profilePostsPopup-details-comments-head'>Comments</h3>

        {/* Show Comments */}
        {comments && comments.length > 0 ? <div className='profilePostsPopup-details-comments-show'>
          {
            (!parentSpinner)
              ?
              comments
              &&
              comments.map((comment, index) => (
                <div key={index} className='profilePostsPopup-details-comments-show-user'>

                  {
                    comment.commentUser.imageId
                      ?
                      <img className='profilePostsPopup-details-comments-show-profile' src={`${AwsFileUrl}${comment.commentUser.imageId}`} alt={comment.parentCommentId} height={40} width={40} />
                      :
                      <img className='profilePostsPopup-details-comments-show-profile' src={`${BASE_URL_STATIC}/dummy.png`} alt="profile" height={40} width={40} />
                  }

                  <div className='profilePostsPopup-details-comments-show-head'>
                    <h6 className='profilePostsPopup-details-comments-show-name'>{comment.commentUser.username ? comment.commentUser.username : comment.commentUser.fname}</h6>
                    <span className='profilePostsPopup-details-comments-show-time'>
                      <PostTime date={comment.comments[0].createdAt} />
                    </span>
                    <img className='profilePostsPopup-details-comments-show-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="threeDot" onClick={() => handleProfilePostCommentThreeDot(comment)} />

                    <div className='d-flex justify-content-start align-items-center gap-2'>
                      <span className='profilePostsPopup-details-comments-show-comment'>{comment.comments[0].text}</span>
                      <small className='reply' onClick={() => handleCommentReply(comment, index)} >Reply</small>
                      <small className=''>
                        <img onClick={() => HandleLikeComment(comment)} className="likePostBtn cp" src={`${BASE_URL_STATIC}/icon/like-${comment.isLiked === 1 ? '2' : "1"}.svg`} alt="" style={{width : "11px", marginRight:"2px" , marginBottom:"5px"}} />
                        {comment.commentlikeCount}
                      </small>
                    </div>

                    <div style={{ height: `${!viewReply && '0px'}`, margin: "5px 0px" }}>

                      {/* view reply comments */}
                      <small className='viewReply' onClick={() => { toggleViewReply(comment._id); setViewChildComment(!viewChildComment ); setCommentIndex(index) }}>
                        <span className='mb-2 fs-10' style={{ cursor: "pointer" }}>
                          {
                            comment.replyComments.length >  0  ? (
                              comment.replyComments && comment.replyComments[0].userid &&`------- ${commentStates[comment._id] ? 'Hide' : 'View'} replies(${comment.replyComments.length})-------`

                            ) : ''
                          }
                       
  
                       
                        </span>
                      </small>
                    </div>

                    {/* view reply comments */}
                    {comment.replyComments && viewChildComment &&  index === commentIndex
                      &&

                      (<div className='profilePostsPopup-details-replyComments' >
                        <div className='profilePostsPopup-details-replyComments-show'  >
                          {
                            comment.replyComments && comment.replyComments.length  > 0
                            &&
                            comment.replyComments.map((comment, index) => (
                              comment.childComment ? 
                              
                              
                              (<div key={index} className='profilePostsPopup-details-replyComments-show-user'>
                                {
                                  comment.imageid
                                    ?
                                    <img className='profilePostsPopup-details-comments-show-profile' src={`${AwsFileUrl}${comment.imageid}`} alt={comment._id} height={40} width={40} />
                                    :
                                    <img className='profilePostsPopup-details-comments-show-profile' src={`${BASE_URL_STATIC}/dummy.png`} alt="profile" height={40} width={40} />
                                }

                                <div className='profilePostsPopup-details-replyComments-show-head'>
                                  <h6 className='profilePostsPopup-details-comments-show-name'>{comment.fname}</h6>
                                  <span className='profilePostsPopup-details-comments-show-time'>
                                    <PostTime date={comment.createdAt} />
                                  </span>
                                  <img className='profilePostsPopup-details-comments-show-threeDot' src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="threeDot" onClick={() => handleProfilePostCommentThreeDot(comment)} />

                                  <div className='d-flex justify-content-start align-items-center'>
                                    <span className='profilePostsPopup-details-comments-show-comment'>
                                      {comment.childComment &&
                                        comment.childComment.split(/(@\S+)/).map((part, index) =>
                                          part.match(/@(\S+)/) ? (
                                            <Link key={index} to={`${BASEURL}/profile/${comment.createdFor}`} target='_blank'>
                                              <span style={{ color: '#444eaa', marginRight: "5px", paddingRight: "0px" }}>
                                                {part}
                                              </span>
                                            </Link>
                                          ) : (
                                            <span style={{ paddingRight: "0px" }} key={index}>{part}</span>
                                          )
                                        )}
                                    </span>

                                    <small className='replyView'>
                                 
                                      <img onClick={() => HandleChildCommentsLike(comment._id)} className="likePostBtn cp" src={`${BASE_URL_STATIC}/icon/like-${comment.likes.includes(loggedInUserId) ? '2' : "1"}.svg`} alt="" style={{width : "11px", marginRight:"2px" , marginBottom:"5px"}} />
                                      {comment.likes.length}
                                      </small>
                                    <small className='replyView' onClick={() => handleCommentReply2(comment)}>Reply</small>
                                  </div>

                                </div>

                                <img className='profilePostsPopup-details-comments-show-like' src={`${BASE_URL_STATIC}/feed-icon2.png`} alt="like" height={27} width={20} />
                              </div>) : null
                            ))
                          }
                        </div>
                      </div>)}

                  </div>

                  <img className='profilePostsPopup-details-comments-show-like' src={`${BASE_URL_STATIC}/feed-icon2.png`} alt="like" height={27} width={20} />
                </div>
              ))
              :
              <div className='commentLoader'></div>
          }
        </div> :
          <div className='zero_comments'>
            <img src={`${BASE_URL_STATIC}/noupcomming-event.png`} alt="" />
            <p>0 comments</p>
          </div>}

        {/* Create Comments */}
        <div className='profilePostsPopup-details-comments-create'>
          <img onClick={() => setOpenEmoji(!openEmoji)} className='profilePostsPopup-details-comments-create-icon' src={`${BASE_URL_STATIC}/create-icon1.png`} alt="icon" height={27} width={27} />

          <form className='profilePostsPopup-details-comments-create-form' onSubmit={(e) => { clickedreply ? handleSendReply(e) : createPostComment(e, createComment, config); }}>
            <label htmlFor="comment">
              <input type="text" id='comment' name='comment' value={createComment} onChange={(e) => setCreateComment(e.target.value)} placeholder='Add a comment' />
            </label>


            <span className='emoji-select-emilo'>
              {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} />}
            </span>

            <button type='submit' className='profilePostsPopup-details-comments-create-btn'>
              {clickedreply ?  'Reply'   : 'Comment'  }
            </button>


          </form>

        </div>
      </div>
    </>
  )
}

export default AddComments