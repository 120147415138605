import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';
import { businessContext } from '../../../UseContext/BusinessContext';


const ViewFranchisePopup = () => {

  const {
    showViewFranchisePopup, setShowViewFranchisePopup,
    franchise, setFranchise,
    deleteFranchise,
    setFranchiseImages,
  } = useContext(businessContext);

  // handle popup close
  const handlePopupClose = () => {
    setShowViewFranchisePopup(false);

    setFranchiseImages([]);

    setFranchise({
      images: [],
      title: '',
      description: '',
      price: '',
      link: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };


  return (
    <>
      <Modal show={showViewFranchisePopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Product</h4>

          {/* Images */}
          <div className='mb-3'>
            {
              franchise && franchise.images && franchise.images.length > 0 
              && 
              <Slider className="franchise-images-slider" {...settings}>
                {/* Live Images */}
                {
                  franchise.images.map((value, index) => (
                    <div className='border rounded-3' key={index}>
                      <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={franchise.title} placeholder='Title...' />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={franchise.description} placeholder='Description...'></textarea>

          {/* Delete */}
          <div className='text-center'>
            <button type="button" className='btn btn-danger w-50 rounded-pill' onClick={deleteFranchise}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewFranchisePopup
