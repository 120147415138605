import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from "axios";
import { BASEAPIURL } from "../../../../../../config";


const ProfilePostCommentThreeDotPopup = ({ showProfilePostCommentThreeDotPopup, setShowProfilePostCommentThreeDotPopup, comment, getPostComment,  }) => {

  const { config,  setComments, } = useContext(ApiContext);
  
  const deletePostComment = ( ) => {
    if(comment.childComment === undefined){

    axios.post( `${BASEAPIURL}/post/commentdelete`, {  commantid:  comment._id }, config)

    .then(res => {


      if(res.data.status === 1){

        setComments((prevComments) => {
          const updatedComments = prevComments.filter(c => c._id !== comment._id);
          return updatedComments;
        });
      }
    
      
      setShowProfilePostCommentThreeDotPopup(false);
      getPostComment(comment.postId, config);
      
    })
    .catch(err => {
      console.log(err);
    });
  }else{
    axios.post(`${BASEAPIURL}/post/commentdelete`, {
      eventType: "deleteReplyComment",
      childCommentId: comment._id
  }, config)
  .then(response => {
    
  
      if(response.data.data.modifiedCount> 0){
        setComments((prev) =>
          prev.map((comment) => {
            const replyComments = comment.replyComments || [];
            const  replyComments1 = replyComments.filter(item => item._id !==   response.data.childcommentObj)
            return { ...comment, replyComments: replyComments1  };
          })
        );

      }
  
  setShowProfilePostCommentThreeDotPopup(false);
  })
  .catch(error => {
      // Handle error (e.g., show error message)
      console.error("An error occurred while deleting the comment: ", error);
  });

  }
  };

  return (
    <>
      {
      // comment && (comment.commentUser.id  === loggedInUserId)  ||  comment && (comment.postedBy  === loggedInUserId)  || comment && (comment.createdBy === loggedInUserId)  
       comment
     
        ?
        <Modal show={showProfilePostCommentThreeDotPopup} onHide={() => setShowProfilePostCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter"  centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text profilePostThreeDotPopup-text-1" onClick={() =>  deletePostComment()}>Delete Comment</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfilePostCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
        :
        <Modal show={showProfilePostCommentThreeDotPopup} onHide={() => setShowProfilePostCommentThreeDotPopup(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <div className="profilePostThreeDotPopup">
              <span className="profilePostThreeDotPopup-text" onClick={() => console.log('report')}>Report</span> <hr />
              <span className="profilePostThreeDotPopup-text" onClick={() => setShowProfilePostCommentThreeDotPopup(false)}>Cancel</span>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}

export default ProfilePostCommentThreeDotPopup
