import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from '../../../config'
// import { ApiContext } from '../UseContext/ApiCall';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faClose } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import SetChatGroupName from './SetChatGroupName';
import { ApiContext } from '../UseContext/ApiCall';

const CreateChatGroupPop = ({ open, close, setCreateGroup }) => {

   const { config } = useContext(ApiContext);

   const [loader, setLoader] = useState(false)
   const [inputValue, setinputValue] = useState('')
   const [searchList, setSearchList] = useState([])
   const [selectedMembers, setSelectedMembers] = useState([])
   const [createGroupFinal, setCreateGroupFinal] = useState(false)
   const [isAdmincanMsg, setisAdmincanMsg] = useState("false")

   // console.log("foller" , followerList);

   useEffect(() => {
      setinputValue("")
      setSearchList([])
      setSelectedMembers([])
   }, [setCreateGroup])

   const handleSearch = async (e) => {
      setLoader(true)
      setinputValue(e.target.value)
      if (e.target.value.length > 0) {
         const res = await axios.post(`${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, {}, config)
         setSearchList(res.data.users)
         setLoader(false)
      }
      else {
         setSearchList([])
         setinputValue('')
         setLoader(false)
      }
   }


  

   const handleSelectedMembers = (user) => {
     

      if (selectedMembers.length >= 10) {
         toast.error("Only 10 members allowed in a group");
         return;
      }

      const isUserSelected = selectedMembers.some(member => member.userId === user.userId);

      if (isUserSelected) {
         toast.error(`${user.fname} already selected`);
         return;
      }

      setSelectedMembers((prev) => [...prev, user]);
   }


   const handleNext = () => {
      if (selectedMembers.length === 0) {
         toast.error("please select group members")
      }
      else {
         setCreateGroupFinal(true)
         setCreateGroup(false)
      }
   }

   const filterMember = (e) => {
      setSelectedMembers((prev) => prev.filter((user) => user !== e))
   }

   const handleCheckBox = (e) => {
      if (e.target.checked) {
         setisAdmincanMsg('true')
      } else {
         setisAdmincanMsg("false")
      }
   }

   return (
      <div>

         <SetChatGroupName isAdmincanMsg={isAdmincanMsg} open={createGroupFinal} close={() => setCreateGroupFinal(false)} setCreateGroupFinal={setCreateGroupFinal} selectedMembers={selectedMembers} />

         <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call modal-head-search" >
            <Modal.Header closeButton style={{ width: "100%" }}>
               <div className='create-group-header'>
                  <h5 >Create Group</h5>
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className="search-box">
                  <div className="input-box">
                     <form action="#" className='search'>
                        <input type="text" placeholder="Search" onChange={handleSearch} value={inputValue} />
                     </form>
                  </div>

                  <div className="w-100 form-check form-switch d-flex justify-content-between align-items-center mt-2">
                     <p className="form-check-label fs-10" htmlFor="flexSwitchCheckDefault">Only admin can message</p>
                     <input className="form-check-input position-relative cp" onChange={handleCheckBox} type="checkbox" role="switch" id="flexSwitchCheckDefault" aria-checked />
                  </div>

                  {inputValue &&
                     <div className="searchList">
                        {loader ?

                           <FontAwesomeIcon className='searchLoaderShare' icon={faSpinner} spinPulse style={{ color: "#000000", }} />

                           :

                           searchList.length > 0 ?
                              searchList.map((user, index) => (
                                 <div className='flex' key={index} onClick={() => handleSelectedMembers(user)}>
                                    <div className='name-img'>
                                       <div>
                                          <img className="searchImage" src=
                                             {user.profileImg ? AwsFileUrl + user.profileImg.imageId : `${BASE_URL_STATIC}/dummy.png`} alt="" />
                                       </div>

                                       <div className="userSearchList">
                                          <span >{user.fname}</span>
                                          <div>
                                             {user.username && <span>{user.username}</span>}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              )) :

                              <p className="w-100 text-center">
                                 <i style={{ color: "#ff6863" }}>No user found</i>
                              </p>
                        }
                     </div>}


                  {selectedMembers && selectedMembers.length > 0 && <div className='selected-mem-list'>
                     {
                        selectedMembers.reverse().map((e, i) => (
                           <div key={i} className='selected-mem'>
                              <span>{e.fname}</span>
                              <span onClick={() => filterMember(e)}>
                                 <FontAwesomeIcon icon={faClose} />
                              </span>
                           </div>
                        ))
                     }
                  </div>}
               </div>

               <button onClick={handleNext} className='group-create-button'>Next</button>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default CreateChatGroupPop