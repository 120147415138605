import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import * as cam from '@mediapipe/camera_utils';
let backgroundImage = '';
// , setLoadingBG, loadingBG
const ArVrBgEffectsSection = ({ bgImgUrl, windowWidth, windowHeight, canvasRef, selectedCamera }) => {
  const videoRef = useRef(null);

  useEffect(() => {

    const videoElement = document.getElementById('inputVideo');
    const canvasElement = document.getElementById('outputCanvas');


    if (windowWidth < 1000) {
      videoElement.width = windowWidth;
      videoElement.height = windowHeight;
      // Ensure canvas matches video
        canvasElement.width = videoElement.width;
        canvasElement.height = videoElement.height;
    }
    // setLoading(true);
    backgroundImage = new Image();
    backgroundImage.src = bgImgUrl; // Replace with your background image path
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });
    selfieSegmentation.setOptions({
      modelSelection: 0, // 0 for general-purpose, 1 for close-up
    });

    selfieSegmentation.onResults((results) => {
      if (!canvasRef.current) return;
      // const canvasCtx = canvasRef.current.getContext('2d');
      const canvasCtx = canvasRef.current.getContext('2d') || canvasRef.current.getContext('webgl');
      console.log("#########################");
      console.log('canvasRef.current');
      console.log(canvasRef.current);
      console.log('canvasRef.current');
      const canvasWidth = canvasRef.current.width;
      const canvasHeight = canvasRef.current.height;
      console.log("#########################");

      // Step 1: Draw the blurred background
      canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear previous frames
      // const zoomLevel = 2;
      // canvasCtx.save();
      // canvasCtx.scale(zoomLevel, zoomLevel); // Apply zoom to the canvas content

      canvasCtx.filter = 'blur(-10px)'; // Apply a blur filter

      // Assume `backgroundImage` is an Image object
      const imgWidth = backgroundImage.width;
      const imgHeight = backgroundImage.height;


      // Calculate image aspect ratio and canvas aspect ratio
      const imgAspectRatio = imgWidth / imgHeight;
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let drawWidth, drawHeight, offsetX, offsetY;

      // Determine whether to scale by width or height
      if (imgAspectRatio > canvasAspectRatio) {
        // Image is wider relative to the canvas: scale by height
        drawHeight = canvasHeight;
        drawWidth = imgWidth * (canvasHeight / imgHeight);
        offsetX = -(drawWidth - canvasWidth) / 2; // Center horizontally
        offsetY = 0;
      } else {
        // Image is taller relative to the canvas: scale by width
        drawWidth = canvasWidth;
        drawHeight = imgHeight * (canvasWidth / imgWidth);
        offsetX = 0;
        offsetY = -(drawHeight - canvasHeight) / 2; // Center vertically
      }

      // Draw the image with calculated dimensions
      canvasCtx.drawImage(backgroundImage, offsetX, offsetY, drawWidth, drawHeight);
      // canvasCtx.drawImage(backgroundImage, offsetX, offsetY, canvasWidth, canvasHeight);


      // canvasCtx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);

      // Step 2: Apply the segmentation mask to cut out the person from the blurred background
      canvasCtx.globalCompositeOperation = 'destination-out';
      canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasWidth, canvasHeight);

      // Step 3: Draw the unblurred person (foreground)
      canvasCtx.globalCompositeOperation = 'destination-over';
      canvasCtx.filter = 'none'; // Remove blur for the person
      canvasCtx.drawImage(results.image, 0, 0, canvasWidth, canvasHeight);

      // Step 4: Reset composite operation to default
      canvasCtx.globalCompositeOperation = 'source-over';
    });

    // if (videoRef.current) {
    // if (typeof videoRef.current !== 'undefined' && videoRef.current !== null && videoRef.current) {
    //   const camera = new cam.Camera(videoRef.current, {
    //     onFrame: async () => {
    //       await selfieSegmentation.send({ image: videoRef.current });
    //       // setLoading(false);
    //     },
    //     // width: 1280,  // Set desired width (HD resolution)
    //     // height: 720,  // Set desired height (HD resolution)
    //     // facingMode: 'user', //  Use 'user' for the rear camera Use 'environment' for the rear camera
    //     facingMode: facingMode, 
    //   });
    //   camera.start();
    // }

    if (typeof videoRef.current !== 'undefined' && videoRef.current !== null && videoRef.current) {
      const startCamera = async () => {
        try {
          // Stop any existing video streams
          const existingStream = videoRef.current.srcObject;
          if (existingStream) {
            existingStream.getTracks().forEach((track) => track.stop());
          }
    
          // Get the video stream for the selected camera
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedCamera ? { exact: selectedCamera } : undefined },
          });
    
          // Assign the stream to the video element
          videoRef.current.srcObject = stream;
    
          // Initialize MediaPipe camera with the video element
          const camera = new cam.Camera(videoRef.current, {
            onFrame: async () => {
              await selfieSegmentation.send({ image: videoRef.current });
              // setLoading(false);
            },
          });
          camera.start();
        } catch (err) {
          console.error('Error starting camera:', err);
        }
      };
    
      startCamera();
    }
    

  }, [bgImgUrl, selectedCamera]);

  return (
    <>
      {/* {loadingBG && (
          <div className="text-center pt-5 pb-5" style={{ background: '#2f2ee9' }}>
              <img src="/loader.webp" alt="" style={{ maxWidth: '100px' }} />
              <br />
                    <span className='text-center'>
                        Loading...
                    </span>
          </div>
      )} */}
      {/* <div style={{ opacity: loadingBG ? 0 : 1 }}></div> */}
      <div>
        <video
            id="inputVideo"
            ref={videoRef}
            style={{
                display: 'none',
                // width: `${windowWidth}px`,
            }}
            autoPlay
            muted
            className="videoCreateARVR"
        />
        <canvas id="outputCanvas" ref={canvasRef} className="canvasCreateARVR" />
      </div>
    </>
  );
};


ArVrBgEffectsSection.propTypes = {
  bgImgUrl: PropTypes.any.isRequired,
  windowWidth: PropTypes.any.isRequired,
  setLoading: PropTypes.any.isRequired,
  selectedCamera: PropTypes.any.isRequired,
}

export default ArVrBgEffectsSection;
