import axios from "axios";
import React, { useRef, useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { HiMiniXMark } from "react-icons/hi2";
// import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";
import "../../../assets/css/feelingsCss.css";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from "../../../config";
import ReactPlayer from "react-player";
import { ApiContext } from "../UseContext/ApiCall";
import GetThumbnail from "../ThumbnailExtractor/GetThumbnails";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AddTags = ({
  selectphotoAndVideo,
  tagsByCarouselItem,
  setTagsByCarouselItem,
  fileUrl,
  selectedImage, setSelectedImage
}) => {
  const [openInput, setopenInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [xVal, setxVal] = useState(null);
  const [yVal, setyVal] = useState(null);
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [status, setStatus] = useState(false);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const { config } = useContext(ApiContext)

  const inputref = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    if (e.target.value.length > 0) {
      setLoader(true);
      axios
        .post(
          `${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, {}, config
        )
        .then((res) => {
          setSearchList(res.data.users);
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoader(false);
        });
    } else {
      setSearchList([]);
      setLoader(false);
    }
  };

  const clickTagUser = async (user) => {
    setInputValue("");
    setopenInput(false);

    // Conditionally set x and y values based on the type of media
    const x =
      selectphotoAndVideo &&
        selectphotoAndVideo[activeCarouselIndex].type === "video/mp4"
        ? null
        : xVal;
    const y =
      selectphotoAndVideo &&
        selectphotoAndVideo[activeCarouselIndex].type === "video/mp4"
        ? null
        : yVal;

    const updatedTags = [...tagsByCarouselItem];
    updatedTags[activeCarouselIndex] = [
      ...(updatedTags[activeCarouselIndex] || []),
      {
        user,
        xVal: x,
        yVal: y,
        tagUserId: user.userId,
        fname: user.fname,
        username: user.username,
        profileImg: user.profileImg,
        index: activeCarouselIndex,
        videoTag:
          selectphotoAndVideo[activeCarouselIndex].type.includes("video"),
      },
    ];

    setTagsByCarouselItem(updatedTags);
    setSearchList([]);
  };

  const handleTag = (event) => {
    if (inputref.current) {
      inputref.current.focus();
    }
    setopenInput(!openInput);
    const rect = event.target.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;
    setxVal(x);
    setyVal(y);
  };

  function handleDel(carouselIndex, tagIndex) {
    const updatedTags = [...tagsByCarouselItem];
    const currentTags = updatedTags[carouselIndex];

    if (currentTags && currentTags.length > tagIndex) {
      updatedTags[carouselIndex] = currentTags.filter(
        (_, index) => index !== tagIndex
      );

      // Update the state
      setTagsByCarouselItem(updatedTags);
    }
  };

  const carouselRef = useRef();

  const handleNextRef = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setActiveCarouselIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevRef = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setActiveCarouselIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    setTagsByCarouselItem([]);
  }, [selectphotoAndVideo]);

  return (
    <div className="mainContainer">
      <button
        onClick={handleNextRef}
        style={{
          display:
            activeCarouselIndex >= (fileUrl && fileUrl.length - 1)
              ? "none"
              : "block",
        }}
        className="nextButton"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>

      <button
        onClick={handlePrevRef}
        style={{
          display:
            activeCarouselIndex <= 0 || !fileUrl || fileUrl.length === 0
              ? "none"
              : "block",
        }}
        className="prevButton"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>


      <div className="imageContainer img-box createMeme-2-Meme" id="image-wrap" >
        {fileUrl && fileUrl.length >= 2 && (
          <Carousel ref={carouselRef} slide={false} interval={null} activeIndex={activeIndex} onSelect={handleSelect}>
            {fileUrl.map((img, index) => (
              <Carousel.Item key={index} >
                {
                  selectphotoAndVideo && selectphotoAndVideo[index] && selectphotoAndVideo[index].type === "video/mp4"
                    ?
                    <>
                      <ReactPlayer className="bestClip-selectedVideo" onClick={(event) => handleTag(event)}
                        url={img}
                        playing={index === activeIndex} height="100%" width="100%"
                      />
                      <GetThumbnail
                        status={status} setStatus={setStatus}
                        file={selectphotoAndVideo[index]}
                        selectedImages={selectedImage}
                        setSelectedImage={setSelectedImage}
                        index={index}
                      />
                    </>
                    :
                    <img src={img} onClick={(event) => handleTag(event)} alt="photoAndVideo" height="100%" width="100%" style={{ margin: "auto" }} />
                }
              </Carousel.Item>
            ))}
          </Carousel>
        )}

        {fileUrl && fileUrl.length === 1 && (
          <div className="bestClip-selectedVideo">
            {selectphotoAndVideo &&
              selectphotoAndVideo[0] &&
              selectphotoAndVideo[0].type &&
              selectphotoAndVideo[0].type.includes("video") ? (
              <>
                {fileUrl.map((e, i) => (
                  <>
                    <video key={i} controls autoPlay>
                      <source src={e} />
                    </video>
                    <GetThumbnail
                      status={status} setStatus={setStatus}
                      file={selectphotoAndVideo[i]}
                      selectedImages={selectedImage}
                      setSelectedImage={setSelectedImage}
                      index={i}
                    />
                  </>
                ))}
              </>
            ) : (
              <img
                src={fileUrl[0]}
                alt="photoAndVideo"
              />
            )}
          </div>
        )}
      </div>

      <div className="inputContainer">
        {openInput && (
          <div className="searchContainerMain">
            <div className="searchContainer">
              <input
                ref={inputref}
                type="text"
                autoFocus
                placeholder="Tag peoples"
                value={inputValue}
                onChange={handleInputChange}
              />

              {loader ? (
                <FontAwesomeIcon
                  className="searchLoader"
                  icon={faSpinner}
                  spinPulse
                  style={{ color: "#402154" }}
                />
              ) : (
                <div className="searchDiv">
                  {searchList.length !== 0 &&
                    searchList.map((user, index) => (
                      <div
                        onClick={() => clickTagUser(user, index)}
                        className="tagUserLIst"
                        key={index}
                      >
                        <img
                          src={
                            user.profileImg
                              ? (AwsFileUrl + user.profileImg.imageId)
                              : `${BASE_URL_STATIC}/dummy.png`
                          }
                          alt=""
                        />
                        <span>
                          <small>{user.fname}</small>
                          <small>{user.username}</small>
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>{" "}
          </div>
        )}
      </div>

      {tagsByCarouselItem &&
        tagsByCarouselItem.map((tags, index) => (
          <div
            key={index}
            className={`${selectphotoAndVideo[index].type.includes("video")
              ? "videoTags"
              : "taggedContainer"
              }`}
          >
            {activeCarouselIndex === index &&
              tags &&
              tags.map(
                (tag, tagIndex) =>
                  // Conditionally render tags based on media type
                  (selectphotoAndVideo[index].type.includes("image") ||
                    (selectphotoAndVideo[index].type.includes("video") &&
                      tag.videoTag)) && (
                    <div
                      key={tagIndex} className="rounded"
                      style={{ top: `${tag.yVal}%`, left: `${tag.xVal}%` }}
                    >
                      <small>{tag.user.fname}</small>
                      <small
                        className="delTag"
                        onClick={() => handleDel(index, tagIndex)}
                      >
                        <HiMiniXMark size={16} />
                      </small>
                    </div>
                  )
              )}
          </div>
        ))}
    </div>
  );
};

export default AddTags;
