import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';
import toast from 'react-hot-toast';


const EditProductPopup = () => {

  const {
    showEditProductPopup, setShowEditProductPopup,
    product, setProduct,
    updateProduct,
    productImages, setProductImages,
    deleteProductImage,
  } = useContext(businessContext);


  const fileInputRef = useRef(null);

  // handle click
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  // handle file change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if((productImages.length + product.images.length + files.length) <= 5) {
      setProductImages((prevImages) => [...files, ...prevImages]);
    } else {
      toast.error(`You can only select 5 images`);
    };
  };
  
  // handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  // remove product image 
  const removeProductImage = (indexToRemove) => {
    setProductImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  // handle popup close
  const handlePopupClose = () => {
    setShowEditProductPopup(false);

    setProductImages([]);

    setProduct({
      images: [],
      title: '',
      description: '',
      price: '',
      link: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };

  return (
    <>
      <Modal show={showEditProductPopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Edit Product</h4>

          {/* Images */}
          <div className='mb-3'>
            {/* Add */}
            <>
              {
                (productImages.length + product.images.length) < 5
                &&
                <div className='p-1 bg-secondary-subtle rounded-circle d-inline-block mb-2 pt-0' onClick={handleDivClick}>
                  <img src="/images/business/icons/add-circle.svg" style={{ height: '20px' }} alt="add" />

                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </div>
              }
            </>

            {
              <Slider className="product-images-slider" {...settings}>
                {/* Local Images */}
                {
                  productImages && productImages.length > 0 
                  &&
                  productImages.map((image, index) => (
                    <div className='position-relative' key={index}>
                      <div className='border rounded-3'>
                        <img src={URL.createObjectURL(image)} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                      </div>

                      {/* Delete */}
                      <>
                        {
                          (productImages.length + product.images.length) > 1
                          &&
                          <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => removeProductImage(index)}>
                            <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                          </div>
                        }
                      </>
                    </div>
                  ))
                }

                {/* Live Images */}
                {
                  product && product.images && product.images.length > 0
                  &&
                  product.images.map((value, index) => (
                    <div className='position-relative' key={index}>
                      <div className='border rounded-3'>
                        <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                      </div>

                      {/* Delete */}
                      <>
                        {
                          (product.images.length) > 1
                          &&
                          <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => deleteProductImage(value)}>
                            <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                          </div>
                        }
                      </>
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={product.title} onChange={handleInputChange} placeholder='Title...' />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={product.description} onChange={handleInputChange} placeholder='Description...'></textarea>

          {/* Price */}
          <small className='text-secondary'>Price</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='price' value={product.price} onChange={handleInputChange} placeholder='Price...' />

          {/* Buy Now Link */}
          <small className='text-secondary'>Buy Now Link</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-3' name='link' value={product.link} onChange={handleInputChange} placeholder='Price...' />

          {/* Key Product */}
          <div class="form-check float-none mb-5">
            <input type="checkbox" class="form-check-input" id="keyProduct" checked={product.keyProduct === 'true'} 
              onChange={(e) => setProduct((prevState) => ({ ...prevState, keyProduct: e.target.checked ? 'true' : 'false', }))}
            />
            <label class="form-check-label" htmlFor="keyProduct">Key Product</label>
          </div>

          {/* Edit */}
          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' onClick={updateProduct}>Edit</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProductPopup;