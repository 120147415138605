import React, {useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC, AwsFileUrl, BASEURL } from "../../../config";

import CopyToClipboard from "react-copy-to-clipboard";
import PostEdit from "./Userpopup/PostEdit";
import AudienceControl from "./Userpopup/AudienceControl";

import PostDeletion from "./Userpopup/PostDeletetion";

const Userpopup = ({ showPopup, setShowPopup, postsPopupData }) => {
  const [iframeCode, setIframeCode] = useState("");
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [showPopupAudience, setShowAudience] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const handleClose = () => setShowPopup(false);
 


  const heandleDelete = () =>{
    setShowPopupDelete(true)
    setShowPopup(false);

    }
 

  const toggleModal = () => setShowAudience(!showPopupAudience);

  const handleIframeGenerator = () => {
    const mediaSrc = postsPopupData.mediaUrls[0].url; // This should be dynamically provided

    // Check if it's an image or a video
    const isImage = mediaSrc.match(/\.(jpeg|jpg|gif|png)$/i) !== null;
    const isVideo = mediaSrc.match(/\.(mp4|ogg|webm)$/i) !== null;

    let iframe;
    if (isImage) {
      iframe = `<iframe src="${AwsFileUrl + mediaSrc
        }" width="1248" height="703" frameborder="0"></iframe>`;
    } else if (isVideo) {
      iframe = `<iframe width="1248" height="703" src="${AwsFileUrl + mediaSrc
        }" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
    } else {
      iframe = `<iframe srcdoc="${encodeURIComponent(
        AwsFileUrl + mediaSrc
      )}" width="1248" height="703" frameborder="0"></iframe>`;
    }

    setIframeCode(iframe);
  };
  


  return (
    <div>
      <PostEdit
        showPopupEdit={showPopupEdit}
        setShowPopupEdit={setShowPopupEdit}
        postsPopupData={postsPopupData}
      />
      <AudienceControl
        showPopupAudience={showPopupAudience}
        setShowAudience={setShowAudience}
        postsPopupData={postsPopupData}
      />
      <PostDeletion showPopupDelete={showPopupDelete}   setShowPopupDelete={ setShowPopupDelete} postsPopupData={postsPopupData}/>
    

      <Modal
        show={showPopup}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        className="main-modal"
      >
        <Modal.Header closeButton> </Modal.Header>
          {postsPopupData && <Modal.Body className="popupModal-body">
          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>

            <div className="textBox" onClick={() => setShowPopupEdit(true)}>
              Edit post
              <span>Update this post</span>
            </div>
          </div>

          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>

            <div className="textBox" onClick={toggleModal}>
              Change audience
              <span>You want chnage audience, who can see this post</span>
            </div>
          </div>

          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>

            <div className="textBox">
              Don't Show Notification for this post
              <span>
                Do not notify when someone likes or comment to this post
              </span>
            </div>
          </div>

          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>
            <CopyToClipboard
              text={`${BASEURL}/showCopyLink/${postsPopupData._id}`}
            >
              <div className="textBox">
                Copy Link
                <span>
                  Copy this post link & share it with your friend and more
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>

            <div className="textBox">
              {postsPopupData.mediaUrls && postsPopupData.mediaUrls.length > 0 ? (
                <a  href={`${AwsFileUrl}${postsPopupData.mediaUrls[0].url}`} target="_blank" download={true} rel="noopener noreferrer">
                  Download
                  <span>Save this file to your device</span>
                </a>
              ) : (
                <span>No media available for download</span> // Fallback if no media is available
              )}
            </div>

          </div>

          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>

            <div className="textBox" onClick={heandleDelete}>
              Delete
              <span>want to Delete this post</span>
            </div>
          </div>


          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>
            <CopyToClipboard text={iframeCode}>
              <div className="textBox" onClick={handleIframeGenerator}>
                Implant <span>&lt;/&gt;</span>
                <span>you want to show this post in your website.</span>
              </div>
            </CopyToClipboard>
          </div>

          <div className="linkBox">
            <div className="imgBox">
              <img
                src={`${BASE_URL_STATIC}/popup-icon1.png`}
                alt="Add to favourites icon"
              />
            </div>

            <div className="textBox">
              Report Service
              <span>Any Suggestion or improvement to you think to do </span>
            </div>
          </div>
        </Modal.Body>}
      </Modal>
    </div>
  );
};

export default Userpopup;
