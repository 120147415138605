import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AwsFileUrl, BASE_URL_STATIC} from '../../../config';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { getProfileUser } from '../../Emilo/UseContext/ApiHelpers';

const OnboardingUser = () => {
  const [userData, setUserData] = useState(null)
  const [isChecked, setIsChecked] = useState(false);
  // const [isLearnmore , setIslearnmore] = useState(false)

  const { config, loggedInUserId } = useContext(ApiContext)

  useEffect(() => {
    getProfileUser(loggedInUserId, config).then((res) => {
      setUserData(res.data.user);
    })
  }, [])


  return (
    <>
      <section className='onboardingUser row'>
        {/* Left */}
        <div className='d-none d-lg-block col-7'>
          <img src="/images/onboarding-sc.svg" alt="" />
        </div>

        {/* Right */}
        {userData && <div className='onboardingUser-area col-12 col-md-8 col-lg-5 mx-auto mt-5'>
          <div className='col-12 col-md-10 col-lg-7 mx-auto'>
            {/* Emilo Logo */}
            <div className='text-center'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
            </div>

            {/* Soft Corner Logo */}
            <div className='mt-5 d-flex flex-column align-items-center'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
              <br />
              <h4 className='fw-bold'>Soft Corner</h4>
            </div>

            {/* Data */}
            <div className='mt-5'>
              <p className='text-center my-3 my-lg-0' style={{ fontSize: "13px" }}>Unique separate profile for only two people who have soft feelings/crush/infatuation for each other. <span className='onboardingUser-area-data-learnMore'>Learn more</span></p>

              <div className='onboardingUser-area-data-user'>
                <img src={AwsFileUrl + userData.imageId} alt="user-icon3" height={50} width={50} />

                <div className='onboardingUser-area-data-userName'>
                  <h5 className='fw-bold '>{userData.fname}</h5>
                  <p className='fs-10 op-8'>{userData.username}</p>
                </div>
              </div>

              <p className='onboardingUser-area-data-uploadImage p-2'>In order to Get started Please upload your live Image & Contact info</p>

              <div className='onboardingUser-area-data-termsAndConditions'>
                <form className='d-flex justify-content-center align-items-center'>
                  <input type="checkbox" id='termsAndConditions' name='termsAndConditions' onChange={(e) => setIsChecked(e.target.checked)} />
                  <label htmlFor="termsAndConditions">I agree to E-Milo’s soft corner <span className='onboardingUser-area-data-tac'>Terms And conditions</span></label>
                </form>
              </div>
            </div>

            {/* Button */}
            <div className='row text-center'>
              <Link to={`/SoftCorner/OnboardingImage`}>
                <button disabled={!isChecked} type="button" className='onboardingUser-area-btn col-8'>Start</button>
              </Link>
            </div>
          </div>
        </div>}
      </section>
    </>
  );
};

export default OnboardingUser;

