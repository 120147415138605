import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { ApiContext } from "../../UseContext/ApiCall";
import axios from "axios";
import { AwsFileUrl, BASEAPIURL, BASE_URL_STATIC } from "../../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import CreateChatGroupPop from "../../Button/CreateChatGroupPop";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { TiPin } from "react-icons/ti";
import { RiUnpinFill } from "react-icons/ri";
import { BiBlock } from "react-icons/bi";
import exportFromJSON from 'export-from-json';
import { TbMessageCircleDown, TbMessageCircleUp, TbMessageCircleX } from "react-icons/tb";

import { ClearEmiloMessage, CreateHideChat, CreatePinChat } from "../../UseContext/ApiHelpers";
import AllChatUsers from "./AllChatUsers";
import FilterChatpopup from "./FilterChatpopup";

const ChatCenterBlock = () => {
  const {
    config,
    setIsgroupChat,
    isnewGroupAdded,
    chatUsers,pinnedUsers, setPinnedUser,
    setChatUsers, lastMsg,hideUsers, setHideUsers,
    isUpdate,allChatUsers , setAllChatUsers,
    socket, chatData, setRightReload
  } = useContext(ApiContext);

 const loggedInUserId = localStorage.getItem("loggedInUserId")

  const [createGroup, setCreateGroup] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [openPopover, setOpenPopover] = useState(null)
  const [showUsers , setShowUsers] = useState(false)
  const [filterOpen , setFilterOpen] = useState(false)

  
 
  // To get chats users
  const getChatUsers = (config) => {
    axios
      .post(`${BASEAPIURL}/user/listemchat`, {}, config)
      .then((res) => {
        // To set the chat users
        const chats = res.data.chatList.map((prev) => ({
          ...prev, isPinned: false, isHidden: false
        }))
        setChatUsers(chats);
      })
      .catch((err) => {
        return null
      });
  };
  
  const params = useParams()
  
  useEffect(() => {
    if (params.chatId) {
      setSelectedUser(params.chatId)
    }
    socket && socket.emit("join_room_emilo" , params.chatId)
  }, [params.chatId])

  // console.log("chatId" , params.chatId)

  useMemo(() => {
    setChatUsers(prev => prev.map(user => {
      if(lastMsg && lastMsg.chatId && user.chatId === lastMsg.chatId){
        return {...user , lastMessage : lastMsg.message}
      }
      return user
    }))
  } , [lastMsg])

  useEffect(() => {
    if (socket) {
      socket.on("receive_message_emilo", (data) => {
  
        const updateUserList = (list, chatId) => {
          const userExists = list.some(user => user.chatId === chatId);
          let updatedList;
  
          if (!userExists) {
            updatedList = [...list, {
              chatId,
              lastMessage: data.message,
              senderFname: data.senderFname,
              senderUserId: data.senderUserId,
              createdTime: new Date(data.createdTime)
            }];
          } else {
            updatedList = list.map(user => {
              if (user.chatId === chatId) {
                return {
                  ...user,
                  lastMessage: data.message,
                  senderFname: user.isGroupChat === "true" ? data.senderFname : user.senderFname,
                  senderUserId: user.isGroupChat === "true" ? data.senderUserId : user.senderUserId,
                  createdTime: new Date(data.createdTime)
                };
              }
              return user;
            });
          }
  
          // Sort the updated list by createdTime
          updatedList.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
  
          return updatedList;
        };
  
        setChatUsers(prev => {
          const updatedList = updateUserList(prev, data.chatId);
          return updatedList.filter(user => !user.isPinned && !user.isHidden);
        });
  
        setPinnedUser(prev => {
          const updatedList = updateUserList(prev, data.chatId);
          return updatedList.filter(user => user.isPinned);
        });
  
        setHideUsers(prev => {
          const updatedList = updateUserList(prev, data.chatId);
          return updatedList.filter(user => user.isHidden);
        });
      });
    }
  }, [params.chatId, socket]);
  
  

  // To get Pinned chats users
  const getPinnedUsers = (config) => {
    axios
      .post(`${BASEAPIURL}/user/listempinchat`, {}, config)
      .then((res) => {
        const pinnedChats = res.data.chatList.map((prev) => ({
          ...prev, isPinned: true, isHidden: false
        }))
        setPinnedUser(pinnedChats);
      })
      .catch((err) => {
        console.error("Error occurred while fetching pinned users:", err);
      });
  };

  // To get Pinned chats users
  const getHiddenUsers = (config) => {
    axios
      .post(`${BASEAPIURL}/user/listemhidechat`, {}, config)
      .then((res) => {
        const hiddenChats = res.data.chatList.map((prev) => ({
          ...prev, isHidden: true, isPinned: false
        }))
        setHideUsers(hiddenChats);
      })
      .catch((err) => {
        console.error("Error occurred while fetching hidden users:", err);
      });
  };

  // To clear chat
  const clearChat = (user) => {
    setOpenPopover(null)
    ClearEmiloMessage(user.chatId, config).then((res) => {
      setRightReload(true);
      setChatUsers(prev => prev.filter(id => id.chatId !== user.chatId))
      setPinnedUser(prev => prev.filter(id => id.chatId !== user.chatId))
    })
  }

  const handleChatProfile = (user) => {
    setIsgroupChat(user.isGroupChat);
  };

  const handleButtonClick = (id) => {
    setOpenPopover(openPopover === id ? null : id);
  };

  useEffect(() => {
    getChatUsers(config);
    getPinnedUsers(config)
    getHiddenUsers(config)
  }, [isnewGroupAdded, isUpdate]);



  useEffect(() => {
setAllChatUsers([...pinnedUsers , ...chatUsers , ...hideUsers])
  } , [pinnedUsers , chatUsers, hideUsers])


  const handlePin = (user, action) => {
    setOpenPopover(null)
    if (action === false) {
      setChatUsers(prev => prev.filter(id => id.chatId !== user.chatId))
      setHideUsers(prev => prev.filter(id => id.chatId !== user.chatId))
      setPinnedUser(prev => [{ ...user, isPinned: true }, ...prev])
    }

    else {
      setPinnedUser(prev => prev.filter(id => id.chatId !== user.chatId))
      setHideUsers(prev => prev.filter(id => id.chatId !== user.chatId))
      setChatUsers(prev => [{ ...user, isPinned: false }, ...prev])
    }
    CreatePinChat(user.chatId, action, config)
  }

  const handleHide = (user, action) => {
    setOpenPopover(null)
    if (action === false) {
      setChatUsers(prev => prev.filter(id => id.chatId !== user.chatId))
      setPinnedUser(prev => prev.filter(id => id.chatId !== user.chatId))
      setHideUsers(prev => [{ ...user, isHidden: true }, ...prev])
    }
    else {
      // setChatUsers(prev => prev.filter(id => id.chatId !== user.chatId))
      setChatUsers(prev => [{ ...user, isHidden: false }, ...prev])
      setPinnedUser(prev => prev.filter(id => id.chatId !== user.chatId))
      setHideUsers(prev => prev.filter(id => id.chatId !== user.chatId))
    }
    CreateHideChat(user.chatId, action, config)
  }

  const ExportToExcel = (chatData) => {
    const fileName = 'emilo_chats';
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: chatData, fileName, fields: ["fname", "message"], exportType });
    setOpenPopover(null)
  };

  const popover = (user) => (
    <Popover id="popover-basic" className="chat-popover">
      <Popover.Body className="message-options-cb">

        {!user.isPinned && !user.isHidden && <li onClick={() => handlePin(user, false)}><TiPin /> <span>  pin </span></li>}

        {user.isPinned && <li li onClick={() => handlePin(user, true)
        } ><RiUnpinFill /> <span>  unpin </span></li>}

        {!user.isPinned && !user.isHidden && <li onClick={() => handleHide(user, false)}><TbMessageCircleUp /> <span>  Hide chat </span></li>}

        {user.isHidden && <li onClick={() => handleHide(user, true)}><TbMessageCircleUp /> <span>  unhide chat </span></li>}

        <li onClick={() => clearChat(user)}><TbMessageCircleX /> <span>  Clear chat </span></li>

        <li onClick={() => ExportToExcel(chatData)}><TbMessageCircleDown /> <span>  Export chat </span></li>

        {!(user.isGroupChat === "true") && <li><BiBlock color="#ff474c" /> <span> Block and Report </span></li>}

      </Popover.Body>
    </Popover>
  );

  const handleJoinRoom = async(user) => {
   await socket && socket.emit("join_room_emilo", user && user.chatId);
    setSelectedUser(user && user.chatId);
    setIsgroupChat(user && user.isGroupChat)
    localStorage.setItem("chatId_emilo" ,user && user.chatId)
  };

  const originalUsers = [...allChatUsers];

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.trim();
    if (searchTerm.length > 0) {
      setShowUsers(true)
      const filteredUsers = allChatUsers.filter(user => 
        user.fname.includes(searchTerm)
        );
        setAllChatUsers(filteredUsers);
      } 
      else {
        setShowUsers(false)
      setAllChatUsers(originalUsers); 
    }
  };

  // ----- Show/Hide Popup ----- 

  const optionShowHide = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!optionShowHide.current || !optionShowHide.current.contains(event.target)) {
      handleButtonClick(null)
    };
  };

  return (
    <>
      <CreateChatGroupPop open={createGroup} setCreateGroup={setCreateGroup} close={() => setCreateGroup(false)} />

      <FilterChatpopup open={filterOpen} close={() => setFilterOpen(false)}/>

      <section className="px-2 px-md-3 px-lg-4">
        {/* Search, filter and icon */}
        <div className="d-flex align-items-center pb-3">
          <form className="d-flex flex-grow-1 rounded-pill bg-body-secondary p-1 p-sm-2">
            <img className="mx-2" src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />

            <input type="search" className="rounded-pill bg-body-secondary w-100 border-0" id="search" name="search" placeholder="Search" onChange={handleSearchChange} />
          </form>

          <span className="mx-3 mx-lg-4 text-primary" onClick={() => setFilterOpen(true)}>Filter</span>

          <span className="">
            <FontAwesomeIcon icon={faUserGroup} onClick={() => setCreateGroup(true)} />
          </span>
        </div>

        {showUsers &&  <AllChatUsers setShowUsers={setShowUsers} allUsers={allChatUsers} loggedInUserId={loggedInUserId} />}

       {
        !showUsers 
        &&
        <div className="accordion accordion-flush m-0 overflow-scroll scrollbar-hidden">
          {/* Pinned */}
          <>
            {
              pinnedUsers && pinnedUsers.length > 0 
              && 
              <>
                <div className="mb-3 cursor-pointer" data-bs-toggle="collapse" data-bs-target="#pinnedChat">
                  <img src={`${BASE_URL_STATIC}/icon/pinned.svg`} alt="pinned" />
                  <span className="ms-2 fw-bold">Pinned</span>
                </div>

                <div id="pinnedChat" className="accordion-collapse collapse show">
                  {
                    pinnedUsers 
                    &&
                    pinnedUsers.map((user, index) => (
                      <div key={index}>
                        {/* Single */}
                        {
                          user && !(user.isGroupChat === "true" )
                          && 
                          <Link to={`/chats/${user.chatId}`} className={` d-flex align-items-center mb-3 ${selectedUser === user.chatId && "selected-user"}`}   key={index}
                            // className={`chats-cb-chat-all-user `}
                            onClick={() => {handleJoinRoom(user.chatId) }}
                          >
                            <img className='chat-cb-profile rounded-circle' src={AwsFileUrl + user.profileImg.imageId} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />

                            <div className="ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0">
                              <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{user.senderFname}</h6>

                              {
                                user.lastMessage 
                                && 
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
                                  {user.lastMessage && user.lastMessage.length > 15 ? user.lastMessage.substr(0, 25) + "..." : user.lastMessage}
                                </small>
                              }
                            </div>

                            <span className="badge bg-primary rounded-circle ms-2">2</span>

                            <OverlayTrigger trigger="click" placement="left" overlay={popover(user)} show={openPopover === user.chatId} rootClose >
                              <i className="far fa-ellipsis-v mx-2 text-dark" onClick={() => handleButtonClick(user.chatId)} />
                            </OverlayTrigger>
                          </Link>
                        }

                        {/* Group */}
                        {
                          user && (user.isGroupChat ==="true") 
                          && 
                          <Link to={`/chats/${user.chatId}`} className={`d-flex align-items-center mb-3 ${selectedUser === user.chatId && "selected-user"}`}  key={index}
                            // className={`chats-cb-chat-all-user ${selectedUser === user.chatId && "selected-user"}`}
                            onClick={() => {handleChatProfile(user); setSelectedUser(user.chatId);}}
                          >
                            <img className='chat-cb-profile rounded-circle' src={AwsFileUrl + user.groupProfileImg} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />

                            <div className="ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0">
                              <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{user.groupName}</h6>

                              {
                                user.lastMessage 
                                && 
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
                                  {user.senderUserId === loggedInUserId ? "You : " : user.senderFname}
                                  {user.lastMessage && user.lastMessage.length > 15 ? user.lastMessage.substr(0, 20) + "..." : user.lastMessage}
                                </small>
                              }
                            </div>

                            <span className="badge bg-primary rounded-circle ms-2">5</span>

                            <OverlayTrigger trigger="click" placement="left" overlay={popover(user)} show={openPopover === user.chatId} rootClose >
                              <i className="far fa-ellipsis-v mx-2 text-dark" onClick={() => handleButtonClick(user.chatId)} />
                            </OverlayTrigger>
                          </Link>
                        }
                      </div>
                    ))
                  }
                </div>

                <hr className="hri" />
              </>
            }
          </>

          {/* All */}
          <>
            {
              chatUsers && chatUsers.length > 0
              &&
              <>
                <span className="fw-bold mb-3 cursor-pointer" data-bs-toggle="collapse" data-bs-target="#allChat">All</span>

                <div id="allChat" className="accordion-collapse collapse show">
                  {
                    chatUsers 
                    &&
                    chatUsers.map((user, index) => (
                      <div key={index}>
                        {/* Single */}
                        {
                          user && !(user.isGroupChat === "true" )
                          && 
                          <Link to={`/chats/${user.chatId}`} className={`d-flex align-items-center mb-3 ${selectedUser === user.chatId && "selected-user"}`} key={index}
                            // className={`chats-cb-chat-all-user ${selectedUser === user.chatId && "selected-user"}`}
                            
                            onClick={() => {handleJoinRoom(user)}}
                          >
                            {
                              user.profileImg
                              ?
                              <img className='chat-cb-profile rounded-circle' src={AwsFileUrl+user.profileImg.imageId} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                              :
                              <img className='chat-cb-profile rounded-circle' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />
                            }

                            <div className="ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0">
                              <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{user.senderFname}</h6>

                              {
                                user.lastMessage 
                                ?
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
                                  {user.lastMessage && user.lastMessage.length > 15 ? user.lastMessage.substr(0, 20) + "..." : user.lastMessage}
                                </small>
                                :
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">say "Hii" <span className="wobble-hor-bottom" role="img" aria-label="wave"> &#9995; </span> {user.fname } </small>
                              }
                            </div>

                            <span className="badge bg-primary rounded-circle ms-2">8</span>

                            <OverlayTrigger trigger="click" placement="left" overlay={popover(user)} show={openPopover === user.chatId} rootClose >
                              <i className="far fa-ellipsis-v mx-2 text-dark" onClick={() => handleButtonClick(user.chatId)} />
                            </OverlayTrigger>
                          </Link>
                        }

                        {/* Group */}
                        {
                          user && (user.isGroupChat === "true")
                          && 
                          <Link to={`/chats/${user.chatId}`} className={`d-flex align-items-center mb-3 ${selectedUser === user.chatId && "selected-user"}`}  key={index}
                            onClick={() => {handleChatProfile(user); setSelectedUser(user.chatId);}}
                          >
                            <img className='chat-cb-profile rounded-circle' src={ AwsFileUrl + user.groupProfileImg} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />

                            <div className="ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0">
                              <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{user.groupName}</h6>

                              {
                                user.lastMessage 
                                ?
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
                                  {user.senderUserId === loggedInUserId ? "You : " : ( user.senderFname + " : ") }
                                  {user.lastMessage && user.lastMessage.length > 15 ? user.lastMessage.substr(0, 20) + "..." : user.lastMessage}
                                </small>
                                :
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">say "Hii" <span className="wobble-hor-bottom" role="img" aria-label="wave"> &#9995; </span> to group members..</small>
                              }
                            </div>

                            <span className="badge bg-primary rounded-circle ms-2">6</span>

                            <OverlayTrigger trigger="click" placement="left" overlay={popover(user)} show={openPopover === user.chatId} rootClose >
                              <i className="far fa-ellipsis-v mx-2 text-dark" onClick={() => handleButtonClick(user.chatId)} />
                            </OverlayTrigger>
                          </Link>
                        }
                      </div>
                    ))
                  }
                </div>

                <hr className="hri" />
              </>
            }
          </>

          {/* Hide */}
          <>
            {
              hideUsers && hideUsers.length > 0 
              && 
              <>
                <span className="fw-bold mb-3 cursor-pointer" data-bs-toggle="collapse" data-bs-target="#archievedChat">Archieved</span>

                <div id="archievedChat" className="accordion-collapse collapse">
                  {
                    hideUsers 
                    &&
                    hideUsers.map((user, index) => (
                      <div key={index}>
                        {/* Single */}
                        {
                          user && !(user.isGroupChat ==="true")
                          && 
                          <Link to={`/chats/${user.chatId}`} className={`d-flex align-items-center mb-3 ${selectedUser === user.chatId && "selected-user"}`} key={index}
                  
                            onClick={() => {handleJoinRoom(user)}}
                          >
                            {
                              user.profileImg
                              ?
                              <img className='chat-cb-profile rounded-circle' src={AwsFileUrl+user.profileImg.imageId} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                              :
                              <img className='chat-cb-profile rounded-circle' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />
                            }

                            <div className="ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0">
                              <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{user.senderFname}</h6>

                              <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
                                {user.lastMessage && user.lastMessage.length > 15 ? user.lastMessage.substr(0, 20) + "..." : user.lastMessage}
                              </small>
                            </div>

                            <span className="badge bg-primary rounded-circle ms-2">6</span>

                            <OverlayTrigger trigger="click" placement="left" overlay={popover(user)} show={openPopover === user.chatId} rootClose >
                              <i className="far fa-ellipsis-v mx-2 text-dark" onClick={() => handleButtonClick(user.chatId)} />
                            </OverlayTrigger>
                          </Link>
                        }

                        {/* Group */}
                        {
                          user && (user.isGroupChat === "true")
                          && 
                          <Link to={`/chats/${user.chatId}`} className={`d-flex align-items-center mb-3 ${selectedUser === user.chatId && "selected-user"}`} key={index}
                            // className={`chats-cb-chat-all-user ${selectedUser === user.chatId && "selected-user"}`}

                            onClick={() => {handleChatProfile(user); setSelectedUser(user.chatId);}}
                          >
                            {
                              user.groupProfileImg
                              ?
                              <img className='chat-cb-profile rounded-circle' src={AwsFileUrl+user.groupProfileImg} alt="profile" onError={(e) => e.target.src=`${BASE_URL_STATIC}/profile-img1.jpg`} />
                              :
                              <img className='chat-cb-profile rounded-circle' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" />
                            }

                            <div className="ms-2 ms-sm-3 ms-lg-2 ms-xl-3 me-auto col-8 col-sm-9 col-lg-8 px-0">
                              <h6 className="text-dark text-nowrap overflow-hidden text-truncate">{user.groupName}</h6>

                              {
                                user.lastMessage 
                                && 
                                <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
                                  {user.senderUserId === loggedInUserId ? "You : " : user.senderFname + " : "}
                                  {user.lastMessage && user.lastMessage.length > 15 ? user.lastMessage.substr(0, 20) + "..." : user.lastMessage}
                                </small>
                              }
                            </div>

                            <span className="badge bg-primary rounded-circle ms-2">6</span>

                            <OverlayTrigger trigger="click" placement="left" overlay={popover(user)} show={openPopover === user.chatId} rootClose >
                              <i className="far fa-ellipsis-v mx-2 text-dark" onClick={() => handleButtonClick(user.chatId)} />
                            </OverlayTrigger>
                          </Link>
                        }
                      </div>
                    ))
                  }
                </div>
              </>
            }
          </>
        </div>
        }
      </section>
    </>
  );
};

export default ChatCenterBlock;
