import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from '../../UseContext/ApiCall';
import {  AwsFileUrl } from '../../../../config';
import {  GetuserFavorite } from '../../UseContext/ApiHelpers';
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';


const FavouriteBestClips = () => {

  const [favouriteBestClips, setFavouriteBestClips] = useState([]);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [mainpoastData , setmainpoastData] = useState()

  const { config } = useContext(ApiContext);

  // To get favourite bestClips
  const getFavouriteBestClips = async(config) => {
    await  GetuserFavorite("bestclips", config)
    .then(res => {
      // console.log(res.data.listBestclip);
      setFavouriteBestClips(res.reverse());
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouriteBestClips(config);
  }, []);

  // To handle best clip popup data

  return (
    <>
      {/* Profile Best Clip Popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />

      <section className='favourite-bestClips'>
        <h1 className='favourite-bestClips-head'>BestClips</h1>

        <div className='favourite-bestClips-imgs'>
          {
            favouriteBestClips && favouriteBestClips.map((favouriteBestClip, index) => (
            
            <img className='favourite-bestClips-img' src={AwsFileUrl+(favouriteBestClip.mediaUrls[0].thumbnail || favouriteBestClip.postTumbnailUrl)} alt={favouriteBestClip.tumbnailId} onClick={() =>{setShowPostsPopup(!showPostsPopup); setmainpoastData(favouriteBestClip);  setPostId(favouriteBestClip._id)}}  height={215} width={200} />
            ))
          }
        </div>
      </section>
    </>
  )
}

export default FavouriteBestClips
