import React, { useContext, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { BASE_URL_STATIC } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';

const OnboardingImage = () => {

  const webcamRef = useRef(null);
  const [error, setError] = useState(null);
  const [startCamera, setStartCamera] = useState(false)

  const {scImage , setScImage} = useContext(ApiContext)

  const nav = useNavigate()

  const startHandler = () => {
    if (!error) {
      setScImage(null)
      setStartCamera(true)
    }
  }

  const captureImage = () => {
    if (webcamRef.current) {
      const capturedImage = webcamRef.current.getScreenshot();
      setScImage(capturedImage);
    }
  };

  const handleCameraError = (error) => {
    setStartCamera(false)
    setError("Camera not connected or accessible.");
    console.error(error);
  };

  const handleNav = () => {
    setStartCamera(false)
    if (scImage) {
      // Convert base64 scImage to file before uploading
      const file = base64ToFile(scImage, 'captured-image.jpg');
      setScImage(file)
      console.log("file" , file)
      //uploadImage(file);  // Call the upload function
    }
    nav('/SoftCorner/OnboardingPin')
  }

    // Convert base64 to File
    const base64ToFile = (base64Data, filename) => {
      const arr = base64Data.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

  if (startCamera) {
    return (
      <section className='onboardingImage'>
        <div className='onboardingImage-area'>
          {/* Emilo Logo */}
          <div className='onboardingImage-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {scImage ?
            <div className='row col-12 col-lg-8 mx-auto'>
              <img className='rounded' src={scImage} alt="Captured" style={{ maxWidth: '100%' }} />
              <div className='flex justify-content-center gap-3 mt-2'>
                <button className='text-white bg-primary flex rounded' onClick={() => { startHandler(); }}>
                  <FontAwesomeIcon icon={faRotateRight} />
                  <span>Retake</span>
                </button>
                <button className='text-white bg-success rounded' onClick={() => handleNav()}>Done</button>
              </div>
            </div>
            :
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                onUserMediaError={handleCameraError}
              />

              <button className='bg-primary text-white flex rounded' onClick={captureImage}>
                <FontAwesomeIcon icon={faCamera} style={{ color: "#f7f7f7", }} />
                <span>Capture</span>
              </button>
            </>}

        </div>
      </section>
    )
  }



  return (
    <>
      <section className='onboardingImage'>
        <div className='onboardingImage-area'>
          {/* Emilo Logo */}
          <div className='onboardingImage-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='onboardingImage-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {/* Data */}
          <div className='onboardingImage-area-data'>
            <h2 className='onboardingImage-area-data-head'>Verify Live Image</h2>

            <p className='onboardingImage-area-data-message'>Make sure that the entire face is in view, including the ears, so that nothing is obstructing or hidden from sight.</p>

            <img src={`${BASE_URL_STATIC}/SoftCorner/verify-live-image.png`} alt="verify-live" height={200} width={200} />
          </div>

          {error && <div className='text-danger'>{error}</div>}

          {/* Button */}
          <div className='onboardingImage-area-button'>
            <button type="button" className='onboardingImage-area-btn' onClick={startHandler} >Scan My Face</button>
          </div>

        </div>
      </section>
    </>
  );
};

export default OnboardingImage;
