import React, { useContext, useEffect } from 'react';
import BusinessInfoPopup from '../Popup/Home/BusinessInfoPopup';
import BusinessMissionVisionPopup from '../Popup/Home/BusinessMissionVisionPopup';
import BusinessCategoryPopup from '../Popup/Home/BusinessCategoryPopup';
import BusinessAddressPopup from '../Popup/Home/BusinessAddressPopup';
import BusinessContactInfoMobilePopup from '../Popup/Home/BusinessContactInfoMobilePopup';
import BusinessContactInfoEmailPopup from '../Popup/Home/BusinessContactInfoEmailPopup';
import BusinessDealerPopup from '../Popup/Home/BusinessDealerPopup';
import BusinessWebsiteEmailPopup from '../Popup/Home/BusinessWebsiteEmailPopup';
import BusinessActiveHoursPopup from '../Popup/Home/BusinessActiveHoursPopup';
import BusinessBioPopup from '../Popup/Home/BusinessBioPopup';
import { businessContext } from '../UseContext/BusinessContext';
import toast from 'react-hot-toast';
import { AwsFileUrl } from '../../../config';
import AddMilestonePopup from '../Popup/Home/Milestone/AddMilestonePopup';
import EditMilestonePopup from '../Popup/Home/Milestone/EditMilestonePopup';
import ViewMilestonePopup from '../Popup/Home/Milestone/ViewMilestonePopup';
import Slider from "react-slick";


const BusinessHomeAbout = () => {

  const {
    paramsPageId,

    setShowBioPopup,
    setShowInfoPopup,
    setShowMissionVisionPopup,
    setShowCategoryPopup,
    setShowAddressPopup,
    setShowContactInfoMobilePopup,
    setShowContactInfoEmailPopup,
    setShowDealerPopup,
    setShowWebsiteEmailPopup,
    setShowActiveHoursPopup,
    setShowAddMilestonePopup,
    setShowEditMilestonePopup,
    setShowViewMilestonePopup,

    setMilestone,
    
    pageAbout,
    setNewAddress, deletePageAddress,
    setContactInfo, deleteContactInfo,
    
    getPageAboutDetails,
    checkIsItMyPage, isItMyPage,
    
    setActiveHour,
    deleteActiveHour,
  } = useContext(businessContext);

  // To handle edit page address
  const handleEditPageAddress = (address) => {
    setNewAddress(address);

    setShowAddressPopup(true);
  };

  // To handle edit contact info 
  const handleEditContactInfo = (info) => {    
    setContactInfo(info);

    if(info.type === 'mobile') {
      setShowContactInfoMobilePopup(true);
    } else {
      setShowContactInfoEmailPopup(true);
    };
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  useEffect(() => {
    getPageAboutDetails(paramsPageId);
    checkIsItMyPage(paramsPageId);
  }, [paramsPageId]);

  return (
    <>
      {/* Popup */}
      <>
        {/* Bio Popup */}
        <BusinessBioPopup />

        {/* Info Popup */}
        <BusinessInfoPopup />

        {/* Mission & Vision Popup */}
        <BusinessMissionVisionPopup />

        {/* Category Popup */}
        <BusinessCategoryPopup />

        {/* Address Popup */}
        <BusinessAddressPopup />

        {/* Contact Info Mobile Popup */}
        <BusinessContactInfoMobilePopup />

        {/* Contact Info Email Popup */}
        <BusinessContactInfoEmailPopup />

        {/* Dealer Popup */}
        <BusinessDealerPopup />

        {/* Website & Email Popup */}
        <BusinessWebsiteEmailPopup />

        {/* Add Milestone Popup */}
        <AddMilestonePopup />

        {/* Edit Milestone Popup */}
        <EditMilestonePopup />

        {/* View Milestone Popup */}
        <ViewMilestonePopup />

        {/* Active Hours Popup */}
        <BusinessActiveHoursPopup />
      </>

      <section>
        {/* Bio */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Bio</h6>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => setShowBioPopup(true)}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <div className='d-flex'>
            {
              pageAbout.bio
              ?
              <span className='bg-white p-3 rounded-3 shadow-sm cursor-pointer flex-grow-1 ms-2 ms-sm-3' onClick={() => setShowBioPopup(true)}>{pageAbout.bio}</span>
              :
              <span className='bg-white p-3 rounded-3 shadow-sm cursor-pointer flex-grow-1 ms-2 ms-sm-3 text-secondary' onClick={() => setShowBioPopup(true)}>Write your bio here...</span>
            }
          </div>
        </div>

        {/* Info & About Page */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Info */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Info</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => setShowInfoPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <span className='bg-secondary-subtle px-3 py-1 rounded-3 mx-3 mb-3 shadow-sm cursor-pointer' onClick={() => setShowInfoPopup(true)}>Year of Establishment</span>

            <input type="date" className='rounded-3 border-0 px-3 py-1 shadow-sm cursor-pointer' value={pageAbout.yearOfEstablishment} onClick={() => setShowInfoPopup(true)} readOnly />
          </>

          <hr className='hr bg-secondary' />

          {/* About Page */}
          <>
            <h6 className='mb-0 fw-semibold mb-3'>About Page</h6>

            <div className='d-flex'>
              {
                pageAbout.aboutPage
                ?
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm flex-grow-1 ms-2 ms-sm-3 cursor-pointer' onClick={() => setShowInfoPopup(true)}>{pageAbout.aboutPage}</span>
                :
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm flex-grow-1 ms-2 ms-sm-3 cursor-pointer text-secondary' onClick={() => setShowInfoPopup(true)}>Write about your page...</span>
              }
            </div>
          </>
        </div>

        {/* Mission & Vission */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Mission */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Mission</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => setShowMissionVisionPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <div className='d-flex'>
              {
                pageAbout.mission
                ?
                <span className='bg-white p-3 rounded-3 ms-3 mb-3 shadow-sm cursor-pointer flex-grow-1 ms-3' onClick={() => setShowMissionVisionPopup(true)}>{pageAbout.mission}</span>
                :
                <span className='bg-white p-3 rounded-3 ms-3 mb-3 shadow-sm cursor-pointer flex-grow-1 ms-3 text-secondary' onClick={() => setShowMissionVisionPopup(true)}>Write your mission here...</span>
              }
            </div>
          </>

          <hr className='hr bg-secondary' />

          {/* Vission */}
          <>
            <h6 className='mb-0 fw-semibold mb-3'>Vision</h6>

            <div className='d-flex'>
              {
                pageAbout.vission
                ?
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm cursor-pointer flex-grow-1 ms-3' onClick={() => setShowMissionVisionPopup(true)}>{pageAbout.vission}</span>
                :
                <span className='bg-white p-3 rounded-3 ms-3 shadow-sm cursor-pointer flex-grow-1 ms-3 text-secondary' onClick={() => setShowMissionVisionPopup(true)}>Write your vission here...</span>
              }
            </div>
          </>
        </div>

        {/* Category & Sub Category */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Category */}
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className='mb-0 fw-semibold'>Category</h6>

              {
                isItMyPage
                &&
                <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => setShowCategoryPopup(true)}>
                  <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                </div>
              }
            </div>

            <span className='bg-secondary-subtle px-3 py-1 rounded-3 mx-3 mb-3 shadow-sm cursor-pointer' onClick={() => setShowCategoryPopup(true)}>{pageAbout.category}</span>
          </>

          <hr className='hr bg-secondary' />

          {/* Sub Category */}
          <>
            <h6 className='mb-0 fw-semibold mb-3'>Sub Category</h6>

            <span className='bg-secondary-subtle px-3 py-1 rounded-3 mx-3 shadow-sm cursor-pointer' onClick={() => setShowCategoryPopup(true)}>{pageAbout.subCategory}</span>            
          </>
        </div>

        {/* Address */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <h6 className='fw-semibold mb-0'>Address</h6>

            {
              isItMyPage
              &&
              <img src={`/images/business/icons/add-circle.svg`} className='cursor-pointer' style={{ height: '25px' }} onClick={() => setShowAddressPopup(true)} alt="add-circle" />
            }
          </div>

          <hr className='hr bg-secondary' />

          <div className='ms-3'>
            {
              pageAbout.address.length > 0
              &&
              pageAbout.address.map((address, index) => (
                <div className='d-flex align-items-center mb-4' key={index}>
                  <img src={`/images/business/icons/location.svg`} style={{ height: '30px' }} alt="location" />

                  <div className='mx-3 flex-grow-1 border-bottom'>
                    <p className='text-secondary'>{address.name}</p>
                    <p>{address.line1}, {address.line2}, {address.city}, {address.state} - {address.pincode}</p>
                  </div>

                  <>
                    {
                      isItMyPage
                      &&
                      <>
                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm me-2 cursor-pointer' onClick={() => handleEditPageAddress(address)}>
                          <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                        </div>

                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deletePageAddress(address._id)}>
                          <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                        </div>
                      </>
                    }
                  </>

                </div>
              ))
            }
          </div>
        </div>

        {/* Contact Info */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <h6 className='fw-semibold mb-0'>Contact Info</h6>

          <hr className='hr bg-secondary' />

          {/* Mobile */}
          <div className='ms-3'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='bg-secondary-subtle d-inline-block px-3 py-1 rounded-3'>
                <img src={`/images/business/icons/mobile.svg`} className='me-2' style={{ height: '25px' }} alt="mobile" />
                <span>Mobile</span>
              </div>

              {
                isItMyPage
                &&
                <img src={`/images/business/icons/add-circle.svg`} className='cursor-pointer' style={{ height: '25px' }} onClick={() => setShowContactInfoMobilePopup(true)} alt="add-circle" />
              }
            </div>

            <div className='ms-3'>
              {
                pageAbout.contactInfo.mobile.length > 0
                &&
                pageAbout.contactInfo.mobile.map((mobile, index) => (
                  <div className='d-flex align-items-center mb-3' key={index}>
                    <div className='flex-grow-1'>
                      <p className='text-secondary'>{mobile.name}</p>
                      <p className='text-primary border-bottom'>{mobile.mobile}</p>
                    </div>

                    <>
                      {
                        isItMyPage
                        &&
                        <>
                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm mx-2 cursor-pointer' onClick={() => handleEditContactInfo(mobile)}>
                            <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                          </div>

                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deleteContactInfo(mobile._id)}>
                            <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                          </div>
                        </>
                      }
                    </>
                  </div>
                ))
              }
            </div>
          </div>

          <hr className='hr bg-secondary-subtle ms-3' />

          {/* Email */}
          <div className='ms-3'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='bg-secondary-subtle d-inline-block px-3 py-1 rounded-3'>
                <img src={`/images/business/icons/mail.svg`} className='me-2' style={{ height: '25px' }} alt="mobile" />
                <span>Email</span>
              </div>

              {
                isItMyPage
                &&
                <img src={`/images/business/icons/add-circle.svg`} className='cursor-pointer' style={{ height: '25px' }} onClick={() => setShowContactInfoEmailPopup(true)} alt="add-circle" />
              }
            </div>

            <div className='ms-3'>
              {
                pageAbout.contactInfo.email.length > 0
                &&
                pageAbout.contactInfo.email.map((email, index) => (
                  <div className='d-flex align-items-center mb-3' key={index}>
                    <div className='flex-grow-1'>
                      <p className='text-secondary'>{email.name}</p>
                      <p className='text-primary border-bottom'>{email.email}</p>
                    </div>

                    <>
                      {
                        isItMyPage
                        &&
                        <>
                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm mx-2 cursor-pointer' onClick={() => handleEditContactInfo(email)}>
                            <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                          </div>

                          <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deleteContactInfo(email._id)}>
                            <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                          </div>
                        </>
                      }
                    </>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        {/* Dealer */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <h6 className='mb-0 fw-semibold'>Dealer</h6>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => setShowDealerPopup(true)}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <hr className='hr bg-secondary-subtle' />

          {
            pageAbout.dealer
            &&
            <div className='ms-3'>
              <div className='d-flex align-items-center mb-3'>
                <img src={`/images/business/icons/dealer-man.svg`} className='me-3' style={{ height: '30px' }} alt="dealer-man" />

                <div className='border-bottom flex-grow-1'>
                  <p className='text-primary'>{pageAbout.dealer}</p>
                </div>
              </div>
            </div>
          }
        </div>

        {/* Website */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Website</h6>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => setShowWebsiteEmailPopup(true)}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <hr className='hr bg-secondary' />

          <>
            {
              pageAbout.websiteLink
              &&
              <div className='ms-3 d-flex align-items-center' onClick={() => setShowWebsiteEmailPopup(true)}>
                <img src={`/images/business/icons/link.svg`} className='me-3' style={{ height: '20px' }} alt="link" />

                <span className='text-primary border-bottom flex-grow-1'>{pageAbout.websiteLink}</span>
              </div>
            }
          </>
        </div>

        {/* Milestone */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Milestone</h6>

            {
              isItMyPage
              &&
              <img src={`/images/business/icons/add-circle.svg`} className='cursor-pointer' style={{ height: '25px' }} onClick={() => setShowAddMilestonePopup(true)} alt="add-circle" />
            }
          </div>

          <hr className='hr bg-secondary' />

          <div>
            {
              pageAbout.milestone.length > 0 
              && 
              <Slider className="business-milestone-slider" {...sliderSettings}>
                {
                  pageAbout.milestone.map((value, index) => (
                  <div className="p-3 bg-white rounded-3 shadow-sm" onClick={() => { setMilestone(value); setShowViewMilestonePopup(true); }} key={index}>
                    <div className="border position-relative rounded-3">
                      <img src={ value.images && value.images.length > 0 && AwsFileUrl + value.images[0].image } className="w-100 rounded-3 object-fit-contain" style={{ maxHeight: "150px" }} alt="milestone" />

                      {
                        isItMyPage
                        &&
                        <img src="/images/business/icons/edit-blue.svg" className="position-absolute top-0 end-0 mt-2 me-2" style={{ height: "20px" }} alt="edit"
                          onClick={(e) => { e.stopPropagation(); setShowEditMilestonePopup(true); setMilestone(value); }}
                        />
                      }
                    </div>

                    <div className="my-2">
                      <h6 className="fw-semibold d-inline me-2">{value.title.length > 50 ? `${value.title.slice(0, 50)}...` : value.title}</h6>
                      <span className="badge bg-primary">{value.date}</span>
                    </div>

                    <span className="text-secondary">{value.description.length > 200 ? `${value.description.slice(0, 200)}...` : value.description}</span>
                  </div>
                ))}
              </Slider>
            }
          </div>
        </div>

        {/* Active Hours */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h6 className='mb-0 fw-semibold'>Active Hours</h6>

            {
              isItMyPage
              &&
              <img src={`/images/business/icons/add-circle.svg`} style={{ height: '25px' }} onClick={() => setShowActiveHoursPopup(true)} alt="add" />
            }
          </div>

          <hr className='hr bg-secondary mb-4' />

          <div className='ms-3'>
            {
              pageAbout && pageAbout.activeHours && pageAbout.activeHours.length > 0
              &&
              pageAbout.activeHours.map((activeHour, index) => (
                <div className='d-flex align-items-center mb-3' key={index}> 
                  <img src={`/images/business/icons/active-hours.svg`} className='me-3' style={{ height: '17px' }} alt="active hours" />

                  <div className='flex-grow-1 border-bottom'>
                    { activeHour.sun === 'true' && <><span className='text-capitalize'>sun,</span> <span> </span></>}
                    { activeHour.mon === 'true' && <><span className='text-capitalize'>mon,</span> <span> </span></>}
                    { activeHour.tue === 'true' && <><span className='text-capitalize'>tue,</span> <span> </span></>}
                    { activeHour.wed === 'true' && <><span className='text-capitalize'>wed,</span> <span> </span></>}
                    { activeHour.thu === 'true' && <><span className='text-capitalize'>thu,</span> <span> </span></>}
                    { activeHour.fri === 'true' && <><span className='text-capitalize'>fri,</span> <span> </span></>}
                    { activeHour.sat === 'true' && <span className='text-capitalize'>sat</span> }
                    {' ● '}
                    <span className='text-capitalize'>{activeHour.startTime} - {activeHour.endTime}</span>
                  </div>

                  <>
                    {
                      isItMyPage
                      &&
                      <>
                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm mx-2 cursor-pointer' onClick={() => { setActiveHour(activeHour); setShowActiveHoursPopup(true); }}>
                          <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
                        </div>

                        <div className='bg-white px-1 pb-1 rounded-2 shadow-sm cursor-pointer' onClick={() => deleteActiveHour(activeHour._id)}>
                          <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete-red" />
                        </div>
                      </>
                    }
                  </>
                </div>
              ))
            }
          </div>
        </div>

        {/* Account Info */}
        <div className='p-3 rounded-3 shadow-sm mb-3' style={{ backgroundColor: '#f5f5f5' }}>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <div>
              <h6 className='mb-0 fw-semibold'>Account Info</h6>
              <small><span className='text-primary'>eMilo</span> is showing information to help you understand the purpost of this page.</small>
            </div>

            {
              isItMyPage
              &&
              <div className='bg-white px-1 pb-1 rounded-2 shadow-sm' onClick={() => toast.success('Click')}>
                <img src={`/images/business/icons/edit-pen.svg`} style={{ height: '17px' }} alt="edit-pen" />
              </div>
            }
          </div>

          <hr className='hr bg-secondary mb-4' />

          <div>
            {/* Page Id */}
            <div className='d-flex gap-3 align-items-center mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <div className='border-bottom flex-grow-1'>
                <small className='text-secondary d-block'>Page Id</small>
                <span className='fw-semibold'>123456789</span>
              </div>
            </div>

            {/* Page Creation Date */}
            <div className='d-flex gap-3 align-items-center mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <div className='border-bottom flex-grow-1'>
                <small className='text-secondary d-block'>Page Creation Date</small>
                <span className='fw-semibold'>May 01, 2024</span>
              </div>
            </div>

            {/* Admin Info */}
            <div className='d-flex gap-3 align-items-center mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <div className='border-bottom flex-grow-1'>
                <small className='text-secondary d-block'>Admin Info</small>
                <span className='fw-semibold'>Nov 06, 2000</span>
              </div>
            </div>

            {/* Organizers */}
            <div className='d-flex gap-3 mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} className='mt-3' style={{ height: '20px' }} alt="hashtag" />

              <div className='flex-grow-1'>
                <small className='text-secondary d-block'>Organizers that manage this page</small>
                <span className='fw-semibold border-bottom mb-2 d-block'>Meraki International Operations Japan M.I.O.S. is responsible for this page</span>
                <small className='text-secondary d-block'>This is a person or organization that has complete our verification process and claimed responsibility for this page.</small>
              </div>
            </div>

            {/* Page Verification */}
            <div className='d-flex gap-3 mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} className='mt-3' style={{ height: '20px' }} alt="hashtag" />

              <div className='flex-grow-1'>
                <small className='text-secondary d-block'>Page Verification</small>
                <span className='fw-semibold border-bottom d-block'>Verified</span>
                <small className='text-secondary d-block my-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam dolores ad optio quidem necessitatibus quas eaque, reprehenderit repellendus quo quisquam officiis mollitia esse autem est commodi sint omnis dignissimos? Quae?</small>
                <span className='fw-semibold d-block'>Meraki International Operations Japan M.I.O.S.</span>
              </div>
            </div>

            {/* History */}
            <div className='d-flex gap-3 mb-4'>
              <img src={`/images/business/icons/hashtag.svg`} className='mt-3' style={{ height: '20px' }} alt="hashtag" />

              <div className='flex-grow-1'>
                <small className='text-secondary d-block'>History</small>

                <span className='fw-semibold'>Changed name to Meraki</span>
                <small className='text-secondary d-block border-bottom mb-3'>Dec 11, 2010</small>

                <span className='fw-semibold'>Created - Meraki Global</span>
                <small className='text-secondary d-block border-bottom'>Nov 06, 2000</small>
              </div>
            </div>

            {/* Report Page */}
            <div className='d-flex gap-3 align-items-center'>
              <img src={`/images/business/icons/hashtag.svg`} style={{ height: '20px' }} alt="hashtag" />

              <h6 className='fw-semibold mb-0'>Report Page</h6>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
};

export default BusinessHomeAbout;