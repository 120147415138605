import React, { useContext, useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import ProfileImage from '../UseContext/ProfileImage';
import { getMemebyID, postViewMeme, sharememeCount } from '../UseContext/ApiHelpers';
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from 'react-router-dom';
import { BASEAPIURL, BASEURL, BASE_URL_STATIC } from '../../../config';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
// import { ApiContext } from '../UseContext/ApiCall';
import LikeMemeButton from '../Button/LikeButton/LikeMemeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FavouriteMemeButton from '../Button/FavouriteButton/FavouriteMemeButton';
import { ApiContext } from '../UseContext/ApiCall';

const MemePopup = ({ open, close, memeId, onNext, onPrev }) => {

  const [singleMeme, setSingleMeme] = useState([])
  const [userId, setUserId] = useState()
  const [showCopyLinkUserName, setshowCopyLinkUserName] = useState('');
  
  const { config } = useContext(ApiContext);

  useEffect(() => {
    getMemebyID(memeId).then(res => { setSingleMeme(res); setUserId(res && res[0] && res[0].userId) })

    postViewMeme(memeId)
  }, [memeId])

  useEffect(() => {
    getShowCopyLinkUser(userId)
  }, [userId])

  const getShowCopyLinkUser = (userId) => {
    axios.post(`${BASEAPIURL}/user/getoneuser`, { userId }, config)
      .then(res => {
        if (res.data.status === 1) {
        } else {
          setshowCopyLinkUserName(res.data.user.fname);
        }
      })
      .catch(err => {
        return null;
      });
  };

  return (
    <div>
      <Modal show={open} size='xl' onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='popupbody'>
          {
            Array.isArray(singleMeme)
            &&
            singleMeme.map((meme) => (
              <section key={memeId} >
                <section className="meme-flex" style={{ border: '1px solid black', width: '1077px', padding: "20px", margin: '45px auto 0px' }}>
                  {
                    (meme.details && meme.details.length === 1)
                      ?
                      <div className='memeDetails' >
                        {meme.details && meme.details[0].imageId && <img className='memeImg' src={meme.details[0].imageUrl} width='60%' alt='' />}
                        {
                          meme.details[0].videoId &&
                          <section className='video-wrap'>
                            <video
                              controls
                              autoPlay
                              loop
                              style={{ background: "black" }}
                            >
                              <source
                                src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${meme.details[0].videoId}`}
                              />
                            </video>
                          </section>
                        }
                      </div>
                      :
                      <Carousel className='memeCarousel' slide={false}>
                        {meme.details && meme.details.map((detail, index) => (
                          <Carousel.Item>
                            <div className='memeDetails' key={index}>
                              {detail.imageId && <img className='memeImg' src={detail.imageUrl} alt={detail.imageId} width='60%' />}
                              {
                                detail.videoId
                                &&
                                <section className='video-wrap'>
                                  <video controls autoPlay loop style={{ background: "black" }} >
                                    <source src={`https://emilonode.sjain.io/post/getvideobyid?memeVideoId=${detail.videoId}`} />
                                  </video>
                                </section>
                              }
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                  }

                  <section>
                    <div className="d-flex" style={{ width: "455px", justifyContent: "space-between" }} >
                      <div className="d-flex" style={{ marginTop: "10px" }}>
                        <div style={{ margin: "0px 10px", }} >
                          <ProfileImage userId={userId} height='45' width='45' borderRadius='50%' />
                        </div>
                        <div>
                          <Link to={`${BASEURL}/profile/${userId}`} style={{ color: 'black' }}>{showCopyLinkUserName}</Link>
                        </div>
                      </div>

                      <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" style={{ cursor: "pointer", marginRight: "10px" }} />
                    </div>

                    <hr style={{ height: "0.1px", width: "100%", backgroundColor: "gray", margin: "5px 0px", }} />

                    <div style={{ height: "400px", width: "457px" }}>
                      <h3 style={{ textAlign: "center" }}>Description</h3>
                      <span>{meme.memedescription}</span>
                    </div>

                    <hr style={{ height: "0.1px", width: "100%", backgroundColor: "gray", margin: "5px 0px", }} />

                    <div className="d-flex" style={{ width: "455px", marginTop: "10px" }} >
                      <div className="d-flex">
                        <img src={`${BASE_URL_STATIC}/feed-icon1.png`} alt="views" height="70%" width="100%" style={{ margin: "5px 5px 0px 5px" }} />
                        <p style={{ margin: "2px 25px 0px 0px" }}>{meme.memeView}</p>
                      </div>

                      <LikeMemeButton memeId={meme.memeId} />

                      <div className="d-flex" style={{ cursor: "pointer" }}>
                        <img src={`${BASE_URL_STATIC}/feed-icon3.png`} alt="comment" style={{ marginRight: "5px" }} />
                        <p style={{ marginRight: "25px" }}>1k</p>
                      </div>

                      <CopyToClipboard text={`${BASEURL}/memeSharePage/${memeId}`} onCopy={() => sharememeCount(memeId)}  >

                        <div className="d-flex">
                          <img src={`${BASE_URL_STATIC}/feed-icon4.png`} alt="share" style={{ marginRight: "5px" }} />
                          <p style={{ marginRight: "127px" }}>{meme.shareCount}</p>
                        </div>
                      </CopyToClipboard>

                      <div style={{ marginRight: "20px" }}>
                        <FavouriteMemeButton memeId={memeId} />
                      </div>

                      <div >
                        <img src={`${BASE_URL_STATIC}/feed-icon6.png`} alt="views" />
                      </div>
                    </div>

                    <div
                      style={{
                        height: "140px",
                        width: "406px",
                        overflowY: "auto",
                        padding: "10px",
                      }}
                    >
                    </div>

                    <div
                      style={{
                        height: "100px",
                        width: "406px",
                        overflowY: "auto",
                        padding: "10px",
                      }}
                    >

                    </div>
                  </section>
                </section>
              </section>
            ))
          }
        </Modal.Body>

        <div className='popupButtons'>
          <button onClick={onPrev} className='popBtnPrev'>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button onClick={onNext} className='popBtnNext'>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default MemePopup