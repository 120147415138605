import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../../../config";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router";
import { ApiContext } from "../../../UseContext/ApiCall";
import Countdown from 'react-countdown-now';

import axios from "axios";
import toast from "react-hot-toast";


const VerifyChangePassword = () => {

  const [OTP, setOTP] = useState("")
  const nav = useNavigate()
  const { loggedInUserId } = useContext(ApiContext)
  const [profileUser, setProfileUser] = useState(null)
  const [resendTimer, setResendTimer] = useState(59);
  const [btnDisable, setbtnDisable] = useState(true)

  const {selectDevice , config}  = useContext(ApiContext)


  useEffect(() => {
    if (OTP.length < 4) {
      setbtnDisable(true)
    } else {
      setbtnDisable(false)
    }
  }, [OTP])

  const getProfileUser = async (loggedInUserId) => {
    await axios.post(`${BASEAPIURL}/user/getoneuser`, { userId: loggedInUserId } , config)
       .then(res => {
          // console.log(res.data.user);
          setProfileUser(res.data.user);
       })
       .catch(err => {
          return null
       });
 };

 useEffect(() => {
  getProfileUser(loggedInUserId)
}, [loggedInUserId])

  const handleSubmitOtp = async() => {
    try {
      let requestData = {};
      if (selectDevice === "mobile") {
          requestData = { mobile: profileUser.mobile, otp: OTP };
      } else {
          requestData = { email : profileUser.email, otp: OTP };
      }
  
      await axios.post(`${BASEAPIURL}/user/otp-verify`, requestData).then((res) => {
          toast.success(res.data.message);
          setbtnDisable(false);
          nav("/signup/change-password");
      });
  } catch (error) {
      toast.error("Failed to verify OTP");
  }
  
  }

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return <span style={{cursor : "pointer"}} onClick={() => reSendOtp()}>Resend OTP</span>;
    } else {
      return <span style={{opacity : 0.75}}> {`Resend OTP in 00 : ${seconds < 10 ? `0${seconds}` : seconds}`}</span>;
    }
  };

  const reSendOtp = async () => {
    try {
        await axios.post(`${BASEAPIURL}/user/otp-send`, { mobile: profileUser.mobile }).then((res) => {
          toast.success(res.data.message)
          setResendTimer(59)
        })
    } catch (error) {
      toast.error("failed to send otp")
    }
  }

  return (
    <section className="row vh-100 mx-auto">
      {/* Left */}
      <div className="d-none d-lg-block col-lg-6 p-0 h-100" >
        <img src={`${BASE_URL_STATIC}/forgot-password-otp.png`} className="bg-primary h-100 w-100" alt="forgot password" />
      </div>

      {/* Right */}
      <div className="col-12 col-lg-6 p-0">
        {/* Logo */}
        <div className="text-center">
          <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="logo" />
        </div>

        <Form className="w-75 mx-auto">
          <h4 className="cp-rb-header">Enter OTP</h4>
          <p>Please check your registered mobile/email for the OTP (One Time Password) and enter it below to proceed.</p>

          <div className="forgotpass-code-otp">

            <OtpInput value={OTP} onChange={setOTP} numInputs={4} renderSeparator={<pre> </pre>}
              renderInput={(props) => (
                <input {...props} className="onboardingOTP-area-data-inputOTP" style={{ width: "70px" }} />
              )}
            />
          </div>

          <Button className="mt-4 w-50 d-block mx-auto rounded-pill" disabled={btnDisable} onClick={handleSubmitOtp}>Next</Button>

          <div className="forgot-otp mt-3">

          <p className="resendOTP mt-0">Didn’t get the OTP? <b><Countdown date={Date.now() + resendTimer * 1000} renderer={renderer} /></b></p>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default VerifyChangePassword;
