import React, { useState, useEffect, useRef, useContext } from "react";
import './Reels.css';
import BackToTop from "../Helper/BackToTop";
import ViewReelCount from "../Button/ViewReelCount";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import FavouriteReelButton from "../Button/FavouriteButton/FavouriteReelButton";
// import LikeReelButton2 from "../Button/LikeButton/LikeReelButton2";
import ProfilePostPopup from "../Popup/Profile/ProfileShow/ProfilePostPopup";
import FollowUnfollowButton from "../Button/FollowUnfollowButton";
import { AwsFileUrl, BASE_URL_STATIC, } from "../../../config";
import { getDataTypePost } from "../UseContext/ApiHelpers";
import { ApiContext } from "../UseContext/ApiCall";
import Slider from "react-slick";
import LikeButton from "../Button/LikeButton/LikeButton";

const Reels = () => {

  const [allReels, setAllReels] = useState([])

  const [page, setPage] = useState(1)
  const { config, } = useContext(ApiContext)


  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [postData, setPostData] = useState(null);

  const containerRef = useRef(null);
  const loggedInUserId = localStorage.getItem("loggedInUserId");

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
  };


  useEffect(() => {

    getDataTypePost('flix', 'postType', "", page, config).then(res => {
      setAllReels(res.data.posts)
      setPage(page + 1)
    });
  }, []);


  // Handle mouse scroll (wheel) event
  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      // Scroll up, show the previous slide
      sliderRef.current.slickPrev();
    } else if (event.deltaY > 0) {
      // Scroll down, show the next slide
      sliderRef.current.slickNext();
    }
  };


  // To handle reel

  document.title = "Emilo-Flix";

  return (
    <>
      {/* Profile Reel Popup */}
      {/* <ProfileReelPopup showProfileReelPopup={showProfileReelPopup} setShowProfileReelPopup={setShowProfileReelPopup} profileReelPopupData={profileReelPopupData} /> */}

      <section className="reel-section">
        <div className="carouselSlider" ref={containerRef} onWheel={handleWheel}>
          <div className="carousel reels-carousel" >


            <Slider {...settings} ref={sliderRef}>

              {
                allReels
                &&
                allReels.reverse().map((reel, index) => (
                  <div key={index} className="main reel-section-reel">
                    {console.log("reel", reel)}
                    <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postData} postId={postId} />

                    <ViewReelCount showPostsPopup={showPostsPopup} allReels={allReels} id={reel._id} reelId={reel.mediaUrls[0] && reel.mediaUrls[0].url} setPage={setPage} page={page} />

                    <div className="leftArea reels-area">
                      <span>

                        <img src={`${BASE_URL_STATIC}/icon/view-0.svg`} alt="view" />

                        <small className="text-white fs-10">{reel.viewcount}</small>
                      </span>

                      <LikeButton postId={reel.reelId} likecount={reel.likecount} postedBy={reel.postedBy} postType='flix' />

                      <span>
                        <img src={`${BASE_URL_STATIC}/icon/comment-dots.svg`} alt="comment" onClick={() => { setShowPostsPopup(!showPostsPopup); setPostId(reel._id); setPostData(reel) }} />
                        <small className="text-white fs-10">{reel.commentcount}</small>
                      </span>

                      <span>
                        <img src={`${BASE_URL_STATIC}/icon/share-0.svg`} alt="share" />
                        <small className="text-white fs-10">{reel.sharecount}</small>
                      </span>

                      {/* <FavouriteReelButton reelId={reel._id} /> */}

                      <span>
                        <img src={`${BASE_URL_STATIC}/icon/star-0.svg`} alt="download" />
                      </span>

                      <span>
                        <img src={`${BASE_URL_STATIC}/icon/download-0.svg`} alt="download" />
                      </span>

                      <span>
                        <img src={`${BASE_URL_STATIC}/icon/more-0.svg`} alt="more" />
                      </span>
                    </div>

                    <div className="reel-details">
                      <div className={`flex ${(reel.postedBy === loggedInUserId) && 'justify-content-end'} `}>
                        {reel.postedBy !== loggedInUserId && <FollowUnfollowButton otherUserId={reel.postedBy} type='flix' />}

                        <div className="flex flix_details">
                          <p className="d-flex flex-column align-items-end">
                            <span className="text-white fw-bold">{reel.fname}</span>
                            <span className="text-white fs-10">{reel.username}</span>
                          </p>

                          <div className="profile_flix">
                            <img src={AwsFileUrl + reel.imageId} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="reel-details-desp">
                        <ReadMore>
                          <span>{reel.description}</span>
                        </ReadMore>
                      </div>

                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>  
        <BackToTop />
      </section>
    </>
  );
};


export const ReadMore = ({ children }) => {
  // Ensure text is always a string, even if children is undefined or invalid
  const text = React.isValidElement(children) && typeof children.props.children === 'string'
    ? children.props.children
    : (children || '').toString();

  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="fs-10 text-white">
      {isReadMore ? text.slice(0, 100) : text}
      {text.length > 100 && (
        <span onClick={toggleReadMore} className="read-more fst-italic text-white" style={{ cursor: 'pointer' }}>
          {isReadMore ? '... More' : '... Less'}
        </span>
      )}
    </p>
  );
};

export default Reels;
