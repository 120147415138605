import React, { useContext, useState, useEffect } from 'react';
import { ApiContext } from '../UseContext/ApiCall';
import PostTime from '../Home/CenterBlock/CenterBlockPosts/PostTime';
// import NotificationNamePopup from '../Popup/Notification/NotificationNamePopup';
import { useNavigate } from 'react-router-dom';
import { AwsFileUrl, BASEAPIURL, BASE_URL_STATIC } from '../../../config';
import { getNotification, seenNotifications } from '../UseContext/ApiHelpers';
import axios from 'axios';
import FollowRequestPopup from './FollowRequestPopup';


const Notifications = () => {

  // const [showNotificationNamePopup, setShowNotificationNamePopup] = useState(false);
  const [notification, setnotification] = useState([])
  const [toggleFollow, setToggleFollow] = useState(false)
  const [openFollow, setOpenFollow] = useState(false)
  const [followData, setFollowData] = useState({})

  const { config, isNotificationStopped,
    handleChangeStopAllNotification,
  } = useContext(ApiContext);

  const nav = useNavigate();

  localStorage.removeItem('showNewNotificationDot');

  useEffect(() => {
    if (openFollow === false) {
      getNotification(config).then((res) => {
        setnotification(res)
      })
    }
  }, [openFollow]);


  const handleAcceptandFollow = async (type, id, userID) => {
    if (type === 'accept') {
      await axios.post(`${BASEAPIURL}/user/acceptefollowersreq`, { followingID: userID }, config);
      setToggleFollow(true);
      
    } else if (type === 'reject') {
      await axios.post(`${BASEAPIURL}/user/deletefollowersreq`, { followingID: userID }, config);
    }
    seenNotifications(id, config)
    setnotification((prev) => prev.filter((notif) => notif.notifications[0]._id !== id));
  };



  const handleClick = (n) => {
    if (n.targetEvent === 'follow' && n.users.length > 1) {
      setFollowData({ notification: n.notifications, users: n.users });
      setOpenFollow(true)
    }
    else if (n.targetEvent !== 'follow') {
      n.notifications.map((e) => (
        seenNotifications(e._id, config)
      ))
      nav(`/showCopyLink/${n.targetEventId}`)
    }
  }

  return (
    <div className='notification_head'>
      {/* Notification Name Popup */}
      {/* <NotificationNamePopup showNotificationNamePopup={showNotificationNamePopup} setShowNotificationNamePopup={setShowNotificationNamePopup} /> */}

      <FollowRequestPopup setFollowData={setFollowData} config={config} open={openFollow} close={() => setOpenFollow(false)} followData={followData} />
      <h6 className="trending_head pt-lg-5 mt-lg-5">Notifications</h6>
      {
        !isNotificationStopped
          ?
          // <section className='notifications'  onScroll={handleNotificationInfiniteScroll}>

          <section className='notification w-75 m-auto' >
            {/* New Notification */}
            {
              notification
              &&
              notification.map((n, index) => (
                <section className='oldNotification' key={index}>
                  {/* <Link to={`/showCopyLink/${n.targetEventId}`} className='w-100'> */}
                  <section className='flex p-2' onClick={() => handleClick(n)}>
                    <div className='flex justify-content-start gap-2'>
                      <img className='oldNotification-follow-1-img rounded-circle me-2 me-sm-3' src={AwsFileUrl + n.users[0].imageId} alt="profile" />

                      <div className='d-flex flex-column align-items-start'>
                        <p className='fs-14'>{n.description}</p>
                        <span className='fs-14 text-secondary'>
                          <PostTime date={n.notifications[0] && n.notifications[0].createdAt} />
                        </span>
                      </div>
                    </div>

                    {/* ---------- like ---------- */}

                    {n.notifications[0] && n.notifications[0].targetEvent !== 'follow' && <img className='oldNotification-like-1-post ms-auto' src={AwsFileUrl + n.targetImage} alt="post" />}

                    {/* ---------- Follow ---------- */}

                    {/* <img className='oldNotification-like-1-post ms-auto' src={AwsFileUrl + n.targetImage} alt="post" /> */}

                    {n.notifications.length === 1 && n.notifications[0] && n.notifications[0].targetEvent === 'follow' &&
                      <div>
                        {!toggleFollow &&
                          <div className='flex'>
                            <button type="button" className="followUnfollowBtn" onClick={() => handleAcceptandFollow('accept', n.notifications[0]._id, n.notifications[0].userID)}>
                              Accept
                            </button>

                            <button type="button" className="followUnfollowBtn bg-danger" onClick={() => handleAcceptandFollow('reject', n.notifications[0]._id)}>
                              Reject
                            </button>
                          </div>
                        }
                      </div>
                    }
                    {n.notifications.length > 1 && n.notifications[0] && n.notifications[0].targetEvent === 'follow' &&
                      <div onClick={(e) => e.stopPropagation()}>
                        <button type="button" className="followUnfollowBtn"
                          onClick={() => {
                            setOpenFollow(true);
                            setFollowData({ notification: n.notifications, users: n.users });
                          }}>
                          View
                        </button>
                      </div>
                    }

                    {/* <FollowUnfollowButton otherUserId={n.notifications[0].createdAt} /> */}
                  </section>
                  {/* </Link> */}

                </section>
              ))
            }

          </section>

          :
          <section className='notifications-stop'>
            <div>
              <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="notification" />
            </div>

            <h6>You have stopped all notifications if you want to see again <span onClick={() => handleChangeStopAllNotification(config)}>click here</span></h6>
          </section>
      }
    </div>
  )
}

export default Notifications;
