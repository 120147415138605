import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';


const BusinessContactInfoMobilePopup = () => {

  const { 
    showContactInfoMobilePopup, setShowContactInfoMobilePopup,
    contactInfo, setContactInfo,
    createContactInfo,
  } = useContext(businessContext);

  return (
    <>
      <Modal show={showContactInfoMobilePopup} onHide={() => setShowContactInfoMobilePopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center'>Contact Info</h4>

          {/* Contact 1 */}
          <div className='my-5'>
            <div className='d-flex justify-content-between mb-4'>
              <span className='bg-secondary-subtle px-3 rounded-3 shadow-sm'>Mobile</span>

              {/* <div className="form-check mb-0 me-0">
                <input type="checkbox" className="form-check-input" id="primaryNubmer" />
                <small className="form-check-label text-secondary" htmlFor="primaryNubmer">Select this as primary number</small>
              </div> */}
            </div>

            <div className='ms-3'>
              <small className='text-secondary'>Department Name</small>
              <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='name' placeholder='Sales, Service...' 
                value={contactInfo.name} onChange={(e) => setContactInfo((prevState) => ({ ...prevState, [e.target.name]: e.target.value, }))}
              />

              <small className='text-secondary'>Mobile</small>
              <input type="text" className='form-control border-0 border-bottom shadow-none' name='mobile' placeholder='+91 74157 47501...' 
                value={contactInfo.mobile} onChange={(e) => setContactInfo((prevState) => ({ ...prevState, [e.target.name]: e.target.value, email: '' }))}
              />
            </div>
          </div>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50 rounded-pill' disabled={contactInfo.name === '' || contactInfo.mobile === ''} onClick={()=> createContactInfo('mobile')}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BusinessContactInfoMobilePopup
