
//---------------------------------------------------Login SC----------------------------------------------------


import axios from "axios"
import { BASEAPIURL } from "../../../config"


export const loginSoftCorner = async (password, config) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/loginsc`, { password }, config)
  return res
}
//-------------------- Add/Remove/Cancel partner----------------
export const addSCPartner = async (emiloUserId, partnerUserId, action, scConfig) => {
  console.log('statuss' , action)
  try {
    const res = await axios.post(`${BASEAPIURL}/softcorner/addSCPartner`, { emiloUserId, partnerUserId, action: action }, scConfig)
    return res
} catch (error) {
  return null;
  }
}

export const getPartnerinfo = async (config) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getPartnerInfo`, {}, config)
  return res
}


//-------------------- get soft coner profile ----------------
export const getSoftCornerProfile = async (userId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getScProfileDetails`, { userId }, scConfig)
  return res.data.myprofile
}

//--------------------createPosts-----------------------

export const createPosts = async (formData, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/uploadscpost`, formData, scConfig)
  return res.data
}

export const getPosts = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getuserpost`, {}, scConfig)
  return res.data.listPost
}

//--------------------createFavPosts-----------------------
export const createFavPosts = async (postId, action, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createscfavpost`, { postId, action }, scConfig)
  return res.data
}

//--------------------getUserFavPosts-----------------------
export const getUserFavPosts = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getuserfvpost`, {}, scConfig)
  return res.data.listPost
}

//------------------------getPartnerPost-------------------------

export const getPartnerPosts = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getpartnerpost`, {}, scConfig)
  return res.data.listPost
}


//------------------------matchPartner-------------------------

export const matchPartner = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/matchingpartner`, {}, scConfig)
  return res.data
}

//------------------------matchingTime-------------------------

export const getMatchingTime = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/matchingtime`, {}, scConfig)
  return res.data.createdTime
}

//------------------------get_Profile_Image_After_Match-------------------------

export const getMyImage = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/profilemy`, {}, scConfig)
  return res.data.response
}

export const getPartnerImage = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/profilepartner`, {}, scConfig)
  return res.data.response
}

//----------------------------------CreateDatePlanner-------------------------------

export const createDatePlanner = async (inputData, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createdateplanner`,
    {
      title: inputData.title,
      alertTime: inputData.setAlerts,
      repeat: inputData.repeat,
      category: inputData.category,
      location: inputData.location,
      startDateTime: inputData.startDateTime,
      endDateTime: inputData.endDateTime,
      note: inputData.notes,
      allDay: inputData.isAllDay,
      customAlertTime: inputData.customAlertTime
    }, scConfig)
  return res.data
}

export const getDatePlanner = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getdateplanner`, {}, scConfig)
  return res.data.response
}

//---------------------------------CreateDatePlanner-------------------------------
export const createSpecialDates = async (inputData, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createspecialday`,
    {
      title: inputData.title,
      category: inputData.category,
      alertTime: inputData.setAlerts,
      dateTime: inputData.selectDate,
      location: inputData.location,
      note: inputData.notes,
      customAlertTime: inputData.customAlertTime
    }, scConfig)
  return res.data
}

export const getSpecialDays = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getspecialday`, {}, scConfig)
  return res.data.response
}

//---------------------------Chat Section -------------------------

export const createConnection = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createchatconnection`, {}, scConfig)
  return res.data
}

export const typingMessage = async (chatId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createchatmessage`, { chatId }, scConfig)
  return res.data
}

export const createSendMessage = async (message, chatId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createchatmessage`, { message, chatId }, scConfig)
  return res.data
}

export const getMessage = async (page, chatId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/getchatmessage?page=${page}`,  { chatId }, scConfig)
  return res.data.message
}

export const clearChat = async (chatId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/clear_sc_message`, { chatId }, scConfig)
  return res.data.message
}

export const ReplyMessage = async (message, replyMessageId, chatId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/createchatmessage`, { message, replyMessageId, chatId }, scConfig)
  return res.data
}

export const DeleteMessage = async (messageId, chatId, scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/deletemessage`, { messageId, chatId }, scConfig)
  return res.data
}

export const checkOnline = async (scConfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/checkuseronline`, {}, scConfig)
  return res
}

export const scmessageSeen = async (userId, chatId, messageId, config) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/scMessageSeenBy`, { userId, chatId, messageId }, config)
  return res.data
}

export const delete_me_Message = async (messageId , sconfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/delete_me_Message`, { messageId }, sconfig)
  return res.data
}

export const delete_everyone = async (messageId , sconfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/delete_everyone`, { messageId }, sconfig)
  return res.data
}

export const deleted_sc_everyOne_Message = async (messageId , scconfig) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/edit_sc_Message`, { messageId , message:"This message was deleted", isEdited : "2"}, scconfig)
  return res.data
}

export const editMessage_sc = async (messageId ,message, config) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/edit_sc_Message`, { messageId , message, isEdited:"1"}, config)
  return res.data
}

export const check_sc_pin = async (password,config) => {
  const res = await axios.post(`${BASEAPIURL}/softcorner/checkScPin`, { password }, config)
  return res.data
}

export const change_sc_pin = async (pin,config) => {
  console.log("pin", config)
  const res = await axios.post(`${BASEAPIURL}/softcorner/changeSCPin`, { pin }, config)
  return res.data
}

