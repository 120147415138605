import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Circle, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '600px',
  };
  

 
 const GoogleMapApi = ({centerPosition, setCenterPosition}) => {
   const [circleRadius, setCircleRadius] = useState(5000); // Initial radius in meters
  const [circleArea, setCircleArea] = useState(0); // Area in square meters


  // Function to calculate the area based on radius (in meters)
  const calculateArea = (radius) => {
    const area = Math.PI * Math.pow(radius, 2); // Area = π * r^2
    return area;
  };

  // Handle circle radius change
  const handleRadiusChange = (e) => {
    const newRadius = e.target.value;
    setCircleRadius(newRadius);
    setCircleArea(calculateArea(newRadius)); // Update area when radius changes
  };

  // Handle map click to set new center
  const handleMapClick = (event) => {
    setCenterPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };


  useEffect(() =>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenterPosition({ lat: latitude, lng: longitude });
        },
        () => {
          console.log("Error getting user's location");
          // Default to a fixed location if geolocation fails
          setCenterPosition({ lat: 28.6139, lng: 77.2090 });
        }
      );
    } else {
      console.log("Geolocation not supported");
      // Default to a fixed location if geolocation is not supported
      setCenterPosition({ lat: 28.6139, lng: 77.2090 });
    }
  }, 
 [])


  return (
    <div>
    
      <div>
       
        <input
          type="number"
          value={circleRadius}
          onChange={handleRadiusChange}
          min="100"
        />
      </div>
      <div>
        <p>Circle Area: {circleArea.toFixed(2)} square meters</p>
      </div>

      <LoadScript googleMapsApiKey="AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60">
        
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={centerPosition}
          zoom={12}
          onClick={handleMapClick}
        >
          {/* Add Circle on the map */}
          <Circle
            center={centerPosition}
            radius={circleRadius} // in meters
            options={{
              fillColor: 'blue',
              fillOpacity: 0.3,
              strokeColor: 'blue',
              strokeOpacity: 0.6,
              strokeWeight: 2,
            }}
          />
          {/* Marker at the center */}
          <Marker position={centerPosition} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};
export default GoogleMapApi
 