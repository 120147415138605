import React, { useState, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { BASE_URL_STATIC } from '../../../../../config';
import CreateReels4 from './CreateReels4';

const Reels1 = ({ showCreateReelsPopup1, setSelectedEmoji, setShowCreateReelsPopup1 }) => {

  const [reels, setReels] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [open, setOpen] = useState(false)

  const inputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const reel = e.dataTransfer.files[0];
    setReels(reel);
    setOpen(true)
    setShowCreateReelsPopup1(false)
  };

  const handleClickReels = () => {
    inputRef.current.click();
  };

  const handleSelectReels = async (e) => {
    const reel = e.target.files[0];
    setReels(reel);
    setOpen(true)
    setShowCreateReelsPopup1(false)
  };

  return (
    <>
      <section>
        {/* Create Reels Popup 1 */}
        <section>
          <Modal
            show={showCreateReelsPopup1}
            onHide={() => setShowCreateReelsPopup1(false)}
            backdrop="static"
            className={`create-call ${isDragging ? 'dragging' : ''}`}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
              <section id='create-reels-1'>
                <div>
                  <p className='col-12 fs-5 py-1 mb-5 text-center'>Create Flix</p>
                </div>

                <div className='col-12'
                  style={{
                    height: '300px',
                    width: '375px',
                    border: '1px dashed black',
                    borderRadius: '30px',
                    margin: '0px auto ',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    marginBottom: '25px',
                  }}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div>
                    <img src={`${BASE_URL_STATIC}/crdad.png`} alt="crdad" />
                  </div>

                  <div>
                    <h4 style={{ textAlign: 'center' }}>Drag And Drop</h4>
                    <small style={{ color: '#BABABA', fontSize: '11px' }}> video or browse from computer to upload</small>
                  </div>

                  <form>
                    <label htmlFor="reels"></label>
                    <input type="file" id='reels' name='reels' ref={inputRef} onChange={handleSelectReels} accept='video/*' style={{ display: 'none' }} multiple />
                    <button
                      type="button"
                      style={{
                        height: '40px',
                        width: '278px',
                        color: 'white',
                        borderRadius: '30px',
                        backgroundColor: isDragging ? '#91C9F7' : '#64A6F0',
                        border: 'none',
                      }}
                      onClick={handleClickReels}
                    >
                      Browse from computer
                    </button>
                  </form>
                </div>
              </section>
            </Modal.Body>
          </Modal>
        </section>

        {/* Create Reels Popup 2 */}
        <section>
          {/* <CreateReels2 setSelectedEmoji={setSelectedEmoji} selectedEmoji={selectedEmoji}
            reels={reels} thumbnailReel={thumbnailReel}
            showCreateReelsPopup1={showCreateReelsPopup1} setShowCreateReelsPopup1={setShowCreateReelsPopup1}
            location={location} setLocation={setLocation}
          /> */}
          <CreateReels4
            reels={reels}
            showCreateReelsPopup2={showCreateReelsPopup1} setSelectedEmoji={setSelectedEmoji} setShowCreateReelsPopup2={setShowCreateReelsPopup1}
            showCreateReelsPopup4={open} setShowCreateReelsPopup4={() => setOpen(false)}

          />
        </section>
      </section>
    </>
  )
}

export default Reels1;
