import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL_STATIC } from "../../../../config";
import { useLocation } from 'react-router-dom';

const ProfileNavigate = ({profileUserId}) => {


  let location = useLocation();

  const pathParts = location.pathname.split('/');
  const lastPart = pathParts[pathParts.length - 1];

  return (
    <>
      <section className="mb-3 mb-lg-5 d-flex justify-content-evenly align-items-center text-center col-12 col-lg-10 col-xl-8 mx-auto p-0">
        {/* Posts */}
        <>
          {
            lastPart === profileUserId
            ?
            <Link to={`/profile/${profileUserId}`} className="px-2 py-1 px-lg-3 py-lg-2 rounded-3 bg-body-secondary shadow-sm">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-post-2.svg`} alt="" />
              <p className="pn-text mt-1">Posts</p>
            </Link>
            :
            <Link to={`/profile/${profileUserId}`} className="px-2 py-1 px-lg-3 py-lg-2  ">

              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-post-2.svg`} alt="" />
              <p className="pn-text mt-1">Posts</p>
            </Link>
          }
        </>

        {/* Flix */}
        <>
          {
            lastPart === 'profile-reels'
            ?
            <Link to={`/profile/${profileUserId}/profile-reels`} className="px-2 py-1 px-lg-3 py-lg-2 rounded-3 bg-body-secondary shadow-sm">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-flix-2.svg`} alt="" />
              <p className="pn-text mt-1">Flix</p>
            </Link>
            :
            <Link to={`/profile/${profileUserId}/profile-reels`} className="px-2 py-1 px-lg-3 py-lg-2">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-flix-1.svg`} alt="" />
              <p className="pn-text mt-1">Flix</p>
            </Link>
          }
        </>

        {/* Videos */}
        <>
          {
            lastPart === 'profile-videos'
            ?
            <Link to={`/profile/${profileUserId}/profile-videos`} className="px-2 py-1 px-lg-3 py-lg-2 rounded-3 bg-body-secondary shadow-sm">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-video-2.svg`} alt="" />
              <p className="pn-text mt-1">Videos</p>
            </Link>
            :
            <Link to={`/profile/${profileUserId}/profile-videos`} className="px-2 py-1 px-lg-3 py-lg-2">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-video-1.svg`} alt="" />
              <p className="pn-text mt-1">Videos</p>
            </Link>
          }
        </>

        {/* Bestclips */}
        <>
          {
            lastPart === 'profile-bestClips'
            ?
            <Link to={`/profile/${profileUserId}/profile-bestClips`} className="px-2 py-1 px-lg-3 py-lg-2 rounded-3 bg-body-secondary shadow-sm">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-bestClip-2.svg`} alt="" />
              <p className="pn-text mt-1">Best Clips</p>
            </Link>
            :
            <Link to={`/profile/${profileUserId}/profile-bestClips`} className="px-2 py-1 px-lg-3 py-lg-2">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-bestClip-1.svg`} alt="" />
              <p className="pn-text mt-1">Best Clips</p>
            </Link>
          }
        </>

        {/* Memes */}
        <>
          {
            lastPart === 'profile-memes'
            ?
            <Link to={`/profile/${profileUserId}/profile-memes`} className="px-2 py-1 px-lg-3 py-lg-2 rounded-3 bg-body-secondary shadow-sm">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/meme.svg`} alt="" height="40" width="40" />
              <p className="pn-text mt-1">Memes</p>
            </Link>
            :
            <Link to={`/profile/${profileUserId}/profile-memes`} className="px-2 py-1 px-lg-3 py-lg-2">
              <img className="pn-img" src={`${BASE_URL_STATIC}/icon/meme.svg`} alt="" height="40" width="40" />
              <p className="pn-text mt-1">Memes</p>
            </Link>
          }
        </>
      </section>
    </>
  );
};

export default ProfileNavigate;
