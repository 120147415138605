 const  Categories  = [
    {
      name: "Sports",
      subcategories: [
        "Football",
        "Basketball",
        "Tennis",
        "Cricket",
        "Baseball",
      ],
    },
    {
      name: "Technology",
      subcategories: [
        "Artificial Intelligence",
        "Blockchain",
        "Cloud Computing",
        "Cybersecurity",
        "Software Development",
      ],
    },
    {
      name: "Entertainment",
      subcategories: ["Movies", "Music", "Video Games", "TV Shows", "Theater"],
    },
    {
      name: "Health",
      subcategories: ["Fitness", "Mental Health", "Nutrition", "Healthcare", "Wellness"],
    },
    {
      name: "Education",
      subcategories: [
        "Primary Education",
        "Secondary Education",
        "Higher Education",
        "Online Learning",
        "Skill Development",
      ],
    },
    {
      name: "Business",
      subcategories: [
        "Entrepreneurship",
        "Marketing",
        "Finance",
        "Human Resources",
        "Operations",
      ],
    },
  ]

  const ActionButton = [
    'Shop Now',
    'Buy Now',
    'Contact Us',
    'Learn More',
    'Call Now',
    'Get Started',
    'Subscribe Now',
    'Discover More',
    'Get Offer',
    'See Plans',
    'Join Now',
    'Sign Up',
    'Start Free Trial',
    'View Details',
    'Order Now',
    'Try It Free',
    'Request a Demo',
    'Browse Collection',
    'Check Availability',
    'Shop Today'
  ];
  
  const radioOptions = [
    { id: 'inlineRadio1', label: 'All', value: 'value' },
    { id: 'inlineRadio2', label: 'Male', value: 'Male' },
    { id: 'inlineRadio3', label: 'Female', value: 'Female' },
  ];

export {Categories,  ActionButton, radioOptions}