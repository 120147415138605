import React, { useContext, useEffect, useState } from 'react'
import { getRelation, relationList, updateRelation } from '../../../UseContext/ApiHelpers'
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL_STATIC } from '../../../../../config';
import { profileContext } from '../../../UseContext/ProfileContext';

const AddEditRelationPop = ({ open, close, relationId, relationObj }) => {
   const [relationsData, setRelationsDatas] = useState([])
   const { config, loggedInUserGender } = useContext(ApiContext)
   const [saveLoader, setSaveLoader] = useState(false)
   // const [inputValue, setinputValue] = useState('')
   const [err, setErr] = useState('')
   const [secondUserAs, setsecondUserAs] = useState('')
   const [firstUser, setFirstUser] = useState('')
   const { setRelationsData } = useContext(profileContext)
   const { loggedInUserId } = useContext(ApiContext)

   useEffect(() => {
      relationList(config).then((res) => {
         setRelationsDatas(res)
      })
      setsecondUserAs(relationObj.secondUserAs)
      setFirstUser(relationObj)

      setErr("")
      setSaveLoader(false)
   }, [relationObj])

   const femaleRelationships = relationsData.femaleRelationships ? Object.keys(relationsData.femaleRelationships) : []
   const maleRelationships = relationsData.maleRelationships ? Object.keys(relationsData.maleRelationships) : []

   const handleSubmit = (e) => {
      e.preventDefault()

      const getRelationship = (a, b) => {
         if (firstUser.firstGender === "Male") {
            const relation = relationsData.maleRelationships[a];
            if (relation) {
               return relation[b.toLowerCase()];
            } else {
               return "Unknown relationship";
            }
         }
         else {
            const relation = relationsData.femaleRelationships[a];
            if (relation) {
               return relation[b.toLowerCase()];
            } else {
               return "Unknown relationship";
            }
         }
      };

      const data = {
         secondUserAs,
         siblingId: relationObj._id,
         firstUserAs: getRelationship(secondUserAs, loggedInUserGender.toLowerCase())
      }

      updateRelation(data, config).then((res) => {
         getRelation(loggedInUserId).then(res => { setRelationsData(res) })
         close();
         setFirstUser("")
      })
   }

   return (
      <section>
         <Modal show={open} onHide={() => { close(); setFirstUser(""); }} centered backdrop="static" keyboard={false} className="create-call" >
            <Modal.Header closeButton>&nbsp;</Modal.Header>
            <Modal.Body>
               <form onSubmit={handleSubmit}>
                  <h5>Relations</h5>

                  <div className="">
                     <p className='fav_movie_1'>
                        <span>
                           <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#bcbec2", }} />
                        </span>
                        <span>Relation Name</span>
                     </p>

                     <div className="d-flex  justify-content-center align-items-center my-4 gap-3">

                        {firstUser.imageId ? <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/user/${firstUser.imageId}`} alt="" />
                           :
                           <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/dummy.png`} alt="" />
                        }

                        <div>
                           <h6><b>{relationObj.secondUserName}</b></h6>
                           {relationObj.siblingStatus === 0 && <small>request sent</small>}
                           {relationObj.siblingStatus === 1 && <small>{relationObj.secondUserAs}</small>}

                        </div>

                     </div>


                     <div className='w-75 m-auto'>
                        <select defaultValue={relationObj.secondUserAs} onChange={(e) => { setsecondUserAs(e.target.value); setErr("") }} className='w-100 text-center' name="" id="">

                           {relationObj.secondGender === "Female" && femaleRelationships && femaleRelationships.map((e) => (
                              <option key={e} value={e}>{e}</option>
                           ))}
                           {relationObj.secondGender === "Male" && maleRelationships && maleRelationships.map((e) => (
                              <option key={e} value={e}>{e}</option>
                           ))}
                        </select>
                     </div>


                     <div className='text-center'>
                        <button type='submit' className='fav_movie_btn'>{saveLoader ? "Saving..." : "Save"}</button>
                     </div>

                     <p className='mt-1 text-center' style={{ color: "#FF4640" }}>
                        <span>{err}</span>
                     </p>

                  </div>

               </form>
            </Modal.Body>
         </Modal>
      </section>
   )
}

export default AddEditRelationPop;
