import React, { useContext, useEffect, useState } from 'react';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Slider from "react-slick";
import { businessContext } from '../UseContext/BusinessContext';
import { getAllBusinessPost } from '../../Emilo/UseContext/ApiHelpers';
import ViewPosts from '../../Emilo/Button/ViewPosts';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LikeButton from '../../Emilo/Button/LikeButton/LikeButton';
import PostSharePopup from '../../Emilo/Popup/Share/PostSharePopup';
import ProfilePostPopup from '../../Emilo/Popup/Profile/ProfileShow/ProfilePostPopup';
import { ReadMoreData } from '../../Emilo/Data/EducationData';


const BusinessHomePosts = () => {

  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [showPostSharePopup, setShowPostSharePopup] = useState(false);
  const [postsPopupData, setPostsPopupData] = useState("");
  const [shareData, setShareData] = useState(null);

  const {
    paramsPageId,
    // pageDetails,
    checkIsItMyPage,
    // isItMyPage,
    getPages,
    setBoostPostId
  } = useContext(businessContext);

  const { trendingfeed, config, ReadMore, posts, setPosts, } = useContext(ApiContext);

  const navigate = useNavigate();
  const { pageId } =  useParams();

const handleClick = async (postData) => {
   
    setBoostPostId(postData); 
    navigate(`/business/boost-post-1/${pageId}`);

 
};

  

  const feedslider = {
    autoplay: false,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    pauseOnHover: true,
    adaptiveHeight: true
  };

  const ads = [
    {
      id: 1,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 2,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 3,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 1,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 2,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
    {
      id: 3,
      profileImage: `${BASE_URL_STATIC}/profile-img1.jpg`,
      title: 'Interesting Facts',
      subtitle: 'Sponsored',
      description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.',
      adImage: 'https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg',
      linkText: 'www.example.com',
      footerText: 'Lorem ipsum dolor sit amet.',
    },
  ];

  const adsSetting = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  const messages = [
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      message: 'Good Morning',
      time: '12:17 AM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      message: 'How are you doing today?',
      time: '1:30 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      message: 'Just finished my work.',
      time: '3:45 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      message: 'Looking forward to our meeting.',
      time: '5:15 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      message: 'Goodnight!',
      time: '10:30 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      message: 'Good Morning',
      time: '12:17 AM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      message: 'How are you doing today?',
      time: '1:30 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      message: 'Just finished my work.',
      time: '3:45 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      message: 'Looking forward to our meeting.',
      time: '5:15 PM'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      message: 'Goodnight!',
      time: '10:30 PM'
    },
  ];

  const events = [
    {
      image: 'https://i.ytimg.com/vi/5DQckg0L0sk/maxresdefault.jpg',
      time: '25 Jan - 28 Jan',
      name: 'Loreal',
      address: 'Loreal, Sector-24, Atal Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://images-eu.ssl-images-amazon.com/images/G/31/img21/Beauty/MayART/BrandDay/AMZMASLK0520283_1_N_3125X1563_8fbbce25-d55f-4742-a876-40ac133615ac.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Lakme',
      address: 'Lakme, Lakhe Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://i.ytimg.com/vi/a5RiQXOnqAM/maxresdefault.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Sugar Cosmetics',
      address: 'Loreal, Sector-22, Gautam Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://i.ytimg.com/vi/5DQckg0L0sk/maxresdefault.jpg',
      time: '25 Jan - 28 Jan',
      name: 'Loreal',
      address: 'Loreal, Sector-24, Atal Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://images-eu.ssl-images-amazon.com/images/G/31/img21/Beauty/MayART/BrandDay/AMZMASLK0520283_1_N_3125X1563_8fbbce25-d55f-4742-a876-40ac133615ac.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Lakme',
      address: 'Lakme, Lakhe Nagar, Raipur, Chhattisgarh',
    },
    {
      image: 'https://i.ytimg.com/vi/a5RiQXOnqAM/maxresdefault.jpg',
      time: '17 Mar - 19 Mar',
      name: 'Sugar Cosmetics',
      address: 'Loreal, Sector-22, Gautam Nagar, Raipur, Chhattisgarh',
    },
  ];

  const photos = [
    'https://i.pinimg.com/736x/ea/9d/46/ea9d46323473d7713eb19a495fe956c4.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv8fH0a8cejEZmiETT_nV5F-LXrsw-Ksqc7A&s',
    'https://down-id.img.susercontent.com/file/efc4f6f503f0765d9c0a61622ce46927',
    'https://i.pinimg.com/550x/b6/19/ff/b619fff8fe37807fe033baa02374e033.jpg',
    'https://static.theprint.in/wp-content/uploads/2022/01/worlds-leading-hair-removal-brand-nair-signs-on-bollywood-actress-mrunal-thakur-as-brand-ambassador.jpg',
    'https://live.staticflickr.com/3002/5750478430_746fe38236_b.jpg',
    'https://m.media-amazon.com/images/I/71VQNyAWdmL.jpg',
    'https://newspaperads.ads2publish.com/wp-content/uploads/2017/10/stars-cosmetics-the-choice-og-bollywood-leading-makeup-artists-ad-times-of-india-pune-20-10-2017.jpg',
    'https://www.adgully.com/img/800/202212/khatija-lokhandwala-2022-12-07t104155-044.png.jpg',
    'https://i.pinimg.com/736x/ea/9d/46/ea9d46323473d7713eb19a495fe956c4.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv8fH0a8cejEZmiETT_nV5F-LXrsw-Ksqc7A&s',
    'https://down-id.img.susercontent.com/file/efc4f6f503f0765d9c0a61622ce46927',
    'https://i.pinimg.com/550x/b6/19/ff/b619fff8fe37807fe033baa02374e033.jpg',
    'https://static.theprint.in/wp-content/uploads/2022/01/worlds-leading-hair-removal-brand-nair-signs-on-bollywood-actress-mrunal-thakur-as-brand-ambassador.jpg',
    'https://live.staticflickr.com/3002/5750478430_746fe38236_b.jpg',
    'https://m.media-amazon.com/images/I/71VQNyAWdmL.jpg',
    'https://newspaperads.ads2publish.com/wp-content/uploads/2017/10/stars-cosmetics-the-choice-og-bollywood-leading-makeup-artists-ad-times-of-india-pune-20-10-2017.jpg',
    'https://www.adgully.com/img/800/202212/khatija-lokhandwala-2022-12-07t104155-044.png.jpg',
  ];

  const followers = [
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      username: 'siddharth06@',
      followedBy: 'Avinash & 5 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      username: 'aarav_sharma@',
      followedBy: 'Priya & 2 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      username: 'nishapatel12@',
      followedBy: 'Rahul & 3 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      username: 'rahulverma89@',
      followedBy: 'Siddharth & 1 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      username: 'priya_mehta@',
      followedBy: 'Nisha & 4 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Siddharth Dongre',
      username: 'siddharth06@',
      followedBy: 'Avinash & 5 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Aarav Sharma',
      username: 'aarav_sharma@',
      followedBy: 'Priya & 2 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Nisha Patel',
      username: 'nishapatel12@',
      followedBy: 'Rahul & 3 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Rahul Verma',
      username: 'rahulverma89@',
      followedBy: 'Siddharth & 1 More'
    },
    {
      profile: `${BASE_URL_STATIC}/profile-img1.jpg`,
      name: 'Priya Mehta',
      username: 'priya_mehta@',
      followedBy: 'Nisha & 4 More'
    },
  ];


  const handleCommentPopup = (post) => {
    if (post._id) {
      setShowPostsPopup(true);
      setPostsPopupData(post);
      setPostId(post._id)
    }
  };

  // To handle post share
  const handlePostShare = (postId, data) => {
    if (postId) {
      setPostId(postId);
    }
    setShareData(data)
    setShowPostSharePopup(true);
  };

  useEffect(() => {
    getAllBusinessPost( 1, config).then((res) => {
      setPosts(res.posts)
    })
  }, [])

  useEffect(() => {
    checkIsItMyPage(paramsPageId);
    getPages(paramsPageId);
  }, [paramsPageId]);


  return (
    <>

      {/* Post image popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postsPopupData} postId={postId} />

      {/* Profile User > Three Dot > Share Popup */}
      <PostSharePopup shareData={shareData} showPostSharePopup={showPostSharePopup} setShowPostSharePopup={setShowPostSharePopup} />

      <section className='row'>
        {/* Main > Left */} 
        <div className='bus_left col-12 order-1 order-lg-0 col-lg-6 px-0'>
          {/* Posts */}
          <div className='rounded-3 px-2 px-lg-5'>
            {
              posts.length > 0 
              ?
              posts.map((post, index) => (
                <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }} key={index}>
                  <div className='d-flex align-items-center'>


                    <img src={AwsFileUrl + post.business_img} className='rounded-circle' style={{ height: '43px', width: '43px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>{post.business_name}</h6>
                      <span className='text-primary'>Sponsored</span>
                    </div>

                    <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
                  </div>

                  {post.type === 'text' ?
                    <div className="text_post mb-4 mt-2">
                      <ReadMoreData>
                        <div dangerouslySetInnerHTML={{ __html: post.description }}></div>
                      </ReadMoreData>
                    </div>

                    :
                    <div className="textArea mt-3">
                      <ReadMore>
                        {post.description}
                      </ReadMore>
                    </div>}

                  {/* <img src={post} className='w-100 object-fit-contain bg-secondary-subtle' style={{ maxHeight: '60vh' }} alt="post" /> */}
                  {/* <img src={post} className='w-100 object-fit-contain' alt="post" /> */}

                  <Slider {...feedslider}>
                    {post._id && post.mediaUrls.map((image, index) => (
                      <ViewPosts showPostsPopup={showPostsPopup} post={post} index={index} fileType={image.filetype} imageSrc={image.url && image.url} vidSrc={image.url && image.url} key={image._id} mediaTags={image.tags} trendingfeed={trendingfeed} />
                    ))}
                  </Slider>

                  {post.business_about && post.business_about.websiteLink && <div className='d-flex justify-content-between align-items-center my-3'>
                    <div>
                      <h6 className='fw-semibold mb-0'>{post.business_about.websiteLink}</h6>
                      <span >
                        <ReadMore>{post.business_about.aboutPage}</ReadMore>
                      </span>
                      {/* <span >{post.business_about.aboutPage}</span> */}
                    </div>

                    <button type="button" className='btn btn-primary'>
                      <Link to={post.business_about.websiteLink} target='_blank' className='text-white text-decoration-none' >
                        Visit Us
                      </Link>
                    </button>
                  </div>}

                  <hr className='hr bg-secondary' />

                  {/* View, Like, Comment and Share */}
                  <div className='d-flex align-items-center justify-content-between'>
                    {/* View */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/view-2.svg`} className='me-2' alt="view" />
                      <span>{post.viewcount}</span>
                    </div>

                    {/* Like  */}
                    <div className='text-center'>
                      <LikeButton postedBy={post.postedBy} likecount={post.likecount} postId={post._id} postType={post.type} />
                    </div>

                    {/* Comment */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/comment-1.svg`} className='me-2 cp' alt="view" onClick={() => handleCommentPopup(post)} />
                      <span>{post.commentcount}</span>
                    </div>

                    {/* Share */}
                    <div className='text-center'>
                      <img src={`${BASE_URL_STATIC}/icon/share-1.svg`} className='me-2 cp' alt="view" onClick={() => handlePostShare(post._id, post)} />
                      {/* <span>{post.share}</span> */}
                    </div>

                    {/* Boost Ad */}
                  
                      <button type="button" className='btn btn-light shadow-sm'  onClick={() => handleClick(post)} >
                    
                      <img src={`/images/business/icons/boost-1-blue.svg`} className='shadow-sm me-2 cp' style={{ height: '25px' }} alt="boost" />
                      <span className='text-primary'>Boost Ad</span>
                    </button>
                 
                  </div>
                </div>
              )) 
              :
              <div className='d-flex flex-column gap-3 align-items-center justify-content-center mt-3'>
                <img src="/images/business/icons/add-circle-gray.svg" style={{ height: '100px' }} alt="add" />

                <span className='text-secondary text-center w-75'>Start engaging with your audience! Create your first post to share updates, news, or introduce your page.</span>
              </div>
            }
          </div>
        </div>

        {/* Main > Right */}
        <div className='bus_right col-12 order-0 order-lg-1 col-lg-6 px-0'>
          {/* Ads */}
          <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }}>
            <div className='d-flex justify-content-between mb-3'>
              <div>
                <h6 className='fw-semibold mb-0'>Ads</h6>
                <span className='text-secondary'>Lorem ipsum dolor sit amet.</span>
              </div>

              <span className='text-primary'>See All</span>
            </div>

            {/* <div className='d-flex overflow-x-scroll scrollbar-hidden'> */}
            <Slider {...adsSetting} className='business-home-ads-slick'>
              {
                ads.map((ad, index) => (
                  <div className='p-3 bg-white rounded-3 shadow-sm' style={{ width: '300px' }} key={index}>
                    <div className='d-flex align-items-center'>
                      <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                      <div className='ms-3'>
                        <h6 className='fw-semibold mb-0'>Intresting Facts</h6>
                        <span className='text-primary'>Sponsored</span>
                      </div>
                    </div>

                    <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus, tempora.</span>

                    <img src="https://exchange4media.gumlet.io/news-photo/101148-MaricoHairCare.jpg" className='rounded-3 my-2' alt="ads" />

                    <span className='fw-semibold'>www.example.com</span>
                    <span>Lorem ipsum dolor sit amet.</span>

                    <button type="button" className='btn btn-primary btn-sm w-100'>Visit Us</button>
                  </div>
                ))
              }
            </Slider>
            {/* </div> */}
          </div>

          {/* Messages */}
          <div className='p-3 mb-3 rounded-3 bg-white shadow-sm'>
            <div className='d-flex justify-content-between mb-3'>
              <h6 className='fw-semibold mb-0'>Messages</h6>
              <span className='text-primary'>See All</span>
            </div>

            <div className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '250px' }}>
              {
                messages.map((message, index) => (
                  <div className='d-flex align-items-center mb-3 cursor-pointer' key={index}>
                    <img src={message.profile} className='rounded-circle' style={{ height: '40px', width: '40px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>{message.name}</h6>
                      <span className='text-secondary'>{message.message}</span>
                    </div>

                    <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
                  </div>
                ))
              }
            </div>
          </div>

          {/* Events */}
          <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }}>
            <div className='d-flex justify-content-between'>
              <h6 className='fw-semibold mb-0'>Events</h6>
              <span className='text-primary'>See All</span>
            </div>

            <span className='mb-3 text-secondary'>Lorem ipsum dolor sit amet.</span>

            <div className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '300px' }}>
              {
                events.map((event, index) => (
                  <div className='d-flex mb-3' key={index}>
                    <img src={event.image} className='rounded-3 object-fit-cover' style={{ width: '100px' }} alt="profile" />

                    <div className='ms-3'>
                      <p className='text-secondary'>{event.time}</p>
                      <h6 className='fw-semibold mb-0'>{event.name}</h6>
                      <p className='text-secondary'>{event.address}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {/* Photos */}
          <div className='p-3 mb-3 rounded-3 bg-white shadow-sm'>
            <div className='d-flex justify-content-between mb-3'>
              <h6 className='fw-semibold mb-0'>Photos</h6>
              <span className='text-primary'>See All</span>
            </div>

            <div className='business-home-photos overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '312px' }}>
              {
                photos.map((photo, index) => (
                  <div className='border px-0 position-relative shadow-sm rounded-3' style={{ height: '150px' }} key={index}>
                    <img src={photo} className='rounded-3 object-fit-cover h-100 w-100' alt="profile" />

                    <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} className='position-absolute top-0 end-0 p-1 rounded-1 mt-2 me-2' style={{ height: '17px', backgroundColor: '#c2c3c3' }} alt="post" />
                  </div>
                ))
              }
            </div>
          </div>

          {/* New Followers */}
          <div className='p-3 mb-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f6f7f7' }}>
            <div className='d-flex justify-content-between mb-3'>
              <h6 className='fw-semibold mb-0'>New Followers</h6>
              <span className='text-primary'>See All</span>
            </div>

            <div className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '300px' }}>
              {
                followers.map((follower, index) => (
                  <div className='d-flex align-items-center mb-3' key={index}>
                    <img src={follower.profile} className='rounded-circle border border-primary p-1' style={{ height: '60px', width: '60px' }} alt="profile" />

                    <div className='ms-3 me-auto'>
                      <h6 className='fw-semibold mb-0'>{follower.name}</h6>
                      <p className='text-secondary'>{follower.username}</p>
                      <p className='text-secondary'>Followed by {follower.followedBy}</p>
                    </div>

                    <button type="button" className='btn btn-primary ms-3'>Follow</button>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BusinessHomePosts
