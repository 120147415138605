import React, { useContext, useState } from "react";
import { ApiContext } from "../../../../UseContext/ApiCall";
import Modal from 'react-bootstrap/Modal';
import StoryGalleryTags from "./StoryGalleryTags";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StoryEmiloGallaryPost = ({ selectedData, setSelectedData, open, close }) => {

  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([])
  const [loader, setLoader] = useState(false)

  const {
    setShowStoriesEmiloGalleryPopup,
    createUserStories,
    setShowStoriesAdvanceSettingPopup,
    setStoriesAdvanceSetting,
    config
  } = useContext(ApiContext);

  // To handle create stories
  const handleCreateStories = async () => {
    setLoader(true);
    await selectedData.forEach((file, i) => {
      createUserStories(file, file.type, tagsByCarouselItem[i], "gallery")
        .then(() => {
          setSelectedData([])
          close()
          setShowStoriesEmiloGalleryPopup(false);
          setLoader(false);
        }).catch((error) => {
          setLoader(false);
          console.error("Error creating stories:", error);
        });
    });
    setStoriesAdvanceSetting({
      visibility: 'public',
      expire: '24',
      like: 'on',
      comment: 'on',
    });
  };

  return (
    <>
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call create-stories-popup" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className='p-3'>
          {/* Header */}
          <div className='d-flex mt-3'>
            <span className='cursor-pointer' onClick={() => { close(); }}>⮜</span>
            <h5 className='mb-0 mx-auto fw-semibold'>Add to Stories</h5>
          </div>

          <StoryGalleryTags close={close} config={config} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} setPhotoVideos={setSelectedData} photoVideos={selectedData} />


          {/* Button */}
          <div className="text-center mt-4">
            <button type="button" className="btn btn-primary w-50 rounded-5" disabled={loader} onClick={handleCreateStories}>
              {
                loader ?
                  <span>
                    <FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin />{" "}Adding...
                  </span>
                  :
                  'Add to Stories'}

            </button>
          </div>

          {/* Advance Setting */}
          <div className="text-center">
            <small className="text-primary cursor-pointer" onClick={() => {
              setShowStoriesAdvanceSettingPopup(true);
            }}>Advance Setting</small>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoryEmiloGallaryPost;