import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BASEAPIURL } from '../../../config';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import toast from 'react-hot-toast';


export const businessContext = createContext();


const BusinessContext = ({ children }) => {

  const [paramsPageId, setParamsPageId] = useState('');

  const { config } = useContext(ApiContext);

  const pageConfig = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("pageToken")}`,
      // 'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json',
    },
  };


  // ---------- Basic Info ----------

  const [pageBasicInfo, setPageBasicInfo] = useState({
    pageName: '',
    pageType: '',
    pageCategory: '',
    pageSubCategory: '',
  });

  const categories = [
    {
      category: "Technology",
      subcategories: [
        "Web Development",
        "Mobile Apps",
        "AI & Machine Learning",
        "Blockchain",
        "Software"
      ]
    },
    {
      category: "Fashion",
      subcategories: [
        "Clothing",
        "Accessories",
        "Footwear",
        "Jewelry",
        "Beauty Products"
      ]
    },
    {
      category: "Food & Beverage",
      subcategories: [
        "Restaurants",
        "Cafes",
        "Bakeries",
        "Organic Foods",
        "Beverages"
      ]
    },
    {
      category: "Health & Fitness",
      subcategories: [
        "Gyms",
        "Yoga Centers",
        "Nutrition",
        "Wellness Products",
        "Physical Therapy"
      ]
    },
    {
      category: "Education",
      subcategories: [
        "Schools",
        "Colleges",
        "Online Courses",
        "Tutoring",
        "Skill Development"
      ]
    },
    {
      category: "Finance",
      subcategories: [
        "Banking",
        "Investment",
        "Insurance",
        "Accounting",
        "Cryptocurrency"
      ]
    },
    {
      category: "Entertainment",
      subcategories: [
        "Movies",
        "Music",
        "TV Shows",
        "Events",
        "Theater"
      ]
    },
    {
      category: "Real Estate",
      subcategories: [
        "Residential",
        "Commercial",
        "Rental Services",
        "Property Management",
        "Architecture"
      ]
    },
    {
      category: "Travel & Tourism",
      subcategories: [
        "Hotels",
        "Flights",
        "Tour Operators",
        "Car Rentals",
        "Travel Guides"
      ]
    },
    {
      category: "Sports",
      subcategories: [
        "Cricket",
        "Football",
        "Hockey",
      ]
    },
  ];

  const [myPages, setMyPages] = useState({
    businessPages: [],
    publicFigurePages: [],
  });  

  const [pages, setPages] = useState({
    businessPages: [],
    publicFigurePages: [],
  });  


  // To handle create business or public figure page
  const handleCreatePage = () => {
    axios.post(`${BASEAPIURL}/page/createPage`, { pageBasicInfo }, config)
    .then(res => {
      if(res.data.message === 'Page created successfully') {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      };

      localStorage.setItem('pageId', res.data.pageData._id);
      localStorage.setItem('pageToken', res.data.pageToken);

      window.location.href = `${pageBasicInfo.pageType === 'businessPage' ? '/business' : '/public-figure'}/${res.data.pageData._id}`;
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // Get logged in user (business or pubilc figure) pages to show in header dropdown
  const getMyPages = () => {
    axios.post(`${BASEAPIURL}/page/getMyPages`, {}, config)
    .then(res => {
      setMyPages({
        businessPages: res.data.businessPages || [],
        publicFigurePages: res.data.publicFigurePages || [],
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // Get page user (business or pubilc figure) pages to show in left block accordion
  const getPages = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPages`, { paramsPageId })
      .then(res => {
        setPages({
          businessPages: res.data.businessPages || [],
          publicFigurePages: res.data.publicFigurePages || [],
        });
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };



  // ---------- Home ----------

  const [showUpdateProfilePopup, setShowUpdateProfilePopup] = useState(false);
  const [showAddPageBannerPopup, setShowAddPageBannerPopup] = useState(false);
  const [showCreatePostPopup, setShowCreatePostPopup] = useState(false);
  const [showEditPagePopup, setShowEditPagePopup] = useState(false);  
  const [pageDetails, setPageDetails] = useState({});
  const [addedPageBanners, setAddedPageBanners] = useState([]);
  const [pageBanners, setPageBanners] = useState([]);
  const [isItMyPage, setIsItMyPage] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchPages, setSearchPages] = useState([]);
  
  const pageId = localStorage.getItem('pageId'); 

  // get search pages
  const getSearchPages = (value) => {
    if(value !== '') {
      axios.post(`${BASEAPIURL}/page/getSearchPages`, { value })
      .then(res => {
        // console.log('res : ', res.data.pages);
  
        setSearchPages(res.data.pages);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      setSearchPages([]);
    };
  };

  // get page token
  const getPageToken = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageToken`, { paramsPageId }, config)
      .then(res => {
        // console.log('res : ', res.data);

        toast.success(res.data.message);

        if(res.data.pageToken) {
          localStorage.setItem('pageToken', res.data.pageToken);
        } else {
          localStorage.removeItem('pageToken');
        };
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };
  
  // Get page details
  const getPageDetails = (paramsPageId) => {    
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageDetails`, { paramsPageId })
      .then(res => {
        setPageDetails(res.data.pageDetails);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // Upload page profile
  const uploadPageProfile = (profile) => {
    setShowUpdateProfilePopup(false);

    const formData = new FormData();

    formData.append('profile', profile); 

    axios.post(`${BASEAPIURL}/page/uploadPageProfile`, formData, pageConfig)
    .then((res) => {
      // console.log('res : ', res.data);

      getPageDetails(paramsPageId);
    })
    .catch((err) => {
      console.error('err : err', err);
    });
  };

  // delete page profile
  const deletePageProfile = () => {
    setPageDetails((prevDetails) => ({
      ...prevDetails,
      imageId: 'default/add-image-icon.jpg'
    }));

    axios.post(`${BASEAPIURL}/page/deletePageProfile`, {}, pageConfig)
    .then((res) => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);
    })
    .catch((err) => {
      console.error('err : err', err);
    });
  };

  // create page banner 
  const createPageBanner = (banners) => {
    const formData = new FormData();

    for (let i = 0; i < banners.length; i++) {
      formData.append('banners', banners[i]);
    };

    axios.post(`${BASEAPIURL}/page/createPageBanner`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddPageBannerPopup(false);

      getPageBanner(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get page banner
  const getPageBanner = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageBanner`, { paramsPageId })
      .then(res => {
        // console.log('res : ', res.data);
  
        setAddedPageBanners(res.data.banners);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // delete page banner
  const deletePageBanner = (banner) => {
    axios.post(`${BASEAPIURL}/page/deletePageBanner`, { banner }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      getPageBanner(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // To check is it my page
  const checkIsItMyPage = (paramsPageId) => {    
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/checkIsItMyPage`, { paramsPageId }, config)
      .then(res => {
        // console.log('res : ', res.data);
  
        setIsItMyPage(res.data.checkIsItMyPage);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };





  // ---------- Home > About ----------

  const [showBioPopup, setShowBioPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showMissionVisionPopup, setShowMissionVisionPopup] = useState(false);
  const [showCategoryPopup, setShowCategoryPopup] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showContactInfoMobilePopup, setShowContactInfoMobilePopup] = useState(false);
  const [showContactInfoEmailPopup, setShowContactInfoEmailPopup] = useState(false);
  const [showDealerPopup, setShowDealerPopup] = useState(false);
  const [showWebsiteEmailPopup, setShowWebsiteEmailPopup] = useState(false)
  const [showActiveHoursPopup, setShowActiveHoursPopup] = useState(false);
  const [BoostPostId, setBoostPostId] = useState([]);

  const [pageAbout, setPageAbout] = useState({
    bio: '',

    yearOfEstablishment: '',
    aboutPage: '',

    mission: '',
    vission: '',

    category: '',
    subCategory: '',

    address: [],

    contactInfo: {
      mobile:  [],
      email:  [],
    },

    dealer: '',

    websiteLink: '',
    email: '',

    milestone: [],

    activeHours: [],
  });

  console.log('pageAbout : ', pageAbout.activeHours);
  
  const [newAddress, setNewAddress] = useState({
    name: 'Home',
    line1: '',
    line2: '',
    state: '',
    city: '',
    pincode: '',
  });

  const [cities, setCities] = useState([]);

  const stateCityData = [
    { state: 'Andhra Pradesh', cities: ['Visakhapatnam', 'Vijayawada', 'Guntur', 'Nellore'] },
    { state: 'Arunachal Pradesh', cities: ['Itanagar', 'Naharlagun', 'Tawang', 'Pasighat'] },
    { state: 'Assam', cities: ['Guwahati', 'Dibrugarh', 'Silchar', 'Tezpur'] },
    { state: 'Bihar', cities: ['Patna', 'Gaya', 'Bhagalpur', 'Muzaffarpur'] },
    { state: 'Chhattisgarh', cities: ['Raipur', 'Bhilai', 'Korba', 'Bilaspur'] },
    { state: 'Goa', cities: ['Panaji', 'Margao', 'Vasco da Gama', 'Mapusa'] },
    { state: 'Gujarat', cities: ['Ahmedabad', 'Surat', 'Vadodara', 'Rajkot'] },
    { state: 'Haryana', cities: ['Faridabad', 'Gurgaon', 'Panipat', 'Ambala'] },
    { state: 'Himachal Pradesh', cities: ['Shimla', 'Manali', 'Dharamshala', 'Solan'] },
    { state: 'Jharkhand', cities: ['Ranchi', 'Jamshedpur', 'Dhanbad', 'Bokaro'] },
    { state: 'Karnataka', cities: ['Bengaluru', 'Mysuru', 'Mangaluru', 'Hubballi'] },
    { state: 'Kerala', cities: ['Thiruvananthapuram', 'Kochi', 'Kozhikode', 'Thrissur'] },
    { state: 'Madhya Pradesh', cities: ['Bhopal', 'Indore', 'Gwalior', 'Jabalpur'] },
    { state: 'Maharashtra', cities: ['Mumbai', 'Pune', 'Nagpur', 'Nashik'] },
    { state: 'Manipur', cities: ['Imphal', 'Thoubal', 'Bishnupur', 'Churachandpur'] },
    { state: 'Meghalaya', cities: ['Shillong', 'Tura', 'Jowai', 'Nongstoin'] },
    { state: 'Mizoram', cities: ['Aizawl', 'Lunglei', 'Serchhip', 'Champhai'] },
    { state: 'Nagaland', cities: ['Kohima', 'Dimapur', 'Mokokchung', 'Tuensang'] },
    { state: 'Odisha', cities: ['Bhubaneswar', 'Cuttack', 'Rourkela', 'Sambalpur'] },
    { state: 'Punjab', cities: ['Ludhiana', 'Amritsar', 'Jalandhar', 'Patiala'] },
    { state: 'Rajasthan', cities: ['Jaipur', 'Jodhpur', 'Udaipur', 'Kota'] },
    { state: 'Sikkim', cities: ['Gangtok', 'Namchi', 'Gyalshing', 'Mangan'] },
    { state: 'Tamil Nadu', cities: ['Chennai', 'Coimbatore', 'Madurai', 'Tiruchirappalli'] },
    { state: 'Telangana', cities: ['Hyderabad', 'Warangal', 'Nizamabad', 'Karimnagar'] },
    { state: 'Tripura', cities: ['Agartala', 'Dharmanagar', 'Udaipur', 'Kailashahar'] },
    { state: 'Uttar Pradesh', cities: ['Lucknow', 'Kanpur', 'Agra', 'Varanasi'] },
    { state: 'Uttarakhand', cities: ['Dehradun', 'Haridwar', 'Rishikesh', 'Nainital'] },
    { state: 'West Bengal', cities: ['Kolkata', 'Asansol', 'Siliguri', 'Durgapur'] }
  ];

  const [contactInfo, setContactInfo] = useState({
    name: '',
    mobile: '',
    email: '',
  });
  


  
  // create page website link & email
  const createWebsiteEmail = () => {
    axios.post(`${BASEAPIURL}/page/createWebsiteEmail`, { websiteLink: pageAbout.websiteLink }, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      setShowWebsiteEmailPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page dealer
  const createDealer = () => {
    axios.post(`${BASEAPIURL}/page/createDealer`, { dealer: pageAbout.dealer }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowDealerPopup(false);

      setPageAbout((prevState) => ({
        ...prevState,
        dealer: res.data.dealer,
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page contact info
  const createContactInfo = (type) => {
    const info = (type === 'mobile') ? contactInfo.mobile :  contactInfo.email;

    axios.post(`${BASEAPIURL}/page/createContactInfo`, { _id: contactInfo._id, name: contactInfo.name, [type]: info }, pageConfig)
    .then(res => {
      console.log('res : ', res.data); 

      // it will update in front end
      setContactInfo({
        name: '',
        mobile: '',
        email: '',
      });

      // it will close popup accordingly type
      if(type === 'mobile') {
        setShowContactInfoMobilePopup(false);
      } else {
        setShowContactInfoEmailPopup(false);
      };

      getContactInfo();
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get page contact info
  const getContactInfo = () => {
    axios.post(`${BASEAPIURL}/page/getContactInfo`, {}, pageConfig)
    .then(res => {
      console.log('res : ', res.data.contactInfo); 

      setPageAbout(prevState => ({
        ...prevState,
        contactInfo: {
          mobile: res.data.contactInfo.mobile,
          email: res.data.contactInfo.email,
        },
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  }; 

  // delete contact info
  const deleteContactInfo = (infoId) => {        
    axios.post(`${BASEAPIURL}/page/deleteContactInfo`, { infoId }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      // Remove the data from front end
      setPageAbout(prevState => ({
        ...prevState,
        contactInfo: {
          mobile: prevState.contactInfo.mobile.filter(info => info._id !== infoId),
          email: prevState.contactInfo.email.filter(info => info._id !== infoId),
        },
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page address
  const createPageAddress = () => {
    if(newAddress.name !== '' && newAddress.line1 !== '' && newAddress.line2 !== '' && newAddress.state !== '' && newAddress.city !== '' && newAddress.pincode !== '') {
      axios.post(`${BASEAPIURL}/page/createPageAddress`, { newAddress }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data); 
  
        toast.success(res.data.message);
  
        setShowAddressPopup(false);

        setNewAddress({
          name: 'Home',
          line1: '',
          line2: '',
          state: '',
          city: '',
          pincode: '',
        });

        getPageAddress();
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Fill Form Properly');
    };
  };

  // get page address
  const getPageAddress = () => {
    axios.post(`${BASEAPIURL}/page/getPageAddress`, {}, pageConfig)
    .then(res => {
      console.log('res : ', res.data.address); 

      setPageAbout((prevState) => ({
        ...prevState,
        address: res.data.address
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  }; 

  // delete page address
  const deletePageAddress = (addressId) => {
    axios.post(`${BASEAPIURL}/page/deletePageAddress`, { addressId }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      // Remove address from front end
      setPageAbout((prevState) => ({
        ...prevState,
        address: prevState.address.filter(add => add._id !== addressId)
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page category & sub category
  const createCategorySubCategory = () => {
    if(pageAbout.category !== '' && pageAbout.subCategory !== '') {
      axios.post(`${BASEAPIURL}/page/createCategorySubCategory`, { category: pageAbout.category, subCategory: pageAbout.subCategory }, pageConfig)
      .then(res => {
        console.log('res : ', res.data); 
  
        toast.success(res.data.message);
  
        setShowCategoryPopup(false);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      toast.error('Select Sub Category');
    };
  };

  // create page mission & vission
  const createMissionVission = () => {
    axios.post(`${BASEAPIURL}/page/createMissionVission`, { mission: pageAbout.mission, vission: pageAbout.vission }, pageConfig)
    .then(res => {
      console.log('res : ', res.data); 

      toast.success(res.data.message);

      setShowMissionVisionPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page info
  const createPageInfo = () => {
    axios.post(`${BASEAPIURL}/page/createPageInfo`, { yearOfEstablishment: pageAbout.yearOfEstablishment, aboutPage: pageAbout.aboutPage }, pageConfig)
    .then(res => {
      console.log('res : ', res.data); 

      toast.success(res.data.message);

      setShowInfoPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // create page bio
  const createPageBio = async () => {
    await axios.post(`${BASEAPIURL}/page/createPageBio`, { pageBio: pageAbout.bio }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowBioPopup(false);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // To get business page > about page details
  const getPageAboutDetails = async (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageAboutDetails`, { paramsPageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        setPageAbout((prevState) => ({
          ...prevState,
          bio: res.data.bio,
  
          yearOfEstablishment: res.data.yearOfEstablishment,
          aboutPage: res.data.aboutPage,
  
          mission: res.data.mission,
          vission: res.data.vission,
          
          category: res.data.category,
          subCategory: res.data.subCategory,
  
          address: res.data.address,
  
          contactInfo: {
            mobile: res.data.contactInfo.mobile,
            email: res.data.contactInfo.email,
          },
  
          dealer: res.data.dealer,
  
          websiteLink: res.data.websiteLink,
          email: res.data.email,
  
          milestone: res.data.milestone,
  
          activeHours: res.data.activeHours,
        }));
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  

  // ---------- Home > Review ----------

  const [showWriteReveiwPopup, setShowWriteReveiwPopup] = useState(false);
  const [showReplyReviewPopup, setShowReplyReviewPopup] = useState(false);
  const [addNewReview, setAddNewReview] = useState({
    rating: '',
    review: '',
  });
  const [pageReviews, setPageReviews] = useState([]);
  const [replyReviewData, setReplyReviewData] = useState();

  // create page review 
  const createPageReview = (paramsPageId) => {
    axios.post(`${BASEAPIURL}/page/createPageReview`, { addNewReview, pageId: paramsPageId }, config)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowWriteReveiwPopup(false);

      setAddNewReview({
        rating: '',
        review: '',
      });

      getPageReviews(paramsPageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get page reviews
  const getPageReviews = (paramsPageId) => {
    if(paramsPageId !== '') {
      axios.post(`${BASEAPIURL}/page/getPageReviews`, { paramsPageId }, config)
      .then(res => {
        // console.log('res : ', res.data);
  
        setPageReviews(res.data.reviews);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // reply to a review 
  const replyReview = () => {
    axios.post(`${BASEAPIURL}/page/replyReview`, { replyReviewData }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowReplyReviewPopup(false);

      // Update reply to front end
      setPageReviews(prevReviews => {
        const updatedReviews = prevReviews.map(review => {
          if (review._id === replyReviewData._id) {
            return {
              ...review,
              reply: replyReviewData.reply, 
              updatedAt: new Date().toISOString() 
            };
          }
          return review; 
        });

        return updatedReviews;
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // like dislike review
  const likeDislikeReview = (review) => {    
    axios.post(`${BASEAPIURL}/page/likeDislikeReview`, { reviewId: review._id, postedBy: review.userId._id }, config)
    .then(res => {
      console.log('res : ', res);
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  // ---------- Event ----------

  const [showCreateEventPopup, setShowCreateEventPopup] = useState(false);
  const [showUpdateEventPopup, setShowUpdateEventPopup] = useState(false);
  const [showAddEventHostsPopup, setShowAddEventHostsPopup] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [eventOrganiserPage, setEventOrganiserPage] = useState({});
  const [eventHostsList, setEventHostsList] = useState([]);

  const [newEvent, setNewEvent] = useState({
    image: null,
    title: '',
    subTitle: '',
    about: '',
    mode: 'virtual',
    address: '',
    mobile: '',
    email: '',
    host: '',
    websiteLink: '',
    ticketLink: '',
    repeatEvent: 'off',
    repeatEventOption: 'daily',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    rsvp: 'off',
    boostEvent: 'off',
  });

  const [searchedHostsList, setSearchedHostsList] = useState([]);
  const [selectedHostsList, setSelectedHostsList] = useState([]);
  

  // create event
  const createEvent = () => {
    const formData = new FormData();
    
    for (const key in newEvent) {
      formData.append(key, newEvent[key]);
    };

    formData.append("selectedHostsList", JSON.stringify(selectedHostsList));

    axios.post(`${BASEAPIURL}/page/createEvent`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res);

      setShowCreateEventPopup(false);

      setNewEvent({
        image: null,
        title: '',
        subTitle: '',
        about: '',
        mode: 'virtual',
        address: '',
        mobile: '',
        email: '',
        host: '',
        websiteLink: '',
        ticketLink: '',
        repeatEvent: 'off',
        repeatEventOption: 'daily',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        rsvp: 'off',
        boostEvent: 'off',
      });

      setSelectedHostsList([]);

      getMyEvents();
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get my events
  const getMyEvents = () => {
    axios.post(`${BASEAPIURL}/page/getMyEvents`, {}, pageConfig)
    .then(res => {
      // console.log('res : ', res.data.myEvents);

      setMyEvents(res.data.myEvents);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // update my event
  const updateEvent = () => {
    axios.post(`${BASEAPIURL}/page/updateEvent`, { event }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowUpdateEventPopup(false);

      // getEvent(res.data.eventId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete my event 
  const deleteEvent = (eventId) => {
    axios.post(`${BASEAPIURL}/page/deleteEvent`, { eventId }, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      window.location.href = `/business/my-events/${paramsPageId}`;
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get events
  const getEvents = () => {
    axios.post(`${BASEAPIURL}/page/getEvents`, {}, pageConfig)
    .then(res => {
      // console.log('res : ', res.data.events);

      setEvents(res.data.events);
    }).catch(err => {
      console.log('err : ', err); 
    });
  };

  // get event 
  const getEvent = (eventId) => {
    if(eventId !== '') {
      axios.post(`${BASEAPIURL}/page/getEvent`, { eventId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data.event);

        setEvent(res.data.event);

        setEvent(preState => ({ ...preState, myEvent: res.data.myEvent }));
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // get event organiser page
  const getEventOrganiserPage = (eventId) => {
    axios.post(`${BASEAPIURL}/page/getEventOrganiserPage`, { eventId })
    .then(res => {
      // console.log('res : ', res.data);

      setEventOrganiserPage(res.data.page);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get searched hosts (while creating event)
  const getSearchedHosts = (value) => {    
    if(value !== '') {
      axios.post(`${BASEAPIURL}/page/getSearchedHosts`, { value })
      .then(res => {
        // console.log('res : ', res.data.users);
  
        setSearchedHostsList(res.data.users);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      setSearchedHostsList([]);
    };
  };

  // get searched hosts (after creating event, it will show filtered hosts)
  const getHostsToAdd = (value, eventId) => {
    if(value !== '') {
      axios.post(`${BASEAPIURL}/page/getHostsToAdd`, { value, eventId })
      .then(res => {
        // console.log('res : ', res.data.users);

        setSearchedHostsList(res.data.users);
      }).catch(err => {
        console.log('err : ', err);
      });
    } else {
      setSearchedHostsList([]);
    };
  };

  // add event hosts
  const addEventHosts = (eventId) => {
    axios.post(`${BASEAPIURL}/page/addEventHosts`, { eventId, selectedHostsList }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddEventHostsPopup(false);

      setNewEvent(preState => ({ ...preState, host: '' }));
      setSearchedHostsList([]);
      setSelectedHostsList([]);

      getEventHosts(eventId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get event hosts
  const getEventHosts = (eventId) => {    
    axios.post(`${BASEAPIURL}/page/getEventHosts`, { eventId })
    .then(res => {
      // console.log('res : ', res.data);

      setEventHostsList(res.data.hosts);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete event host
  const deleteEventHost = (hostId) => {
    axios.post(`${BASEAPIURL}/page/deleteEventHost`, { hostId }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      getEventHosts(res.data.eventId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // update event banner 
  const updateEventBanner = (file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('eventId', event._id);

    axios.post(`${BASEAPIURL}/page/updateEventBanner`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setEvent(preState => ({ ...preState, image: res.data.image }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };




  // ---------- Home > Product & Services > Product ----------

  const [showAddProductPopup, setShowAddProductPopup] = useState(false);
  const [showEditProductPopup, setShowEditProductPopup] = useState(false);
  const [showViewProductPopup, setShowViewProductPopup] = useState(false);

  const [product, setProduct] = useState({
    images: [],
    title: '',
    description: '',
    price: '',
    link: '',
    keyProduct: 'false',
  });

  const [productImages, setProductImages] = useState([]);

  const [products, setProducts] = useState([]);


  // create product
  const createProduct = () => {
    const formData = new FormData();

    formData.append('title', product.title);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('link', product.link);
    formData.append('keyProduct', product.keyProduct);

    productImages.forEach(image => {
      formData.append(`images`, image); 
    });

    axios.post(`${BASEAPIURL}/page/createProduct`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddProductPopup(false);

      setProductImages([]);
      
      setProduct({
        images: [],
        title: '',
        description: '',
        price: '',
        link: '',
        keyProduct: 'false',
      });

      getProducts(res.data.pageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get products
  const getProducts = (pageId) => {
    if(pageId !== '') {
      axios.post(`${BASEAPIURL}/page/getProducts`, { pageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        // toast.success(res.data.message);
  
        setProducts(res.data.products);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // update product
  const updateProduct = () => {
    const formData = new FormData();

    Object.keys(product).forEach((key) => {
      formData.append(key, product[key]);
    });

    productImages.forEach(image => {
      formData.append(`images`, image);
    });

    axios.post(`${BASEAPIURL}/page/updateProduct`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowEditProductPopup(false);

      getProducts(product.pageId);

      setProductImages([]);

      setProduct({
        images: [],
        title: '',
        description: '',
        price: '',
        link: '',
        keyProduct: 'false',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete product image
  const deleteProductImage = (value) => {
    axios.post(`${BASEAPIURL}/page/deleteProductImage`, { value }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setProduct((prevProduct) => ({
        ...prevProduct,
        images: prevProduct.images.filter((image) => image._id !== value._id),
      }));

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product._id === value.productId
          ? 
          {
            ...product,
            images: product.images.filter((image) => image._id !== value._id),
          }
          : 
          product
        )
      );
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete product
  const deleteProduct = () => {
    axios.post(`${BASEAPIURL}/page/deleteProduct`, { productId: product._id }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowViewProductPopup(false);

      setProducts(prevProducts => prevProducts.filter(p => p._id !== product._id));

      setProduct({
        images: [],
        title: '',
        description: '',
        price: '',
        link: '',
        keyProduct: 'false',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };


  // ---------- Home > Product & Service > Service ----------

  const [showAddServicePopup, setShowAddServicePopup] = useState(false);
  const [showEditServicePopup, setShowEditServicePopup] = useState(false);
  const [showViewServicePopup, setShowViewServicePopup] = useState(false);

  const [service, setService] = useState({
    images: [],
    title: '',
    description: '',
    price: '',
    number: '',
  });

  const [serviceImages, setServiceImages] = useState([]);

  const [services, setServices] = useState([]);


  // create service
  const createService = () => {
    const formData = new FormData();

    formData.append('title', service.title);
    formData.append('description', service.description);
    formData.append('price', service.price);
    formData.append('number', service.number);

    serviceImages.forEach(image => {
      formData.append(`images`, image); 
    });

    axios.post(`${BASEAPIURL}/page/createService`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddServicePopup(false);

      setServiceImages([]);
      
      setService({
        images: [],
        title: '',
        description: '',
        price: '',
        number: '',
      });

      getServices(res.data.pageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get services
  const getServices = (pageId) => {
    if(pageId !== '') {
      axios.post(`${BASEAPIURL}/page/getServices`, { pageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        // toast.success(res.data.message);
  
        setServices(res.data.services);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // update service
  const updateService = () => {
    const formData = new FormData();

    Object.keys(service).forEach((key) => {
      formData.append(key, service[key]);
    });

    serviceImages.forEach(image => {
      formData.append(`images`, image);
    });

    axios.post(`${BASEAPIURL}/page/updateService`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowEditServicePopup(false);

      getServices(service.pageId);

      setServiceImages([]);

      setService({
        images: [],
        title: '',
        description: '',
        price: '',
        number: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete service image
  const deleteServiceImage = (value) => {
    console.log('value : ', value);

    axios.post(`${BASEAPIURL}/page/deleteServiceImage`, { value }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setService((prevService) => ({
        ...prevService,
        images: prevService.images.filter((image) => image._id !== value._id),
      }));

      setServices((prevServices) =>
        prevServices.map((service) =>
          service._id === value.serviceId
          ? 
          {
            ...service,
            images: service.images.filter((image) => image._id !== value._id),
          }
          : 
          service
        )
      );
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete service
  const deleteService = () => {
    axios.post(`${BASEAPIURL}/page/deleteService`, { serviceId: service._id }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowViewServicePopup(false);

      setServices(prevServices => prevServices.filter(p => p._id !== service._id));

      setService({
        images: [],
        title: '',
        description: '',
        price: '',
        number: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };


  // ---------- Home > Job & Frenchise > Job ----------

  const [showAddJobPopup, setShowAddJobPopup] = useState(false);
  const [showEditJobPopup, setShowEditJobPopup] = useState(false);
  const [showViewJobPopup, setShowViewJobPopup] = useState(false);

  const [job, setJob] = useState({
    images: [],
    title: '',
    description: '',
    salary: '',
    experience: 'fresher',
  });

  const [jobImages, setJobImages] = useState([]);

  const [jobs, setJobs] = useState([]);


  // create job
  const createJob = () => {
    const formData = new FormData();

    formData.append('title', job.title);
    formData.append('description', job.description);
    formData.append('salary', job.salary);
    formData.append('experience', job.experience);

    jobImages.forEach(image => {
      formData.append(`images`, image); 
    });

    axios.post(`${BASEAPIURL}/page/createJob`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddJobPopup(false);

      setJobImages([]);
      
      setJob({
        images: [],
        title: '',
        description: '',
        salary: '',
        experience: 'fresher',
      });

      getJobs(res.data.pageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get jobs
  const getJobs = (pageId) => {
    if(pageId !== '') {
      axios.post(`${BASEAPIURL}/page/getJobs`, { pageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        // toast.success(res.data.message);
  
        setJobs(res.data.jobs);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // update job
  const updateJob = () => {
    const formData = new FormData();

    Object.keys(job).forEach((key) => {
      formData.append(key, job[key]);
    });

    jobImages.forEach(image => {
      formData.append(`images`, image);
    });

    axios.post(`${BASEAPIURL}/page/updateJob`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowEditJobPopup(false);

      getJobs(job.pageId);

      setJobImages([]);

      setJob({
        images: [],
        title: '',
        description: '',
        salary: '',
        experience: 'fresher',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete job image
  const deleteJobImage = (value) => {
    axios.post(`${BASEAPIURL}/page/deleteJobImage`, { value }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setJob((prevJob) => ({
        ...prevJob,
        images: prevJob.images.filter((image) => image._id !== value._id),
      }));

      setJobs((prevJobs) =>
        prevJobs.map((job) =>
          job._id === value.jobId
          ? 
          {
            ...job,
            images: job.images.filter((image) => image._id !== value._id),
          }
          : 
          job
        )
      );
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete job
  const deleteJob = () => {
    axios.post(`${BASEAPIURL}/page/deleteJob`, { jobId: job._id }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowViewJobPopup(false);

      setJobs(prevJobs => prevJobs.filter(p => p._id !== job._id));

      setJob({
        images: [],
        title: '',
        description: '',
        salary: '',
        experience: 'fresher',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  // ---------- Home > Job & Frenchise > Franchise ----------

  const [showAddFranchisePopup, setShowAddFranchisePopup] = useState(false);
  const [showEditFranchisePopup, setShowEditFranchisePopup] = useState(false);
  const [showViewFranchisePopup, setShowViewFranchisePopup] = useState(false);

  const [franchise, setFranchise] = useState({
    images: [],
    title: '',
    description: '',
  });

  const [franchiseImages, setFranchiseImages] = useState([]);

  const [franchises, setFranchises] = useState([]);


  // create franchise
  const createFranchise = () => {
    const formData = new FormData();

    formData.append('title', franchise.title);
    formData.append('description', franchise.description);

    franchiseImages.forEach(image => {
      formData.append(`images`, image); 
    });

    axios.post(`${BASEAPIURL}/page/createFranchise`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddFranchisePopup(false);

      setFranchiseImages([]);
      
      setFranchise({
        images: [],
        title: '',
        description: '',
      });

      getFranchises(res.data.pageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get franchises
  const getFranchises = (pageId) => {
    if(pageId !== '') {
      axios.post(`${BASEAPIURL}/page/getFranchises`, { pageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        // toast.success(res.data.message);
  
        setFranchises(res.data.franchises);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // update franchise
  const updateFranchise = () => {
    const formData = new FormData();

    Object.keys(franchise).forEach((key) => {
      formData.append(key, franchise[key]);
    });

    franchiseImages.forEach(image => {
      formData.append(`images`, image);
    });

    axios.post(`${BASEAPIURL}/page/updateFranchise`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowEditFranchisePopup(false);

      getFranchises(franchise.pageId);

      setFranchiseImages([]);

      setFranchise({
        images: [],
        title: '',
        description: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete franchise image
  const deleteFranchiseImage = (value) => {
    axios.post(`${BASEAPIURL}/page/deleteFranchiseImage`, { value }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setFranchise((prevFranchise) => ({
        ...prevFranchise,
        images: prevFranchise.images.filter((image) => image._id !== value._id),
      }));

      setFranchises((prevFranchises) =>
        prevFranchises.map((franchise) =>
          franchise._id === value.franchiseId
          ? 
          {
            ...franchise,
            images: franchise.images.filter((image) => image._id !== value._id),
          }
          : 
          franchise
        )
      );
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete franchise
  const deleteFranchise = () => {
    axios.post(`${BASEAPIURL}/page/deleteFranchise`, { franchiseId: franchise._id }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowViewFranchisePopup(false);

      setFranchises(prevFranchises => prevFranchises.filter(p => p._id !== franchise._id));

      setFranchise({
        images: [],
        title: '',
        description: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };


  // ---------- Home > About > Milestone ----------

  const [showAddMilestonePopup, setShowAddMilestonePopup] = useState(false);
  const [showEditMilestonePopup, setShowEditMilestonePopup] = useState(false);
  const [showViewMilestonePopup, setShowViewMilestonePopup] = useState(false);


  const [milestone, setMilestone] = useState({
    images: [],
    title: '',
    date: '',
    description: '',
  });

  const [milestoneImages, setMilestoneImages] = useState([]);


  // create milestone
  const createMilestone = () => {
    const formData = new FormData();

    formData.append('title', milestone.title);
    formData.append('date', milestone.date);
    formData.append('description', milestone.description);

    milestoneImages.forEach(image => {
      formData.append(`images`, image); 
    });

    axios.post(`${BASEAPIURL}/page/createMilestone`, formData, pageConfig)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowAddMilestonePopup(false);

      setMilestoneImages([]);
      
      setMilestone({
        images: [],
        title: '',
        date: '',
        description: '',
      });

      getMilestones(res.data.pageId);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get milestones
  const getMilestones = (pageId) => {
    if(pageId !== '') {
      axios.post(`${BASEAPIURL}/page/getMilestones`, { pageId }, pageConfig)
      .then(res => {
        // console.log('res : ', res.data);
  
        // toast.success(res.data.message);
  
        setPageAbout(prevState => ({
          ...prevState,
          milestone: res.data.milestones,
        }));
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // update milestone
  const updateMilestone = () => {
    const formData = new FormData();

    Object.keys(milestone).forEach((key) => {
      formData.append(key, milestone[key]);
    });

    milestoneImages.forEach(image => {
      formData.append(`images`, image);
    });

    axios.post(`${BASEAPIURL}/page/updateMilestone`, formData, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowEditMilestonePopup(false);

      getMilestones(milestone.pageId);

      setMilestoneImages([]);

      setMilestone({
        images: [],
        title: '',
        date: '',
        description: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete milestone image
  const deleteMilestoneImage = (value) => {
    axios.post(`${BASEAPIURL}/page/deleteMilestoneImage`, { value }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setMilestone((prevMilestone) => ({
        ...prevMilestone,
        images: prevMilestone.images.filter((image) => image._id !== value._id),
      }));

      setPageAbout((prevState) => ({
        ...prevState,
        milestone: prevState.milestone.map((milestone) =>
          milestone._id === value.milestoneId
            ? {
                ...milestone,
                images: milestone.images.filter((image) => image._id !== value._id),
              }
            : milestone
        ),
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete milestone
  const deleteMilestone = () => {
    axios.post(`${BASEAPIURL}/page/deleteMilestone`, { milestoneId: milestone._id }, pageConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowViewMilestonePopup(false);

      setPageAbout((prevState) => ({
        ...prevState,
        milestone: prevState.milestone.filter((p) => p._id !== milestone._id),
      }));

      setMilestone({
        images: [],
        title: '',
        date: '',
        description: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  // ---------- Home > About > Active Hours ----------

  const [activeHour, setActiveHour] = useState({
    sun: 'false',
    mon: 'false',
    tue: 'false',
    wed: 'false',
    thu: 'false',
    fri: 'false',
    sat: 'false',
    startTime: '',
    endTime: '',
  });

  console.log('activeHour : ', activeHour);

  // create active hour
  const createActiveHour = () => {
    axios.post(`${BASEAPIURL}/page/createActiveHour`, { activeHour }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      setShowActiveHoursPopup(false);

      // add active hour in front end
      if(res.data.activeHour) {
        setPageAbout((prev) => ({
          ...prev,
          activeHours: [res.data.activeHour, ...prev.activeHours],
        }));
      };

      // update active hour in front end
      if(activeHour._id) {
        setPageAbout((prev) => ({
          ...prev,
          activeHours: prev.activeHours.map((hour) => (hour._id === activeHour._id ? activeHour : hour)),
        }));
      };

      setActiveHour({
        sun: 'false',
        mon: 'false',
        tue: 'false',
        wed: 'false',
        thu: 'false',
        fri: 'false',
        sat: 'false',
        startTime: '',
        endTime: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete active hour
  const deleteActiveHour = (activeHourId) => {
    axios.post(`${BASEAPIURL}/page/deleteActiveHour`, { activeHourId }, pageConfig)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      // remove active hour from the front end
      setPageAbout((prev) => ({
        ...prev,
        activeHours: prev.activeHours.filter((hour) => hour._id !== activeHourId),
      }));
    }).catch(err => {
      console.log('err : ', err);
    });
  };




  useEffect(() => {
    getPageToken(paramsPageId);
  }, [paramsPageId]);


  const Value = {

    pageConfig,

    paramsPageId, setParamsPageId,

    // ---------- Home > About > Milestone ----------

    showAddMilestonePopup, setShowAddMilestonePopup,
    showEditMilestonePopup, setShowEditMilestonePopup,
    showViewMilestonePopup, setShowViewMilestonePopup,
    milestone, setMilestone,
    milestoneImages, setMilestoneImages,
    createMilestone,
    getMilestones,
    updateMilestone,
    deleteMilestoneImage,
    deleteMilestone,


    // ---------- Home > Job & Frenchise > Frenchise ----------

    showAddFranchisePopup, setShowAddFranchisePopup,
    showEditFranchisePopup, setShowEditFranchisePopup,
    showViewFranchisePopup, setShowViewFranchisePopup,
    franchise, setFranchise,
    franchiseImages, setFranchiseImages,
    franchises, setFranchises,
    createFranchise,
    getFranchises,
    updateFranchise,
    deleteFranchiseImage,
    deleteFranchise,


    // ---------- Home > Job & Frenchise > Job ----------

    showAddJobPopup, setShowAddJobPopup,
    showEditJobPopup, setShowEditJobPopup,
    showViewJobPopup, setShowViewJobPopup,
    job, setJob,
    jobImages, setJobImages,
    jobs, setJobs,
    createJob,
    getJobs,
    updateJob,
    deleteJobImage,
    deleteJob,


    // ----- Home > Product & Service > Service -----

    showAddServicePopup, setShowAddServicePopup,
    showEditServicePopup, setShowEditServicePopup,
    showViewServicePopup, setShowViewServicePopup,
    service, setService,
    serviceImages, setServiceImages,
    services, setServices,
    createService,
    getServices,
    updateService,
    deleteServiceImage,
    deleteService,


    // ----- Home > Product & Service > Product -----

    showAddProductPopup, setShowAddProductPopup,
    showEditProductPopup, setShowEditProductPopup,
    showViewProductPopup, setShowViewProductPopup,
    product, setProduct,
    productImages, setProductImages,
    products, setProducts,
    createProduct,
    getProducts,
    updateProduct,
    deleteProductImage,
    deleteProduct,


    // ----- Home > Event -----
    showCreateEventPopup, setShowCreateEventPopup,
    showUpdateEventPopup, setShowUpdateEventPopup,
    showAddEventHostsPopup, setShowAddEventHostsPopup,
    newEvent, setNewEvent,
    myEvents, setMyEvents,
    events, setEvents,
    event, setEvent,
    eventOrganiserPage, setEventOrganiserPage,
    searchedHostsList, setSearchedHostsList,
    selectedHostsList, setSelectedHostsList,
    eventHostsList, setEventHostsList,
    createEvent,
    updateEvent,
    deleteEvent,
    getMyEvents, getEvents, getEvent, 
    getEventOrganiserPage, getSearchedHosts,
    getEventHosts, getHostsToAdd,
    addEventHosts, deleteEventHost,
    updateEventBanner,


    // ---------- Basic Info ----------
    pageBasicInfo, setPageBasicInfo,
    myPages, setMyPages,
    pages, setPages,
    categories,
    handleCreatePage,
    getMyPages,
    getPages,


    // ----- Home -----
    showUpdateProfilePopup, setShowUpdateProfilePopup,
    showAddPageBannerPopup, setShowAddPageBannerPopup,
    showCreatePostPopup, setShowCreatePostPopup,
    showEditPagePopup, setShowEditPagePopup,
    pageDetails, setPageDetails,
    addedPageBanners, setAddedPageBanners,
    pageBanners, setPageBanners,
    isItMyPage, setIsItMyPage,
    searchText, setSearchText,
    searchPages, setSearchPages,
    getSearchPages,
    pageId,
    uploadPageProfile,
    deletePageProfile,
    createPageBanner,
    deletePageBanner,
    checkIsItMyPage,
    getPageDetails,
    getPageBanner,


    // ----- Home > About -----
    showBioPopup, setShowBioPopup,
    showInfoPopup, setShowInfoPopup,
    showMissionVisionPopup, setShowMissionVisionPopup,
    showCategoryPopup, setShowCategoryPopup,
    showAddressPopup, setShowAddressPopup,
    showContactInfoMobilePopup, setShowContactInfoMobilePopup,
    showContactInfoEmailPopup, setShowContactInfoEmailPopup,
    showDealerPopup, setShowDealerPopup,
    showWebsiteEmailPopup, setShowWebsiteEmailPopup,
    showActiveHoursPopup, setShowActiveHoursPopup,

    pageAbout, setPageAbout,
    newAddress, setNewAddress,
    cities, setCities,
    contactInfo, setContactInfo,
    activeHour, setActiveHour,
    stateCityData,
    createPageBio,
    createPageInfo,
    createMissionVission,
    createCategorySubCategory,
    createPageAddress, deletePageAddress,
    createContactInfo, deleteContactInfo,
    createDealer,
    createWebsiteEmail,
    createActiveHour,
    deleteActiveHour,
    getPageAboutDetails,


    // ----- Home > Review -----
    showWriteReveiwPopup, setShowWriteReveiwPopup,
    showReplyReviewPopup, setShowReplyReviewPopup,
    addNewReview, setAddNewReview,
    pageReviews, setPageReviews,
    replyReviewData, setReplyReviewData,
    createPageReview,
    getPageReviews,
    replyReview,
    likeDislikeReview,

    //***BoostPost*****/
    BoostPostId, setBoostPostId


  };

  return (<businessContext.Provider value={Value}>{children}</businessContext.Provider>);
}

export default BusinessContext;