import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import * as cam from '@mediapipe/camera_utils';

const ArVrBgEffectsSection = ({ bgVideoUrl, windowWidth, windowHeight, canvasRef, selectedCamera }) => {
  const videoRef = useRef(null);
  const backgroundVideoRef = useRef(null);

  useEffect(() => {
    const videoElement = document.getElementById('inputVideo');
    const canvasElement = document.getElementById('outputCanvas');

    // Adjust dimensions for mobile screens
    if (windowWidth < 1000) {
      videoElement.width = windowWidth;
      videoElement.height = windowHeight;

      // Ensure canvas matches video dimensions
      canvasElement.width = videoElement.width;
      canvasElement.height = videoElement.height;
    }

    // Initialize SelfieSegmentation
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });

    selfieSegmentation.setOptions({
      modelSelection: 1, // 0 for general-purpose, 1 for close-up
    });

    selfieSegmentation.onResults((results) => {
      if (!canvasRef.current) return;
      const canvasCtx = canvasRef.current.getContext('2d') || canvasRef.current.getContext('webgl');
      const canvasWidth = canvasRef.current.width;
      const canvasHeight = canvasRef.current.height;

      // Step 1: Draw the video background
      canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear previous frames

      if (backgroundVideoRef.current && !backgroundVideoRef.current.paused) {
        const videoWidth = backgroundVideoRef.current.videoWidth;
        const videoHeight = backgroundVideoRef.current.videoHeight;

        const videoAspectRatio = videoWidth / videoHeight;
        const canvasAspectRatio = canvasWidth / canvasHeight;

        let drawWidth, drawHeight, offsetX, offsetY;

        // Adjust dimensions to "cover" the canvas
        if (videoAspectRatio > canvasAspectRatio) {
          drawHeight = canvasHeight;
          drawWidth = videoWidth * (canvasHeight / videoHeight);
          offsetX = -(drawWidth - canvasWidth) / 2; // Center horizontally
          offsetY = 0;
        } else {
          drawWidth = canvasWidth;
          drawHeight = videoHeight * (canvasWidth / videoWidth);
          offsetX = 0;
          offsetY = -(drawHeight - canvasHeight) / 2; // Center vertically
        }

        canvasCtx.drawImage(backgroundVideoRef.current, offsetX, offsetY, drawWidth, drawHeight);
      }

      // Step 2: Apply the segmentation mask to cut out the person from the background
      canvasCtx.globalCompositeOperation = 'destination-out';
      canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasWidth, canvasHeight);

      // Step 3: Draw the unblurred person (foreground)
      canvasCtx.globalCompositeOperation = 'destination-over';
      canvasCtx.filter = 'none'; // Remove blur for the person
      canvasCtx.drawImage(results.image, 0, 0, canvasWidth, canvasHeight);

      // Step 4: Reset composite operation to default
      canvasCtx.globalCompositeOperation = 'source-over';
    });

    // Initialize the camera feed with the selected device
    if (videoRef.current) {
      const startCamera = async () => {
        try {
          // Stop any existing video streams
          const existingStream = videoRef.current.srcObject;
          if (existingStream) {
            existingStream.getTracks().forEach((track) => track.stop());
          }

          // Get the video stream for the selected camera
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedCamera ? { exact: selectedCamera } : undefined },
          });

          // Assign the stream to the video element
          videoRef.current.srcObject = stream;

          // Initialize MediaPipe camera with the video element
          const camera = new cam.Camera(videoRef.current, {
            onFrame: async () => {
              await selfieSegmentation.send({ image: videoRef.current });
            },
          });
          camera.start();
        } catch (err) {
          console.error('Error starting camera:', err);
        }
      };

      startCamera();
    }

    // Play the background video
    if (backgroundVideoRef.current && bgVideoUrl) {
      backgroundVideoRef.current.src = bgVideoUrl;
      backgroundVideoRef.current.play();
    }
  }, [bgVideoUrl, selectedCamera]);

  return (
    <>
      <div>
        {/* Hidden camera feed */}
        <video
          id="inputVideo"
          ref={videoRef}
          style={{ display: 'none' }}
          autoPlay
          muted
          className="videoCreateARVR"
        />
        
        {/* Canvas for AR effects */}
        <canvas id="outputCanvas" ref={canvasRef} className="canvasCreateARVR" />

        {/* Background video */}
        <video
          ref={backgroundVideoRef}
          style={{ display: 'none' }}
          loop
          muted
          playsInline
        />
      </div>
    </>
  );
};

ArVrBgEffectsSection.propTypes = {
  bgVideoUrl: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  windowHeight: PropTypes.number.isRequired,
  canvasRef: PropTypes.object.isRequired,
  selectedCamera: PropTypes.string,
};

export default ArVrBgEffectsSection;
