import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { BASEAPIURL } from '../../../../../config';
import { profileContext } from '../../../UseContext/ProfileContext';
import { ApiContext } from '../../../UseContext/ApiCall';
import axios from 'axios';

const BioPop = ({ show, close, userProfile }) => {

  const { bioInput, setBioInput } = useContext(profileContext);
  const [loader, setLoader] = useState(false)
  const { config } = useContext(ApiContext)

  const user = { ...userProfile, bio: bioInput };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    updateUser(user).then((result) => {
      
      setBioInput(bioInput)
      setLoader(false)
      close();
    });
  };

  const updateUser = async () => {
    try {
      // Assuming education is an array of education objects
      const res = await axios.post(
        `${BASEAPIURL}/user/updateuserprofile`, { ...user }, config );
      return res.data;
    } catch (error) {
      console.error("Error creating hangout:", error);
    }
  };

  return (
    <>
      <section>
        <Modal show={show} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={handleSubmit} className='d-flex flex-column justify-content-center '>
              <h5>Bio</h5>
              <textarea rows='8' defaultValue={bioInput && bioInput} onChange={(e) => setBioInput(e.target.value)} placeholder='write your bio here...'
                style={{ border: '2px solid #d3d3d3', width: "85%", margin: "auto", padding: "10px", borderRadius: "10px" }}
              />
              <div className='d-flex justify-content-center'>
                <button type="submit" className="btn btn-dark mt-4 col-lg-4">{loader ? "saving..." : "save"}</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default BioPop;