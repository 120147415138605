import React, { useEffect, useState, useContext } from 'react'
import ProfileImage from '../UseContext/ProfileImage';
import { getBestClipById, shareBestclipCount } from '../UseContext/ApiHelpers';
import CopyToClipboard from "react-copy-to-clipboard";

import { Link, useParams } from 'react-router-dom';
import { BASEAPIURL, BASEURL, BASE_URL_STATIC } from '../../../config';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { ApiContext } from '../UseContext/ApiCall';
import LikeBestClipButton from '../Button/LikeButton/LikeBestClipButton';


const BestclipSharePage = () => {

   const { bestclipId } = useParams();

   const [bestclip, setBestClip] = useState([])
   const [userId, setUserId] = useState()
   const [showCopyLinkUserName, setshowCopyLinkUserName] = useState('');

   const [isBestClipFavourite, setIsBestClipFavourite] = useState(false);

   const { config } = useContext(ApiContext);



   useEffect(() => {
      getBestClipById(bestclipId).then(res => {
         setUserId(res && res[0] && res[0].userId);
         setBestClip(res);
      })
   }, [bestclipId])

   useEffect(() => {
      getShowCopyLinkUser(userId)
   }, [userId])

   const getShowCopyLinkUser = (userId) => {
      axios.post(`${BASEAPIURL}/user/getoneuser`, { userId } , config)
         .then(res => {
            setshowCopyLinkUserName(res.data.user.fname);
         })
         .catch(err => {
            return null;
         });
   };

   // To check post is added to favourite or not
   const checkFavouriteBestClip = (bestClipId, config) => {
      axios.post(`${BASEAPIURL}/post/checkfevbestclip`, { bestclipId: bestClipId }, config)
         .then(res => {
            setIsBestClipFavourite(res.data.added);
         })
         .catch(err => {
            return null;
         });
   };


   useEffect(() => {
      checkFavouriteBestClip(bestclipId, config);
   }, [bestclipId]);

   // To add post to favourite
   const createFavouriteBestClip = (bestClipId, config) => {
      let action = isBestClipFavourite ? 'add' : 'remove';

      axios.post(`${BASEAPIURL}/post/createfavbestclip`, { bestclipId: bestClipId, action: action }, config)
         .then(res => {
            setIsBestClipFavourite(!isBestClipFavourite);
         })
         .catch(err => {
            return null;
         });
   };

   return (
      <div>
         {

            bestclip.map((clip, index) => (
               <section key={index}>
                  <section className="flex" style={{ border: '1px solid black', width: '1077px', padding: "20px", margin: '100px auto 0px' }}>
                     {
                        (clip.details && clip.details.length === 1)
                           ?
                           <div className='bestClipDetails'>
                              {
                                 <section className='best-video-wrap'>
                                    <video controls autoPlay muted loop >
                                       <source src={`${BASEAPIURL}/post/getvideobyid?bestclipVideoId=${clip.details[0].videoId}`} />
                                    </video>
                                 </section>
                              }
                           </div>
                           :
                           <Carousel className='memeCarousel' slide={false}>
                              {clip.details && clip.details.map((detail, index) => (
                                 <Carousel.Item key={index}>
                                    <div className='bestClipDetails' >
                                       {
                                          detail.videoId &&
                                          <section className='best-video-wrap'>
                                             <video
                                                controls
                                                autoPlay
                                                muted
                                                loop
                                             >
                                                <source
                                                   src={`${BASEAPIURL}/post/getvideobyid?bestclipVideoId=${detail.videoId}`}
                                                />
                                             </video>
                                          </section>
                                       }
                                    </div>
                                 </Carousel.Item>
                              ))}
                           </Carousel>
                     }

                     <section>
                        <div className="d-flex" style={{ width: "455px", justifyContent: "space-between" }} >
                           <div className="d-flex" style={{ marginTop: "10px" }}>
                              <div style={{ margin: "0px 10px", }} >
                                 <ProfileImage userId={userId} height='45' width='45' borderRadius='50%' />
                              </div>
                              <div>
                                 <Link to={`${BASEURL}/profile/${userId}`} style={{ color: 'black' }}>{showCopyLinkUserName}</Link>
                              </div>
                           </div>

                           <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" style={{ cursor: "pointer", marginRight: "10px" }} />
                        </div>

                        <hr
                           style={{
                              height: "0.1px",
                              width: "100%",
                              backgroundColor: "gray",
                              margin: "5px 0px",
                           }}
                        />

                        <div style={{ height: "400px", width: "457px" }}>
                           <h3 style={{ textAlign: "center" }}>Description</h3>
                           <span>{clip.bestClipId && clip.description}</span>
                        </div>

                        <hr
                           style={{
                              height: "0.1px",
                              width: "100%",
                              backgroundColor: "gray",
                              margin: "5px 0px",
                           }}
                        />

                        <div
                           className="d-flex"
                           style={{ width: "455px", marginTop: "10px" }}
                        >
                           <div className="d-flex">
                              <img
                                 src={`${BASE_URL_STATIC}/feed-icon1.png`}
                                 alt="views"
                                 height="70%"
                                 width="100%"
                                 style={{ margin: "5px 5px 0px 5px" }}
                              />
                              <p style={{ margin: "2px 25px 0px 0px" }}>{clip.viewCount}</p>
                           </div>

                           <LikeBestClipButton bestClipId={bestclipId} />


                           <div className="d-flex">
                              <img
                                 src={`${BASE_URL_STATIC}/feed-icon3.png`}
                                 alt="comment"
                                 style={{ marginRight: "5px" }}
                              />
                              <p style={{ marginRight: "25px" }}>{clip.commentCount}</p>
                           </div>

                           <CopyToClipboard text={`${BASEURL}/bestClipShare/${bestclipId}`} onCopy={() => { shareBestclipCount(bestclipId); console.log("uyes copies"); }} >
                              <div className="d-flex">
                                 <img
                                    src={`${BASE_URL_STATIC}/feed-icon4.png`}
                                    alt="share"
                                    style={{ marginRight: "5px" }}
                                 />
                                 <p style={{ marginRight: "127px" }}>{clip.shareCount}</p>
                              </div>
                           </CopyToClipboard>


                           <div style={{ marginRight: "20px" }}>
                              {
                                 isBestClipFavourite
                                    ?
                                    <img src={`${BASE_URL_STATIC}/feed-icon5.png`} alt="views" onClick={() => createFavouriteBestClip(bestclipId, config)} style={{ cursor: 'pointer', }} />
                                    :
                                    <img src={`${BASE_URL_STATIC}/favourite2.png`} alt="views" onClick={() => createFavouriteBestClip(bestclipId, config)} height={25} width={25} style={{ cursor: 'pointer', }} />
                              }
                           </div>

                           <div >
                              <img src={`${BASE_URL_STATIC}/feed-icon6.png`} alt="views" />
                           </div>
                        </div>

                        <div
                           style={{
                              height: "140px",
                              width: "406px",
                              overflowY: "auto",
                              padding: "10px",
                           }}
                        >
                        </div>

                        <div
                           style={{
                              height: "100px",
                              width: "406px",
                              overflowY: "auto",
                              padding: "10px",
                           }}
                        >
                        </div>
                     </section>
                  </section>
               </section>
            ))
         }

      </div>
   )
}

export default BestclipSharePage