import React, { useContext } from 'react';
import { businessContext } from '../../../UseContext/BusinessContext';
import Modal from 'react-bootstrap/Modal';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';


const ViewServicePopup = () => {

  const {
    showViewServicePopup, setShowViewServicePopup,
    service, setService,
    deleteService,
    setServiceImages,
  } = useContext(businessContext);

  // handle popup close
  const handlePopupClose = () => {
    setShowViewServicePopup(false);

    setServiceImages([]);

    setService({
      images: [],
      title: '',
      description: '',
      price: '',
      number: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };

  return (
    <>
      <Modal show={showViewServicePopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Service</h4>

          {/* Images */}
          <div className='mb-3'>
            {
              service && service.images && service.images.length > 0 
              && 
              <Slider className="service-images-slider" {...settings}>
                {/* Live Images */}
                {
                  service.images.map((value, index) => (
                    <div className='border rounded-3' key={index}>
                      <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={service.title} placeholder='Title...' />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={service.description} placeholder='Description...'></textarea>

          {/* Price */}
          <small className='text-secondary'>Price</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='price' value={service.price} placeholder='Price...' />

          {/* Number */}
          <small className='text-secondary'>Number</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-5' name='number' value={service.number} placeholder='Number...' />

          {/* Delete */}
          <div className='text-center'>
            <button type="button" className='btn btn-danger w-50 rounded-pill' onClick={deleteService}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewServicePopup
