import React, { useContext, useEffect, useRef } from 'react';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Slider from "react-slick";
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { businessContext } from '../UseContext/BusinessContext';
import EditPagePopup from '../Popup/EditPage/EditPagePopup';
import CreateNewPostPopup from '../../Emilo/Popup/CreateNewPost/CreateNewPostPopup';
import BusinessBioPopup from '../Popup/Home/BusinessBioPopup';
import UpdateProfilePopup from '../Popup/UpdateProfilePopup';
import AddPageBannerPopup from '../Popup/AddPageBannerPopup';
import BusinessContactInfoMobilePopup from '../Popup/Home/BusinessContactInfoMobilePopup';
import BusinessContactInfoEmailPopup from '../Popup/Home/BusinessContactInfoEmailPopup';
import BusinessWebsiteEmailPopup from '../Popup/Home/BusinessWebsiteEmailPopup';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';


const BusinessHome2 = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const {
    paramsPageId,
    getPageDetails, pageDetails,
    setShowBioPopup, setShowEditPagePopup,
    showCreatePostPopup, setShowCreatePostPopup,
    addedPageBanners, getPageBanner, deletePageBanner,
    checkIsItMyPage, isItMyPage,
    setShowUpdateProfilePopup,
    setShowAddPageBannerPopup,
    setPageBanners,
    setShowContactInfoMobilePopup,
    setShowContactInfoEmailPopup,
    setShowWebsiteEmailPopup,
    searchText, setSearchText,
    searchPages, setSearchPages,
    getSearchPages,
  } = useContext(businessContext);

  const { setPostType } = useContext(ApiContext)

  const businessUserId = localStorage.getItem("businessUserId")

  // handle search page navigate
  const handleSearchPageNavigate = (pageId) => {
    navigate(`/business/${pageId}`);
    setSearchText('');
    setSearchPages([]);
  };

  const options = [
    { name: 'Posts', navigate: `/business/${paramsPageId}` },
    { name: 'About', navigate: `/business/about/${paramsPageId}` },
    { name: 'Product & Services', navigate: `/business/product-services/${paramsPageId}` },
    { name: 'Tagged', navigate: `/business/tagged/${paramsPageId}` },
    { name: 'Reviews', navigate: `/business/reviews/${paramsPageId}` },
    { name: 'Listings', navigate: `/business/listings/${paramsPageId}` },
  ];


  // ----- Profile ----- 
  // const profileInputRef = useRef(null);

  // const handleProfileClick = () => {
  //   profileInputRef.current.click(); 
  // };

  // const handleProfileChange = (e) => {
  //   const profile = e.target.files[0];

  //   if (profile) {  
  //     uploadPageProfile(profile);
  //   };
  // };


  // ----- Banner ----- 
  const bannerInputRef = useRef(null);

  const handleBannerClick = () => {
    if (bannerInputRef.current) {
      bannerInputRef.current.click();
    };
  };

  const handleBannerChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      // Update state with new files
      setPageBanners((prevBanners) => [...prevBanners, ...files]);
    };

    setShowAddPageBannerPopup(true);
  };

  const bannerSetting = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
    dots: true,
    customPaging: (i) => (
      <img
        src={addedPageBanners && addedPageBanners[i] && addedPageBanners[i].banner && AwsFileUrl + addedPageBanners[i].banner}
        className="cursor-pointer h-100 w-100 object-fit-fill"
        style={{ borderRadius: '30px' }}
        alt={`dot-${i}`}
      />
    ),
    appendDots: (dots) => (
      <div className="d-flex justify-content-center gap-3 mt-3">{dots}</div>
    ),
  };

  useEffect(() => {
    getPageBanner(paramsPageId);
    getPageDetails(paramsPageId);
    checkIsItMyPage(paramsPageId);
  }, [paramsPageId]);

  return (
    <>
      {/* Popup */}
      <>
        {/* Edit Page Popup */}
        <EditPagePopup />

        {/* Create post popup */}
        <CreateNewPostPopup showCreatePostPopup={showCreatePostPopup} setShowCreatePostPopup={setShowCreatePostPopup} />

        {/* Bio Popup */}
        <BusinessBioPopup />

        {/* Update Profile Popup */}
        <UpdateProfilePopup />

        {/* Add page banner popup */}
        <AddPageBannerPopup />

        {/* Mobile Popup */}
        <BusinessContactInfoMobilePopup />

        {/* Email Popup */}
        <BusinessContactInfoEmailPopup />

        {/* Website Popup */}
        <BusinessWebsiteEmailPopup />
      </>

      <section className='business-rb h-100 overflow-y-scroll scrollbar-hidden pt-3 px-2 px-sm-3 px-md-4 px-xl-5' style={{ backgroundColor: '#edf1f4' }}>
        {/* Search Bar, Notification, Chat and Profile */}
        <div className='position-relative bg-white d-flex justify-content-between align-items-center rounded-3 px-2 px-sm-3 px-md-4 py-3'>
          {/* Search Bar */}
          <div className='d-flex flex-grow-1 w-75 rounded-pill px-3 px-sm-4 py-1 py-sm-2 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} className='me-2 me-sm-3' alt="search" />

            <input type="search" className='w-100 bg-transparent border-0' value={searchText} onChange={(e) => { setSearchText(e.target.value); getSearchPages(e.target.value) }} placeholder='Search Pages...' />
          </div>

          {/* Show Search Pages */}
          <>
            {
              searchPages && searchPages.length > 0
                ?
                <div className='searchPages position-absolute top-100 bg-white p-2 p-sm-3 rounded-3 z-1 mt-1 overflow-y-scroll shadow'>
                  {
                    searchPages.map((page, index) => (
                      <div className='d-flex gap-3 align-items-center mb-3' key={index}>
                        <img src={AwsFileUrl + page.imageId} className='border rounded-circle cursor-pointer' style={{ height: '40px', width: '40px' }} alt="profile"
                          onClick={() => handleSearchPageNavigate(page._id)}
                        />

                        <div>
                          <h6 className='mb-0 fw-semibold cursor-pointer' onClick={() => handleSearchPageNavigate(page._id)}>{page.fname}</h6>
                          <span className='cursor-pointer' onClick={() => handleSearchPageNavigate(page._id)}>{page.pageCategory} ({page.pageSubCategory})</span>
                        </div>

                        <button type="button" className='btn btn-primary btn-sm ms-auto'>Follow</button>
                      </div>
                    ))
                  }
                </div>
                :
                searchText !== ''
                &&
                <div className='searchPages position-absolute top-100 bg-white p-2 p-sm-3 rounded-3 z-1 mt-1 overflow-y-scroll shadow'>
                  <h6 className='mb-0 fw-semibold'>Page Not Found...</h6>
                </div>
            }
          </>

          {/* Notification */}
          <div className='mx-3 mx-sm-4 mx-md-5'>
            <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="notification" />
          </div>

          {/* Chat */}
          <div>
            <Link to={`/business/business_chats/${businessUserId}`}>
              <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="chat" />
            </Link>
          </div>

          {/* Profile */}
          <div className='border rounded-circle ms-3 ms-sm-4 ms-md-5' style={{ height: '33px', width: '33px' }}>
            <img src={pageDetails && AwsFileUrl + pageDetails.imageId} className='rounded-circle h-100 w-100 object-fit-contain' alt="profile" />
          </div>
        </div>


        {/* Phone, Mail and Link */}
        <div className='my-4 d-flex flex-wrap justify-content-end gap-3 gap-md-4'>
          {/* Phone */}
          <>
            {
              pageDetails && pageDetails.mobile
              &&
              <>
                {
                  pageDetails.mobile.length === 0
                  &&
                  <div className='bg-white rounded-3 px-3 shadow-sm d-flex align-items-center cursor-pointer' onClick={() => setShowContactInfoMobilePopup(true)}>
                    <img src={`/images//business/icons/phone-blue.svg`} style={{ width: '30px' }} alt="phone" />
                    <span className='ms-2 fw-semibold text-secondary'>Add Mobile No.</span>
                  </div>
                }

                {
                  pageDetails.mobile.length === 1
                  &&
                  <div className='bg-white rounded-3 px-3 shadow-sm d-flex align-items-center cursor-pointer' onClick={() => setShowContactInfoMobilePopup(true)}>
                    <img src={`/images//business/icons/phone-blue.svg`} style={{ width: '30px' }} alt="phone" />
                    <span className='ms-2 fw-semibold'>{pageDetails.mobile[0].mobile} ({pageDetails.mobile[0].name})</span>
                  </div>
                }

                {
                  Array.isArray(pageDetails.mobile) && pageDetails.mobile.length > 1
                  &&
                  <div className='bg-white rounded-3 px-3 shadow-sm d-flex gap-1 align-items-center'>
                    <img src={`/images//business/icons/phone-blue.svg`} style={{ width: '30px' }} alt="phone" />

                    <select class="form-select my-1 py-0" aria-label="Default select example">
                      {
                        pageDetails.mobile.map((value, index) => (
                          <option value={value.mobile} key={index}>{value.mobile} ({value.name})</option>
                        ))
                      }
                    </select>
                  </div>
                }
              </>
            }
          </>

          {/* Email */}
          <>
            {
              pageDetails && pageDetails.email
                ?
                <div className='bg-white rounded-3 px-3 shadow-sm d-flex align-items-center cursor-pointer' onClick={() => setShowContactInfoEmailPopup(true)}>
                  <img src={`/images//business/icons/mail-blue.svg`} style={{ width: '27px' }} alt="mail" />
                  <span className='ms-2 fw-semibold'>{pageDetails.email.email}</span>
                </div>
                :
                <div className='bg-white rounded-3 px-3 shadow-sm d-flex align-items-center cursor-pointer'>
                  <img src={`/images//business/icons/mail-blue.svg`} style={{ width: '27px' }} alt="mail" />
                  <span className='ms-2 fw-semibold text-secondary'>Add Email Id</span>
                </div>
            }
          </>

          {/* Link */}
          <>
            {
              pageDetails && pageDetails.websiteLink
                ?
                <div className='bg-white rounded-3 px-3 shadow-sm d-flex align-items-center cursor-pointer'>
                  <img src={`/images//business/icons/website-black.svg`} style={{ width: '27px' }} onClick={() => setShowWebsiteEmailPopup(true)} alt="link" />
                  <span className='ms-2 fw-semibold text-primary' onClick={() => window.open(/^https?:\/\//i.test(pageDetails.websiteLink) ? pageDetails.websiteLink : `https://${pageDetails.websiteLink}`, '_blank')}>{pageDetails.websiteLink}</span>
                </div>
                :
                <div className='bg-white rounded-3 px-3 shadow-sm d-flex align-items-center cursor-pointer' onClick={() => setShowWebsiteEmailPopup(true)}>
                  <img src={`/images//business/icons/website-black.svg`} style={{ width: '27px' }} alt="link" />
                  <span className='ms-2 fw-semibold text-secondary'>Add Website Link</span>
                </div>
            }
          </>
        </div>

        {/* Profile and Banner */}
        <div className='position-relative'>
          {/* Banner */}
          <>
            {
              addedPageBanners && addedPageBanners.length > 0
                ?
                <Slider className='business-banner-slider shadow-sm rounded-3' {...bannerSetting}>
                  {
                    addedPageBanners.map((banner, index) => (
                      <div className='position-relative' key={index}>
                        <img src={AwsFileUrl + banner.banner} className='w-100 object-fit-fill rounded-3' style={{ height: '300px' }} alt="banner" />

                        {
                          isItMyPage
                          &&
                          <div className='position-absolute top-0 end-0 mt-2 me-2 d-flex gap-2'>
                            {/* Banner > Add */}
                            {
                              addedPageBanners.length < 4
                              &&
                              <div className='p-1 rounded-3 cursor-pointer shadow-sm' style={{ backgroundColor: '#5f5f5f85' }} onClick={handleBannerClick}>
                                <img src={`/images/business/icons/add-white.svg`} style={{ width: '20px' }} alt="add-circle" />

                                <input type='file' ref={bannerInputRef} style={{ display: 'none' }} onChange={handleBannerChange} multiple />
                              </div>
                            }

                            {/* Banner > Delete */}
                            <div className='p-1 rounded-3 cursor-pointer shadow-sm' style={{ backgroundColor: '#5f5f5f85' }} onClick={() => deletePageBanner(banner)}>
                              <img src={`/images/icons/delete-white.svg`} style={{ width: '20px' }} alt="delete-red" />
                            </div>
                          </div>
                        }

                      </div>
                    ))
                  }
                </Slider>
                :
                <div className='position-relative bg-primary-subtle rounded-3 shadow-sm' style={{ height: '300px' }}>
                  <div className='position-absolute top-0 end-0 mt-2 me-2 d-flex gap-2'>
                    {/* Banner > Add */}
                    {
                      isItMyPage
                      &&
                      <div className='bg-white p-1 pt-0 rounded-circle cursor-pointer' onClick={handleBannerClick}>
                        <img src={`/images/business/icons/add-circle.svg`} style={{ width: '20px' }} alt="add-circle" />

                        <input type='file' ref={bannerInputRef} style={{ display: 'none' }} onChange={handleBannerChange} multiple />
                      </div>
                    }
                  </div>
                </div>
            }
          </>

          {/* Profile */}
          <div className='business-banner-profile position-absolute'>
            <div className='position-relative'>
              <img src={pageDetails && AwsFileUrl + pageDetails.imageId} className='business-banner-profile-img rounded-circle bg-white object-fit-contain' alt="profile" />

              {/* Edit */}
              {
                isItMyPage
                &&
                <div className='bg-secondary position-absolute top-100 start-50 translate-middle px-1 pb-1 rounded-circle cursor-pointer' onClick={() => setShowUpdateProfilePopup(true)}>
                  <img src={`/images/business/icons/camera-white.svg`} style={{ width: '20px' }} alt="camera" />
                </div>
              }
            </div>
          </div>
        </div>

        {/* Details */}
        <div className='my-4 rounded-3 shadow-sm p-3' style={{ backgroundColor: '#f5f5f5' }}>
          {/* Div 1 */}
          <div className='d-flex flex-wrap align-items-start gap-3 mb-3'>
            <div className='me-auto'>
              <h3 className='m-0 fw-semibold'>{pageDetails && pageDetails.fname}</h3>
              <span>{pageDetails && pageDetails.pageCategory} {pageDetails && pageDetails.pageSubCategory && `(${pageDetails.pageSubCategory})`}</span>
            </div>

            {
              isItMyPage
                ?
                <>
                  {/* Location  */}
                  <button type="button" className='btn border border-primary'>
                    <img src={`/images/business/icons/location-blue.svg`} style={{ width: '25px' }} alt="location" />
                    <span className='text-primary ms-2'>Location</span>
                  </button>

                  {/* Share  */}
                  <button type="button" className='btn bg-primary'>
                    <img src={`/images/business/icons/share-white.svg`} style={{ width: '25px' }} alt="share" />
                    <span className='text-white ms-2'>Share</span>
                  </button>

                  {/* Edit Page  */}
                  <button type="button" className='btn shadow-sm' onClick={() => setShowEditPagePopup(true)}>
                    <img src={`/images/business/icons/phone-blue.svg`} style={{ width: '25px' }} alt="edit page" />
                    <span className='text-primary ms-2'>Edit Page</span>
                  </button>
                </>
                :
                <>
                  {/* Follow  */}
                  <button type="button" className='btn border border-primary'>
                    <img src={`/images/business/icons/follow-red.svg`} style={{ width: '25px' }} alt="location" />
                    <span className='text-primary ms-2'>Follow</span>
                  </button>

                  {/* Message  */}
                  <button type="button" className='btn border border-primary'>
                    <img src={`/images/business/icons/message-blue-2.svg`} style={{ width: '25px' }} alt="location" />
                    <span className='text-primary ms-2'>Message</span>
                  </button>

                  {/* Location  */}
                  <button type="button" className='btn border border-primary'>
                    <img src={`/images/business/icons/location-blue.svg`} style={{ width: '25px' }} alt="location" />
                    <span className='text-primary ms-2'>Location</span>
                  </button>

                  {/* Share  */}
                  <button type="button" className='btn bg-primary'>
                    <img src={`/images/business/icons/share-white.svg`} style={{ width: '25px' }} alt="share" />
                    <span className='text-white ms-2'>Share</span>
                  </button>
                </>
            }


            {/* Three Dot */}
            <button type="button" className='btn shadow-sm'>
              <img src={`${BASE_URL_STATIC}/icon/threeDot.svg`} alt="three dot" />
            </button>
          </div>

          {/* Div 2 */}
          <div className='d-flex flex-wrap align-items-start gap-3'>
            {/* Followes */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm text-center'>
              <h5 className='mb-0'>40M</h5>
              <span className='text-secondary'>Followers</span>
            </div>

            {/* Likes */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm text-center'>
              <h5 className='mb-0'>250k</h5>
              <span className='text-secondary'>Likes</span>
            </div>

            {/* Posts */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm text-center'>
              <h5 className='mb-0'>125</h5>
              <span className='text-secondary'>Posts</span>
            </div>

            {/* Bio */}
            <div className='d-inline-block p-3 bg-white rounded-3 shadow-sm flex-grow-1'>
              <div className='mb-2 d-flex align-items-center'>
                <h5 className='me-3 d-inline mb-0'>Bio</h5>

                {
                  isItMyPage
                  &&
                  <img src={`/images/business/icons/edit-blue.svg`} className='cursor-pointer' style={{ height: '20px', width: '20px' }} onClick={() => setShowBioPopup(true)} alt="edit" />
                }
              </div>

              <>
                {
                  pageDetails
                    ?
                    <span className='text-primary'>{pageDetails.bio}</span>
                    :
                    <span className='text-secondary'>Write your bio here...</span>
                }
              </>
            </div>
          </div>
        </div>

        {/* Create Post and Search */}
        <div className='p-3 d-flex flex-wrap gap-3 rounded-3 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
          <button type='button' className='btn btn-primary rounded-pill' onClick={() => { setShowCreatePostPopup(true); setPostType('businessType') }}>
            <img src={`/images/business/icons/add-white.svg`} className='me-2' style={{ height: '20px', width: '20px' }} alt="add" />

            <span>Create Post</span>
          </button>

          <div className='d-flex flex-grow-1 align-items-center rounded-pill ps-3 ps-sm-4 py-2 bg-white shadow-sm'>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />

            <input type="search" className='w-100 bg-transparent border-0 ms-2 ms-sm-3' placeholder='Search Post, Product & Services...' />
          </div>
        </div>

        {/* Options */}
        <div className='d-flex my-4 overflow-x-scroll scrollbar-hidden pb-1'>
          {
            options.map((option, index) => (
              <div
                className={`${location.pathname === option.navigate ? 'bg-primary text-white' : 'bg-white'} shadow-sm px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer`} key={index}
                onClick={() => navigate(option.navigate)}
              >
                {option.name}
              </div>
            ))
          }
        </div>

        <Outlet />
      </section>
    </>
  );
};

export default BusinessHome2;