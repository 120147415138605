import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { data } from "../../../Data/EducationData";
import { getEducationDetails, updateEducation } from "../../../UseContext/ApiHelpers";
import { profileContext } from "../../../UseContext/ProfileContext";
import { BASE_URL_STATIC } from "../../../../../config";

const EducationEditPopup = ({ open, close, educationId, eduObj, loggedInUserId }) => {

  const { setEducationDetails } = useContext(profileContext);

  const [eduInput, setEduInput] = useState({
    schColl: "",
    instituteName: "",
    degree: "",
    startDate: "",
    endDate: "",
    board: "",
  });

  const [validationMessage, setValidationMessage] = useState(false);
  const [selection, setSelection] = useState("");
  const [years, setYears] = useState([])

  useEffect(() => {
    const newDate = new Date().getFullYear() + 5;
    const yearsArr = []
    for (let i = 0; i < 60; i++) {
      yearsArr.push(newDate - i)
    }

    setYears(yearsArr)
  }, [])

  useEffect(() => {
    setEduInput({
      schColl: eduObj.schColl,
      instituteName: eduObj.instituteName,
      degree: eduObj.degree,
      startDate: eduObj.startDate,
      endDate: eduObj.endDate,
      board: eduObj.board,
    });
    setSelection(eduObj.schColl);
  }, [eduObj])

  const handleChange = (e) => {
    setEduInput({ ...eduInput, [e.target.name]: e.target.value });
  };

  const education = { educationId, ...eduInput };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (eduInput.startDate >= eduInput.endDate) {

        setValidationMessage(true);
        setTimeout(() => {
          setValidationMessage(false);
        }, 2000);
      } else {
        await updateEducation(education).then(() => {
          getEducationDetails(loggedInUserId).then((res) => setEducationDetails(res))
          close()
        }
        );
      }
    } catch (error) {
      console.log("error occoured", error);
    }

  }

  // const handleSelectionChange = (e) => {
  //   setSelection(e.target.value);
  //   setEduInput({ ...eduInput, schColl: e.target.value });
  // };

  return (
    <>
      <section>
        <Modal
          show={open}
          onHide={close}
          centered
          backdrop="static"
          keyboard={false}
          className="create-call"
        >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
            <form action="POST" onSubmit={handleSubmit}>

              <h5 >Education</h5>

              <div className="col-lg-12">
                <span
                  className="d-flex align-items-center"
                  style={{
                    marginRight: "5px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "150px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Education Level
                  </p>
                </span>

                <div className="w-100">

                  <select
                    className="form-control m-auto"
                    defaultValue={eduObj.schColl}
                    required
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #f3f3f3",
                      marginTop: "12px",
                      textAlign: "center",
                      width: "80%"
                    }}
                  >
                    <option>{eduObj.schColl}</option>
                  </select>
                </div>

              </div>

              <div className="">
                <span
                  className="d-flex align-items-center gap-0"
                  style={{
                    margin: "5px 5px 5px 17px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "150px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Institute Name
                  </p>
                </span>
                <div className="d-flex flex-column justify-content-start gap-0">
                  <input
                    type="text"
                    className="border-0 mt-2 w-100 px-4"
                    placeholder="institute name"
                    name="instituteName"
                    onChange={handleChange}
                    defaultValue={eduObj.instituteName}
                  />
                  <hr className="w-100" style={{ background: "#f5f5f5" }} />
                </div>
              </div>

              {selection === "school" || selection === "college" ? (
                <div>
                  {selection === "school" ? (
                    <div>
                      <span
                        className="d-flex align-items-center gap-2"
                        style={{
                          margin: "5px 5px 5px 17px",
                          background: "#f5f5f5",
                          padding: "6px",
                          borderRadius: "10px",
                          width: "fit-content",

                        }}
                      >
                        <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                        <p
                          style={{
                            fontSize: "15px",
                            margin: "auto",
                            color: "#616161",
                          }}
                        >
                          Select Standard
                        </p>
                      </span>
                      <select
                        className="form-control"
                        name="degree"
                        onChange={handleChange}
                        required
                        style={{
                          fontWeight: "600",
                          border: "0px",
                          borderBottom: "2px solid #a6a6a659",
                          marginTop: "12px",
                        }}
                      >
                        {eduObj.standard && <option value={eduObj.standard}>{eduObj.standard}</option>}

                        {
                          data.educationSelect.student.standards.filter((e) => e !== eduObj.standard).map(
                            (standard, index) => (
                              <option key={index}>{standard}</option>
                            )
                          )}
                      </select>

                      <span
                        className="d-flex align-items-center"
                        style={{
                          margin: "5px 5px 5px 17px",
                          background: "#f5f5f5",
                          padding: "6px",
                          borderRadius: "10px",
                          width: "150px",
                        }}
                      >
                        <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                        <p
                          style={{
                            fontSize: "15px",
                            margin: "auto",
                            color: "#616161",
                          }}
                        >
                          Select Boards
                        </p>
                      </span>
                      <select
                        className="form-control"
                        name="board"
                        onChange={handleChange}
                        required
                        style={{
                          fontWeight: "600",
                          border: "0px",
                          borderBottom: "2px solid #a6a6a659",
                          marginTop: "12px",
                        }}
                      >
                        {eduObj.board && <option value={eduObj.board}>{eduObj.board}</option>}

                        {data.educationSelect.student.boards.filter((e) => e !== eduObj.board).map(
                          (board, index) => (
                            <option key={index}>{board}</option>
                          )
                        )}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <span
                        className="d-flex align-items-center"
                        style={{
                          margin: "5px 5px 5px 17px",
                          background: "#f5f5f5",
                          padding: "6px",
                          borderRadius: "10px",
                          width: "150px",
                        }}
                      >
                        <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                        <p
                          style={{
                            fontSize: "15px",
                            margin: "auto",
                            color: "#616161",
                          }}
                        >
                          Select Degree
                        </p>
                      </span>
                      <select
                        name="degree"
                        className="form-control"
                        id="degree"
                        onChange={handleChange}
                        required
                        style={{
                          fontWeight: "600",
                          border: "0px",
                          borderBottom: "2px solid #a6a6a659",
                          marginTop: "12px",
                        }}
                      >
                        {eduObj.degree && <option value={eduObj.degree}>{eduObj.degree}</option>}

                        {data.educationSelect.college.degrees.filter((e) => e !== eduObj.degree).map(
                          (degree, index) => (
                            <option key={index}>{degree}</option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                </div>
              ) : null}

              {selection === "other" && (
                <div className="mt-3" >
                  <span
                    className="d-flex align-items-center gap-0"
                    style={{
                      margin: "5px 5px 5px 17px",
                      background: '#f5f5f5',
                      padding: '6px',
                      borderRadius: '10px',
                      width: "150px",
                    }}
                  >
                    <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                    <p
                      style={{
                        fontSize: '15px',
                        margin: 'auto',
                        color: '#616161',
                      }}
                    >
                      Course
                    </p>
                  </span>
                  <div className='d-flex flex-column justify-content-start gap-0'>
                    <input type="text" className='border-0 mt-2 w-100 px-4' placeholder='course name' defaultValue={eduObj.degree} name='degree' onChange={handleChange} />
                    <hr className='w-100' style={{ background: '#f5f5f5' }} />
                  </div>
                </div >
              )}



              <div className="">
                <span
                  className="d-flex align-items-center gap-0"
                  style={{
                    marginRight: "5px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "80px",
                  }}
                >
                  <img src={`${BASE_URL_STATIC}/icon/calender.svg`} alt="" />

                  <span
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Date
                  </span>
                </span>
                <div className="d-flex justify-content-between align-items-center gap-0">
                  <div
                    style={{
                      background: "#d3d3d3",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "5px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <label htmlFor=""><small> Start Date</small></label>
                    <select name="startDate" className="date_select" id="" onChange={handleChange} defaultValue={eduObj.startDate}>
                      {years.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                      ))}
                    </select>

                  </div>

                  <div
                    style={{
                      background: "#d3d3d3",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "5px 20px",
                      margin: "10px 0px",
                    }}
                  >
                    <label htmlFor=""><small> End Date</small></label>
                    <select className="date_select" name="endDate" onChange={handleChange} id="" defaultValue={eduObj.endDate}>
                      {years.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                      ))}
                    </select>

                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  onClick={validationMessage ? close : null}
                  className="btn btn-dark mt-4 col-4"
                >
                  Save
                </button>
              </div>

              {validationMessage && (
                <div className="text-danger my-1 text-center">
                  <small>Start date cannot be greater than end date</small>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default EducationEditPopup;
