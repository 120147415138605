import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../../UseContext/ApiCall";
import ProfileVideosPopup from "../../Popup/Profile/ProfileShow/ProfileVideosPopup";
import { BASE_URL_STATIC, AwsFileUrl } from "../../../../config";
import { getPostData } from '../../UseContext/ApiHelpers'
import { useParams } from "react-router-dom";
import Loader from "../../../Loader";

const ProfileVideos = () => {

  const [showProfileVideosPopup, setShowProfileVideosPopup] = useState(false);
  const [profileVideosPopupData, setProfileVideosPopupData] = useState();
  const [postData, setpostData] = useState([]);
  const { profilePost, config } = useContext(ApiContext);
  const [isOtherUserAccountPrivate, setIsAccountPrivate] = useState(false)
  const [loader, setLoader] = useState(false)

  // To handle profile video popup
  const handleProfileVideosPopup = (post) => {
    setProfileVideosPopupData(post);
    setShowProfileVideosPopup(true);
  };

  const { paramsUserId } = useParams();

  useEffect(() => {
    getProfileData()
  }, [])

  const getProfileData = async () => {
    setLoader(true)
    try {
      const responsedata = await getPostData(paramsUserId, 'bestclips', config)
      if (responsedata.status === 1) {
        setIsAccountPrivate(true)
      } else {
        setpostData(responsedata.posts)
      }
      setLoader(false)
    }
    catch (error) {
      setLoader(false)
      console.error(error);
    }
  }

  if (loader) {

    return (
      <div className="position-relative">
        <Loader />
      </div>
    )
  }

  else if (isOtherUserAccountPrivate) {
    return (
      <section className="profileReels-private">
        <div className="profileReels-private-img">
          <img src={`${BASE_URL_STATIC}/accountPrivacy.png`} alt="accountPrivacy" />
        </div>

        <span className="profileReels-private-head">Account is private</span>
      </section>
    )
  }

  return (
    <>
      {/* Profile Videos Popup */}
      <ProfileVideosPopup showProfileVideosPopup={showProfileVideosPopup} setShowProfileVideosPopup={setShowProfileVideosPopup} profileVideosPopupData={profileVideosPopupData} setProfileVideosPopupData={setProfileVideosPopupData} />

      <section className="col-12 col-lg-7 p-0 mx-auto ps-3 row d-flex gap-3">
        {

          profilePost
            ?
            profilePost.map((post, index) => (
              <div className='px-0 col-2 mx-1 mb-1 mx-lg-2 mb-lg-3'>
                {
                  !post.type && post.details.length > 0
                    ?
                    <img src={post.details[0].imageUrl} alt="post" onClick={() => handleProfileVideosPopup(post)} onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-4.png`} />
                    :
                    <img src={`${BASE_URL_STATIC}/cp-4.png`} alt="post" onClick={() => handleProfileVideosPopup(post)} />
                }
              </div>


              // ----- Pahle ka hai -----
              // post.details && post.details.length !== 0 && post.details[0].videoId 
              // &&
              // <div className="profile-videos-div" key={index}>
              //   <video className="profile-videos-video" height={215} width={200} onClick={() => handleProfileVideosPopup(post)}>
              //     <source src={`https://emilonode.sjain.io/post/getvideobyid?postVideoId=${post.details[0].videoId}`} />
              //   </video>

              //   {
              //     post.details.length === 1 
              //     ?
              //     <div className="profilePosts-post-icon">
              //       <img className="profilePosts-post-icon-img" src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="icon" />
              //     </div>
              //     :
              //     <div className="profilePosts-post-icon">
              //       <img className="profilePosts-post-icon-img" src={`${BASE_URL_STATIC}/icon/white-posts.svg`} alt="icon" />
              //     </div>
              //   }
              // </div>
            ))
            :
            <div className='w-100 d-flex justify-content-center'>
              <span className="loaderMain"></span>
            </div>

        }

        {
          postData.map((item) => (
            <div key={item._id} className='px-0 col-2 mx-1 mb-1 mx-lg-2 mb-lg-3'>

              <h3>{item.title}</h3>
              <p>{item.description}</p>


              {/* Check if mediaUrls exist and map over them */}
              {item.mediaUrls && item.mediaUrls.length > 0 && (

                <div>
                  {item.mediaUrls.map((media, index) => (
                    <div key={index}>
                      {media.filetype === 'image' && (
                        <img src={`${AwsFileUrl + media.url}`} alt={item.title} className='img-fluid' />

                      )}
                      {media.filetype === "video" && (
                        <video className='img-fluid' controls>
                          <source src={`${AwsFileUrl + media.url}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>

                      )}
                      {media.filetype === 'audio' && (
                        <audio controls>
                          <source src={media.audio} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                      )}
                      {/* Add more media type handling as needed */}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        }
      </section>
    </>
  );
};

export default ProfileVideos;
