import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUtensils, faTree, faGear, faTty, faHandsAslInterpreting, faChildReaching, faShapes, faLandmark, faLandmarkDome, faMonument, faUmbrellaBeach, faCartShopping, faFilm, faAtom, faCloudSun, } from "@fortawesome/free-solid-svg-icons";

import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';
import { BASE_URL_STATIC } from "../../../config";



export const ReadMoreData = ({ children, charLimit = 350 }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const truncatedText = (htmlText, limit) => {
    const div = document.createElement('div');
    div.innerHTML = htmlText;
    const plainText = div.textContent || div.innerText || '';

    // If text length exceeds the limit, truncate
    return plainText.length > limit ? plainText.substring(0, limit) + '...' : plainText;
  };

  const content = children.props.dangerouslySetInnerHTML.__html; // Original HTML content from dangerouslySetInnerHTML

  return (
    <div>
      <div
        className="text-center position-relative"
        dangerouslySetInnerHTML={{ __html: isReadMore ? truncatedText(content, charLimit) : content }}
        style={{ whiteSpace: 'pre-wrap' }}
      />
      {content.length > charLimit && (
        <span
          onClick={toggleReadMore}
          className="position-absolute right-0 text-primary cp fs-10 fst-italic" style={{right : "46%"}}
        >
          {isReadMore ? 'Read More' : 'Show Less'}
        </span>
      )}
    </div>
  );
};


export const scResponsive =  [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]


export const dummyMusic = [
  {
     id: 1,
     audio: 'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3',
     title: 'Ve Kamleya',
     artist: 'Arjit Singh, Sherya Ghoshal',
  },
  {
     id: 2,
     audio: 'https://commondatastorage.googleapis.com/codeskulptor-assets/Epoq-Lepidoptera.ogg',
     title: 'Lepidoptera',
     artist: 'Epoq',
  },
  {
     id: 3,
     audio: 'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3',
     title: 'The Neverwritten Role Playing Game',
     artist: 'Kangaroo MusiQue',
  },
  {
     id: 4,
     audio: 'https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3',
     title: 'Galaxy Invaders',
     artist: 'Unknown',
  },
  {
     id: 5,
     audio: 'https://codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3',
     title: 'Paza Moduless',
     artist: 'Unknown',
  },
];

export const gifArray = ["https://cdn.pixabay.com/animation/2024/02/13/18/38/18-38-09-301_512.gif" , "https://cdn.pixabay.com/animation/2024/03/08/13/17/13-17-23-34_512.gif" , "https://cdn.pixabay.com/animation/2023/04/29/08/43/08-43-00-635_512.gif" , "https://cdn.pixabay.com/animation/2023/04/10/09/11/09-11-55-211_512.gif","https://cdn.pixabay.com/animation/2023/07/13/17/33/17-33-04-975_512.gif" , "https://cdn.pixabay.com/animation/2023/06/01/08/46/08-46-22-123_512.gif","https://cdn.pixabay.com/animation/2024/02/13/18/38/18-38-09-301_512.gif" , "https://cdn.pixabay.com/animation/2024/03/08/13/17/13-17-23-34_512.gif" , "https://cdn.pixabay.com/animation/2023/04/29/08/43/08-43-00-635_512.gif" , "https://cdn.pixabay.com/animation/2023/04/10/09/11/09-11-55-211_512.gif","https://cdn.pixabay.com/animation/2023/07/13/17/33/17-33-04-975_512.gif" , "https://cdn.pixabay.com/animation/2023/06/01/08/46/08-46-22-123_512.gif"]


export const DatePlannerCategory = [
  {
    "id": 1,
    "name": "Coffee Date",
  },
  {
    "id": 2,
    "name": "Romantic Dinner",
  },
  {
    "id": 3,
    "name": "Movie Night",
  },
  {
    "id": 4,
    "name": "Beach Day",
  },
  {
    "id": 5,
    "name": "Adventure Day",
  },
  {
    "id": 6,
    "name": "Game Night",
  },
  {
    "id": 8,
    "name": "Outdoor Picnic",
  },
  {
    "id": 10,
    "name": "Surprise Date",
  }
];

export const bgImages = [
  {
    name : "",
    img : ``
   },
 {
  name : "create-bg-0",
  img : `${BASE_URL_STATIC}/create-bg-0.jpg`
 },
 {
  name : "create-bg-1",
  img : `${BASE_URL_STATIC}/create-bg-1.jpg`
 },
 {
  name : "create-bg-2",
  img : `${BASE_URL_STATIC}/create-bg-2.jpg`
 },
 {
  name : "create-bg-3",
  img : `${BASE_URL_STATIC}/create-bg-3.jpg`
 },
 {
  name : "create-bg-4",
  img : `${BASE_URL_STATIC}/create-bg-4.jpg`
 },
 {
  name : "create-bg-5",
  img : `${BASE_URL_STATIC}/create-bg-5.jpg`
 },
 {
  name : "create-bg-6",
  img : `${BASE_URL_STATIC}/create-bg-6.jpg`
 },
 {
  name : "create-bg-7",
  img : `${BASE_URL_STATIC}/create-bg-7.jpg`
 },
 {
  name : "create-bg-8",
  img : `${BASE_URL_STATIC}/create-bg-8.jpg`
 },
 {
  name : "create-bg-9",
  img : `${BASE_URL_STATIC}/create-bg-9.jpg`
 },
 {
  name : "create-bg-11",
  img : `${BASE_URL_STATIC}/create-bg-11.jpg`
 },

];

export const data = {
  educationSelect: {
    student: {
      "standards": ["V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"],
      "boards": ["State Board", "CBSE", "ICSE", "NIOS", "IB", "CISCE"]
    },
    "college": {
      "degrees": [
        "Bachelor's of Arts (B.A.)",
        "Bachelor of Science (B.Sc.)",
        "Bachelor of Commerce (B.Com)",
        "Bachelor of Engineering (B.E.)",
        "Bachelor of Technology (B.Tech)",
        "Bachelor of Business Administration (BBA)",
        "Bachelor of Computer Applications (BCA)",
        "Bachelor of Medicine, Bachelor of Surgery (MBBS)",
        "Bachelor of Dental Surgery (BDS)",
        "Bachelor of Pharmacy (B.Pharm)",
        "Bachelor of Architecture (B.Arch)",
        "Bachelor of Design (B.Des)",
        "Bachelor of Fine Arts (BFA)",
        "Bachelor of Hotel Management (BHM)",
        "Master of Arts (M.A.)",
        "Master of Science (M.Sc.)",
        "Master of Commerce (M.Com)",
        "Master of Business Administration (MBA)",
        "Master of Technology (M.Tech)",
        "Master of Computer Applications (MCA)",
        "Master of Social Work (MSW)",
        "Master of Laws (LL.M)",
        "Master of Pharmacy (M.Pharm)",
        "Master of Dental Surgery (MDS)",
        "Master of Design (M.Des)",
        "Master of Fine Arts (MFA)",
        "Master of Public Health (MPH)",
        "Master of Hotel Management (MHM)",
        "Doctor of Philosophy (Ph.D.)",
        "Doctor of Medicine (MD)",
        "Doctor of Dental Surgery (DDS)",
        "Doctor of Science (D.Sc.)",
        "Bachelor of Laws (LL.B)",
        "Chartered Accountancy (CA)",
        "Company Secretary (CS)",
        "Cost and Management Accountancy (CMA)",
        "Certified Public Accountant (CPA)",
        "Bachelor of Education (B.Ed)",
        "Bachelor of Physical Education (B.P.Ed)",
        "Bachelor of Occupational Therapy (B.O.T)",
        "Bachelor of Physiotherapy (B.P.T)",
        "Diploma in Engineering",
        "Diploma in Management (PGDM)",
        "Certificate in Information Technology (CIT)",
        "Diploma in Banking and Finance",
        "Diploma in Hotel Management"
      ]
    }
  },
};

export const feelings = [
  { name: 'Happy', emoji: '😊' },
  { name: 'Sad', emoji: '😢' },
  { name: 'Excited', emoji: '😃' },
  { name: 'Angry', emoji: '😡' },
  { name: 'Surprised', emoji: '😮' },
  { name: 'Confused', emoji: '😕' },
  { name: 'Love', emoji: '❤️' },
  { name: 'Fear', emoji: '😨' },
  { name: 'Calm', emoji: '😌' },
  { name: 'Bored', emoji: '😑' },
  { name: 'Energetic', emoji: '💪' },
  { name: 'Grateful', emoji: '🙏' },
  { name: 'Proud', emoji: '😎' },
  { name: 'Nostalgic', emoji: '🕰️' },
  { name: 'Hopeful', emoji: '🌈' },
  { name: 'Optimistic', emoji: '🌞' },
  { name: 'Apprehensive', emoji: '😬' },
  { name: 'Content', emoji: '😌' },
  { name: 'Amused', emoji: '😄' },
  { name: 'Enthusiastic', emoji: '🤩' },
  { name: 'Frustrated', emoji: '😤' },
  { name: 'Anxious', emoji: '😰' },
  { name: 'Lonely', emoji: '😔' },
  { name: 'Thankful', emoji: '🙌' },
  { name: 'Determined', emoji: '👊' },
  { name: 'Loved', emoji: '💖' },
  { name: 'Intrigued', emoji: '🤔' },
  { name: 'Overwhelmed', emoji: '😵' },
  { name: 'Relieved', emoji: '😌' },
  { name: 'Silly', emoji: '😜' },
  { name: 'Disappointed', emoji: '😞' },
  { name: 'Empowered', emoji: '💪' },
  { name: 'Playful', emoji: '😃' },
  { name: 'Gloomy', emoji: '☁️' },
  { name: 'Adventurous', emoji: '🌍' },
  { name: 'Satisfied', emoji: '😌' },
  { name: 'Doubtful', emoji: '🤨' },
  { name: 'Motivated', emoji: '🚀' },
  { name: 'Guilty', emoji: '😳' },
  { name: 'Disgusted', emoji: '🤢' },
  { name: 'Shocked', emoji: '😱' },
  { name: 'Regretful', emoji: '😔' },
  { name: 'Inspired', emoji: '🌟' },
  { name: 'Curious', emoji: '🤓' },
  { name: 'Tired', emoji: '😴' },
  { name: 'Sassy', emoji: '💁‍♀️' },
  { name: 'Cautious', emoji: '🚦' },
];

export const activityDatas =  [
  {"activity": "Reading", "emoji": "📚"},
  {"activity": "Running", "emoji": "🏃‍♂️"},
  {"activity": "Cooking", "emoji": "🍳"},
  {"activity": "Swimming", "emoji": "🏊‍♀️"},
  {"activity": "Coding", "emoji": "💻"},
  {"activity": "Painting", "emoji": "🎨"},
  {"activity": "Hiking", "emoji": "🥾"},
  {"activity": "Gaming", "emoji": "🎮"},
  {"activity": "Dancing", "emoji": "💃"},
  {"activity": "Photography", "emoji": "📷"},
  {"activity": "Writing", "emoji": "✍️"},
  {"activity": "Meditation", "emoji": "🧘‍♀️"},
  {"activity": "Cycling", "emoji": "🚴‍♂️"},
  {"activity": "Traveling", "emoji": "✈️"},
  {"activity": "Playing Guitar", "emoji": "🎸"},
  {"activity": "Singing", "emoji": "🎤"},
  {"activity": "Fishing", "emoji": "🎣"},
  {"activity": "Gardening", "emoji": "🌷"},
  {"activity": "Watching Movies", "emoji": "🎬"},
  {"activity": "Camping", "emoji": "⛺"},
  {"activity": "Skydiving", "emoji": "🪂"},
  {"activity": "Skiing", "emoji": "⛷️"},
  {"activity": "Surfing", "emoji": "🏄‍♂️"},
  {"activity": "Volunteering", "emoji": "🤝"},
  {"activity": "Listening to Music", "emoji": "🎧"},
  {"activity": "Horseback Riding", "emoji": "🐎"},
  {"activity": "Drawing", "emoji": "✏️"},
  {"activity": "Bowling", "emoji": "🎳"},
  {"activity": "Cooking", "emoji": "🍲"},
  {"activity": "Crafting", "emoji": "🎨"},
  {"activity": "Sailing", "emoji": "⛵"},
  {"activity": "Scuba Diving", "emoji": "🤿"},
  {"activity": "Snorkeling", "emoji": "🤽‍♂️"},
  {"activity": "Rock Climbing", "emoji": "🧗‍♂️"},
  {"activity": "Golfing", "emoji": "⛳"},
  {"activity": "Mountain Biking", "emoji": "🚵‍♂️"},
  {"activity": "Caving", "emoji": "🕳️"},
  {"activity": "Jogging", "emoji": "🏃‍♀️"},
  {"activity": "Horse Riding", "emoji": "🐴"},
  {"activity": "Writing Poetry", "emoji": "✍️"}
];

export const hobbies = [
  {
    name: "Swimming",
    icon: `${BASE_URL_STATIC}/icon/swimming.svg`,
  },
  {
    name: "Dancing",
    icon: `${BASE_URL_STATIC}/icon/dancing.svg`,
  },
  {
    name: "Singing",
    icon: `${BASE_URL_STATIC}/icon/singing.svg`,
  },
  {
    name: "Reading",
    icon: `${BASE_URL_STATIC}/icon/reading.svg`,
  },
  {
    name: "Writing",
    icon: `${BASE_URL_STATIC}/icon/writing.svg`,
  },
  {
    name: "Drawing",
    icon: `${BASE_URL_STATIC}/icon/painting.svg`,
  },
  {
    name: "Cooking",
    icon: `${BASE_URL_STATIC}/icon/cooking.svg`,
  },
  {
    name: "Photography",
    icon: `${BASE_URL_STATIC}/icon/photography.svg`,
  },
  {
    name: "Sewing",
    icon: `${BASE_URL_STATIC}/icon/sewing.svg`,
  },
  {
    name: "Video Games",
    icon: `${BASE_URL_STATIC}/icon/videogame.svg`,
  },
  {
    name: "Gardening",
    icon: `${BASE_URL_STATIC}/icon/gardening.svg`,
  },
  {
    name: "Running",
    icon: `${BASE_URL_STATIC}/icon/running.svg`,
  },
  {
    name: "Hiking",
    icon: `${BASE_URL_STATIC}/icon/hiking.svg`,
  },
  {
    name: "Basketball",
    icon: `${BASE_URL_STATIC}/icon/basketball.svg`,
  },
  {
    name: "Acting",
    icon: `${BASE_URL_STATIC}/icon/acting.svg`,
  },
  {
    name: "Chess",
    icon: `${BASE_URL_STATIC}/icon/chess.svg`,
  },
  {
    name: "Cricket",
    icon: `${BASE_URL_STATIC}/icon/cricket.svg`,
  },
  {
    name: "Guitar",
    icon: `${BASE_URL_STATIC}/icon/guitar.svg`,
  },
  {
    name: "Handicraft",
    icon: `${BASE_URL_STATIC}/icon/handicraft.svg`,
  },
  {
    name: "Boating",
    icon: `${BASE_URL_STATIC}/icon/boating.svg`,
  },
  {
    name: "Hockey",
    icon: `${BASE_URL_STATIC}/icon/hockey.svg`,
  },
];

export const places =
  [
    {
      "place": "Restaurant",
      "icon": <FontAwesomeIcon icon={faUtensils} />

    },
    {
      "place": "Parks and Recreation Areas",
      "icon": <FontAwesomeIcon icon={faTree} />

    },
    {
      "place": "Museums and Cultural Institutions",
      "icon": <FontAwesomeIcon icon={faLandmark} />
    },
    {
      "place": "Tourist Attractions",
      "icon": <FontAwesomeIcon icon={faLandmarkDome} />
    },
    {
      "place": "Historical Sites",
      "icon": <FontAwesomeIcon icon={faMonument} />
    },
    {
      "place": "Beaches",
      "icon": <FontAwesomeIcon icon={faUmbrellaBeach} />
    },
    {
      "place": "Shopping Centers",
      "icon": <FontAwesomeIcon icon={faCartShopping} />
    },
    {
      "place": "Entertainment Venues",
      "icon": <FontAwesomeIcon icon={faFilm} />
    },
    {
      "place": "Religious Sites",
      "icon": <FontAwesomeIcon icon={faAtom} />
    },
    {
      "place": "Natural Wonders",
      "icon": <FontAwesomeIcon icon={faCloudSun} />
    }
];

export const skillsCategory = [
  {
    "category_id": 1,
    "category_name": "Technical Skills",
    "icon": <FontAwesomeIcon icon={faGear} />,
    "skills": [
      "Programming",
      "Database Management",
      "Network Administration",
      "Data Analysis",
      "Software Development",
      "Cybersecurity"
    ]
  },
  {
    "category_id": 2,
    "category_name": "Soft Skills",
    "icon": <FontAwesomeIcon icon={faTty} />,
    "skills": [
      "Communication",
      "Teamwork",
      "Problem Solving",
      "Adaptability",
      "Time Management",
      "Leadership"
    ]
  },
  {
    "category_id": 3,
    "category_name": "Language Skills",
    "icon": <FontAwesomeIcon icon={faHandsAslInterpreting} />,
    "skills": [
      "English",
      "Spanish",
      "French",
      "Mandarin",
      "German",
      "Japanese"
    ]
  },
  {
    "category_id": 4,
    "category_name": "Leadership Skills",
    "icon": <FontAwesomeIcon icon={faChildReaching} />,
    "skills": [
      "Strategic Planning",
      "Conflict Resolution",
      "Decision Making",
      "Team Building",
      "Motivation",
      "Change Management"
    ]
  },
  {
    "category_id": 5,
    "category_name": "Creative Skills",
    "icon": <FontAwesomeIcon icon={faShapes} />,
    "skills": [
      "Graphic Design",
      "Copywriting",
      "Photography",
      "Music Composition",
      "Artistic Drawing",
      "Innovation"
    ]
  }
];

export const socialMedia = [
  {
    name: "instagram",
    icon: <FaInstagram size={20} />
  },
  {
    name: "facebook",
    icon: <FaFacebook size={20} />
  },
  {
    name: "twitter",
    icon: <FaTwitter size={20} />
  },
  {
    name: "linkedin",
    icon: <FaLinkedin size={20} />
  },
  {
    name: "github",
    icon: <FaGithub size={20} />
  },
];

export const profileNav = [
  {
    name: "Basic Info",
    route: "account-profile",
    icon1: `${BASE_URL_STATIC}/icon/web-navigation-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/web-navigation-2.svg`,
    description: "Discover the features of the emilo web"
  },
  {
    name: "Invite & follow contacts/friends",
    route: "invite-follow",
    icon1: `${BASE_URL_STATIC}/icon/invite-follow-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/invite-follow-2.svg`,
    description: "Invite your friends & family"
  },
  {
    name: "Privacy",
    route: "privacy",
    icon1: `${BASE_URL_STATIC}/icon/privacy-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/privacy-1.svg`,
    description: "Change preference who can view your profile"
  },
  {
    name: "Notification",
    route: "notification",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage the notifications where you want to receive or from where you want to receive"
  },
  {
    name: "Blocked & Restricted Users",
    route: "blocked-users",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage the blocked and restricted users"
  },
  {
    name: "Language",
    route: "language",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage your language setting"
  },
  {
    name: "Device And Browser",
    route: "device-browser",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage your device and browser setting"
  },
  {
    name: "Security",
    route: "security",
    icon1: `${BASE_URL_STATIC}/icon/security-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/security-1.svg`,
    description: "Manage your security setting like change password, two factor authentication, login alert and other"
  },
  {
    name: "Ads Preferences",
    route: "ads-preferences",
    icon1: `${BASE_URL_STATIC}/icon/security-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/security-1.svg`,
    description: "Manage your ADS Prefrences."
  },
  {
    name: "Usage Time",
    route: "usage-time",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage your time spending on emilo"
  },
  {
    name: "Suggestion",
    route: "suggestion",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage your suggestion setting"
  },
  {
    name: "Deactivation & Delete",
    route: "deactivation-delete",
    icon1: `${BASE_URL_STATIC}/icon/deactivate-delete-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/deactivate-delete-1.svg`,
    description: "Delete temporarily or permanently your account."
  },
  {
    name: "Cellular/wifi Data",
    route: "cellular-wifi-data",
    icon1: `${BASE_URL_STATIC}/icon/cellular-wifi-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/cellular-wifi-1.svg`,
    description: "Manage data usage and settings related to cellular and wifi"
  },
  {
    name: "Other Setting",
    route: "other",
    icon1: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    icon2: `${BASE_URL_STATIC}/icon/notification-1.svg`,
    description: "Manage your other settings"
  },
];



