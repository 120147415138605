import React,{useContext} from 'react';
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from 'axios';
import { BASEAPIURL } from '../../../../../../config';

const HidePostmessage = ({ids}) => {
 console.log("ids", ids); 


 const {  allDataPost, setallDataPost, Unhide, config} = useContext(ApiContext);
    
 const unhidedata =   Unhide.filter(items => items._id ===  ids);
  console.log("unhidedata", unhidedata)
   

  const handleUnhide = () =>{

    
     const updatedPosts =  allDataPost.map(post => 
        post._id === ids? unhidedata[0] : post
      );
      setallDataPost(updatedPosts)

     axios.post(`${BASEAPIURL}/post/hidepost`, { postId: ids }, config).then(res =>{
        console.log(res.data.status); 
       }).catch(err =>{
        console.log(err); 
     })
    }

    return (
    <div className="container my-5">
      <div className="card">
       
        <div className="card-body text-center">
       
          <p className="card-text">
            This is the content of the post. You can hide or show this post message.
          </p>
          <p  onClick={handleUnhide}  className='my-3'> Report Post</p>
          <p  onClick={handleUnhide}  className='my-3'> Unfollow {unhidedata[0].fname}</p>
          <p  onClick={handleUnhide}  className='my-3'> Mute  {unhidedata[0].fname} </p>
          <p  onClick={handleUnhide}  className='my-3'> Undo</p>
        </div>
      </div>
    </div>
  );
};

export default HidePostmessage;
