import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { data } from "../../../Data/EducationData"
import { BASEAPIURL, BASE_URL_STATIC } from '../../../../../config';
import { profileContext } from '../../../UseContext/ProfileContext';
import { createUserEducation, getEducationDetails } from '../../../UseContext/ApiHelpers';

const EducationDetailPopup = ({ open, close, loggedInUserId }) => {

   const [years, setYears] = useState([])

   const Token = localStorage.getItem('token');
   const { setEducationDetails } = useContext(profileContext)

   const [eduInput, setEduInput] = useState({
      schColl: "",
      instituteName: "",
      degree: "",
      startDate: new Date().getFullYear(),
      endDate: new Date().getFullYear(),
      board: "",
   });
   const [validationMessage, setValidationMessage] = useState(false);
   const [selection, setSelection] = useState('');

   const fetchData = async () => {
      try {
         const res = await fetch(`${BASEAPIURL}/user/myprofile`, {
            method: 'POST',
            headers: {
               Authorization: `Bearer ${Token}`,
            },
            body: JSON.stringify({}),
         });
         const resJson = await res.json();
         return resJson

      } catch (err) {
         console.log(err);
      }
   };

   useEffect(() => {
      const newDate = new Date().getFullYear() + 5;
      const yearsArr = []
      for (let i = 0; i < 60; i++) {
         yearsArr.push(newDate - i)
      }

      setYears(yearsArr)
   }, [])

   useEffect(() => {
      fetchData();
   }, []);

   const handleChange = (e) => {
      setEduInput({ ...eduInput, [e.target.name]: e.target.value });
   };

   async function handleSubmit(e) {
      e.preventDefault();
      try {
         if (eduInput.startDate >= eduInput.endDate) {
            setValidationMessage(true);
            setTimeout(() => {
               setValidationMessage(false);
            }, 2000);
         } 
         else {
            await createUserEducation(eduInput)
               .then(() => {
                  getEducationDetails(loggedInUserId).then((res) => {
                     setEducationDetails(res);
                  });
                  close();
               })
               .catch((error) => {
                  console.log("Error occurred:", error);
               });
         }
      } catch (error) {
         console.log("Error occurred:", error);
      }
   }


   const handleSelectionChange = (e) => {
      setSelection(e.target.value);
      setEduInput({ ...eduInput, schColl: e.target.value });
   };


   return (
      <>
         <section>
            <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
               <Modal.Header closeButton>&nbsp;</Modal.Header>
               <Modal.Body>
                  <form action="POST" onSubmit={handleSubmit}>

                     <h5>Education</h5>

                     <div className="col-lg-11">
                        <span
                           className="d-flex align-items-center"
                           style={{
                              marginRight: '5px',
                              background: '#f5f5f5',
                              padding: '6px',
                              borderRadius: '10px',
                              width: "150px",
                           }}
                        >
                           <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                           <p
                              style={{
                                 fontSize: '15px',
                                 margin: 'auto',
                                 color: '#616161',
                              }}
                           >
                              Education Level
                           </p>
                        </span>
                        <select value={selection} onChange={handleSelectionChange} name='schColl'
                           className="form-control"
                           required
                           style={{
                              fontWeight: '600',
                              border: '0px',
                              borderBottom: '2px solid #a6a6a659',
                              marginTop: '12px',
                           }}
                        >
                           <option value="" disabled>select</option>
                           <option value="school">School</option>
                           <option value="college">College</option>
                           <option value="other">Other</option>
                        </select>
                     </div>

                     <div className="" >
                        <span
                           className="d-flex align-items-center gap-0"
                           style={{
                              margin: "5px 5px 5px 17px",
                              background: '#f5f5f5',
                              padding: '6px',
                              borderRadius: '10px',
                              width: "150px",
                           }}
                        >
                           <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                           <p
                              style={{
                                 fontSize: '15px',
                                 margin: 'auto',
                                 color: '#616161',
                              }}
                           >
                              Institute Name
                           </p>
                        </span>
                        <div className='d-flex flex-column justify-content-start gap-0'>
                           <input type="text" className='border-0 mt-2 w-100 px-4' placeholder='institute name' name='instituteName' onChange={handleChange} required />
                           <hr className='w-100' style={{ background: '#f5f5f5' }} />
                        </div>
                     </div >

                     {selection === 'school' || selection === 'college' ? (
                        <div>
                           {selection === 'school'
                              ?
                              <div>
                                 <span
                                    className="d-flex align-items-center gap-2"
                                    style={{
                                       margin: "5px 5px 5px 17px",
                                       background: "#f5f5f5",
                                       padding: "6px",
                                       borderRadius: "10px",
                                       width: "fit-content",
                                    }}
                                 >
                                    <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                                    <p
                                       style={{
                                          fontSize: '15px',
                                          margin: 'auto',
                                          color: '#616161',
                                       }}
                                    >
                                       Select Standard
                                    </p>
                                 </span>

                                 {/* <select className="form-control" name='degree' onChange={handleChange}
                                    required
                                    style={{
                                       fontWeight: '600',
                                       border: '0px',
                                       borderBottom: '2px solid #a6a6a659',
                                       marginTop: '12px',
                                    }}>
                                    <option value=''>select</option>
                                    {
                                       data.educationSelect.student.standards.map((standard, index) => (
                                          <option key={index}>{standard}</option>
                                       ))
                                    }
                                 </select> */}

                                 <select
                                    className="form-control"
                                    name="degree"
                                    value={eduInput.degree || ""}
                                    onChange={handleChange}
                                    required
                                    style={{
                                       fontWeight: '600',
                                       border: '0px',
                                       borderBottom: '2px solid #a6a6a659',
                                       marginTop: '12px',
                                    }}
                                 >
                                    <option value="" disabled>select</option>
                                    {data.educationSelect.student.standards.map((standard, index) => (
                                       <option key={index} value={standard}>{standard}</option>
                                    ))}
                                 </select>


                                 <span
                                    className="d-flex align-items-center"
                                    style={{
                                       margin: "5px 5px 5px 17px",
                                       background: '#f5f5f5',
                                       padding: '6px',
                                       borderRadius: '10px',
                                       width: "150px",
                                    }}
                                 >
                                    <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                                    <p
                                       style={{
                                          fontSize: '15px',
                                          margin: 'auto',
                                          color: '#616161',
                                       }}
                                    >
                                       Select Boards
                                    </p>
                                 </span>
                                 <select className="form-control" name="board" onChange={handleChange}
                                    required 
                                    style={{
                                       fontWeight: '600',
                                       border: '0px',
                                       borderBottom: '2px solid #a6a6a659',
                                       marginTop: '12px',
                                    }}>
                                    <option value="" >select</option>
                                    {
                                       data.educationSelect.student.boards.map((standard, index) => (
                                          <option key={index}>{standard}</option>
                                       ))
                                    }
                                 </select>
                              </div>
                              :
                              <div>
                                 <span
                                    className="d-flex align-items-center"
                                    style={{
                                       margin: "5px 5px 5px 17px",
                                       background: '#f5f5f5',
                                       padding: '6px',
                                       borderRadius: '10px',
                                       width: "150px",
                                    }}
                                 >
                                    <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                                    <p
                                       style={{
                                          fontSize: '15px',
                                          margin: 'auto',
                                          color: '#616161',
                                       }}
                                    >
                                       Select Degree
                                    </p>
                                 </span>
                                 <select name="degree" className="form-control" id="degree" onChange={handleChange} required
                                 defaultValue=''
                                    style={{
                                       fontWeight: '600',
                                       border: '0px',
                                       borderBottom: '2px solid #a6a6a659',
                                       marginTop: '12px',
                                    }}>
                                    <option value="" >select</option>
                                    {data.educationSelect.college.degrees.map((degree, index) => (
                                       <option key={index}>{degree}</option>
                                    ))}
                                 </select>
                              </div>
                           }
                        </div>
                     ) : null}

                     {selection === "other" && (
                        <div className="mt-3" >
                           <span
                              className="d-flex align-items-center gap-0"
                              style={{
                                 margin: "5px 5px 5px 17px",
                                 background: '#f5f5f5',
                                 padding: '6px',
                                 borderRadius: '10px',
                                 width: "150px",
                              }}
                           >
                              <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                              <p
                                 style={{
                                    fontSize: '15px',
                                    margin: 'auto',
                                    color: '#616161',
                                 }}
                              >
                                 Course
                              </p>
                           </span>
                           <div className='d-flex flex-column justify-content-start gap-0'>
                              <input type="text" className='border-0 mt-2 w-100 px-4' placeholder='course name' name='degree' onChange={handleChange} />
                              <hr className='w-100' style={{ background: '#f5f5f5' }} />
                           </div>
                        </div >
                     )}



                     <div className="" >
                        <span
                           className="d-flex align-items-center gap-0"
                           style={{
                              marginRight: '5px',
                              background: '#f5f5f5',
                              padding: '6px',
                              borderRadius: '10px',
                              width: "80px"
                           }}
                        >
                           <img src={`${BASE_URL_STATIC}/icon/calender.svg`} alt='calender' />

                           <span
                              style={{
                                 fontSize: '15px',
                                 margin: 'auto',
                                 color: "#616161"
                              }}
                           >
                              Date
                           </span>
                        </span>
                        <div className='d-flex justify-content-between align-items-center gap-0'>
                           <div style={{ background: '#d3d3d3', position: 'relative', borderRadius: "30px", padding: "5px 20px", margin: "10px 0px" }}>
                              <label htmlFor=""><small> Start Date</small></label>
                              <select name="startDate" id="" className="date_select" onChange={handleChange} defaultValue={new Date().getFullYear()}>
                                 {years.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                 ))}
                              </select>

                           </div>
                           <div style={{ background: '#d3d3d3', position: 'relative', borderRadius: "30px", padding: "5px 20px", margin: "10px 0px" }}>
                              <label htmlFor=""><small> End Date</small></label>
                              <select name="endDate" className="date_select" onChange={handleChange} id="" defaultValue={new Date().getFullYear()}>
                                 {years.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                 ))}
                              </select>
                           </div>
                        </div>
                     </div>

                     <div className='d-flex justify-content-center'>
                        <button type="submit" className="btn btn-dark mt-4 col-4">Save</button>
                     </div>

                     {validationMessage &&
                        <div className="text-danger my-1 text-center">
                           <small>Start date cannot be greater than end date</small>
                        </div>
                     }

                  </form>
               </Modal.Body>
            </Modal>
         </section>
      </>
   )
}

export default EducationDetailPopup;
