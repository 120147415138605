import { faBowlRice, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import {  getFavFood,  updateFavFood } from '../../../UseContext/ApiHelpers';
import { ApiContext } from '../../../UseContext/ApiCall';
import { profileContext } from '../../../UseContext/ProfileContext';
import toast from 'react-hot-toast';
const FoodEditPopup = ({ open, close, foodObj }) => {

  const [foodName, setfoodName] = useState("")
  const [foodDesc, setfoodDesc] = useState("")
  const [loader, setLoader] = useState(false)

  const { config,loggedInUserId  } = useContext(ApiContext)
  const { setfoodDetails } = useContext(profileContext)


  useEffect(() => {
    setfoodName(foodObj.foodName)
    setfoodDesc(foodObj.foodDetail)
  }, [foodObj])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true)
    if (foodName === "" || foodDesc === "") {
      toast.error("please fill all fields")
      setLoader(false)
    }
    else {
      setLoader(true)
      updateFavFood(foodObj._id, foodName, foodDesc, config).then((res) => {
        getFavFood(loggedInUserId ).then((res) => setfoodDetails(res))
        close()
        setLoader(false)
      }).catch((error) => {
        setLoader(false)
        return null;
      })

    }
  }

  return (
    <div>
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>

          <form onSubmit={handleSubmit}>
            <h5>Favourite Foods</h5>

            <div>
              <p className='fav_movie_1'>
                <span><FontAwesomeIcon icon={faBowlRice} style={{ color: "#bcbec2", }} /></span>
                <span>Food Name</span>
              </p>

              <div className='fav_movie_2'>
                <input defaultValue={foodObj.foodName} type="text" onChange={(e) => setfoodName(e.target.value)} />
              </div>

              <p className='fav_movie_1'>
                <span><FontAwesomeIcon icon={faNewspaper} style={{ color: "#bcbec2", }} /></span>
                <span>About Food</span>
              </p>

              <div className='fav_movie_2'>
                <textarea defaultValue={foodObj.foodDetail} name="" id="" cols="30" rows="4" maxLength={100}
                  onChange={(e) => setfoodDesc(e.target.value)}
                ></textarea>
              </div>

              <div className='text-center'>
                <button type='submit' className='fav_movie_btn'>{loader ? "updating..." : "update"}</button>
              </div>



            </div>

          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default FoodEditPopup