import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from '../../../UseContext/ApiCall';
import { getValue } from '../../../UseContext/SettingHelper';
import { settingContext } from '../../../UseContext/SettingContext';
import TwoFactorPage from './TwoFactorPage';
import SecurityChecks from './SecurityChecks';
import LoginAlert from './LoginAlert';
import SavedLogin from './SavedLogin';
import ChangePassword from '../../../createUser/multiStepSignup/ChangePassword/ChangePassword';

const Security = () => {
  const [FormType, setFormType] = useState('');
  const { value, setValue, handleOptionChange } = useContext(settingContext);

  // To get value
  useEffect(() => {
    getValue('security', config)
    .then(res => {
      // console.log('res : ', res.data.message);
      setValue(prevState => ({
        ...prevState,
        ...res.data.message,
      }));
    })
    .catch(err => {
      console.error('err : ', err);
    });
  }, []);



  const [deviceType, setDeviceType] = useState(null);
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);

  console.log('device type : ', deviceType);
  console.log('location : ', location);
  console.log('address : ', address);

  // To get device type and latitude and longitude
  useEffect(() => {
    // To get device type
    const userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      setDeviceType('Mobile');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceType('Mobile');
    } else if (/Windows Phone/i.test(userAgent)) {
      setDeviceType('Mobile');
    } else if (/iPad/i.test(userAgent)) {
      setDeviceType('Tablet');
    } else if (/tablet/i.test(userAgent)) {
      setDeviceType('Tablet');
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceType('Mac');
    } else {
      setDeviceType('Desktop');
    };

    // To get device latitude and longitude 
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        error => {
          console.error('Error getting location:', error);
          setLocation(null); // Reset location state on error
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setLocation(null); // Reset location state if geolocation is not supported
    };
  }, []);

  // To get address using latitude and longitude
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60`);
        if (!response.ok) {
          throw new Error('Failed to fetch address.');
        };

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          setAddress(data.results[0].formatted_address.split(', ').slice(-3, -1).join(', '));
          // setAddress(data.results[0].formatted_address);
        } else {
          setAddress('Address not found.');
        };
      } catch (error) {
        console.error('Error fetching address:', error);
        setAddress('Error fetching address.');
      }
    };

    if (location) {
      fetchAddress();
    }
  }, [location]);

  function loginAlertIcon(value) {
    let returnValue = "danger";
    if (value.loginAlertApp === 'true') {
      returnValue = "warning";
    }
    if (value.loginAlertEmail === 'true') {
      returnValue = "warning";
    }
    if (value.loginAlertApp === 'true' && value.loginAlertEmail === 'true') {
      returnValue = "info";
    }
    return returnValue;
  }

  const { config } = useContext(ApiContext);

  return (
    <>
      <section className='vh-100 pt-5 px-2 px-sm-3 px-lg-4 px-xl-5 overflow-scroll scrollbar-hidden' id='securityToggle'>  
        <div className='accordion accordion-flush'>
          {/* Breadcrumb */}
          <nav className='pt-4 pt-lg-4'>
            <ol className="breadcrumb bg-transparent p-0">
              <li className="breadcrumb-item"><span className='text-primary'>Setting</span></li>
              <li className="breadcrumb-item active" aria-current="page">Security</li>
            </ol>
          </nav>

          {/* Login Details */}
          <div className='my-3'>
            <div className='shadow-sm accordion-level-1 accordion-button pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#loginDetails">
              {/* <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" /> */}
              <i className="fa fa-users securityIcon text-info"></i>
              <h5 className='fw-semibold ms-2 pt-2'>Save login details for devices</h5>
            </div>
            
            <div id='loginDetails' className="accordion-collapse collapse show" data-bs-parent="#securityToggle">
              <SavedLogin />          
            </div>
          </div>

          {/* Change Password */}
          <div className='my-3'>
            <div
              className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4'
              data-bs-toggle="collapse"
              data-bs-target="#changePassword"
              onClick={() => {
                setFormType('changePassword');
              }}
            >
              {/* <img src={`${BASE_URL_STATIC}/icon/content-and-post.svg`} alt="content-and-post" /> */}
              <i className="fa fa-lock-alt securityIcon text-info"></i>
              <h5 className='fw-semibold ms-2 pt-2'>Change Password</h5>
            </div>

            <div id='changePassword' className="accordion-collapse collapse" data-bs-parent="#securityToggle">
              {/* <Link to={`/signup/change-password`} className='text-secondary'>(Click here to change your password. You should remember your current password.)</Link> */}
              {FormType === "changePassword" ? <ChangePassword /> : 'Loading'}
              {/* <hr className='hr bg-secondary' /> */}
            </div>
          </div>

          {/* Two Factor Authentication */}
          <div className='my-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#twoFactorAuthentication">
              <i className={`fa fa-check-double securityIcon  text-${localStorage.getItem('TwoFactorValue') === '1' ? 'info' : 'danger'}`}></i>
              <h5 className='fw-semibold ms-2 pt-2'>
                Two Factor Authentication
              </h5>
            </div>

            <div id='twoFactorAuthentication' className="accordion-collapse collapse" data-bs-parent="#securityToggle">
              <TwoFactorPage />
              <div className='d-none d-flex-old align-items-center'>

                <span className='w-75 me-auto'>For more secured login, we suggest using Two Factor Authentication you will be sent an OTP to your registered mobile no or email address after entering your Login ID and Password.</span>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='twoFactorAuthentication' 
                    value={value.twoFactorAuthentication === 'true' ? 'false' : 'true'} checked={value.twoFactorAuthentication === 'true'} onChange={(e) => handleOptionChange(e, 'security')}
                  />
                </div>
              </div>
              {/* <hr className='hr bg-secondary' /> */}
            </div>

          </div>

          {/* Recovery Options */}
          <div className='my-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#recoveryOptions">
              <i className="fa fa-shield-check securityIcon text-info"></i>
              <h5 className='fw-semibold ms-2 pt-2'>Security checks</h5>
            </div>

            <div id='recoveryOptions' className="accordion-collapse collapse" data-bs-parent="#securityToggle">
              <div className="card boxShadow rounded-0">
                <div className="card-body">
                  <SecurityChecks value={value} handleOptionChange={handleOptionChange} />
                </div>
              </div>
            </div>
          </div>

          {/* Login Alert */}
          <div className='my-3'>
            <div className='shadow-sm accordion-level-1 accordion-button collapsed pe-3 pe-xl-4' data-bs-toggle="collapse" data-bs-target="#loginAlert">
              <i className={`fa fa-exclamation-triangle securityIcon text-${loginAlertIcon(value)}`}></i>
              <h5 className='fw-semibold ms-2 pt-2'>Login Alert</h5>
            </div>

            <div id='loginAlert' className="accordion-collapse collapse" data-bs-parent="#securityToggle">
              {/* In App Notification */}
                <LoginAlert value={value} handleOptionChange={handleOptionChange} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Security