import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AwsFileUrl } from '../../../../config';
import { editPostDescription } from '../../UseContext/ApiHelpers';
import { ApiContext } from '../../UseContext/ApiCall';

const PostEdit = ({ showPopupEdit, setShowPopupEdit, postsPopupData }) => {
    // State to control the visibility of the modal
    const [description, setDescription] = useState(''); // Default to empty string

    const { config, setallDataPost } = useContext(ApiContext);

    // Update description whenever postsPopupData changes
    useEffect(() => {
        if (postsPopupData && postsPopupData.description) {
            setDescription(postsPopupData.description);
        }
    }, [postsPopupData]); // Runs every time postsPopupData changes

    // Function to handle closing the modal
    const handleClose = () => setShowPopupEdit(false);

    const EditDescription = async () => {
        const editdata = { id: postsPopupData._id, newDescription: description };
        const data = await editPostDescription(editdata, config);

        if (data.status === 1) {
            setallDataPost((prevDescriptions) =>
                prevDescriptions.map((d) =>
                    d._id === postsPopupData._id ? { ...d, description: description } : d
                )
            );
        }

        setShowPopupEdit(false);
    };

    return (
        <div>
            {/* Modal component */}
            <Modal
                show={showPopupEdit}
                onHide={handleClose}
                centered
                backdrop="static"
                keyboard={false}
                className="main-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Edit Post description</Modal.Title>
                </Modal.Header>

                {/* Modal Body */}
                <Modal.Body className="popupModal-body">
                    <form>
                        <div className="form-group">
                            <label htmlFor="postTitle">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="postTitle"
                                placeholder="Enter description"
                                value={description || ''}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        

                       { postsPopupData.length >0 && <div className="form-group mt-3">
                      
                            {
                                postsPopupData.mediaUrls && postsPopupData.mediaUrls.length > 0 ? (
                                    postsPopupData.mediaUrls[0].filetype === "image" ? (
                                        <img
                                            src={AwsFileUrl + postsPopupData.mediaUrls[0].url}
                                            alt={postsPopupData.mediaUrls[0].url}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <video
                                            src={AwsFileUrl + postsPopupData.mediaUrls[0].url}
                                            alt={postsPopupData.mediaUrls[0].url}
                                            style={{ maxWidth: '100vh', maxHeight : "50vh" }}
                                            controls
                                        />
                                    )
                                ) : (
                                    <p>No media available</p>
                                )
                            }
                        </div>}
                    </form>
                </Modal.Body>

                {/* Edit Button */}
                <button className="btn btn-primary text-center mx-4 my-2" onClick={EditDescription}>
                    <i className="bi bi-pencil"></i> Edit Description
                </button>
            </Modal>
        </div>
    );
};

export default PostEdit;
