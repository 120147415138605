// export const BASEAPIURL = "https://emilonode.sjain.io";
// export const BASEURL_LOCAL = "http://localhost:3000";
// export const BASEURL_LIVE = "https://emilorj.sjain.io";
// export const BASEURL = BASEURL_LIVE;
export const gCaptcahSiteKey = "6Lfut2kqAAAAAJt9Y-nkImpo8K_uvUXIXAaGMEJw";

// React Base URL
// const LiveWwwUrl = 'https://emilorj.sjain.io'
const LiveWwwUrl = 'https://dev.emilo.in'
const localWwwUrl = 'http://localhost:3003'
// React Base URL

// Main node JS application
export const ApiVersionCode = '0.0.1';
export const LiveApiUrl = 'https://emilonode.sjain.io';
//  const localApiUrl = 'https://emilonode.sjain.io';  // if you want to use live node Application
const localApiUrl = 'http://localhost:3001';  // if you want to use local node Application
// Main node JS application
const LiveAdminApiUrl = 'https://api.emilo.in/administrator'; 
export const GenerateSecretAdminUrl = `${LiveAdminApiUrl}/api/login/generate-secret`
const LocalAdminApiUrl = 'http://localhost:3005/administrator'; 
// React Image Base URL
// const LiveImageUrl = 'https://demo3.sjainventures.com/e-milo/images';
// const LocalImageUrl = 'https://demo3.sjainventures.com/e-milo/images';

const LiveImageUrl = 'https://s3aws.emilo.in/images';
const LocalImageUrl = 'https://s3aws.emilo.in/images';


//aws base file url
// export const AwsFileUrl = 'https://s3.eu-north-1.amazonaws.com/s3aws.emilo.in/';
export const AwsFileUrl = 'https://s3aws.emilo.in/';

// React Image Base URL

let BaseURLFinal = LiveWwwUrl
let BaseAPIURLFinal = LiveApiUrl
let BaseAdminAPIURLFinal = LiveAdminApiUrl
let BaseImageURLFinal = LiveImageUrl

if (window.location.href.includes(localWwwUrl)) {
  BaseURLFinal = localWwwUrl
  BaseAPIURLFinal = localApiUrl
  BaseImageURLFinal = LocalImageUrl
  BaseAdminAPIURLFinal = LocalAdminApiUrl
};

export const BASEURL = BaseURLFinal
export const BASEAPIURL = BaseAPIURLFinal
export const BASEAdminAPIURL = BaseAdminAPIURLFinal
export const BASE_URL_STATIC = BaseImageURLFinal;

// ----------- Cookie ----------- 
export function setCookie(name, value, days) {
  let expires = "";
  let temp = "";
  const date = new Date();
  if (days) {
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    temp = date.toUTCString();
    expires = `${temp}`;
  }
  // document.cookie = name + '=' + (value || '') + expires + '; path=/';
  console.log(`${name}=${value || ""}; expires=${expires}; path=/;`);
  document.cookie = `${name}=${value || ""}; expires=${expires}; path=/;`;
};

export function getCookie(name) {
  const nameEQ = `${name}=`;
  let i = 0;
  let c = 0;
  const ca = document.cookie.split(";");
  for (i = 0; i < ca.length; i = +1) {
    c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export function RemoveCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function headerUserAPI (value = {}) {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    device: localStorage.getItem('deviceDetail') ? localStorage.getItem('deviceDetail') : 'unknown',
    userip: localStorage.getItem('deviceIp') ? localStorage.getItem('deviceIp') : 0,
    devicetype: localStorage.getItem('deviceOs') ? localStorage.getItem('deviceOs') : 'unknown',
    locuserlong: localStorage.getItem('LocUserLong') ? localStorage.getItem('LocUserLong') : 0,
    locuserlat: localStorage.getItem('LocUserLat') ? localStorage.getItem('LocUserLat') : 0,
    locusermsg: localStorage.getItem('LocUserMsg') ? localStorage.getItem('LocUserMsg') : 'NA',
    locusercity: localStorage.getItem('localityCity') ? localStorage.getItem('localityCity') : 'unknown',
    ...value
  }
}

export async function LogoutUser() {
  localStorage.clear();
  window.location.assign(`${BASEURL}/login`);
}

export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatSecondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = '';
  if (hours > 0) formattedTime += `${hours.toFixed(0)} hours, `;
  formattedTime += `${minutes.toFixed(0)} minutes`;
  
  if (remainingSeconds > 0) {
    formattedTime += `, ${remainingSeconds.toFixed(0)} seconds`;
  }

  return formattedTime;
}

export function formatedDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" }); // Oct, Nov, etc.
  const weekday = date.toLocaleString("default", { weekday: "long" }); // Monday, Tuesday, etc.

  // Get the correct suffix for the day
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // Covers 4th to 20th
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  const dayWithSuffix = day + getDaySuffix(day);

  return `${dayWithSuffix} ${month} (${weekday})`;
}

export function formatedDateTime(dateString) {
  const date = new Date(dateString);

  // Get the day with suffix
  const day = date.getDate();
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };
  const dayWithSuffix = day + getDaySuffix(day);

  // Get formatted month and year
  const monthYear = date.toLocaleDateString("default", {
    month: "short",
    year: "2-digit",
  });

  // Get formatted time
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${dayWithSuffix} ${monthYear} (${time})`;
}

export function showUserIP(userIP) {
  let returnValue = "NA";
  if (userIP && userIP !== null && userIP !== '' && userIP !== '0') {
    returnValue = `<a target='_BLANK' class="p-0" href='https://whatismyipaddress.com/ip/${userIP}'>${userIP}</a>`;
  }
  // returnValue = userIP;
  return returnValue;
}

export function showUserLatLong(lat, long) {
  let returnValue = "NA";
  if (lat && lat !== null && lat !== '' && lat !== '0' && long && long !== null && long !== '' && long !== '0') {
    const theURL = `https://www.google.com/maps?q=${lat},${long}`;;
    returnValue = `<a target='_BLANK' class="p-0" href='${theURL}'>${lat},${long}</a>`;
  }
  // returnValue = `${lat},${long}`;
  return returnValue;
}

export async function convertS3UrlToBlobUrl(url) {
  try {
    // Fetch the file from the S3 URL
    const response = await fetch(url);
    // Check if the request was successful
    if (!response.ok) throw new Error(`Failed to fetch file from ${url}`);
    // Convert the response to a blob
    const blob = await response.blob();
    // Create a blob URL
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  } catch (error) {
    console.error('Error creating blob URL:', error);
    return 'https://s3aws.emilo.in/error.png';
  }
}
