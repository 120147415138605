import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { ApiContext } from '../UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../config';
function DeleteProfileData({ delOpen, delClose, onDelete, entityId, fetchData }) {

   const [loader, setLoader] = useState(false)

   const { config } = useContext(ApiContext)

   const handleDelete = () => {
      setLoader(true)
      if (entityId && onDelete && fetchData) {
         onDelete(entityId, config).then(() => {
            fetchData(); delClose();
         });
      }
      setLoader(false)
   };

   return (
      <Modal
         show={delOpen}
         onHide={delClose}
         backdrop="static"
         keyboard={false}
         centered
         aria-labelledby="contained-modal-title-vcenter"
      >
         <Modal.Body>

            <div className='d-flex flex-column align-items-center gap-4'>
               <div>
                  <img src={`${BASE_URL_STATIC}/icon/confirm-delete.svg`} alt="" />
               </div>

               <div>
                  <h4 className='text-center'><b>Confirm Delete</b></h4>
                  <p className='text-center mt-2 fs-15'>Are you sure you want to delete this item ?</p>
               </div>

               <div className='d-flex justify-content-between align-items-center' style={{width : "80%"}}>
                  <Button className='col-5 del-cancel' onClick={delClose}>
                     Cancel
                  </Button>
                  <Button className='col-5 del-confim' onClick={handleDelete}>
                     {loader ?
                        <div className='del_pop'>
                           <Spinner animation="border" role="status">
                           </Spinner>
                        </div>
                        : "Delete"}
                  </Button>
               </div>

            </div>

         </Modal.Body>
      </Modal>
   );
}

export default DeleteProfileData;
