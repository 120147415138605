import React, { useState, useEffect, useContext } from 'react'
import { ApiContext } from '../../UseContext/ApiCall';
import {  AwsFileUrl } from '../../../../config';
import {  GetuserFavorite } from '../../UseContext/ApiHelpers';
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';
const FavouriteReels = () => {

  const [favouriteReels, setFavouriteReels] = useState([]);
  
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [mainpoastData , setmainpoastData] = useState('')
  

  const { config } = useContext(ApiContext);

  // To get favourite reels
  const getFavouriteReels = (config) => {
   GetuserFavorite("flix", config)
    .then(res => {
      console.log("res", res);
      setFavouriteReels(res);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    getFavouriteReels(config);
  }, []);

  
  return (
    <>
      {/* Profile Reel Popup */}
      <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />

      <section className='favourite-reels'>
        <h1 className='favourite-reels-head'>Flix</h1>

        <div className='favourite-reels-imgs'>
          {
            favouriteReels && favouriteReels.map((favouriteReel, index) => (
             
               <img className='favourite-reels-img' src={AwsFileUrl+(favouriteReel.mediaUrls[0].thumbnail || favouriteReel.postTumbnailUrl)} alt={favouriteReel.tumbnailId} onClick={() =>{setShowPostsPopup(!showPostsPopup); setmainpoastData(favouriteReel);  setPostId(favouriteReel._id)}}  height={315} width={200}  />
            ))
          }
        </div>
      </section> 
    </>
  )
}

export default FavouriteReels
