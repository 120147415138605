import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';


const ViewProductPopup = () => {

  const {
    showViewProductPopup, setShowViewProductPopup,
    product, setProduct,
    deleteProduct,
    setProductImages,
  } = useContext(businessContext);

  // handle popup close
  const handlePopupClose = () => {
    setShowViewProductPopup(false);

    setProductImages([]);

    setProduct({
      images: [],
      title: '',
      description: '',
      price: '',
      link: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };

  return (
    <>
      <Modal show={showViewProductPopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Product</h4>

          {/* Images */}
          <div className='mb-3'>
            {
              product && product.images && product.images.length > 0 
              && 
              <Slider className="product-images-slider" {...settings}>
                {/* Live Images */}
                {
                  product.images.map((value, index) => (
                    <div className='border rounded-3' key={index}>
                      <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={product.title} placeholder='Title...' />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={product.description} placeholder='Description...'></textarea>

          {/* Price */}
          <small className='text-secondary'>Price</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='price' value={product.price} placeholder='Price...' />

          {/* Buy Now Link */}
          <small className='text-secondary'>Buy Now Link</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-3' name='link' value={product.link} placeholder='Price...' />

          {/* Key Product */}
          <div class="form-check float-none mb-5">
            <input type="checkbox" class="form-check-input" id="keyProduct" checked={product.keyProduct === 'true'} />
            <label class="form-check-label" htmlFor="keyProduct">Key Product</label>
          </div>

          {/* Delete */}
          <div className='text-center'>
            <button type="button" className='btn btn-danger w-50 rounded-pill' onClick={deleteProduct}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewProductPopup
