import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';
import { businessContext } from '../../../UseContext/BusinessContext';


const ViewJobPopup = () => {

  const {
    showViewJobPopup, setShowViewJobPopup,
    job, setJob,
    deleteJob,
    setJobImages,
  } = useContext(businessContext);

  // handle popup close
  const handlePopupClose = () => {
    setShowViewJobPopup(false);

    setJobImages([]);

    setJob({
      images: [],
      title: '',
      description: '',
      price: '',
      link: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };

  return (
    <>
      <Modal show={showViewJobPopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Product</h4>

          {/* Images */}
          <div className='mb-3'>
            {
              job && job.images && job.images.length > 0 
              && 
              <Slider className="job-images-slider" {...settings}>
                {/* Live Images */}
                {
                  job.images.map((value, index) => (
                    <div className='border rounded-3' key={index}>
                      <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          {/* Title */}
          <small className='text-secondary'>Title</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='title' value={job.title} placeholder='Title...' />

          {/* Description */}
          <small className='text-secondary'>Description</small>
          <textarea class="form-control border-0 border-bottom shadow-none mb-4" name='description' value={job.description} placeholder='Description...'></textarea>

          {/* Salary */}
          <small className='text-secondary'>Salary</small>
          <input type="text" className='form-control border-0 border-bottom shadow-none mb-4' name='salary' value={job.salary} placeholder='Salary...' />

          {/* Experience */}
          <small className='text-secondary'>Experience</small>
          <select className="form-select border-0 border-bottom shadow-none mb-4" name='experience' value={job.experience}>
            <option value="fresher">Fresher</option>
            <option value="expert">Expert</option>
            <option value="pro">Pro</option>
          </select>

          {/* Delete */}
          <div className='text-center'>
            <button type="button" className='btn btn-danger w-50 rounded-pill' onClick={deleteJob}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewJobPopup
