import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getHangoutDetails, hangoutDetail } from "../../../UseContext/ApiHelpers";
import { profileContext } from "../../../UseContext/ProfileContext";
import { BASE_URL_STATIC } from "../../../../../config";
import GoogleMapApi from "../../../Button/GoogleMapApi";
import toast from "react-hot-toast";

const MosthangoutsPopup = ({ open, close,loggedInUserId }) => {
  const { setHangoutDetails } = useContext(profileContext);
  const [selectedLocation, setSelectedLocation] = useState('')
 
  const places = [
    {
      place: "Parks and Recreation Areas",
    },
    {
      place: "Restaurant",
    },
    {
      place: "Museums and Cultural Institutions",
    },
    {
      place: "Tourist Attractions",
    },
    {
      place: "Historical Sites",
    },
    {
      place: "Beaches",
    },
    {
      place: "Shopping Centers",
    },
    {
      place: "Entertainment Venues",
    },
    {
      place: "Religious Sites",
    },
    {
      place: "Natural Wonders",
    },
  ];

  const [input, setInput] = useState({ location: "", place: "", type: "" });

  function handleChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if(input.type===''){
      toast.error("please select place type")
    }
   else if(!input.location){
      toast.error("please select location")
    }

    else{
      await hangoutDetail(input).then(() =>{
        getHangoutDetails(loggedInUserId).then((res) => setHangoutDetails(res))
        close()
      });
    }
  };

  return (
    <>
      <section>
        <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body>
           <form onSubmit={handleSubmit}>
              <h5 >Most Hangout Places</h5>

              <div className="">
                <span
                  className="d-flex align-content-center"
                  style={{
                    margin: "10px 0px",
                    background: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "10px",
                    width: "110px",
                  }}
                >
                  <img alt="" src={`${BASE_URL_STATIC}/icon/location.svg`} />

                  <p
                    style={{
                      fontSize: "15px",
                      margin: "auto",
                      color: "#616161",
                    }}
                  >
                    Place type
                  </p>
                </span>

                <div className="col-12">
                  <select
                    className="form-control"
                    name="type"
                    defaultValue={input.type}
                    onChange={handleChange}
                    required
                    style={{
                      fontWeight: "600",
                      border: "0px",
                      borderBottom: "2px solid #a6a6a659",
                      marginTop: "12px",
                    }}
                  >
                    <option value='' disabled
                      className=""
                      style={{ textAlign: "center", color: "#616161" }}
                    >
                      --select--
                    </option>

                    {places.map((e) => (
                      <option key={e.place} value={e.value}>
                        {e.place}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <GoogleMapApi selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} input={input} setInput={setInput}  />

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-dark mt-4 col-lg-4">Save</button>
              </div>

              </form>

          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default MosthangoutsPopup;